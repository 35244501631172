import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Modal,
  Stack,
  TableFooter,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import pdfImage from "../images/pdfImage.png";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import SpeedDial from "@mui/material/SpeedDial";

import SpeedDialAction from "@mui/material/SpeedDialAction";

import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";

import TableRow from "@mui/material/TableRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';

import {
  DownloadPDF,
  assetDetailsData,
  deleteAsset,
} from "../redux/nbmData/action";

import UpdateReport from "./UpdateReport";


const InnerImageBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));


const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "300px",
}));

const InnerWarrantyImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //   border:'6px solid red',

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));


const InnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

const WarrantyInnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //    border:'2px solid black',
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));



const ImageCard = styled(Box)(({ theme }) => ({
  width: "159.84px",
  height: "155px",
}));

const WarrantyImageCard = styled(Box)(({ theme }) => ({
  width: "159px",
  height: "145px",
  //  border:"3px solid black"
}));


const QrBox = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const TextBox = styled(Box)(({ theme }) => ({
  margin: "5px",
}));


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));


const SplitCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: "2",
  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const MainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between", 
  gap: "15px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));







function AssetDetailPage() {
  const updateReportResponse = useSelector((store) => store.data.updateReports);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [image, setImage] = useState([]);
  const [status, setStatus] = useState(false);
  const [reportImage, setReportImage] = useState([]);
  const [reportImageName, setReportImageName] = useState([]);

  const [zoomImageUrl, setZoomImageUrl] = useState("");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [hidden, setHidden] = React.useState(false);
  const [certificateImage, setCertificateImage] = useState([]);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const params = useParams();
  const dispatch = useDispatch();
  const detailData = useSelector((store) => store.data.assetDetails);

  const [openImage, setOpenImage] = useState(false);

  const handleImageOpen = (url) => {
    setZoomImageUrl(url);
    setOpenImage(true);
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const text = "Asset";
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handlePrevious = () => {
    setCount((prevCount) => {
      if (prevCount === 0) {
        return image?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNext = () => {
    setCount((prevCount) => {
      if (prevCount === image?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleReportPrevious = () => {
    setCount3((prevCount) => {
      if (prevCount === 0) {
        return reportImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleReportNext = () => {
    setCount3((prevCount) => {
      if (prevCount === reportImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handlePreviousCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === 0) {
        return certificateImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNextCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === certificateImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleDelete = () => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
    };

    dispatch(deleteAsset(data, token));
    navigate(-1);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch, updateReportResponse]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.target = "_blank";
    link.download = "downloaded_image.jpg";
    link.click();
  };

  useEffect(() => {
    if (detailData?.imgs) {
      setImage(detailData?.imgs);
      setCertificateImage(detailData?.wimg);
    }
    if (detailData?.reportimgs) {
      setReportImage(detailData?.reportimgs);
    }
    if (detailData?.reportnames) {
      setReportImageName(detailData?.reportnames);
    }
    // setReportImageName
  }, [detailData]);

  const handleClick = () => {
    localStorage.setItem("incidentId", detailData?.incidid);
  };

  const handleDownloadPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";

      const imageX = (pdfWidth - 30) / 2;

      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);

      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;

      pdf.save(`${name}.pdf`);
    };

    img.src = pdfData;
  };

  const handlePrintPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";
      const imageX = (pdfWidth - 30) / 2;
      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);
      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;
      pdf.text(name, pdfWidth / 2, nameY, { align: "center" });
      pdf.text(code, pdfWidth / 2, codeY, { align: "center" });

      const pdfBlob = pdf.output("blob");

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const newWindow = window.open(pdfUrl);

      newWindow.onload = function () {
        newWindow.print();

        URL.revokeObjectURL(pdfUrl);
      };
    };

    img.src = pdfData;
  };

  const handleDownloadPDF2 = (
    logoUrl,
    pdfData,
    name,
    code,
    brand,
    calibdate,
    calibduedate,
    calibmnth,
    calibreq,
    calibstatus,
    cmcamc,
    cmcamcdue,
    cmcamcstart,
    dept,
    dop,
    imgs,
    wimg,
    serialno,
    incidstatus,
    lifeyrs,
    modelno,
    price,
    servstatus,
    soldby,
    status
  ) => {
    if (!pdfData || !logoUrl) return;

    const pdf = new jsPDF();
    const img = new Image();
    img.src = logoUrl;
    img.onload = function () {
      const desiredHeight = 30;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const lineWidth = 0.9 * pageWidth;
      pdf.setFontSize(22);
      pdf.setTextColor("#FF731D");
      const xPosition = (pageWidth - lineWidth) / 2;
      const yPosition = 10;
      const logoYPosition = 10;

      let yTop = yPosition + 7;
      const logoLineY = yPosition + 22; // Adjust this value to move the line above the table
      pdf.setLineWidth(1);
      pdf.setDrawColor("1b76bc");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.hospname, lineWidth / 2.3, yTop);

      pdf.setFontSize(10);
      pdf.setTextColor("#1746A2");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.city, lineWidth / 2, yTop + 7);
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      const parameterData = [
        ["Name", name],
        ["Code", code],
        ["Brand", brand],
        ["Department", dept],
        ["Status", status],
        ["Calibration Date", calibdate],
        ["Calibration Status", calibstatus],
        ["Useful Life", lifeyrs],
        ["Model No", modelno],
        ["AMC/CMC", cmcamc],
        ["Purchase Date", dop],
        ["Price", price],
        ["Sold by", soldby],
        ["Incident Status", incidstatus],
        ["Service Status", servstatus],
        ["CMC/AMC Start", cmcamcstart],
        ["CMC/AMC Due", cmcamcdue],
        ["Calibration Month", calibmnth],
        ["Calibration Due Date", calibduedate],
        ["Calibration Request", calibreq],
      ];

      pdf.autoTable({
        body: parameterData,
        startY: 40,
        headStyles: {
          fillColor: [26, 188, 156],
          textColor: [255, 255, 255],
        },
      });

      const contentWidth = pdf.internal.pageSize.getWidth();
      const qrCodeX = contentWidth - 70;
      const qrCodeY = pdf.autoTable.previous.finalY + 10; // Position it below the table

      const assetImageX = contentWidth - 70;
      const assetImageY = qrCodeY + 40;

      pdf.addImage(pdfData, "JPEG", qrCodeX, qrCodeY, 25, 25);
      pdf.setTextColor("1b76bc");
      pdf.text("QR Code", qrCodeX, qrCodeY);
      let imageX = assetImageX;
      let imageY = assetImageY + 5;
      const imageWidth = 18;
      const imageHeight = 18;

      pdf.setFontSize(14);
      pdf.setLineWidth(0.5);
      pdf.setTextColor("1b76bc");
      pdf.text("Asset Image", assetImageX, assetImageY + 2);

      for (let i = 0; i < imgs.length; i++) {
        const { imgurl } = imgs[i];
        pdf.setDrawColor("1b76bc");
        pdf.setLineWidth(0.5);

        pdf.rect(imageX, imageY, imageWidth, imageHeight);
        pdf.addImage(imgurl, "JPEG", imageX, imageY, imageWidth, imageHeight);

        imageX += imageWidth + 3;
        if (imageX > contentWidth - imageWidth - 3) {
          imageX = assetImageX;
          imageY += imageHeight + 3;
        }
      }

      if (wimg !== "NA" && wimg.length > 0) {
        const warrantyImageWidth = 18;
        const warrantyImageHeight = 18;
        let warrantyImageX = 20;
        let warrantyImageY = pdf.autoTable.previous.finalY + 10;

        const warrantyImageHeadingX = 20;
        const warrantyImageHeadingY = pdf.autoTable.previous.finalY + 15;

        pdf.setFontSize(14);
        pdf.setLineWidth(0.5);
        pdf.setTextColor("1b76bc");
        pdf.text(
          "Warranty Images",
          warrantyImageHeadingX,
          warrantyImageHeadingY - 7
        );

        for (let i = 0; i < wimg.length; i++) {
          const { imgurl } = wimg[i];

          // Check if imgurl is a PDF
          if (imgurl.toLowerCase().includes(".pdf")) {
            // Handle PDF images if needed
          } else {
            pdf.setDrawColor("1b76bc");
            pdf.setLineWidth(0.5);
            pdf.rect(
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );

            pdf.addImage(
              imgurl,
              "JPEG",
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );
            warrantyImageY += warrantyImageHeight + 3;
          }
        }
      }

      pdf.save(`${name}.pdf`);
    };

    // Set the source of the image to the logo data
    img.src = logoUrl;
  };

  const shareViaWhatsApp = () => {
    const text = `*Asset Details*\n\n*Page URL:* https://vajra.software${window.location.pathname}\n\n*Name:* ${detailData.name}\n*Code:* ${detailData.code}\n*Serial No:* ${detailData.serialno}\n*Model No:* ${detailData.modelno}\n*Department:* ${detailData.dept}\n*Type:* ${detailData.type}\n*Status:* ${detailData.status}\n*Brand:* ${detailData.brand}\n*Sold By:* ${detailData.soldby}\n*Date of Purchase:* ${detailData.dop}\n*Price:* ${detailData.price}\n\n*Warranty Start Date:* ${detailData.wrntdate}\n*Warranty End Date:* ${detailData.wrntexpdate}\n\n*Calibration Status:* ${detailData.calibstatus}\n*Calibration Date:* ${detailData.calibdate}\n*Calibration Due Date:* ${detailData.calibduedate}\n\n*AMC/CMC:* ${detailData.cmcamc}\n*AMC/CMC Start Date:* ${detailData.cmcamcstart}\n*AMC/CMC End Date:* ${detailData.cmcamcdue}\n\n`;

    const whatsappLink = `https://wa.me?text=${encodeURIComponent(text)}`;
    window.open(whatsappLink, "_blank");
  };
  const AssetDetail = {
    Code: detailData.code,
    "Serial No.": detailData.serialno,
    Department: detailData.dept,
    Status: detailData.status,
  };
  const Specifications = {
    Type: detailData.type,
    "Model Number": detailData.modelno,
    "Useful life ": detailData.lifeyrs,
    "Warranty Expiry": detailData.wrntexpdate,
    "AMC/CMC": detailData.cmcamc,

    Calibration: detailData.calibreq,
    Date: detailData.calibdate,
    "Due Date": detailData.calibduedate,
  };
  const Purchase = {
    Price: detailData.price,
    Date: detailData.dop,
    "Seller Brand ": detailData.brand,
    Representative: detailData.soldby,
    "Seller Contact": detailData.sellermobile,
    "Seller Email ID ": detailData.selleremail,
  };



const handleOpenService = ()=>{
  setStatus(true)
}


const handleCloseService = ()=>{
  setStatus(false)
}


const handleNavigate = (requested,assetId) => {
  navigate(`/service-request/${assetId}?requested=${requested}`);
};



  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", 
        padding:'10px'
      }}>
      {/* New Box on top with two nested boxes in row */}
      <MainWrapper>
        <Stack sx={{ flex: "2", gap: "20px" }}>
          {/* <div
            style={{
              padding: "8px",
              background: "white",
            }}>
            Department
          </div> */}
          <SplitCard>
            <Stack sx={{ flex: "1", gap: "20px" }}>
              {/* <Stack sx={{  height: "400px" }}> */}
              <Stack
                sx={{
                  flex: "1",

                  padding: "10px",
                  bgcolor: "white",
                }}>
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                    textAlign: "start",
                  }}>
                  <Label variant="subtitle1">{detailData.name}</Label>
                  <Divider />
                  <Box sx={{ width: "100%" }}>
                    <InnerBorderBoxBox>
                      <InnerImageBox>
                        <IconButton onClick={handlePrevious}>
                          <KeyboardBackspaceIcon style={{ color: "#4690FF" }} />
                        </IconButton>
                        <ImageCard
                          as="img"
                          src={`${image[count]?.imgurl}`}
                          alt="check"
                          onClick={() => handleImageOpen(image[count]?.imgurl)}
                        />
                        <IconButton onClick={handleNext}>
                          <EastIcon style={{ color: "#4690FF" }} />
                        </IconButton>
                      </InnerImageBox>
                      <Tooltip title="Download">
                        <IconButton
                          onClick={() => handleDownload(image[count]?.imgurl)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </InnerBorderBoxBox>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    width: "100%",
                    gap: "10px",
                    textAlign: "start",
                  }}>
                  <Label variant="subtitle1">Working</Label>
                  <Divider />
                  <Stack
                    sx={{
                      width: "100%",

                      height: "300px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <QrBox
                      as="img"
                      src={detailData?.qrimg}
                      onClick={() =>
                        handleImageOpen(detailData?.qrimg)
                      }></QrBox>

                    <Box
                      sx={{
                        position: "relative",
                        ml: "-90px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <StyledSpeedDial
                        ariaLabel="SpeedDial playground example"
                        hidden={hidden}
                        icon={<DownloadIcon />}
                        direction={"right"}
                        sx={{
                          height: "25px",
                          "& .MuiFab-primary": {
                            "&:hover": { backgroundColor: "transparent" },
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                        }}
                        style={{ background: "transparent" }}>
                        <SpeedDialAction
                          onClick={() =>
                            handleDownloadPDF(
                              detailData?.qrimg,
                              detailData?.code,
                              detailData?.name
                            )
                          }
                          icon={<SaveIcon />}
                          tooltipTitle={"Save"}
                        />
                        <SpeedDialAction
                          onClick={() =>
                            handlePrintPDF(
                              detailData?.qrimg,
                              detailData?.code,
                              detailData?.name
                            )
                          }
                          icon={<PrintIcon />}
                          tooltipTitle={"Print"}
                        />
                      </StyledSpeedDial>
                    </Box>

                    <TextBox>
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap:"10px"
                        }}>
                        Scan QR code to get asset info
                        <SaveIcon
                          onClick={() =>
                            handleDownloadPDF(
                              detailData?.qrimg,
                              detailData?.code,
                              detailData?.name
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                        <PrintIcon
                          onClick={() =>
                            handlePrintPDF(
                              detailData?.qrimg,
                              detailData?.code,
                              detailData?.name
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Typography>
                    </TextBox>
                  </Stack>
                </Stack>
              </Stack>
             
              <Stack sx={{ height: "300px" }}>
                <>
                  <Stack
                    sx={{
                      flex: "1",

                      padding: "10px",
                      bgcolor: "white",
                    }}>
                    <Stack
                      sx={{
                        width: "100%",
                        gap: "10px",
                        textAlign: "start",
                      }}>
                      <Label variant="subtitle1">Warranty</Label>
                      <Divider />
                      <Stack
                        sx={{
                          width: "100%",

                          gap: "10px",
                        }}>
                        {detailData?.wimg !== "NA" ? (
                          <Stack sx={{ bgcolor: "white", height: "100%" }}>
                            <WarrantyInnerBorderBoxBox>
                              <InnerWarrantyImageBox>
                                <IconButton onClick={handlePreviousCertificate}>
                                  <KeyboardBackspaceIcon
                                    style={{ color: "#4690FF" }}
                                  />
                                </IconButton>

                                <WarrantyImageCard
                                  as="img"
                                  src={`${
                                    certificateImage[count2]?.imgid
                                      ?.split(".")
                                      ?.pop() === "pdf"
                                      ? pdfImage
                                      : certificateImage[count2]?.imgurl
                                  }`}
                                  alt="check"
                                  onClick={() =>
                                    handleImageOpen(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }
                                />
                                <IconButton onClick={handleNextCertificate}>
                                  <EastIcon
                                    style={{ color: "#4690FF" }}
                                  />
                                </IconButton>
                              </InnerWarrantyImageBox>
                              <Stack
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  marginTop: "20px",
                                }}>
                                <Typography>Warranty Certificate</Typography>
                                <IconButton
                                  onClick={() =>
                                    handleDownload(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }>
                                  <DownloadIcon />
                                </IconButton>
                              </Stack>
                            </WarrantyInnerBorderBoxBox>
                           
                          </Stack>
                        ) : (
                          <Stack
                            sx={{
                              height: "150px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            No warranty
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              </Stack>
            </Stack>
            <Stack
              sx={{
                flex: "1",

                padding: "10px",
                bgcolor: "white",

                justifyContent: "space-between",
                gap: "20px",
              }}>
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",

                  height: "200px",
                }}>
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(AssetDetail).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",
                }}>
                <Label variant="subtitle1">Specifications</Label>
                <Divider />
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(Specifications).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",

                  height: "280px",
                }}>
                <Label variant="subtitle1">Purchase</Label>
                <Divider />
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(Purchase).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
            </Stack>
          </SplitCard>
        </Stack>

        <Stack sx={{ flex: "1", gap: "20px" }}>
          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}>
              <Label variant="subtitle1">Incident History</Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {detailData?.incident_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.incident_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {item?.cleardate}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    No incident recorded
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  {detailData?.incidstatus !== "Open" && (
                    <Link to={`/record-incident/${params.id}`}>
                      <Button
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Add Incident
                      </Button>
                    </Link>
                  )}

                  {detailData?.incidstatus === "Open" && (
                    <Link
                      // to={`/clear-incident/${params.id}`}
                      to={`/incident-list/${detailData?.incidid}`}>
                      <Button
                        onClick={handleClick}
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Clear Incident
                      </Button>
                    </Link>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flex: "1",
              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}>
              <Label variant="subtitle1">Service History</Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {detailData?.service_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.service_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {item?.servstatus}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                  No service history
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
              
                {detailData?.servstatus ==="NA" && 
                    <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    sx={{ borderRadius: "22px ", width: "130px" }}>
                    Add Service
                  </Button>}

                  {detailData?.servstatus ==="Open" && 
                    <Button
                    onClick={()=>navigate(`/service-list/${detailData.servid}`)}
                    variant="contained"
                    sx={{ borderRadius: "22px ", width: "130px" }}>
                    Clear Service
                  </Button>}
                 
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}>
              <Label variant="subtitle1">Report</Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {reportImage?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {reportImage?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {reportImageName[index]}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() => handleDownload(item?.imgurl)}
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    No reports uploaded yet
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                 
                  <UpdateReport assetId={params.id} />
                </Stack>



                {/* {reportImage?.length === 0 && (
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    bgcolor: "white",
                    display: "flex",
                    alignItems: "center",

                    borderRadius: "40px",
                    height: "50px",
                  }}>
                  <SubTitle>Report</SubTitle>
                  <UpdateReport assetId={params.id} />
                </Box>
              )} */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </MainWrapper>



      <div style={{}}>
        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description">
          <ModalBox sx={{ bgcolor: "background.paper" }}>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",

                textAlign: "center",
                alignItems: "center",
              }}>
              <Label variant="h6">Request services</Label>

              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>

             
              <Stack gap={"10px"} sx={{ width: "90%" }}>
                <Label textAlign={"start"}>Choose Service</Label>
                <Stack
                  flexDirection={"row"}
                  gap={"10px"}
                  textAlign={"start"}
                  justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Medpick",params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}>
                    Medpick
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Others",params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}>
                    Others
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </ModalBox>
        </Modal>
      </div>




    </Box>
  );
}

export default AssetDetailPage;
