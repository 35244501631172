import React from "react";
import { Modal, Typography, Button, Box } from "@mui/material";

const ErrorModal = ({ open }) => {
  const onClose = () => {};

  return (
    <Modal open={false} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: "20px",
          minWidth: "300px",
          textAlign: "center",
        }}>
        <Typography color="red" variant="h5">
          Error
        </Typography>
        <Typography color="red" variant="body1">
          Oops, something went wrong. <br /> Please try later.{" "}
        </Typography>
        <Box>
          <Button
            size="medium"
            sx={{ width: "120px", margin: "auto", textTransform: "none" }}
            variant="contained"
            onClick={onClose}>
            Okay
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default ErrorModal;
