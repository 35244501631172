import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import csvFile from "../csv/asset.csv";
import csvImage from "../images/csvImage.PNG";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  csvFileUpload,
  csvFileUploadSubmit,
  deleteCsvFileUpload,
} from "../redux/nbmData/action";
import SuccessModal from "../components/SuccessModal";
import { v4 as uuid } from "uuid";

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DetailContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: "20px",
  gap: "20px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "30px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // flexDirection:"row",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  gap: "20px",
  marginBottom: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",

  width: "80%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  // width: "385px",
  display: "flex",
  gap: "30px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "380px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  paddingBottom: "40px",
  marginTop: "2%",
  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    // border: "1px solid green",
  },
}));

const Image = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "300px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    height: "100px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const ImageBottom = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gap: "10px",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const Download = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  textTransform: "none",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Upload = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  textTransform: "none",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Statement = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "2%",
  marginBottom: "2%",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const BottomSet = styled(Box)(({ theme }) => ({
  width: "100%",
  gap: "50px",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

export const BulkUploadAsset = () => {
  const response = useSelector((store) => store.data.csvFileUploadSubmit);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  let ids = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSubmit, setShowSubmit] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const text = "CSV file uploaded";

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);

    navigate(-1);
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files).slice(0, 3); // Allow up to 3 files
    setFiles(selectedFiles);

    const parsedData = [];
    let hasError = false;

    for (const file of selectedFiles) {
      const fileType = getFileType(file);
      if (fileType !== "csv") {
        setError(`Please upload csv file`);
        hasError = true;
        continue; // Skip processing this file and move to the next
      }

      try {
        const dataUrl = await readFileAsDataUrl(file);
        parsedData.push({ filename: file.name, dataUrl, type: "csv" });
        setData({ filename: file.name, dataUrl, type: "csv" });
      } catch (error) {
        setError(`Error parsing ${file.name}: ${error.message}`);
        hasError = true;
      }
    }

    if (!hasError) {
      setError(""); // Clear any previous errors
    }

    parsedData.forEach(async (fileData) => {
      let datas = {
        hospid: ids.hospid,
        userid: ids.userid,
        csvfileid: `${"csvid_"}` + uuid() + `.${fileData.type}`,
        csvfile: fileData.dataUrl,
        branchid: userBranch.id,
      };

      setShowSubmit(true);
      setCsvData(datas);
      dispatch(csvFileUpload(datas));
    });
  };

  // Helper function to get the file type
  const getFileType = (file) => {
    return file.name.split(".").pop().toLowerCase();
  };

  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataUrl = event.target.result;
        resolve(dataUrl);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleDelete = (id) => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      fileid: id,
      branchid: userBranch.id,
    };
    setToggle(false);
    setShowSubmit(false);
    dispatch(deleteCsvFileUpload(data));

    setFiles(files?.filter((el) => el.name !== id));
  };

  const handleSave = () => {
    if (files.length === 0) {
      setError("Please upload at least one CSV file.");
    } else {
      setError("");
      let datas = {
        hospid: ids.hospid,
        userid: ids.userid,
        hospname: ids.hospname,
        city: ids.city,
        username: ids.username,
        plan: ids.subs_plan,
        email: ids.email,
        mobile: ids.mobile,
        file_id: csvData.csvfileid,
        filetype: "Asset",
        branchid: userBranch.id,
      };
      setToggle(true);

      dispatch(csvFileUploadSubmit(datas));
    }
  };

  const handleDownload = async () => {
    const response = await fetch(csvFile);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "asset.csv";
    anchor.click();

    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (response === true && toggle === true && files.length !== 0) {
      setOpenModal(true);
    }
  }, [response]);
  return (
    <Wrapper>
      <TopBox>
        <TitleHead> Import Assets</TitleHead>
      </TopBox>
      <Main>
        <>
          <TopContainer>
            <DetailContainer sx={{ bgcolor: "white" }}>
              <Image as="img" src={csvImage} />
              <ImageBottom sx={{ textAlign: "start" }}>
                <Statement>Don't have CSV file ? click here to</Statement>

                <Download
                  endIcon={<DownloadIcon />}
                  sx={{ bgcolor: "#1746A2" }}
                  variant="contained"
                  onClick={handleDownload}>
                  Download
                </Download>
              </ImageBottom>
            </DetailContainer>

            <UploadContainer>
              <div>
                {!showSubmit ? (
                  <>
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                      id="fileInput"
                      multiple
                    />
                    <label htmlFor="fileInput">
                      <Upload
                        variant="contained"
                        startIcon={<FileUploadIcon />}
                        component="span"
                        sx={{
                          bgcolor: "#1746A2",
                        }}>
                        Upload CSV
                      </Upload>
                    </label>
                  </>
                ) : (
                  <>
                    {files.length > 0 && (
                      <Typography
                        variant="body1"
                        style={{
                          margin: "10px 0",
                          fontWeight: "bold",
                        }}></Typography>
                    )}
                    <BottomSet sx={{ bgcolor: "white" }}>
                      {files?.map((file) => (
                        <Paper
                          sx={{ height: "40px", padding: "5px" }}
                          key={file.name}
                          elevation={3}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1" style={{ flexGrow: 1 }}>
                              {/* {file.name} */}
                              file.csv
                            </Typography>
                            <IconButton
                              color="error"
                              size="small"
                              style={{ marginLeft: "10px" }}>
                              <Close onClick={() => handleDelete(file.name)} />
                            </IconButton>
                          </div>
                        </Paper>
                      ))}

                      <Upload
                        sx={{
                          bgcolor: "#1746A2",
                        }}
                        variant="contained"
                        onClick={handleSave}>
                        Submit
                      </Upload>
                    </BottomSet>
                  </>
                )}
              </div>
            </UploadContainer>
          </TopContainer>
          {error && (
            <Typography variant="body1" style={{ color: "red" }}>
              {error}
            </Typography>
          )}
          <ButtonBox>
            {/* <Button
            size="large"
            sx={{
              bgcolor: "#1746A2",
              width: "150px",
              height: "50px",
              borderRadius: "30px",
            }}
            variant="contained"
            onClick={handleSave}>
            <Typography variant="h6">Submit</Typography>
          </Button> */}
          </ButtonBox>
        </>

        <SuccessModal
          openModal={response}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          response={response}
          text={text}
          toggle={toggle}
        />
      </Main>
    </Wrapper>
  );
};
