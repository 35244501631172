import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Divider, Stack, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";

import DoneIcon from '@mui/icons-material/Done';
import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
const style = {
  bgcolor: "background.paper",
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "330px",
}));

export default function SuccessModal({
  openModal,
  handleCloseModal,
  response,
  text,
  toggle,
}) {
  const navigate = useNavigate();
  return (
    <div style={{}}>
      <Modal
        keepMounted
        open={openModal && toggle}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
            }}>
            <IconButton style={{ color: "rgba(0, 201, 32, 1)" }}>
              <DoneIcon size={60} />
            </IconButton>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              New Department added!
            </Typography>
            <Typography variant="caption" style={{ fontWeight: 600 }}>
              You can now add new assets to the <br /> newly created department.
            </Typography>
            <Stack sx={{ width: "90%" }}>
              <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
            </Stack>

            <Button
              variant="contained"
              onClick={() => navigate("/add-asset")}
              sx={{
                borderRadius: "35px",

                margin: "auto",
                textTransform: "none",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}
              size="large">
              Add Asset
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </div>
  );
}
