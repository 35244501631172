import React from "react";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Home from "../pages/Home";
import Plans from "../pages/Plans";

import HomeContact from "../components/HomeContact";
import TermsAndService from "../components/TermsAndService";
import PrivacyPolicy from "../components/PrivacyPolicy";
import ServiceSupport from "../components/ServiceSupport";
import FAQ from "../components/FAQ";
import Disclaimer from "../components/Disclaimer";
import SignUpPage from "../components/SignUpPage";
import ForgotPasswordPage from "../components/ForgotPasswordPage";
import SignInPage from "../components/SignInPage";
import RegisterPage from "../components/RegisterPage";
import SellOnlineList from "../pages/SellOnlineList";

function PublicRoute() {
  return (
    <>
      <Routes>
        <Route path="*" element={<SignInPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/how-vajra-works" element={<Home />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<HomeContact />} />
        <Route path="/terms-and-conditions" element={<TermsAndService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route
          path="/biomedical-services-support"
          element={<ServiceSupport />}
        />
        <Route path="/sell-online-list" element={<SellOnlineList />} />
      </Routes>
    </>
  );
}

export default PublicRoute;
