import React, { useEffect, useRef } from "react";
import { logOut } from "../redux/data/action";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";

const TimeOut = () => {
  const timeout = 45 * 60 * 1000;
  const [flag, setFlag] = useState(false);
  const inactivityTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"))
  const handleActivity = () => {
    clearTimeout(inactivityTimeoutRef.current);
    inactivityTimeoutRef.current = setTimeout(() => {
      let data = {
        userid: ids.userid,
      };
      dispatch(logOut(data));

      localStorage.clear();
      sessionStorage.clear();

      navigate("/signin");
      sessionStorage.setItem("TimeOut", true);
      setFlag(true);
    }, timeout);
  };

  useEffect(() => {
    // Add event listeners to track user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("touchstart", handleActivity);
    window.addEventListener("touchmove", handleActivity);

    inactivityTimeoutRef.current = setTimeout(() => {
      let data = {
        userid: ids.userid,
      };
      dispatch(logOut(data));

      localStorage.clear();
      sessionStorage.clear();

      navigate("/signin");
      sessionStorage.setItem("TimeOut", true);
    }, timeout);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.addEventListener("touchstart", handleActivity);
      window.addEventListener("touchmove", handleActivity);
      clearTimeout(inactivityTimeoutRef.current);
    };
  }, []);

  return <></>;
};

export default TimeOut;
