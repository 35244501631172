import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  Autocomplete,
  TextField,
  Modal,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import { DownloadPDF, incidentData, retrieveAsset } from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));


const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  paddingBottom:'10px'
}));


const InnerContainer = styled(Box)(({ theme }) => ({
   display:"flex",
   justifyContent:"space-between",
  width:'100%',
  alignItems:"stretch",
  gap:"10px",

 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {
  gridTemplateColumns:'repeat(3,1fr)',
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));







const IncidentListPage = ({handleDataChange}) => {
  const incidentList = useSelector((store) => store?.data?.incidentData);
  
  const allAssets = useSelector((store) => store.data.retrieveAsset);
  const navigate = useNavigate();
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const [getAllData, setGetAllData] = useState([]);
  const [getId, setGetId] = useState("");
  const [assetNameError, setAssetNameError] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };




  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(incidentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(incidentList)?.length>0) {
      setData(incidentList?.incidents);
      let data = {
        incidentReported:incidentList?.incid_report_count,
        checkin:incidentList?.checkin_count,
        serviceOpen:incidentList?.serv_tot_count
      }
      setDataCount(data)
    }
  }, [incidentList]);



  const handleAssetName = (event, value) => {
    if (value) {
      const selectedAsset = getAllData?.find((asset) => {
        const fullName = `${asset.name} - ${asset.assetcode}`;
        return fullName === value;
      });

      setGetId(selectedAsset.assetid);
      setAssetNameError("")
      console.log("selectedAsset", selectedAsset);
    }
  };



  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(retrieveAsset(data, token));
  }, [dispatch]);



  useEffect(() => {
    if (allAssets && allAssets?.length !== 0) {
      setGetAllData(allAssets);
    }
  }, [allAssets]);


  const handleNavigate = ()=>{

if(getId!==""){
  navigate(`/record-incident/${getId}`)
}else{
  setAssetNameError("Please select the asset.")
}

  
  }

// console.log("allAssets",allAssets)
// console.log("getAllData",getAllData)


useEffect(()=>{
  if(handleDataChange!==undefined){
    let data2 = {
      incident:filteredData?.length,
      checkin:29,
      checkout:6,
      services:32
    }
      handleDataChange(data2)
  }

},[filteredData])

const handleDownload = (pdf,title)=>{
    dispatch(DownloadPDF(pdf,title))
}

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px">


<Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}>
          <Label variant="h6">
            Incident.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          <IconButton>
            <AddCircleIcon
              size={35}
              style={{ color: "#4690FF" }}
              onClick={handleOpenModal}
            />
          </IconButton>
        </Stack>


      <InnerContainer sx={{display: { xs: "none", sm: "none", lg: "flex" } }}>

     



        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label  variant="subtitle1" sx={{ color: "#4690FF" }}>
             {dataCount.incidentReported}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
             
              sx={{ width: "100%" }}>
              <Label sx={{whiteSpace: 'normal', wordWrap: 'break-word',}} variant="subtitle2">Incident reported</Label>

              <ArrowRightAltIcon onClick={() => navigate("/open-incident")} />
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
            {dataCount?.checkin}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label sx={{whiteSpace: 'normal', wordWrap: 'break-word',}} variant="subtitle2">Check In</Label>

              <ArrowRightAltIcon
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/check-in")}
              />
            </Stack>
          </Stack>
        </Box>

        
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
            {dataCount.serviceOpen}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label sx={{whiteSpace: 'normal', wordWrap: 'break-word',}} variant="subtitle2">Services open</Label>

              <ArrowRightAltIcon
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/service-list")}
              />
            </Stack>
          </Stack>
        </Box>
      </InnerContainer>

      <Box display="flex" flexDirection="column" gap="10px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px">
          <Stack
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              display:{ xs: "none", sm: "none", lg: "flex" }
            }}>
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}>{` ${data?.length}`}</Typography>
          </Stack>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="dept"
              Filter2="incid_status"
              FilterName1="Department"
              FilterName2="Status"
              sortDate="incidcleardate"
              dueDate=""
              name="assetname"
              csvName="All Assets"
            />
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 250px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    "&:hover": {
                      backgroundColor: "rgba(255, 237, 237, 1)",
                    },
                  }}>
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>

                  <TableHeading>Department</TableHeading>

                  <TableHeading>Date</TableHeading>

                  <TableHeading>Total Downtime</TableHeading>
                  <TableHeading
                    sx={{
                      textAlign: "center",
                    }}>
                    Status
                  </TableHeading>

                  <TableHeading
                    sx={{
                      textAlign: "center",
                    }}>
                    Download
                  </TableHeading>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "scroll" }}>
                {filteredData?.map((item, index) => (
                  <TableRow
                    key={item?.incidid}
                    
                    sx={{
                      "&:hover": {
                        bgcolor: "#EEF5FF",
                      },
                      textDecoration: "none",
                    }}>
                    <TableCells component={Link}
                    to={`/incident-list/${item?.incidid}`}>
                      <Tooltip title={item.assetname}>{item.assetname}</Tooltip>
                    </TableCells>

                    <TableCells component={Link}
                    to={`/incident-list/${item?.incidid}`}>
                      <Tooltip title={item.assetcode}>{item.assetcode}</Tooltip>
                    </TableCells>

                    <TableCells component={Link}
                    to={`/incident-list/${item?.incidid}`}>
                      <Tooltip title={item.dept}>{item.dept}</Tooltip>
                    </TableCells>

                    <TableCells component={Link}
                    to={`/incident-list/${item?.incidid}`}>
                      {" "}
                      {item.incidcleardate === item?.incidcleartime ? (
                        <>NA</>
                      ) : (
                        <>
                          <>{item?.incidcleardate}</>@
                          <>{item?.incidcleartime}</>
                        </>
                      )}
                    </TableCells >

                    <TableCells component={Link}
                    to={`/incident-list/${item?.incidid}`}>{item.downtime}</TableCells>
                    <TableCells
                    component={Link}
                    to={`/incident-list/${item?.incidid}`}
                      sx={{
                        textAlign: "center",

                        color: "rgba(70, 144, 255, 1)",
                      }}>
                      {item?.incid_status === "Open" ? (
                        <Button
                          disabled={userAccess?.role === "Guest"}
                          size="small"
                          sx={{
                            borderRadius: "35px",
                            textTransform: "none",
                            width: "100px",
                            height: "25px",
                            bgcolor: "rgba(255, 107, 0, 1)",
                            "&:hover": {
                              bgcolor: "rgba(255, 107, 0, 1)",
                            },
                          }}
                          variant="contained">
                          Open
                        </Button>
                      ) : (
                        <>Cleared</>
                      )}
                    </TableCells>


                    <TableCells sx={{
                      textAlign: "center",
                    }}> {item?.incid_status === "Open" ? <></> : <IconButton onClick={()=>handleDownload(item?.pdfurl,(`Incident_report_${item?.assetname}_${item?.incidcleardate}`))}>
                      <DownloadIcon sx={{color:'black'}} />
                      </IconButton>}
                      </TableCells>

                  </TableRow>
                ))}

                
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            sx={{
              width: "100%",
              padding: "10px",
              bgcolor: "white",
              gap: "20px",
            }}>
            <Divider />
            <Stack
              sx={{
                bgcolor: "white",
                alignItems: "flex-end",

                justifyContent: "column",
              }}>
              <Button
              onClick={handleOpenModal}
                variant="contained"
                sx={{
                  borderRadius: "22px",
                  background: "rgba(70, 144, 255, 1)",
                  marginRight: "20px",
                }}>
                Add Incident <AddIcon />
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>



      <div style={{}}>
        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description">
          <ModalBox sx={{ bgcolor: "background.paper" }}>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}>
              <Label variant="h6">Record Incident</Label>

              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>

              <Stack gap={"10px"}>
                <Label textAlign={"start"}>Choose Equipment</Label>
                <Stack flexDirection={"column"} gap={"10px"} textAlign={"start"} >
                  <Autocomplete
                    id="free-solo-demo"
                    onChange={handleAssetName}
                    placeholder="Asset Name"
                    options={getAllData?.map(
                      (option) => `${option.name} - ${option.assetcode}`
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Asset Name"
                        sx={{
                          width: "300px",
                          background: "rgba(244, 246, 246, 1)",
                          "& fieldset": { border: "none" },
                        }}
                      />
                    )}
                  />
                  <FormHelperText>
                    {assetNameError}
                  </FormHelperText>
                </Stack>
              </Stack>
              <Stack gap={"10px"} sx={{ width: "90%" }}>
                <Label textAlign={"start"}>Record incident</Label>
                <Stack
                  flexDirection={"row"}
                  gap={"10px"}
                  textAlign={"start"}
                  justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    onClick={handleNavigate}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}>
                    Submit
                  </Button>

                
                </Stack>
              </Stack>
            </Stack>
          </ModalBox>
        </Modal>
      </div>



    </Box>
  );
};

export default IncidentListPage;
