import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  IconButton,
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { DownloadPDF, checkIn, checkInOut } from "../redux/data/action";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

import AddVendor from "./AddVendor";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const CheckInPage = () => {
  const checkInOutData = useSelector((store) => store.data.checkInOutData);
  const checkInData = useSelector((store) => store.data.checkInData);
  const navigate = useNavigate();
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [showMovementBox, setShowMovementBox] = useState(false);

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
    };
    dispatch(checkInOut(data, token));
  }, [dispatch, checkInData]);

  useEffect(() => {
    if (checkInOutData && checkInOutData?.length > 0) {
      setData(checkInOutData);
    }
  }, [checkInOutData]);
  console.log("checkInOutData", checkInOutData);

  const handleCloseDepart = () => {
    setShowMovementBox(false);
  };
  const handleClick = (checkId) => {
    handleSubmit(checkId);
  };
  const handleSubmit = (checkId) => {
    let data = {
      hospid: ids.hospid,

      checkid: checkId.checkid,
      assetid: checkId?.assetid,
      userid: ids.userid,
      username: ids.username,
      userrole: userAccess.role,
      branchid: userBranch.id,
    };
    dispatch(checkIn(data));
  };
  return (
    <Stack sx={{padding:'10px'}}>




<Stack
              justifyContent="space-between"
              flexDirection={"row"}
              alignItems={"center"}
              sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}>
              <Label variant="h6">
                Check in.
                <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
              </Label>
              <IconButton onClick={() => navigate("/check-out")}>
                <AddCircleIcon size={35} style={{ color: "#4690FF" }} />
              </IconButton>
            </Stack>




      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        gap="10px"
        height="85vh"
        width={"100%"}>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          style={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px">
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                display: { xs: "none", sm: "none", lg: "flex" }, 
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}>
              <Typography>{`Total : ${data?.length}`}</Typography>
            </Stack>
            <Box flex="2">
              <SortSearchFilter
                data={data}
                setData={setData}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                heading={"My Orders"}
                Filter1="status"
                Filter2=""
                sortPrice="checkoutdate"
                sortDate="checkindate"
                name="assetname"
              />
            </Box>
          </Box>
          <Stack
            style={{
              width: "100%",
              height: "100%",

              bgcolor: "white",
            }}
            overflow="auto"
            flexDirection={"column"}>
            <TableContainer
              component={Paper}
              style={{
                height: "100%",

                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}>
              <Table stickyHeader>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableHeading> Asset Name</TableHeading>
                    <TableHeading> Asset Code</TableHeading>
                    <TableHeading>Department</TableHeading>
                    <TableHeading> Checked Out</TableHeading>

                    <TableHeading> Checked In</TableHeading>
                    <TableHeading> User</TableHeading>
                    <TableHeading>Status</TableHeading>
                    <TableHeading>Pdf</TableHeading>
                  </TableRow>
                </TableHead>

                <TableBody style={{ overflowY: "scroll" }}>
                  {filteredData.map((item, index) => (
                    <TableRow>
                      <TableCells
                        component={Link}
                        to={`/checkin-checkout-details/${item.checkid}`}
                        sx={{ textDecoration: "none" }}>
                        <Tooltip title={item.assetname}>
                          {item.assetname}
                        </Tooltip>
                      </TableCells>

                      <TableCells>
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>
                      <TableCells>
                        <Tooltip title={item.depart}>{item.depart}</Tooltip>
                      </TableCells>

                      <TableCells>
                        {item.checkoutdate}
                        <br />
                        {item.checkouttime}
                      </TableCells>

                      {item.checkindate !== item?.checkintime ? (
                        <TableCells
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          <></>
                          {item.checkindate} <br /> {item.checkintime}
                        </TableCells>
                      ) : (
                        <TableCells
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          NA
                        </TableCells>
                      )}
                      {item.checkin_username === "NA" ? (
                        <TableCells
                          style={{ whiteSpace: "nowrap" }}
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          {" "}
                          {item.checkout_username}
                        </TableCells>
                      ) : (
                        <TableCells
                          component={Link}
                          to={`/checkin-checkout-details/${item.checkid}`}>
                          {item.checkin_username}
                        </TableCells>
                      )}

                      {item.status === "check_out" ? (
                        <TableCells sx={{ textAlign: "center" }}>
                          <Box
                            onClick={() => handleClick(item)}
                            sx={{
                              borderRadius: "30px",
                              margin: "auto",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              height: "30px",
                              padding: "4px",

                              width: "100px",
                              bgcolor: "rgba(255, 107, 0, 1)",

                              border: "1px solid rgba(255, 107, 0, 1)",

                              color: "white",
                            }}>
                            <Typography variant="caption" display="block">
                              Check in
                            </Typography>
                          </Box>
                        </TableCells>
                      ) : (
                        <TableCells
                          sx={{ textAlign: "center" }}
                          style={{ whiteSpace: "nowrap" }}>
                          <Box
                            sx={{
                              cursor: "pointer",
                              borderRadius: "30px",
                              margin: "auto",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                              height: "30px",
                              width: "100px",

                              color: " rgba(70, 144, 255, 1)",
                            }}>
                            <Typography variant="caption" display="block">
                              Completed
                            </Typography>
                          </Box>
                        </TableCells>
                      )}
                      {item.status === "check_out" ? (
                        <TableCells>
                          <Tooltip
                            title="Check in asset to get pdf"
                            placement="top"
                            sx={{}}>
                            <IconButton></IconButton>
                          </Tooltip>
                        </TableCells>
                      ) : (
                        <TableCells>
                          <IconButton>
                            <DownloadIcon
                              onClick={() =>
                                dispatch(
                                  DownloadPDF(item?.report_url, item?.assetname)
                                )
                              }
                              style={{
                                fontSize: "20px",
                                color: " #FF731D",
                              }}
                            />
                          </IconButton>
                        </TableCells>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {!showMovementBox && (
              <Stack
                sx={{
                  width: "100%",
                  padding: "20px",
                  bgcolor: "white",
                  gap: "20px",
                }}>
                <Divider />
                <Stack
                  sx={{
                    bgcolor: "white",
                    display: { xs: "none", sm: "none", lg: "flex" },
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}>
                  <Stack sx={{ flexDirection: "row",  }}>
                    <Button
                      onClick={() => navigate("/vendor")}
                      variant="contained"
                      sx={{
                        borderRadius: "22px",
                        background: "rgba(70, 144, 255, 1)",
                        marginRight: "20px",
                        width: "170px",
                      }}>
                      Vendors
                    </Button>
                    <Button
                      onClick={() => navigate("/service-company")}
                      variant="contained"
                      sx={{
                        borderRadius: "22px",
                        background: "rgba(70, 144, 255, 1)",
                        marginRight: "20px",
                        width: "170px",
                      }}>
                      Services
                    </Button>
                  </Stack>
                  <Button
                    onClick={() => navigate("/check-out")}
                    variant="contained"
                    sx={{
                      borderRadius: "22px",
                      background: "rgba(70, 144, 255, 1)",
                      marginRight: "20px",
                      width: "170px",
                    }}>
                    Add Checkout <AddIcon />
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        {showMovementBox && <AddVendor handleCloseDepart={handleCloseDepart} />}
      </Box>
    </Stack>
  );
};

export default CheckInPage;
