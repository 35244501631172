import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import theme from "./Theam";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import DocumentMeta from "react-document-meta";
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
const meta = {
  title: "Vajra - Solution for Smooth Hospital Asset Management",
  description:
    "Manage the capital assets of your hospital with a single click to streamline operations.It helps you categorise materials based on their usefulness and any other standards you decide to monitor.",

  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "Asset management App,Incident management App,Asset Maintenance App,Preventive Maintenance App",
    },
  },
};
root.render(
  <DocumentMeta {...meta}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <ScrollToTop />
          <App />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </DocumentMeta>
);

reportWebVitals();
