import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "350px", lg: "350px", md: "300px", sm: "300px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function AddUserModal({
  openAddUser,
  handleCloseUser,
  addedStatus,
  toggle,
}) {

  return (
    <div>
      <Modal
        keepMounted
        open={addedStatus?.message !== undefined && toggle}
        onClose={handleCloseUser}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          {addedStatus?.message === "Created" && (
            <Typography
              sx={{
                mt: 2,
                color: addedStatus?.success ? "green" : "red",
                textAlign: "center",
              }}>
              User Added!
            </Typography>
          ) } { addedStatus?.success === false && (
            <Typography
              sx={{
                mt: 2,
                color: addedStatus?.success ? "green" : "red",
                textAlign: "center",
              }}>
              {addedStatus?.message}
            </Typography>
          )}
          {addedStatus?.success ? (
            <Box
              sx={{
                color: "red",
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                marginTop: "20px",
              }}>
              <Typography sx={{ mt: 2, color: "green", textAlign: "center" }}>
                User Added Successfully. Enter the user's mobile number as login
                password.
              </Typography>
              <Button
                variant="contained"
                onClick={handleCloseUser}
                sx={{
                  borderRadius: "35px",

                  margin: "auto",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                size="large">
                Done
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10px",
              }}>
              <Button
                variant="contained"
                onClick={handleCloseUser}
                sx={{
                  borderRadius: "35px",
                  margin: "auto",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                size="large">
                Done
              </Button>
            </Box>
          )}
        </ModalBox>
      </Modal>
    </div>
  );
}
