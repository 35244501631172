import { Box } from "@mui/material";
import React from "react";

import playstore from "../images/google.png";
import appstore from "../images/apple.png";
import { Link } from "react-router-dom";
import icon2 from "../images/icon2.png";

import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styled from "@emotion/styled";
function Footer() {
  const FooterFont = styled(Box)(({ theme }) => ({
    "&:hover": { transform: "scale3d(1.05, 1.05, 2)" },
    fontWeight: "400",
    fontSize: "18px",
    textAlign: "start",

    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const FooterHeading = styled(Box)(({ theme }) => ({
    fontWeight: "600",
    fontSize: "24px",
    color: "#1746A2",

    [theme.breakpoints.down("xl")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  }));

  const FooterBox = styled(Box)(({ theme }) => ({
    bgcolor: "#EFF8FF",
    // width: "100%",
    height: "270px",
    alignItems: "center",
    display: "flex",
    padding: "20px",
    justifyContent: "space-evenly",
   

    [theme.breakpoints.down("xl")]: {
      flexDirection: "row",
      height: "190px",
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",

      height: "250px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "830px",
    },

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  }));

  const BetaBox = styled(Box)(({ theme }) => ({
    width: "297px",
    height: "242px",
    alignItems: "start",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",

    textAlign: "start",
    [theme.breakpoints.down("xl")]: {
      height: "200px",
    },
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {},

    [theme.breakpoints.down("xs")]: {},
  }));

  const BetaBox3 = styled(Box)(({ theme }) => ({
    width: "297px",
    height: "242px",
    alignItems: "start",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",

    textAlign: "start",
    [theme.breakpoints.down("xl")]: {
      height: "200px",
    },
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "160px",
      width: "90%",
    },

    [theme.breakpoints.down("xs")]: {},
  }));
  const BetaBox1 = styled(Box)(({ theme }) => ({
    width: "297px",
    height: "242px",
    alignItems: "start",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",

    textAlign: "start",
    [theme.breakpoints.down("xl")]: {
      height: "200px",
    },
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "120px",
      width: "90%",
    },

    [theme.breakpoints.down("xs")]: {},
  }));
  const ImgBox = styled(Box)(({ theme }) => ({
    width: "60%",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},

    [theme.breakpoints.down("xs")]: {},
  }));
  const BetaBox2 = styled(Box)(({ theme }) => ({
    width: "297px",
    height: "242px",
    alignItems: "center",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",

    textAlign: "start",
    [theme.breakpoints.down("xl")]: {
      height: "200px",
    },
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
      height: "150px",
    },

    [theme.breakpoints.down("xs")]: {},
  }));

  return (
    <FooterBox sx={{ bgcolor: "#EFF8FF" }}>
      <BetaBox1 sx={{}}>
        <a
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            padding: 0,
          }}
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.medpick.vajra&pli=1">
          <ImgBox as="img" src={playstore} alt="playstore" />
        </a>
        <a
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
          }}
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/us/app/vajra/id1671968878">
          <ImgBox as="img" src={appstore} alt="appstore" />
        </a>
      </BetaBox1>
      <BetaBox>
        <FooterHeading>About Us</FooterHeading>
        <FooterFont>
          <Link
            to="/terms-and-conditions"
            style={{ textDecoration: "none", color: "black" }}>
            Terms and Conditions
          </Link>
        </FooterFont>
        <FooterFont>
          <Link
            to="/privacy-policy"
            style={{ textDecoration: "none", color: "black" }}>
            Privacy Policy
          </Link>
        </FooterFont>
        <FooterFont>
          <Link
            to="/biomedical-services-support"
            style={{ textDecoration: "none", color: "black" }}>
            Biomedical Services and Support
          </Link>{" "}
        </FooterFont>
        <FooterFont>
          <Link
            to="/disclaimer"
            style={{ textDecoration: "none", color: "black" }}>
            Disclaimer
          </Link>
        </FooterFont>
        <FooterFont>
          <Link to="/faq" style={{ textDecoration: "none", color: "black" }}>
            FAQ
          </Link>
        </FooterFont>
        <FooterFont>
          <Link
            to="/contact-us"
            style={{ textDecoration: "none", color: "black" }}>
            Contact
          </Link>
        </FooterFont>
      </BetaBox>
      <BetaBox3>
        <FooterHeading>Contact</FooterHeading>
        <FooterFont>
          2086,9th Cross RPC Layout, Vijayanagar,Bangalore - 560040
        </FooterFont>
        <FooterFont>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <PhoneIcon /> &nbsp;(+91)89715-80265
          </Box>
        </FooterFont>
        <FooterFont>
          <Link
            to="mailto:contact@vajra.software"
            style={{ textDecoration: "none", color: "black" }}
            target="_blank">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <MailOutlineIcon />
              &nbsp;contact@vajra.software{" "}
            </Box>
          </Link>
        </FooterFont>
      </BetaBox3>
      <BetaBox2>
        <FooterHeading>Our Services</FooterHeading>
        <Box sx={{ marginTop: "4%" }}>
          <Link
            to="https://play.google.com/store/apps/details?id=com.medpick.asthra&hl=en-IN"
            style={{ textDecoration: "none", color: "black" }}
            target="_blank">
            <Box
              as="img"
              sx={{ width: { lg: "70px", sm: "70px" } }}
              src={icon2}
              alt="icon2"
            />
          </Link>
        </Box>
      </BetaBox2>
    </FooterBox>
  );
}

export default Footer;
