import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { warrantyStatus } from "../redux/nbmData/action";
import empty from "../images/empty.png";
import UpperContainer from "../components/UpperContainer";
import NoData from "../components/NoData";
import WarrantyRequestModal from "../nbmComponents/WarrantyRequestModal";
import TableSort from "../components/TableSort";
import FilterMenu from "../components/FilterMenu";
import FilterListIcon from "@mui/icons-material/FilterList";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  minHeight: "400px",

  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#F4F6F6",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const WarrantyStatus = () => {
  const warrantyStatusData = useSelector(
    (store) => store.nbmData?.warrantyStatus
  );
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  const response = useSelector((store) => store.nbmData?.warrantyRequest);

  const params = useParams();
  const [open, setopen] = useState(false);
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [render, setRender] = useState(false);

  const [getId, setGetId] = useState({});
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const [opens, setOpens] = React.useState(false);

  const [showOnlyExpired, setShowOnlyExpired] = useState(false);
  const [showNotExpired, setShowNotExpired] = useState(false);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [anchorElSort2, setAnchorElSort2] = React.useState(null);

  const [anchorElFilter2, setAnchorElFilter2] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? warrantyStatusData.filter((row) => row.warrantstatus === filterValue)
      : warrantyStatusData;
    setData(newFilteredData);
  }, [filterValue]);

  const handleClickFilter2 = (event) => {
    setAnchorElFilter2(event.currentTarget);
  };

  const handleCloseFilter2 = () => {
    setAnchorElFilter2(null);
  };

  const handleFilter2 = (status) => {
    setFilterValue(status);
    setAnchorElFilter2(null);
    setPage(0);
    setFilterChanged(true);
  };

  const status = [
    ...new Set(warrantyStatusData?.map((item) => item.warrantstatus)),
  ];
  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      downloadCsvFile();
    }
  };

  const currentDate = new Date();

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);

    return new Date(year, month - 1, day);
  };

  const uniqueMonths = [
    ...new Set(
      warrantyStatusData
        .filter((item) => item.exp_date !== "NA")
        .map((item) => item.exp_date.split("-")[1])
    ),
  ].sort();
  const uniqueYears = [
    ...new Set(warrantyStatusData.map((item) => item.exp_date.split("-")[2])),
  ].sort();

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const [anchorElPopper, setAnchorElPopper] = React.useState(null);
  const handleClickPopper = (event, data) => {
    setAnchorElPopper(event.currentTarget);
    setGetId(data);
    setopen(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.dop !== "NA" && b.dop !== "NA") {
        const dateA = new Date(a.dop.split("-").reverse().join("-"));
        const dateB = new Date(b.dop.split("-").reverse().join("-"));
        return dateA - dateB;
      } else if (a.dop === "NA" && b.dop !== "NA") {
        return 1;
      } else if (a.dop !== "NA" && b.dop === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const sortLowToHigh = () => {
    const sortedData = [...data].filter((item) => item.exp_date !== "NA");
    let sorted = sortedData.sort((a, b) => {
      const dateA = new Date(a.dop.split("-").reverse().join("-"));
      const dateB = new Date(b.dop.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sorted);
    handleCloseSort();
  };
  const sortHighToLow1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.exp_date.split("-").reverse().join("-"));
      const dateB = new Date(b.exp_date.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.exp_date.split("-").reverse().join("-"));
      const dateB = new Date(b.exp_date.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  useEffect(() => {
    if (warrantyStatusData && warrantyStatusData?.length > 0) {
      setData(warrantyStatusData);
    }
  }, [warrantyStatusData]);

  const department = [
    ...new Set(warrantyStatusData?.map((item) => item.department)),
  ];

  useEffect(() => {
    if (data?.length > 0 && data?.length !== undefined) {
      if (params.id !== "all") {
        let warrantyById = data?.filter((item) => {
          return item?.assetid === params.id;
        });
        setData(warrantyById);
      }
    }
  }, [warrantyStatusData]);

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    setOpens(false);

    dispatch(warrantyStatus(data, token));
  }, [dispatch, opens, response, open, render]);

  useEffect(() => {
    if (response === "Submitted") {
      setOpenModal(true);
    }
  }, [response]);

  const [filterChanged, setFilterChanged] = useState(false);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterIncidents();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterIncidents = () => {
    let filteredIncidents = [...data];

    if (searchQuery) {
      filteredIncidents = filteredIncidents.filter(
        (incident) =>
          incident.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          incident.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategories.length > 0) {
      filteredIncidents = filteredIncidents.filter((incident) =>
        selectedCategories.includes(incident.department)
      );
    }

    if (selectedYear) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentYear = incident.exp_date.split("-")[2];
        return incidentYear === selectedYear;
      });
    }
    if (selectedMonth) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentMonth = incident.exp_date.split("-")[1];
        return incidentMonth === String(selectedMonth).padStart(2, "0");
      });
    }

    const currentDate = new Date();
    filteredIncidents = filteredIncidents.filter((incident) => {
      if (!incident.exp_date) {
        return false;
      }
      if (showOnlyExpired && showNotExpired) {
        return true;
      }
      if (showOnlyExpired) {
        return formatDate(incident.exp_date) < currentDate;
      }
      if (showNotExpired) {
        return formatDate(incident.exp_date) >= currentDate;
      }
      return true;
    });

    return filteredIncidents;
  };

  const filteredProducts = filterIncidents();

  const sortDataByKey = (key, ascending) => {
    const sortedData = [...warrantyStatusData].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    filteredProducts.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(warrantyStatusData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.name;
      case "date":
        return item.dop;

      case "duedate":
        return item.exp_date;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  return (
    <Wrapper>
      <UpperContainer
        data={warrantyStatusData}
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        selectedMonth={selectedMonth}
        handleMonthChange={handleMonthChange}
        uniqueMonths={uniqueMonths}
        selectedYear={selectedYear}
        handleYearChange={handleYearChange}
        uniqueYears={uniqueYears}
        sortDataByKey={sortDataByKey}
        sortLowToHigh={sortLowToHigh}
        sortHighToLow={sortHighToLow}
        sortLowToHigh1={sortLowToHigh1}
        sortHighToLow1={sortHighToLow1}
        department={department}
        selectedCategories={selectedCategories}
        showOnlyExpired={showOnlyExpired}
        setShowOnlyExpired={setShowOnlyExpired}
        showNotExpired={showNotExpired}
        setShowNotExpired={setShowNotExpired}
        handleButtonClick2={handleButtonClick2}
        ids={ids}
        handleCategoryChange={handleCategoryChange}
        text="Warranty Status"
      />
      {warrantyStatusData?.length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: " 15px ",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}>
          <Cards>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={empty}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                sx={{ color: " #212427" }}
                color="text.secondary"
                gutterBottom>
                No assets in warranty status .
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => navigate("/home")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                variant="contained">
                Go to Home
              </Button>
            </CardActions>
          </Cards>
        </BoxWrapper>
      ) : filteredProducts?.length === 0 ? (
        <>
          <NoData text={"warranty status"} />
        </>
      ) : (
        <>
          <BoxWrapper style={{ overflowX: "auto" }}>
            <Box sx={{ overflow: "auto" }}>
              <TableContainer
                sx={{
                  width: "100%",

                  maxHeight: 520,
                }}>
                <Table
                  stickyHeader
                  sx={{
                    borderRadius: " 15px",
                    bgcolor: "white",

                    whiteSpace: "nowrap",
                  }}>
                  <TableHead
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                    }}>
                    <TableRow
                      sx={{
                        borderRadius: " 15px 15px 0px 0px",
                        bgcolor: "rgba(40, 98, 212, 0.1)",
                      }}>
                      <TableHeading
                        sx={{
                          borderRadius: "15px 0px 0px 0px",
                        }}>
                        <TableSortLabel
                          active={true}
                          style={{ color: "#1746A2" }}
                          direction={sortDirection.name}
                          onClick={() => handleSortTable("name")}>
                          Asset Name
                        </TableSortLabel>
                      </TableHeading>
                      <TableHeading>Brand</TableHeading>
                      <TableHeading>Asset Code</TableHeading>

                      <TableHeading>Department</TableHeading>
                      <TableHeading>
                        {" "}
                        <TableSortLabel
                          active={true}
                          style={{ color: "#1746A2" }}
                          direction={sortDirection.date}
                          onClick={() => handleSortTable("date")}>
                          Date
                        </TableSortLabel>
                      </TableHeading>
                      <TableHeading sx={{}}>
                        {" "}
                        <TableSortLabel
                          active={true}
                          style={{ color: "#1746A2" }}
                          direction={sortDirection.duedate}
                          onClick={() => handleSortTable("duedate")}>
                          Exp Date
                        </TableSortLabel>
                      </TableHeading>

                      <TableHeading
                        sx={{
                          borderRadius: "0px 15px 0px 0px",
                          textAlign: "start",
                        }}>
                        Status
                        <Tooltip title="Filter Status">
                          <IconButton onClick={handleClickFilter2} size="small">
                            <FilterListIcon />
                          </IconButton>
                        </Tooltip>
                        <FilterMenu
                          anchorEl={anchorElFilter2}
                          onClose={handleCloseFilter2}
                          onFilter={handleFilter2}
                          status={status}
                        />
                      </TableHeading>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts
                      ?.filter((asset) => {
                        if (showOnlyExpired && showNotExpired) {
                          return true;
                        }
                        if (showOnlyExpired) {
                          return formatDate(asset.exp_date) < currentDate;
                        }
                        if (showNotExpired) {
                          return formatDate(asset.exp_date) >= currentDate;
                        }
                        return true;
                      })
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        const expirationDate = formatDate(item.exp_date);
                        const isExpired = expirationDate < currentDate;
                        const textColor = isExpired ? "red" : "green";
                        if (showOnlyExpired && !isExpired) {
                          return null;
                        }
                        return (
                          <TableRow
                            key={item?.assetid}
                            sx={{
                              borderRadius: "15px 15px 0px 0px",
                              "&:hover": {
                                backgroundColor: "#E8ECF6",
                              },
                            }}>
                            <TableRows>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={`/asset-detail/${item?.assetid}`}>
                                {item?.name}
                              </Link>
                            </TableRows>
                            <TableRows>
                              {" "}
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={`/asset-detail/${item?.assetid}`}>
                                {item?.brand}{" "}
                              </Link>
                            </TableRows>
                            <TableRows>
                              {" "}
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={`/asset-detail/${item?.assetid}`}>
                                {item?.assetcode}{" "}
                              </Link>
                            </TableRows>

                            <TableRows>
                              {" "}
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={`/asset-detail/${item?.assetid}`}>
                                {item?.department}{" "}
                              </Link>
                            </TableRows>
                            <TableRows>
                              {" "}
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={`/asset-detail/${item?.assetid}`}>
                                {item?.dop}{" "}
                              </Link>
                            </TableRows>
                            <TableRows>
                              <span
                                style={{
                                  color: textColor,
                                  whiteSpace: "nowrap",
                                }}>
                                {" "}
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: textColor,
                                  }}
                                  to={`/asset-detail/${item?.assetid}`}>
                                  {item?.exp_date}{" "}
                                </Link>
                              </span>
                            </TableRows>
                            <TableRows sx={{ textAlign: "center" }}>
                              <Button
                                onClick={(e) => handleClickPopper(e, item)}
                                // disabled={
                                //   item?.warrantstatus === "Requested" ||
                                //   (userAccess.nbm_role !== "Admin" &&
                                //     userAccess.nbm_role !== "Super_Admin")
                                // }
                                disabled={
                                  item?.warrantstatus === "Requested" ||
                                  userAccess?.role === "Guest"
                                }
                                size="small"
                                sx={{
                                  borderRadius: "35px",
                                  textTransform: "none",
                                  bgcolor: "#1746A2",
                                }}
                                variant="contained">
                                {item?.warrantstatus === "NA"
                                  ? "Request"
                                  : "Requested"}
                              </Button>
                            </TableRows>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {filteredProducts?.length > rowsPerPage && (
                <TablePagination
                  sx={{
                    display: "flex",
                    justifyContent: isMobileScreen ? "center" : "end",

                    alignItems: isMobileScreen ? "center" : "end",
                    padding: "16px",

                    width: isMobileScreen ? "80%" : "90%",
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: !isMobileScreen ? "14px" : "95%",
                    },

                    "& .MuiTablePagination-actions": {
                      fontSize: !isMobileScreen ? "14px" : "12px",
                    },

                    "& .MuiTablePagination-select": {
                      position: "relative",
                      cursor: "text",

                      alignItems: "center",
                      color: "inherit",

                      flexShrink: 0,
                      marginRight: "3px",
                      marginLeft: "-36px",
                    },

                    "& .MuiTablePagination-displayedRows": {
                      marginRight: "1px",
                      marginLeft: "-30px",
                      fontSize: !isMobileScreen ? "14px" : "12px",
                    },
                  }}
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={
                    showOnlyExpired
                      ? filteredProducts.filter(
                          (asset) => formatDate(asset.exp_date) < currentDate
                        ).length
                      : filteredProducts.length
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Box>
          </BoxWrapper>
        </>
      )}

      <WarrantyRequestModal open={open} setopen={setopen} data={getId} />
    </Wrapper>
  );
};

export default WarrantyStatus;
