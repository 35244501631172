import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Footer from "../components/Footer";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import { useTheme } from "@emotion/react";
import Navbar3 from "../components/Navbar3";
function Plans() {
  const [change, setChange] = useState(true);
  const [count, setCount] = useState(1);

  const theme = useTheme();
  const check = useMediaQuery(theme.breakpoints.down("md"));
  const bmOrNbm = localStorage.getItem("bmOrNbm");

  const handleNext = () => {
    if (count < 3) {
      setCount(count + 1);
    } else {
      setCount(1);
    }
  };

  const handlePrev = () => {
    if (count > 1) {
      setCount(count - 1);
    } else {
      setCount(3);
    }
  };

  return (
    <>
      {/* <Navbar3 /> */}
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          marginTop: {
            xl: "108px",
            lg: "108px",
            md: "90px",
            sm: "80px",
            xs: "80px",
          },

          justifyContent: "center",
          alignItem: "center",
        }}>
        <Typography
          sx={{
            fontSize: {
              xl: "32px",
              lg: "32px",
              md: "32px",
              sm: "16px",
              xs: "16px",
            },
            fontWeight: "600",
            marginTop: "10px",
            color: "#1746A2",
          }}>
          Choose the best plan for your Hospital
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xl: "32px",
              lg: "32px",
              md: "32px",
              sm: "16px",
              xs: "16px",
            },
            marginTop: "10px",
            color: "#1746A2",
            fontWeight: "500",
          }}>
          Hospital Plans
        </Typography>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            margin: "auto",
            marginTop: "10px",
            justifyContent: "center",
            marginBottom: "20px",
            height: "80px",
          }}>
          <Typography
            sx={{
              fontSize: {
                xl: "32px",
                lg: "32px",
                md: "32px",
                sm: "20px",
                xs: "20px",
              },
              color: `${change ? "black" : "#cbcec7"}`,
            }}>
            Monthly
          </Typography>
          <Switch
            sx={{ color: "#60B708" }}
            color="success"
            onChange={() => setChange(!change)}
            inputProps={{ "aria-label": "controlled" }}
          />

          <Box>
            <Typography
              sx={{
                fontSize: {
                  xl: "32px",
                  lg: "32px",
                  md: "32px",
                  sm: "20px",
                  xs: "20px",
                },
                color: `${change ? "#cbcec7" : "black"}`,
              }}>
              Annually
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                color: `${change ? "#cbcec7" : "black"}`,
              }}>
              (save 10%)
            </Typography>
          </Box>
        </Box>

        <Box
          margin={"10px solid blue"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
          }}>
          {/* ...............monthly................ */}

          {change && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
                marginBottom: "30px",
                alignItem: "center",
              }}>
              {check && (
                <ArrowCircleLeftIcon
                  sx={{
                    marginTop: "400px",
                    color: "#1746A2",
                    marginRight: "5px",
                  }}
                  onClick={handlePrev}
                />
              )}
              {(count === 1 || !check) && (
                <Box
                  border={"1px solid #7A7A7A"}
                  sx={{
                    width: {
                      xl: "384px",
                      lg: "384px",
                      md: "324px",
                      sm: "324px",
                      xs: "324px",
                    },
                    height: {
                      xl: "750px",
                      lg: "750px",
                      md: "750px",
                      sm: "750px",
                      xs: "750px",
                    },
                  }}>
                  <Typography
                    fontFamily="Poppins"
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "22px",
                        xs: "22px",
                      },
                      marginTop: "30px",
                    }}>
                    Free
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: {
                        xl: "36px",
                        lg: "36px",
                        md: "36px",
                        sm: "24px",
                        xs: "24px",
                      },
                      fontFamily: "Poppins",
                    }}>
                    ₹ 0
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}>
                    Per Month
                  </Typography>

                  <Button
                    sx={{
                      bgcolor: "#1746A2",
                      fontFamily: "Poppins",
                      marginTop: "20px",
                      width: "179px",
                      height: "48px",
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 2)",
                        cursor: "pointer",
                        color: "white",
                        bgcolor: "#F78117",
                      },
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "20px",
                        xs: "20px",
                      },
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}>
                    Buy it
                  </Button>

                  <hr
                    style={{
                      marginTop: "20px",
                      color: "#5F9DF7",
                      width: "80%",
                    }}
                  />
                  <Box sx={{ paddingLeft: "10px" }}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText primary="2 Users" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Downloadable reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Device History"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Calibration expiry remainders"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Service reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Incident Management"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Equipment Audit reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Single Location"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Data Loss Protection"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Product Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Service Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Biomedical Budget Management"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              )}

              {(count === 2 || !check) && (
                <Box
                  border={"1px solid #7A7A7A"}
                  sx={{
                    width: {
                      xl: "384px",
                      lg: "384px",
                      md: "324px",
                      sm: "324px",
                      xs: "324px",
                    },
                    height: {
                      xl: "750px",
                      lg: "750px",
                      md: "750px",
                      sm: "750px",
                      xs: "750px",
                    },
                  }}>
                  <Typography
                    fontFamily="Poppins"
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "22px",
                        xs: "22px",
                      },
                      marginTop: "30px",
                    }}>
                    Standard
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: {
                        xl: "36px",
                        lg: "36px",
                        md: "36px",
                        sm: "24px",
                        xs: "24px",
                      },
                      fontFamily: "Poppins",
                    }}>
                    ₹ 999
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}>
                    Per Month
                  </Typography>

                  <Button
                    sx={{
                      bgcolor: "#1746A2",
                      fontFamily: "Poppins",
                      marginTop: "20px",
                      width: "179px",
                      height: "48px",
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 2)",
                        cursor: "pointer",
                        color: "white",
                        bgcolor: "#F78117",
                      },
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "20px",
                        xs: "20px",
                      },
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}>
                    Buy it
                  </Button>

                  <hr
                    style={{
                      marginTop: "20px",
                      color: "#5F9DF7",
                      width: "80%",
                    }}
                  />
                  <Box sx={{ paddingLeft: "10px" }}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Unlimited Assets"
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText primary="Unlimited Users" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Downloadable reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Device History"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Calibration expiry remainders"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Service reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Incident Management"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Equipment Audit reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Single Location"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Data Loss Protection"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Product Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Service Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Biomedical Budget Management"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              )}

              {(count === 3 || !check) && (
                <Box
                  border={"1px solid #7A7A7A"}
                  sx={{
                    width: {
                      xl: "384px",
                      lg: "384px",
                      md: "324px",
                      sm: "324px",
                      xs: "324px",
                    },
                    height: {
                      xl: "750px",
                      lg: "750px",
                      md: "750px",
                      sm: "750px",
                      xs: "750px",
                    },
                  }}>
                  <Typography
                    fontFamily="Poppins"
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "22px",
                        xs: "22px",
                      },
                      marginTop: "30px",
                    }}>
                    Premium
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: {
                        xl: "36px",
                        lg: "36px",
                        md: "36px",
                        sm: "24px",
                        xs: "24px",
                      },
                      fontFamily: "Poppins",
                    }}>
                    ₹ 1,999
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}>
                    Per Month
                  </Typography>

                  <Button
                    sx={{
                      bgcolor: "#1746A2",
                      fontFamily: "Poppins",
                      marginTop: "20px",
                      width: "179px",
                      height: "48px",
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 2)",
                        cursor: "pointer",
                        color: "white",
                        bgcolor: "#F78117",
                      },
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "20px",
                        xs: "20px",
                      },
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}>
                    Buy it
                  </Button>

                  <hr
                    style={{
                      marginTop: "20px",
                      color: "#5F9DF7",
                      width: "80%",
                    }}
                  />
                  <Box sx={{ paddingLeft: "10px" }}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Unlimited Assets"
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText primary="Unlimited Users" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Downloadable reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Device History"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Calibration expiry remainders"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Service reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Incident Management"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Equipment Audit reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Single Location"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Data Loss Protection"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Product Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Service Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Biomedical Budget Management"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              )}
              {check && (
                <ArrowCircleRightIcon
                  sx={{
                    marginTop: "400px",
                    color: "#1746A2",
                    marginLeft: "5px",
                  }}
                  onClick={handleNext}
                />
              )}
            </Box>
          )}

          {/* ...............Yearly................ */}

          {!change && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "10px",
                alignItem: "center",
              }}>
              {check && (
                <ArrowCircleLeftIcon
                  sx={{
                    marginTop: "400px",
                    color: "#1746A2",
                    marginRight: "5px",
                  }}
                  onClick={handlePrev}
                />
              )}
              {(count === 1 || !check) && (
                <Box
                  border={"1px solid #7A7A7A"}
                  sx={{
                    width: {
                      xl: "384px",
                      lg: "384px",
                      md: "324px",
                      sm: "324px",
                      xs: "324px",
                    },
                    height: {
                      xl: "750px",
                      lg: "750px",
                      md: "750px",
                      sm: "750px",
                      xs: "750px",
                    },
                  }}>
                  <Typography
                    fontFamily="Poppins"
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "22px",
                        xs: "22px",
                      },
                      marginTop: "30px",
                    }}>
                    Free
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: {
                        xl: "36px",
                        lg: "36px",
                        md: "36px",
                        sm: "24px",
                        xs: "24px",
                      },
                      fontFamily: "Poppins",
                    }}>
                    ₹ 0
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}>
                    Per Year{" "}
                  </Typography>

                  <Button
                    sx={{
                      bgcolor: "#1746A2",
                      fontFamily: "Poppins",
                      marginTop: "20px",
                      width: "179px",
                      height: "48px",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "20px",
                        xs: "20px",
                      },
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 2)",
                        cursor: "pointer",
                        color: "white",
                        bgcolor: "#F78117",
                      },
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}>
                    Buy it
                  </Button>

                  <hr
                    style={{
                      marginTop: "20px",
                      color: "#5F9DF7",
                      width: "80%",
                    }}
                  />

                  <Box sx={{ paddingLeft: "10px" }}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText primary="2 Users" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Downloadable reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Device History"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Calibration expiry remainders"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Service reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Incident Management"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Equipment Audit reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Single Location"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Data Loss Protection"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Product Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Service Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Biomedical Budget Management"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              )}

              {(count === 2 || !check) && (
                <Box
                  border={"1px solid #7A7A7A"}
                  sx={{
                    width: {
                      xl: "384px",
                      lg: "384px",
                      md: "324px",
                      sm: "324px",
                      xs: "324px",
                    },
                    height: {
                      xl: "750px",
                      lg: "750px",
                      md: "750px",
                      sm: "750px",
                      xs: "750px",
                    },
                  }}>
                  <Typography
                    fontFamily="Poppins"
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "22px",
                        xs: "22px",
                      },
                      marginTop: "30px",
                    }}>
                    Standard
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: {
                        xl: "36px",
                        lg: "36px",
                        md: "36px",
                        sm: "24px",
                        xs: "24px",
                      },
                      fontFamily: "Poppins",
                    }}>
                    <strike style={{ color: "#cbcec7" }}>₹11,988</strike> ₹
                    10,790
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}>
                    Per Year
                  </Typography>

                  <Button
                    sx={{
                      bgcolor: "#1746A2",
                      fontFamily: "Poppins",
                      marginTop: "20px",
                      width: "179px",
                      height: "48px",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "20px",
                        xs: "20px",
                      },
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 2)",
                        cursor: "pointer",
                        color: "white",
                        bgcolor: "#F78117",
                      },
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}>
                    Buy it
                  </Button>

                  <hr
                    style={{
                      marginTop: "20px",
                      color: "#5F9DF7",
                      width: "80%",
                    }}
                  />

                  <Box sx={{ paddingLeft: "10px" }}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Unlimited Assets"
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText primary="Unlimited Users" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Downloadable reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Device History"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Calibration expiry remainders"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Service reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Incident Management"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Equipment Audit reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Single Location"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Data Loss Protection"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Product Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Service Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <CloseIcon sx={{ color: "red" }} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px", color: "#9A9A9A" }}
                          primary="Biomedical Budget Management"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              )}

              {(count === 3 || !check) && (
                <Box
                  border={"1px solid #7A7A7A"}
                  sx={{
                    width: {
                      xl: "384px",
                      lg: "384px",
                      md: "324px",
                      sm: "324px",
                      xs: "324px",
                    },
                    height: {
                      xl: "750px",
                      lg: "750px",
                      md: "750px",
                      sm: "750px",
                      xs: "750px",
                    },
                  }}>
                  <Typography
                    fontFamily="Poppins"
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "22px",
                        xs: "22px",
                      },
                      marginTop: "30px",
                    }}>
                    Premium
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: {
                        xl: "36px",
                        lg: "36px",
                        md: "36px",
                        sm: "24px",
                        xs: "24px",
                      },
                      fontFamily: "Poppins",
                    }}>
                    <strike style={{ color: "#cbcec7" }}> ₹ 23,988</strike> ₹
                    21,590
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                    }}>
                    Per Year
                  </Typography>

                  <Button
                    sx={{
                      bgcolor: "#1746A2",
                      fontFamily: "Poppins",
                      marginTop: "20px",
                      width: "179px",
                      height: "48px",
                      fontSize: {
                        xl: "24px",
                        lg: "24px",
                        md: "24px",
                        sm: "20px",
                        xs: "20px",
                      },
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 2)",
                        cursor: "pointer",
                        color: "white",
                        bgcolor: "#F78117",
                      },
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}>
                    Buy it
                  </Button>

                  <hr
                    style={{
                      marginTop: "20px",
                      color: "#5F9DF7",
                      width: "80%",
                    }}
                  />
                  <Box sx={{ paddingLeft: "10px" }}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Unlimited Assets"
                        />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText primary="Unlimited Users" />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Downloadable reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Device History"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Calibration expiry remainders"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Service reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Incident Management"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Digital Equipment Audit reports"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Single Location"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Data Loss Protection"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary=" Upto 50 Assets"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Product Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Service Manuals"
                        />
                      </ListItem>

                      <ListItem disablePadding>
                        <ListItemIcon>
                          <DoneIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          sx={{ lineHeight: "100px" }}
                          primary="Biomedical Budget Management"
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              )}

              {check && (
                <ArrowCircleRightIcon
                  sx={{
                    marginTop: "400px",
                    color: "#1746A2",
                    marginLeft: "5px",
                  }}
                  onClick={handleNext}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
}

export default Plans;
