import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  FormHelperText,
  IconButton,
  Modal,
  Stack,
  TableFooter,
  TableHead,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import EastIcon from '@mui/icons-material/East';
import pdfImage from "../images/pdfImage.png";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import comingSoon from "../images/comingSoon.png"

import {
  DownloadPDF,
  assetDetailsData,
  deleteAsset,
} from "../redux/data/action";

import UpdateReport from "./UpdateReport";
import ViewImageModal from "../components/ViewImageModal";
import { Icon } from "@react-pdf-viewer/core";


const InnerImageBox = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
// const Label = styled(Typography)(({ theme }) => ({
//   color: "rgba(27, 37, 53, 1)",
//   fontWeight: 700,
// }));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));


const ImageBox = styled(Box)(({ theme }) => ({

  width: "230px",
  height: "230px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "230px",
    height: "230px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    // height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));


const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "300px",
}));

const InnerWarrantyImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //   border:'6px solid red',

  alignItems: "center",
  justifyContent: "center",
  margin: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "380px",
    height: "251px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "251px",
  },
  [theme.breakpoints.down("xs")]: {},
}));


const InnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

const WarrantyInnerBorderBoxBox = styled(Box)(({ theme }) => ({
  display: "flex",
  //    border:'2px solid black',
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));



const ImageCard = styled(Box)(({ theme }) => ({
  width: "159.84px",
  height: "155px",
}));

const WarrantyImageCard = styled(Box)(({ theme }) => ({
  width: "159px",
  height: "145px",
  //  border:"3px solid black"
}));


const QrBox = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "100px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const TextBox = styled(Box)(({ theme }) => ({
  margin: "5px",
}));


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));



const SplitCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: "2",
  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const MainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between", 

  gap: "15px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between", 
  alignItems:'center',

  background:'white',
  height:'50px',
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",


  background:'white',
 
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const MidTop = styled(Box)(({ theme }) => ({
  display: "grid",
 gridTemplateColumns:'repeat(3,1fr)',

  background:'white',
  // height:'200px',
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns:'repeat(2,1fr)',
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns:'repeat(1,1fr)',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidTopBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", 
  alignItems:'center',

  background:'white',
  height:'240px',
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection:"column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const MidBottom = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: 'repeat(5, 1fr)',
  alignItems:'center',

  background:'white',
  // height:'50px',
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns:'repeat(2,1fr)',
    gap:'20px',
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const MiniQRset = styled(Box)(({ theme }) => ({
 
  width:'50px',
  height:'50px',
  cursor:'pointer',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {

  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));



function AssetDetailPage() {
  const updateReportResponse = useSelector((store) => store.data.updateReports);
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };


const handleOpenQr = ()=>{
  setOpen(true)
}

const handleCloseQr = ()=>{
  setOpen(false)
}



  const [image, setImage] = useState([]);
  const [status, setStatus] = useState(false);
  const [reportImage, setReportImage] = useState([]);
  const [reportImageName, setReportImageName] = useState([]);

  const [zoomImageUrl, setZoomImageUrl] = useState("");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [hidden, setHidden] = React.useState(false);
  const [certificateImage, setCertificateImage] = useState([]);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const params = useParams();
  const dispatch = useDispatch();
  const detailData = useSelector((store) => store.data.assetDetails);

  const [openImage, setOpenImage] = useState(false);

  const handleImageOpen = (url) => {
    setZoomImageUrl(url);
    setOpenImage(true);
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const text = "Asset";
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handlePrevious = () => {
    setCount((prevCount) => {
      if (prevCount === 0) {
        return image?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNext = () => {
    setCount((prevCount) => {
      if (prevCount === image?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleReportPrevious = () => {
    setCount3((prevCount) => {
      if (prevCount === 0) {
        return reportImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleReportNext = () => {
    setCount3((prevCount) => {
      if (prevCount === reportImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handlePreviousCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === 0) {
        return certificateImage?.length - 1;
      } else {
        return prevCount - 1;
      }
    });
  };

  const handleNextCertificate = () => {
    setCount2((prevCount) => {
      if (prevCount === certificateImage?.length - 1) {
        return 0;
      } else {
        return prevCount + 1;
      }
    });
  };

  const handleDelete = () => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
    };

    dispatch(deleteAsset(data, token));
    navigate(-1);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch, updateReportResponse]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.target = "_blank";
    // link.download = "downloaded_image.jpg";
    link.click();
  };

  useEffect(() => {
    if (detailData?.imgs) {
      setImage(detailData?.imgs);
      setCertificateImage(detailData?.wimg);
    }
    if (detailData?.reportimgs) {
      setReportImage(detailData?.reportimgs);
    }
    if (detailData?.reportnames) {
      setReportImageName(detailData?.reportnames);
    }
    // setReportImageName
  }, [detailData]);

  const handleClick = () => {
    localStorage.setItem("incidentId", detailData?.incidid);
  };

  const handleDownloadPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";

      const imageX = (pdfWidth - 30) / 2;

      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);

      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;

      pdf.save(`${name}.pdf`);
    };

    img.src = pdfData;
  };

  const handlePrintPDF = (pdfData, name, code) => {
    if (!pdfData) return;

    const pdf = new jsPDF();
    const img = new Image();

    img.onload = function () {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (img.height * 30) / img.width;
      const imageType = pdfData.includes("data:image/jpeg") ? "JPEG" : "PNG";
      const imageX = (pdfWidth - 30) / 2;
      pdf.addImage(pdfData, imageType, imageX, 15, 30, pdfHeight);
      pdf.setFontSize(10);
      const codeY = 15 + pdfHeight + 2;
      const nameY = codeY + 5;
      pdf.text(name, pdfWidth / 2, nameY, { align: "center" });
      pdf.text(code, pdfWidth / 2, codeY, { align: "center" });

      const pdfBlob = pdf.output("blob");

      const pdfUrl = URL.createObjectURL(pdfBlob);

      const newWindow = window.open(pdfUrl);

      newWindow.onload = function () {
        newWindow.print();

        URL.revokeObjectURL(pdfUrl);
      };
    };

    img.src = pdfData;
  };

  const handleDownloadPDF2 = (
    logoUrl,
    pdfData,
    name,
    code,
    brand,
    calibdate,
    calibduedate,
    calibmnth,
    calibreq,
    calibstatus,
    cmcamc,
    cmcamcdue,
    cmcamcstart,
    dept,
    dop,
    imgs,
    wimg,
    serialno,
    incidstatus,
    lifeyrs,
    modelno,
    price,
    servstatus,
    soldby,
    status
  ) => {
    if (!pdfData || !logoUrl) return;

    const pdf = new jsPDF();
    const img = new Image();
    img.src = logoUrl;
    img.onload = function () {
      const desiredHeight = 30;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const lineWidth = 0.9 * pageWidth;
      pdf.setFontSize(22);
      pdf.setTextColor("#FF731D");
      const xPosition = (pageWidth - lineWidth) / 2;
      const yPosition = 10;
      const logoYPosition = 10;

      let yTop = yPosition + 7;
      const logoLineY = yPosition + 22; // Adjust this value to move the line above the table
      pdf.setLineWidth(1);
      pdf.setDrawColor("1b76bc");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.hospname, lineWidth / 2.3, yTop);

      pdf.setFontSize(10);
      pdf.setTextColor("#1746A2");
      pdf.line(xPosition, logoLineY, xPosition + lineWidth, logoLineY);
      pdf.text(userIds.city, lineWidth / 2, yTop + 7);
      pdf.setFontSize(12);
      pdf.setTextColor(0, 0, 0);
      const parameterData = [
        ["Name", name],
        ["Code", code],
        ["Brand", brand],
        ["Department", dept],
        ["Status", status],
        ["Calibration Date", calibdate],
        ["Calibration Status", calibstatus],
        ["Useful Life", lifeyrs],
        ["Model No", modelno],
        ["AMC/CMC", cmcamc],
        ["Purchase Date", dop],
        ["Price", price],
        ["Sold by", soldby],
        ["Incident Status", incidstatus],
        ["Service Status", servstatus],
        ["CMC/AMC Start", cmcamcstart],
        ["CMC/AMC Due", cmcamcdue],
        ["Calibration Month", calibmnth],
        ["Calibration Due Date", calibduedate],
        ["Calibration Request", calibreq],
      ];

      pdf.autoTable({
        body: parameterData,
        startY: 40,
        headStyles: {
          fillColor: [26, 188, 156],
          textColor: [255, 255, 255],
        },
      });

      const contentWidth = pdf.internal.pageSize.getWidth();
      const qrCodeX = contentWidth - 70;
      const qrCodeY = pdf.autoTable.previous.finalY + 10; // Position it below the table

      const assetImageX = contentWidth - 70;
      const assetImageY = qrCodeY + 40;

      pdf.addImage(pdfData, "JPEG", qrCodeX, qrCodeY, 25, 25);
      pdf.setTextColor("1b76bc");
      pdf.text("QR Code", qrCodeX, qrCodeY);
      let imageX = assetImageX;
      let imageY = assetImageY + 5;
      const imageWidth = 18;
      const imageHeight = 18;

      pdf.setFontSize(14);
      pdf.setLineWidth(0.5);
      pdf.setTextColor("1b76bc");
      pdf.text("Asset Image", assetImageX, assetImageY + 2);

      for (let i = 0; i < imgs.length; i++) {
        const { imgurl } = imgs[i];
        pdf.setDrawColor("1b76bc");
        pdf.setLineWidth(0.5);

        pdf.rect(imageX, imageY, imageWidth, imageHeight);
        pdf.addImage(imgurl, "JPEG", imageX, imageY, imageWidth, imageHeight);

        imageX += imageWidth + 3;
        if (imageX > contentWidth - imageWidth - 3) {
          imageX = assetImageX;
          imageY += imageHeight + 3;
        }
      }

      if (wimg !== "NA" && wimg.length > 0) {
        const warrantyImageWidth = 18;
        const warrantyImageHeight = 18;
        let warrantyImageX = 20;
        let warrantyImageY = pdf.autoTable.previous.finalY + 10;

        const warrantyImageHeadingX = 20;
        const warrantyImageHeadingY = pdf.autoTable.previous.finalY + 15;

        pdf.setFontSize(14);
        pdf.setLineWidth(0.5);
        pdf.setTextColor("1b76bc");
        pdf.text(
          "Warranty Images",
          warrantyImageHeadingX,
          warrantyImageHeadingY - 7
        );

        for (let i = 0; i < wimg.length; i++) {
          const { imgurl } = wimg[i];

          // Check if imgurl is a PDF
          if (imgurl.toLowerCase().includes(".pdf")) {
            // Handle PDF images if needed
          } else {
            pdf.setDrawColor("1b76bc");
            pdf.setLineWidth(0.5);
            pdf.rect(
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );

            pdf.addImage(
              imgurl,
              "JPEG",
              warrantyImageX,
              warrantyImageY,
              warrantyImageWidth,
              warrantyImageHeight
            );
            warrantyImageY += warrantyImageHeight + 3;
          }
        }
      }

      pdf.save(`${name}.pdf`);
    };

    // Set the source of the image to the logo data
    img.src = logoUrl;
  };

  const shareViaWhatsApp = () => {
    const text = `*Asset Details*\n\n*Page URL:* https://vajra.software${window.location.pathname}\n\n*Name:* ${detailData.name}\n*Code:* ${detailData.code}\n*Serial No:* ${detailData.serialno}\n*Model No:* ${detailData.modelno}\n*Department:* ${detailData.dept}\n*Type:* ${detailData.type}\n*Status:* ${detailData.status}\n*Brand:* ${detailData.brand}\n*Sold By:* ${detailData.soldby}\n*Date of Purchase:* ${detailData.dop}\n*Price:* ${detailData.price}\n\n*Warranty Start Date:* ${detailData.wrntdate}\n*Warranty End Date:* ${detailData.wrntexpdate}\n\n*Calibration Status:* ${detailData.calibstatus}\n*Calibration Date:* ${detailData.calibdate}\n*Calibration Due Date:* ${detailData.calibduedate}\n\n*AMC/CMC:* ${detailData.cmcamc}\n*AMC/CMC Start Date:* ${detailData.cmcamcstart}\n*AMC/CMC End Date:* ${detailData.cmcamcdue}\n\n`;

    const whatsappLink = `https://wa.me?text=${encodeURIComponent(text)}`;
    window.open(whatsappLink, "_blank");
  };
  const AssetDetail = {
    Code: detailData.code,
    "Serial No.": detailData.serialno,
    Department: detailData.dept,
    Status: detailData.status,
  };
  const Specifications = {
    Type: detailData.type,
    "Model Number": detailData.modelno,
    "Useful life ": detailData.lifeyrs,
    "Warranty Expiry": detailData.wrntexpdate,
    "AMC/CMC": detailData.cmcamc,

    Calibration: detailData.calibreq,
    Date: detailData.calibdate,
    "Due Date": detailData.calibduedate,
  };
  const Purchase = {
    Price: detailData.price,
    Date: detailData.dop,
    "Seller Brand ": detailData.brand,
    Representative: detailData.soldby,
    "Seller Contact": detailData.sellermobile,
    "Seller Email ID ": detailData.selleremail,
  };



const handleOpenService = ()=>{
  setStatus(true)
}


const handleCloseService = ()=>{
  setStatus(false)
}


const handleNavigate = (requested,assetId) => {
  navigate(`/service-request/${assetId}?requested=${requested}`);
};


const downloadImage = (imageUrl) => {
  fetch(imageUrl)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      // setImageSrc(url);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'downloaded_image.png');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.error('Error downloading the image:', error));
};

const printImage = (imageSrc) => {
  if (imageSrc) {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`<img src="${imageSrc}" onload="window.print();window.close()" />`);
    printWindow.document.close();
  } else {
    console.log('Image not downloaded yet.');
  }
};





const [totalAmountSpent, setTotalAmountSpent] = useState("");
const [isTotalAmountSpentError, setIsTotalAmountSpentError] = useState("");
const [description, setDescription] = useState("");
const [isDescriptionError, setIsDescriptionError] = useState("");
const [quantity, setQuantity] = useState("");
const [isQuantityError, setIsQuantityError] = useState("");




const handleDeleteAccessory = (id) => {
  let data = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    imageid: id,
    branchid: userBranch.id,
  };
  // dispatch(clearServiceImgDel(data));
  // setFileUrlAccessory(fileUrl?.filter((el) => el?.imageid !== id));
};


const handleDescription = (e) => {
  const value = e.target.value.trim();
  const regex =  /^[a-zA-Z0-9.,'"\s-]*$/;
  if (value === "") {
    setDescription(value);
    setIsDescriptionError("Please fill the field.");
  } else if (regex.test(value.replace(/\s/g, ""))) {
    setDescription(value);
    setIsDescriptionError(null);
  } else {
    setIsDescriptionError("Enter a valid description");
  }
};


const handleQuantity = (e) => {
  const value = e.target.value.trim();
  const regex =  /^[a-zA-Z0-9.,'"\s-]*$/;
  if (value === "") {
    setQuantity(value);
    setIsQuantityError("Please fill the field.");
  } else if (regex.test(value.replace(/\s/g, ""))) {
    setQuantity(value);
    setIsQuantityError(null);
  } else {
    setIsQuantityError("Enter a valid quantity");
  }
};




const handleTotalAmountSpent = (e) => {
  const value = e.target.value.trim();
  const regex = /^[0-9]*$/;
  if (value === "") {
    setTotalAmountSpent(value);
    setIsTotalAmountSpentError("Please fill the field.");
  } else if (regex.test(value.replace(/\s/g, ""))) {
    setTotalAmountSpent(value);
    setIsTotalAmountSpentError(null);
  } else {
    setIsTotalAmountSpentError("Enter a valid amount");
  }
};



const [expenditures, setExpenditures] = useState([]);
const [newExpenditure, setNewExpenditure] = useState({
description: '',
totalAmountSpent: '',
quanity:'',
expImage: [{ image: '', imageId: '' }]
});

const handleAddExpenditure = () => {
setExpenditures([...expenditures, { ...newExpenditure }]);
setNewExpenditure({
  description: '',
  totalAmountSpent: '',
  quanity:'',
  expImage: [{ image: '', imageId: '' }]
});
};

const handleRemoveExpenditure = (index) => {
const updatedExpenditures = expenditures.filter((_, i) => i !== index);
setExpenditures(updatedExpenditures);
};

const handleChange = (e) => {
const { name, value } = e.target;
setNewExpenditure({ ...newExpenditure, [name]: value });
};





const handleTotalAmountSpentObject = (e,index,) => {
handleTotalAmountSpent(e)
let value = e.target.value
let edit = [...expenditures]
 edit[index].totalAmountSpent = value
setExpenditures([...edit]);
};




const handleDescriptionObject = (e,index,) => {
handleDescription(e)
let value = e.target.value
let edit = [...expenditures]
 edit[index].description = value
setExpenditures([...edit]);
};



const handleQuantityObject = (e,index,) => {
  handleQuantity(e)
  let value = e.target.value
  let edit = [...expenditures]
   edit[index].quanity = value
  setExpenditures([...edit]);
  };
  

const handleEdit = ()=>{
  navigate(`/edit-asset/${params.id}`); 
}






  return (
    <Box
      sx={{
        display: "flex",
        padding:'10px',
        gap:'10px',
        flexDirection: "column",
      }}>
   <TopContainer>
   <Label variant="subtitle1">{detailData?.dept} </Label>
   </TopContainer>



   <MidContainer>
    <MidTop>

<MidTopBox>

<InnerBorderBoxBox>
<Box sx={{width:'100%'}}>
  <Typography  sx={{color:'#4690FF', fontWeight:700, textAlign:'left'}} variant="subtitle1">{detailData?.name}</Typography>
  </Box>
 
 
                      <InnerImageBox>
                        <IconButton onClick={handlePrevious}>
                          <KeyboardBackspaceIcon style={{ color: "#4690FF" }} />
                        </IconButton>
                        <ImageCard
                          as="img"
                          src={`${image[count]?.imgurl}`}
                          alt="check"
                          onClick={() => handleImageOpen(image[count]?.imgurl)}
                        />
                        <IconButton onClick={handleNext}>
                          <EastIcon style={{ color: "#4690FF" }} />
                        </IconButton>
                      </InnerImageBox>
                      <Stack sx={{width:'100%'}} flexDirection={"row"} alignItems={'center'} justifyContent={"space-between"}>
                        <Stack gap="10px" flexDirection={"row"}>
                        <Typography>Asset Image</Typography>
                        <Typography>{image.length}/{count+1}</Typography>
                        </Stack>
                       
                      <Tooltip title="Download">
                        <IconButton
                          onClick={() => handleDownload(image[count]?.imgurl)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      </Stack>
                   
                    </InnerBorderBoxBox>
</MidTopBox>

<MidTopBox>
<Box sx={{height:'100%',  width:'100%', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
  <Box >
  <Label sx={{textAlign:'left'}}>
Working Status
</Label>
<Divider/>
  </Box>



<Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={"center"} gap="5px">
<Box sx={{width:'100%'}}>
<Label sx={{textAlign:'left'}}>
 Status
</Label>
<Typography sx={{textAlign:'left', color:'#FF6B00', fontWeight:'700'}}>
 {detailData?.status}
</Typography>
</Box>

<WarningAmberIcon style={{color:'#FF6B00', fontSize:'34px'}}/>
</Stack>


<Box sx={{width:'100%'}}>
<Label sx={{textAlign:'left'}}>
 Status
</Label>
<Typography sx={{textAlign:'left', color:'#FF6B00', fontWeight:'700'}}>
2 days    02 hr : 45 m : 12 s
</Typography>
</Box>




<Stack sx={{width:'100%'}} flexDirection={"row"} alignItems={'center'} justifyContent={"space-between"}>
                        <Stack gap="10px" flexDirection={"row"}>
                        <Typography>Monthly Report</Typography>
                        </Stack>
                       
                      <Tooltip title="Download">
                        <IconButton
                          onClick={() => handleDownload(image[count]?.imgurl)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
</Stack>

</Box>





</MidTopBox>

<MidTopBox>
  <ImageBox as="img" src={comingSoon} alt="image-box"  />
</MidTopBox>

    </MidTop>


    <Divider/>
<MidBottom>

<MiniQRset onClick={handleOpenQr} as="img" src={detailData?.qrimg} >
   
</MiniQRset>



  <Box >
  <Button sx={{ color:'black', borderRadius:'20px',border:'1px solid'}} onClick={handleEdit} variant="outlined">
    Edit Asset 
  </Button>
  </Box>
  
  <Stack sx={{ width: "100%",  }}>
                  {detailData?.incidstatus !== "Open" && (
                    <Link to={`/record-incident/${params.id}`}>
                      <Button
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Add Incident
                      </Button>
                    </Link>
                  )}

                  {detailData?.incidstatus === "Open" && (
                    <Link
                      // to={`/clear-incident/${params.id}`}
                      to={`/incident-list/${detailData?.incidid}`}>
                      <Button
                        onClick={handleClick}
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Clear Incident
                      </Button>
                    </Link>
                  )}
                </Stack>


                
                <Stack sx={{ width: "100%",  }}>
              
              {detailData?.servstatus ==="NA" && 
                  <Button
                  onClick={handleOpenModal}
                  variant="contained"
                  sx={{ borderRadius: "22px ", width: "130px" }}>
                  Add Service
                </Button>}

                {detailData?.servstatus ==="Open" && 
                  <Button
                  onClick={()=>navigate(`/service-list/${detailData.servid}`)}
                  variant="contained"
                  sx={{ borderRadius: "22px ", width: "130px" }}>
                  Clear Service
                </Button>}
               
              </Stack>
  <Box>
  {/* <Button sx={{ color:'black',borderRadius:'20px'}} variant="outlined">
  Add Report
</Button> */}

<UpdateReport assetId={params.id} />


</Box>
</MidBottom>
   </MidContainer>


      <MainWrapper>

        <Stack sx={{ flex: "1", gap: "20px" }}>
        
           


            <Stack
              sx={{
                flex: "1",

                padding: "10px",
                bgcolor: "white",

                justifyContent: "space-between",
                gap: "20px",
              }}>
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",

                  height: "200px",
                }}>
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(AssetDetail).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",
                }}>
                <Label variant="subtitle1">Specifications</Label>
                <Divider />
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(Specifications).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  gap: "10px",
                  textAlign: "start",

                  height: "280px",
                }}>
                <Label variant="subtitle1">Purchase</Label>
                <Divider />
                <Box sx={{ width: "100%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(Purchase).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              {key}
                            </TableCells>

                            <TableCells>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>




              <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start"
              }}>
                <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <Label variant="subtitle1">Accessories</Label>
                <Button disabled={expenditures?.length>0} onClick={handleAddExpenditure} sx={{border:'1px solid black', borderRadius:'20px', color:'black'}} variant="outlined">
                  Add Expenditure
                </Button>
                </Box>
             
              <Divider />

{/* --------------------------------------------------------------------------------------------------- */}





           { expenditures?.map((item, index)=>(
 <Stack
 sx={{
   display: "flex",
   gap: "30px",
 }}>


<Stack
  sx={{
  justifyContent: "space-between",
  flexDirection: "row",
  gap: "30px",
  }}>
 <Label variant="subtitle1">Add new accessories</Label>
 <IconButton onClick={()=>handleRemoveExpenditure(index)}>
  <CancelIcon sx={{color:'black'}}/>
 </IconButton>
              </Stack>

  
<Stack
 sx={{
   justifyContent: "space-between",
   flexDirection: "row",
   gap: "30px",
 }}>




 <Stack
   sx={{
     gap: "10px",
  
     width: "50%",
     textAlign: "start",
   }}>
      <Field
     value={item.description}
     placeholder="Reason description"
     onChange={(e)=>handleDescriptionObject(e,index)}
     variant="outlined"
     sx={{ width: "100%" }}
   />
   <HelperText>
    {isDescriptionError}
   </HelperText>
 </Stack>


 <Stack
                sx={{
                  gap: "10px",
                  width: "50%",
                  textAlign: "start",
                }}>
                   <Field
                  value={item.totalAmountSpent}
                  placeholder="Total amoutnt spent"
                  onChange={(e)=>handleTotalAmountSpentObject(e,index)}
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <HelperText>
    {isTotalAmountSpentError}
   </HelperText>
              </Stack>
              
              


</Stack>







<Stack
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "30px",
              }}>
      
   
            
<Stack
                sx={{
                  width: "50%",

                  gap: "3px",
                  // justifyContent: "space-between",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Stack
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    flexDirection: "row",
                  }}>


<Field
                  value={item.quanity}
                  placeholder="Quantity"
                  onChange={(e)=>handleQuantityObject(e,index)}
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <HelperText>
    {isQuantityError}
   </HelperText>


             
                </Stack>

               
               
              </Stack>
          
</Stack>

<Stack
              sx={{
                justifyContent: "end",
                flexDirection: "row",
                gap: "30px",
              }}>


<Button  variant="contained" sx={{borderRadius:'25px'}}>
Add
</Button>


              </Stack>








<Divider/>





</Stack>





           ))}



{/* ------------------------------------------------------------------------------------------------------------- */}


















              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {detailData?.incident_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "200px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableHead>
                             <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 600,
                              }}>
                              Name
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 600,
                                
                              }}>
                             Quantity
                            </TableCell>


                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 600,
                                textAlign:'center'
                              }}>
                          Unit Price
                            </TableCell>
                      </TableHead>
                      <TableBody>
                        {detailData?.incident_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {item?.cleardate}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    No incident recorded
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  {/* {detailData?.incidstatus !== "Open" && (
                    <Link to={`/record-incident/${params.id}`}>
                      <Button
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Add Incident
                      </Button>
                    </Link>
                  )} */}

                  {detailData?.incidstatus === "Open" && (
                    <Link
                      // to={`/clear-incident/${params.id}`}
                      to={`/incident-list/${detailData?.incidid}`}>
                      <Button
                        onClick={handleClick}
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Clear Incident
                      </Button>
                    </Link>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>





              <Stack sx={{ flex: "1", gap: "20px" }}>
              <Stack sx={{ height: "300px" }}>
                <>
                  <Stack
                    sx={{
                      flex: "1",

                      padding: "10px",
                      bgcolor: "white",
                    }}>
                    <Stack
                      sx={{
                        width: "100%",
                        gap: "10px",
                        textAlign: "start",
                      }}>
                      <Label variant="subtitle1">Warranty</Label>
                      <Divider />
                      <Stack
                        sx={{
                          width: "100%",

                          gap: "10px",
                        }}>
                        {detailData?.wimg !== "NA" ? (
                          <Stack sx={{ bgcolor: "white", height: "100%" }}>
                            <WarrantyInnerBorderBoxBox>
                              <InnerWarrantyImageBox>
                                <IconButton onClick={handlePreviousCertificate}>
                                  <KeyboardBackspaceIcon
                                    style={{ color: "#4690FF" }}
                                  />
                                </IconButton>

                                <WarrantyImageCard
                                  as="img"
                                  src={`${
                                    certificateImage[count2]?.imgid
                                      ?.split(".")
                                      ?.pop() === "pdf"
                                      ? pdfImage
                                      : certificateImage[count2]?.imgurl
                                  }`}
                                  alt="check"
                                  onClick={() =>
                                    handleImageOpen(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }
                                />
                                <IconButton onClick={handleNextCertificate}>
                                  <EastIcon
                                    style={{ color: "#4690FF" }}
                                  />
                                </IconButton>
                              </InnerWarrantyImageBox>
                              <Stack
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  marginTop: "20px",
                                }}>
                                <Typography>Warranty Certificate</Typography>
                                <IconButton
                                  onClick={() =>
                                    handleDownload(
                                      certificateImage[count2]?.imgurl
                                    )
                                  }>
                                  <DownloadIcon />
                                </IconButton>
                              </Stack>
                            </WarrantyInnerBorderBoxBox>
                           
                          </Stack>
                        ) : (
                          <Stack
                            sx={{
                              height: "150px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            No warranty
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              </Stack>
            </Stack>



            </Stack>
       
        </Stack>

        <Stack sx={{ flex: "1", gap: "20px" }}>


          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}>
              <Label variant="subtitle1">Incident History</Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {detailData?.incident_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.incident_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {item?.cleardate}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    No incident recorded
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                  {detailData?.incidstatus !== "Open" && (
                    <Link to={`/record-incident/${params.id}`}>
                      <Button
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Add Incident
                      </Button>
                    </Link>
                  )}

                  {detailData?.incidstatus === "Open" && (
                    <Link
                      // to={`/clear-incident/${params.id}`}
                      to={`/incident-list/${detailData?.incidid}`}>
                      <Button
                        onClick={handleClick}
                        variant="contained"
                        sx={{ borderRadius: "22px ", width: "130px" }}>
                        Clear Incident
                      </Button>
                    </Link>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>



          <Stack
            sx={{
              flex: "1",
              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}>
              <Label variant="subtitle1">Service History</Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {detailData?.service_history !== "No History" ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {detailData?.service_history?.map((item) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {item?.servstatus}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() =>
                                    dispatch(
                                      DownloadPDF(
                                        item?.reporturl,
                                        item?.assetname
                                      )
                                    )
                                  }
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                  No service history
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
              
                {detailData?.servstatus ==="NA" && 
                    <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    sx={{ borderRadius: "22px ", width: "130px" }}>
                    Add Service
                  </Button>}

                  {detailData?.servstatus ==="Open" && 
                    <Button
                    onClick={()=>navigate(`/service-list/${detailData.servid}`)}
                    variant="contained"
                    sx={{ borderRadius: "22px ", width: "130px" }}>
                    Clear Service
                  </Button>}
                 
                </Stack>
              </Stack>
            </Stack>
          </Stack>




          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}>
              <Label variant="subtitle1">Accessory History</Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {reportImage?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {reportImage?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {reportImageName[index]}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() => handleDownload(item?.imgurl)}
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    No reports uploaded yet
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                 
                  {/* <UpdateReport assetId={params.id} /> */}
                  <Button sx={{borderRadius:'20px'}} variant="outlined">
                     Add History
                  </Button>
                </Stack>



                {/* {reportImage?.length === 0 && (
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    bgcolor: "white",
                    display: "flex",
                    alignItems: "center",

                    borderRadius: "40px",
                    height: "50px",
                  }}>
                  <SubTitle>Report</SubTitle>
                  <UpdateReport assetId={params.id} />
                </Box>
              )} */}
              </Stack>
            </Stack>
          </Stack>








          <Stack
            sx={{
              flex: "1",

              padding: "10px",
              bgcolor: "white",
            }}>
            <Stack
              sx={{
                width: "100%",
                gap: "10px",
                textAlign: "start",
              }}>
              <Label variant="subtitle1">Report</Label>
              <Divider />
              <Stack
                sx={{
                  width: "100%",

                  gap: "10px",
                }}>
                {reportImage?.length > 0 ? (
                  <TableContainer
                    style={{
                      overflowX: "auto",
                      maxWidth: "100%",
                      height: "150px",
                    }}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {reportImage?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 500,
                              }}>
                              {reportImageName[index]}
                            </TableCells>

                            <TableCells> Download PDf</TableCells>
                            <TableCells sx={{ textAlign: "center" }}>
                              <IconButton
                                disabled={userAccess?.role === "Guest"}>
                                <DownloadIcon
                                  onClick={() => handleDownload(item?.imgurl)}
                                  sx={{
                                    cursor: "pointer",
                                    color:
                                      userAccess?.role === "Guest"
                                        ? "grey"
                                        : "black",
                                  }}
                                  color="warning"
                                />
                              </IconButton>
                            </TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Stack
                    sx={{
                      height: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    No reports uploaded yet
                  </Stack>
                )}
                <Divider />
                <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                 
                  <UpdateReport assetId={params.id} />
                </Stack>



                {/* {reportImage?.length === 0 && (
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    bgcolor: "white",
                    display: "flex",
                    alignItems: "center",

                    borderRadius: "40px",
                    height: "50px",
                  }}>
                  <SubTitle>Report</SubTitle>
                  <UpdateReport assetId={params.id} />
                </Box>
              )} */}
              </Stack>
            </Stack>
          </Stack>



        



        </Stack>
        
      </MainWrapper>



      <div style={{}}>
        <Modal
          keepMounted
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description">
          <ModalBox sx={{ bgcolor: "background.paper" }}>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "end",
                paddingRight: "10px",
              }}>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",

                textAlign: "center",
                alignItems: "center",
              }}>
              <Label variant="h6">Request services</Label>

              <Stack sx={{ width: "90%" }}>
                <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
              </Stack>

             
              <Stack gap={"10px"} sx={{ width: "90%" }}>
                <Label textAlign={"start"}>Choose Service</Label>
                <Stack
                  flexDirection={"row"}
                  gap={"10px"}
                  textAlign={"start"}
                  justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Medpick",params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}>
                    Medpick
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("Others",params.id)}
                    sx={{
                      borderRadius: "35px",
                      width: "150px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}>
                    Others
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </ModalBox>
        </Modal>
      </div>


      <ViewImageModal imageUrl={detailData?.qrimg} handleClose={handleCloseQr} open={open} />

    </Box>
  );
}

export default AssetDetailPage;
