import { AppBar, Stack, Toolbar, Typography } from '@mui/material'
import React from 'react'

const FooterMain = () => {
  return (
    <AppBar
    position="static"
    sx={{
      top: "auto",
      bottom: 0,
      zIndex: 999,
      bgcolor: "rgba(27, 37, 53, 1)",
    }}>
    <Toolbar>
      <Stack
        sx={{
          width: "100%",

          alignItems: "flex-end",
          flexDirection: "column",
        }}>
        <Typography variant="body1" component="div" sx={{}}>
          <span style={{ marginRight: "20px" }}>Help</span>
          <span style={{ marginRight: "20px" }}>FAQ</span>
          <span style={{ marginRight: "20px" }}>Contact</span>
          <span style={{ marginRight: "20px" }}>Terms and Conditions</span>
          <span style={{ marginRight: "20px" }}>Privacy Policy</span>
        </Typography>
      </Stack>
    </Toolbar>
  </AppBar>
  )
}

export default FooterMain
