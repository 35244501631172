import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import EastIcon from "@mui/icons-material/East";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  IconButton,
  Stack,
  Typography,
  styled,
  Tooltip,
  Checkbox,
  Button,
} from "@mui/material";
import { useState } from "react";
import { bulkAssetDelete, getNewAssets, retrieveAsset } from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import BulkUploadAssetPopUp from "./BulkUploadAssetPopUp";
import BulkDeleteDeleteModal from "../components/BulkDeleteModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  whiteSpace: "nowrap",
}));

const AllAsset = ({handleDataChange}) => {
  const allAssets = useSelector((store) => store.data.retrieveAsset);
  const dataCount = useSelector((store) => store.data.allAssetsNew);
  const [allCounts, setAllCounts] = useState({});
  
  const deletedStatus = useSelector((store) => store.data.bulkAssetDelete);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const deleted = useSelector((store) => store.data.deleteAsset);

  const [filteredData, setFilteredData] = useState([]);

  const [toggle, setToggle] = useState(false);

  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);

  const [filterValue, setFilterValue] = useState(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllClick = () => {
    const allAssetIds = data?.map((item) => item.assetid);

    if (selectAll) {
      // Deselect all
      setSelectedAssetIds([]);
    } else {
      // Select all
      setSelectedAssetIds(allAssetIds);
    }

    setSelectAll(!selectAll);
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Asset is not selected, add it to the array
      newSelected = [...selectedAssetIds, assetId];
    } else {
      // Asset is selected, remove it from the array
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
    }

    setSelectedAssetIds(newSelected);
  };

  useEffect(() => {
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  }, [filterValue]);

  // const text = "asset";

  const handleOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(retrieveAsset(data, token));
    setToggle(false);
  }, [dispatch, deleted, toggle, deletedStatus]);

  // useEffect(() => {
  //   if (allAssets && allAssets?.length > 0) {
  //     setData(allAssets);
  //   }
  // }, [allAssets, deleted, deletedStatus]);

  const handleBulkDelete = () => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetids: selectedAssetIds,
      branchid: userBranch.id,
    };
    dispatch(bulkAssetDelete(data));
    setToggle(true);
    setOpenBulkDeleteModal(false);
  };




useEffect(()=>{
  let data = {
    hospid: ids.hospid,
    userid: ids.userid,
    branchid: userBranch.id,
    depart_ids: userAccess.departmentids,
  };

dispatch(getNewAssets(data))
},[])


useEffect(()=>{
if(Object.keys(dataCount)?.length>0){
  setData(dataCount?.assets);
 let data = {
  department:dataCount?.department_count||0 ,
  checkout:dataCount?.checkout_count||0,
  movements:dataCount?.movement_count||0
 }
  setAllCounts(data);
}
},[dataCount])


useEffect(()=>{
  if(handleDataChange!==undefined){
    let data2 = {
      department:allCounts?.department,
      asset:filteredData?.length,
      movement:allCounts?.movements,
      checkout:allCounts?.checkout
    }
      handleDataChange(data2)
  }

},[filteredData,allCounts])



  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px">
    
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="stretch"
        gap="10px"
        sx={{ display: { xs: "none", sm: "none", lg: "flex" } }}>
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {allCounts?.department}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label>Departments</Label>
              <IconButton onClick={() => navigate("/department-list")}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {allCounts?.checkout}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label>Check Outs</Label>
              <IconButton onClick={() => navigate("/check-in")}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="100px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {allCounts?.movements}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label>Asset movements</Label>
              <IconButton onClick={() => navigate("/asset-history-list")}>
                <EastIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}>
          <Label variant="h6">
            Assets.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          <IconButton>
            <AddCircleIcon
              style={{ color: "#4690FF" }}
              onClick={() => navigate("/add-asset")}
            />
          </IconButton>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px">
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="department"
              Filter2="cmcamc"
              FilterName1="Department"
              FilterName2="AMC/CMC Status"
              sortDate="cmcamcdate"
              sortPrice="price"
              dueDate=""
              name="name"
              csvName="All Assets"
            />
          </Box>

          <Box
            sx={{
              flex: 1,
              width: "100%",
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}>
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-around"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}>
              {selectedAssetIds?.length > 0 && (
                <IconButton
                  onClick={handleOpenBulkDeleteModal}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "27px ",
                    height: "27px",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                  }}>
                  
                  <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
                </IconButton>
              )}
              <IconButton
                onClick={() => navigate("/add-asset")}
                sx={{
                  bgcolor: "#1B2535",
                  width: "27px ",
                  height: "27px",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                }}>
                <AddIcon style={{ color: "white", fontSize: "20px" }} />
              </IconButton>

              <BulkUploadAssetPopUp />
            </Stack>
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          {/* Table */}
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableHeading>
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selectedAssetIds.length > 0 &&
                        selectedAssetIds.length < data?.length
                      }
                      checked={selectedAssetIds.length === data?.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all assets",
                      }}
                    />
                  </TableHeading>
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>
                  <TableHeading>Brand</TableHeading>
                  <TableHeading>Department</TableHeading>
                  <TableHeading>Status</TableHeading>
                  <TableHeading>Warranty expiry</TableHeading>

                  <TableHeading>AMC/CMC</TableHeading>
                  <TableHeading></TableHeading>
                </TableRow>
              </TableHead>

              <TableBody style={{ overflowY: "scroll" }}>
                {filteredData.map((item, index) => (
                  <TableRow key={item.assetid}>
                    <TableCells>
                      <Checkbox
                        checked={selectedAssetIds.includes(item.assetid)}
                        onChange={() => handleSelectRowClick(item.assetid)}
                      />
                    </TableCells>
                    <TableCells
                      component={Link}
                      to={`/asset-detail/${item.assetid}`}>
                      <Tooltip title={item.name}>{item.name}</Tooltip>
                    </TableCells>

                    <TableCells>
                      <Tooltip title={item.assetcode}>{item.assetcode}</Tooltip>
                    </TableCells>
                    <TableCells>
                      <Tooltip title={item.brand}>{item.brand}</Tooltip>
                    </TableCells>
                    <TableCells>
                      <Tooltip title={item.department}>
                        {item.department}
                      </Tooltip>
                    </TableCells>

                    <TableCells
                      sx={{
                        // textAlign: "center",
                        color: "rgba(70, 144, 255, 1)",
                      }}>
                      {item?.status !== "Working" ? (
                        <Button
                          size="small"
                          sx={{
                            borderRadius: "35px",
                            textTransform: "none",
                            width: "90px",
                            height: "25px",
                            bgcolor: "rgba(255, 107, 0, 1)",
                            "&:hover": {
                              bgcolor: "rgba(255, 107, 0, 1)",
                            },
                          }}
                          variant="contained">
                          {item.status}
                        </Button>
                      ) : (
                        <>{item.status}</>
                      )}
                    </TableCells>

                    <TableCells>{item.warranty_expdate}</TableCells>
                    
                    <TableCells>{item.cmcamc}</TableCells>
                    <TableCells>
                      <BorderColorIcon
                        disabled={userAccess?.editprod !== "Yes"}
                        onClick={() => navigate(`/edit-asset/${item.assetid}`)}
                      />
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <BulkDeleteDeleteModal
        open={openBulkDeleteModal}
        onClose={handleCloseBulkDeleteModal}
        onDelete={handleBulkDelete}
        text={selectedAssetIds?.length > 1
          ? `Are you sure you want to delete ${selectedAssetIds?.length} assets?`
          : `Are you sure you want to delete ${selectedAssetIds?.length} asset?`}
        count={selectedAssetIds?.length}
      />
    </Box>
  );
};

export default AllAsset;
