import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery,styled, Box, Modal, Stack, IconButton, Divider, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import deleteimg from "../images/delete.png";


const style = {
  bgcolor: "background.paper",
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "300px",
}));

function BulkDeleteDeleteModal({ open, onClose, onDelete,  text, count }) {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dialogStyle = {
    width: isSmallScreen ? "80vw" : "40vw",
    height: "220px",
    borderRadius: "8px",
  };

  const imageStyle = {
    width: "70px",
    height: "70px",
  };



  const style = {
    bgcolor: "background.paper",
  };
  
  const ModalBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 340,
    bgcolor: "white",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
    height: "320px",
  }));


  return (
  

<div style={{}}>
<Modal
  keepMounted
  open={open}

  onClose={onClose}
  aria-labelledby="keep-mounted-modal-title"
  aria-describedby="keep-mounted-modal-description">
  <ModalBox sx={style}>
    <Stack
      sx={{
        alignItems: "flex-end",
        justifyContent: "end",
        paddingRight: "10px",
      }}>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
    <Stack
      sx={{
        display: "flex",
        gap: "20px",
        flexDirection: "column",

        textAlign: "center",
        alignItems: "center",
      }}>
      <IconButton style={{ color: "red" }}>
        <CloseIcon sx={{fontSize:'80px'}} />
      </IconButton>
      <Typography variant="h6" style={{ fontWeight: 700, padding:'10px' }}>
        {text}
      </Typography>

      <Stack sx={{ width: "90%" }}>
        <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
      </Stack>


      <Box sx={{ width: "90%", display:'flex', gap:'20px', justifyContent:'center' }}>
      <Button
        size="small"
        variant="contained"
        onClick={onClose}
        sx={{
          borderRadius: "35px",

          textTransform: "none",
          bgcolor: "rgba(70, 144, 255, 1)",
        }}>
        Cancel
      </Button>



      <Button
        size="small"
        variant="contained"
        onClick={onDelete}
        sx={{
          borderRadius: "35px",

          textTransform: "none",
          bgcolor: "rgba(70, 144, 255, 1)",
        }}>
        Delete
      </Button>
      </Box>
     
    </Stack>
  </ModalBox>
</Modal>
</div>
  );
}
export default BulkDeleteDeleteModal;
