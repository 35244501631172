import React, { useEffect, useRef, useState } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import html2canvas from "html2canvas";
import vajraBlack from '../images/vajraBlack.png'
import jsPDF from "jspdf";
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, OutlinedInput, Typography, styled,Button, Divider ,


  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper


} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyReport } from "../redux/data/action";
import moment from "moment";


const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap:'130px',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  height:'100%',
  width:'33%',
  padding: theme.spacing(2),

}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  // background:'white',
  // border:'1px solid red',
  width: "100%",
  height: "60px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopRight = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: "white",
  // border: "1px solid red",
  justifyContent:'center',
  alignItems:'center',
  width: "30%",
  height: "60px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const TopLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent:'space-between',
  background: "white",
  // border: "1px solid red",
  alignItems: "center",
  width: "100%",
  height: "60px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopInnerLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: "white",
  // border: "1px solid red",
  paddingLeft:'10px',
  height: "50px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopInnerRight = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: "white",
  // border: "1px solid red",
  paddingLeft:'10px',
  height: "50px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  background:'white',
  // border:'3px solid red',
  width: "100%",
  height:"497mm",
  padding:'10px',

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));



const ImageBox = styled(Box)(({ theme }) => ({
   width:'100%',
  
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const ImageCard = styled(Box)(({ theme }) => ({
  width:'90px',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));


const SecondTitleBox = styled(Box)(({ theme }) => ({
  width:'100%',
  // border:'1px solid red',
  display:'flex',
  justifyContent:'space-between',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));


const TitleBoxLeft = styled(Box)(({ theme }) => ({
  width:'100%',
  display:'flex',
  justifyContent:'start',
  alignItems:'start',
  // border:'1px solid blue',
  flexDirection:'column',
  gap:'10px',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const TitleBoxRight = styled(Box)(({ theme }) => ({
  width:'100%',
  display:'flex',
  // border:'1px solid blue',
  flexDirection:'column',
  gap:'10px',
  justifyContent:'end',
  alignItems:'end',

 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));


const Title3 = styled(Box)(({ theme }) => ({
  width:'100%',
  // border:'1px solid red',
  display:'flex',
  gap:'30px',
  justifyContent:'space-between',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const GraphBox = styled(Box)(({ theme }) => ({
  width:'100%',
  
  display:'flex',
  justifyContent:'space-between',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const LeftGraphBox = styled(Box)(({ theme }) => ({
  width:'50%',
  height:'300px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  // border:'1px solid red',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));



const RightGraphBox = styled(Box)(({ theme }) => ({
  width:'50%',
  height:'300px',
  display:'flex',
  alignItemes:'start',
  flexDirection:'column',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const FullGraph = styled(Box)(({ theme }) => ({
  width:'100%',
  height:'300px',
  display:'flex',
  justifyContent:'space-between',
  alignItems:'start',
  // border:'2px solid red',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const TableSet = styled(Box)(({ theme }) => ({
  width:'100%',
  height:'300px',
  display:'grid',
  gridTemplateColumns:'repeat(3,1fr)',
  // border:'1px solid red',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const SubTableSet = styled(Box)(({ theme }) => ({
  width:'100%',
  height:'300px',
  padding:'10px',
  border:'1px solid #848484',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const IncidentTableSet = styled(Box)(({ theme }) => ({
  width:'100%',

  padding:'10px',
  border:'1px solid #848484',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));

const IncidentSubTableSet = styled(Box)(({ theme }) => ({
  width:'100%',

  padding:'10px',
  border:'1px solid #848484',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {},
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
}));



const DonutChart = () => {
  const chartRef = useRef(null);
  const dispatch = useDispatch()
  const monthlyReport = useSelector((store) => store.data.monthlyReport) || [];
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [toggle, setToggle] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const [piChart, setPiChart] = useState([]);
  const [incidentData, setIncidentData] = useState([]);
  const [incidentPerDep, setIncidentPerDep] = useState([]);
  const [servicePerDep, setServicePerDep] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceChart, setServiceChart] = useState([]);
  const [incidentChart, setIncidentChart] = useState([]);
  const [assetTrackingChart, setAssetTrackingChart] = useState([]);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
    let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));


const handlePrintingScreen = ()=>{

  setToggle(true)


  setTimeout(() => {
    generatePDF()
    setToggle(false)
  }, 4000)


}

  const generatePDF = () => {
    const input = chartRef.current;
    html2canvas(input).then((canvas) => {
      const imgWidth = 190;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const pdf = new jsPDF("p", "mm", "a4");
      let position = 10;

      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        10,
        position,
        imgWidth,
        imgHeight
      );
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          canvas.toDataURL("image/png"),
          "PNG",
          10,
          position,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;
      }

      pdf.save("chart.pdf");
    });
  };



  const data = {
    checkouts: {
      checkedOut: 7,
      checkedIn: 5
    },
    incidents: {
      reported: 15,
      totalDowntime: '2 Days, 12h : 23m : 34s',
      closed: 12,
      open: 3,
      budgetSpent: '₹10,000'
    },
    services: {
      requested: 5,
      closed: 3,
      open: 2,
      budgetSpent: '₹10,000',
      fromMedpick: 3,
      fromOthers: 2
    }
  };




  const formattedData = piChart?.map((item, index) => {
    const colors = [ "#4979d1","#ee7743", "#f9d978"];
    return {
      id: item[0],
      value: item[1],
      color: colors[index],
      label:item[0]
    };
  });




const formattedBarData = assetTrackingChart?.map((item, index) => {
  const colors = ["#4979d1", "#ee7743", "#f9d978"];
  return {
    id: item[2],
    open: parseInt(item[0]),
    closed: parseInt(item[1]),
    color: colors[index],
    label: item[2]
  };
});

const serviceBarData = serviceChart?.map((item, index) => {
  const colors = ["#4979d1", "#ee7743", "#f9d978"];
  return {
    id: item[2],
    open: parseInt(item[0]),
    closed: parseInt(item[1]),
    color: colors[index],
    label: item[2]
  };
});


const incidentBarData = incidentChart?.map((item, index) => {
  const colors = ["#4979d1", "#ee7743", "#f9d978"];
  return {
    id: item[2],
    open: parseInt(item[0]),
    closed: parseInt(item[1]),
    color: colors[index],
    label: item[2]
  };
});



const handleDataeChecker = ()=>{
  const date = dayjs(selectedDate);
  const month = date.format('MM'); 
  const year = date.format('YYYY'); 
  console.log("selectedDate",year,month)

}






// console.log("selectedDate",selectedDate)



  const convertTime = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } = downtime
    ;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} month${totalDuration.months() > 1 ? "s" : ""}`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };




  
useEffect(()=>{
let data = {
  hospid: userIds?.hospid,
  userid: userIds?.userid,
  branchid: userBranch.id,
  depart_ids: userAccess.departmentids,
  branchname: userBranch.branch,
  month:"07",
  year:"2024"
}

dispatch(getMonthlyReport(data))

},[])



useEffect(()=>{
  const date = dayjs(selectedDate);
  const month = date.format('MM'); 
  const year = date.format('YYYY'); 

  let data = {
    hospid: userIds?.hospid,
    userid: userIds?.userid,
    branchid: userBranch.id,
    depart_ids: userAccess.departmentids,
    branchname: userBranch.branch,
    month:month,
    year:year
  }
  
  dispatch(getMonthlyReport(data))
},[selectedDate])


useEffect(()=>{
if(Object.keys(monthlyReport)?.length>0){
  setTotalData(monthlyReport)
  setPiChart(monthlyReport?.total)
  setAssetTrackingChart(monthlyReport?.asset_tracking)
  setServiceChart(monthlyReport?.service_report_graphs)
  setIncidentChart(monthlyReport?.incident_report_graphs)
  setDowntime(monthlyReport?.total_downtime)
  setIncidentData(monthlyReport?.incidents)
  setServiceData(monthlyReport?.services)
  setIncidentPerDep(monthlyReport?.incidents_reported_per_department)
  setServicePerDep(monthlyReport?.services_reported_per_department)
}
},[monthlyReport])



const MyBarChart = styled(BarChart)(({ theme }) => ({
  '& .MuiChart-bar': {
    maxWidth: '30px',
  },
}));




  return (
    <Wrapper ref={chartRef} sx={{}}>




<MainContainer>
      <TopContainer>
        <TopLeft>
          <Box sx={{paddingLeft:'10px'}}>
          <Label>Monthly Report</Label>
          </Box>
        
          <TopInnerRight >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={['year', 'month']}
        sx={{background:'white'}}
        // label="Month and Year"
        minDate={dayjs('2020-01-01')}
        maxDate={dayjs('2030-12-31')}
        value={selectedDate}
        onChange={(newValue) => {
          setSelectedDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} helperText={null} />}
      />
    </LocalizationProvider>
          </TopInnerRight>
        </TopLeft>
        {!toggle && <TopRight>
          <Button onClick={handlePrintingScreen} sx={{borderRadius:'20px'}} variant="contained">
               Download PDF
          </Button>
        </TopRight>}
      </TopContainer>

      <MainContainer >
        <ImageBox>
          <ImageCard as="img" src={vajraBlack} />
        </ImageBox>

        <SecondTitleBox>
<TitleBoxLeft>
<Label>
Hospital: {userIds.hospname}
</Label>
<Label>
Branch: {userBranch.branch}
</Label>
</TitleBoxLeft>
<TitleBoxRight>
<Label>
Monthly rating: {totalData?.monthly_rating}
</Label>
<Label>
Total assets: {totalData?.total_assets}
</Label>
</TitleBoxRight>
        </SecondTitleBox>

<Divider/>

<Title3>
<Label>
Overview: Assets
</Label>



</Title3>

<GraphBox>
  <LeftGraphBox>
  <PieChart
      series={[
        {
          data: formattedData,
          innerRadius: 50, 
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          
        },
      ]}
      height={200}
     
    />
  </LeftGraphBox>
  <RightGraphBox>
    <Title3 sx={{paddingLeft:'50px'}}>
    <Typography>Asset tracking</Typography> 
    </Title3>

    <BarChart
    // series={[
    //   { data: formattedBarData?.map(item => item.open), stack: 'A', label: 'Open', color: "#4979d1" },
    //   { data: formattedBarData.map(item => item.closed), stack: 'A', label: 'Closed', color: "#ee7743" },
    // ]}
    // xAxis={{
    //   labels: formattedBarData?.map(item => item.label)
    // }}
    // barLabel={(item, context) => {
    //   if ((item.value ?? 0) > 10) {
    //     return 'High';
    //   }
    //   return context.bar.height < 60 ? null : item.value?.toString();
    // }}
    // height={300}



    series={[
      { data: formattedBarData?.map(item => item.open), stack: 'A', label: 'Open', color:"#4979d1"  },
      { data:formattedBarData.map(item => item.closed), stack: 'A', label: 'Closed', color:"#ee7743"  },
     
    ]}
    xAxis={[{ scaleType: 'band', data: formattedBarData?.map(item => item.label) }]}
    barLabel={(item, context) => {
      if ((item.value ?? 0) > 10) {
        return 'High';
      }
      return context.bar.height < 60 ? null : item.value?.toString();
    }}
    // width={500}
    height={300}
    


  />
  </RightGraphBox>
</GraphBox>

<Divider/>


<Title3>
<Label>
Not working assets: {totalData?.not_working_assets} Assets
</Label>

<Label>
Total Downtime: {convertTime()}
</Label>
</Title3>




{/* <TableSet>
    <SubTableSet>
<Title3>
<Label>
Check outs:
</Label>
</Title3>
    </SubTableSet>

    <SubTableSet>
    <Title3>
    <Label>
    Incidents:
</Label>
</Title3>
    </SubTableSet>

    <SubTableSet>
    <Title3>
    <Label>
    Services:
</Label>
</Title3>
    </SubTableSet>
</TableSet> */}


    <TableContainer sx={{background:'white'}}>
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell>
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '200px', gap: '10px' }}>
                <Label>Check outs:</Label>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Checked out:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.checkouts.checkedOut}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Checked in:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.checkouts.checkedIn}</Typography>
                  </Box>
                </Box>
              </Box>
            </StyledTableCell>

            <StyledTableCell>
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '200px', gap: '10px' }}>
                <Label>Incidents:</Label>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Incidents:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.incidents.reported} Reported</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Total Downtime:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.incidents.totalDowntime}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Closed:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.incidents.closed}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Open:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.incidents.open}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Budget Spent:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.incidents.budgetSpent}</Typography>
                  </Box>
                </Box>
              </Box>
            </StyledTableCell>

            <StyledTableCell>
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '200px', gap: '10px' }}>
                <Label>Services:</Label>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Services:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.services.requested} Requested</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Closed:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.services.closed}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Open:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.services.open}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Budget Spent:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.services.budgetSpent}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>From Medpick:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.services.fromMedpick}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>From Others:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.services.fromOthers}</Typography>
                  </Box>
                </Box>
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
<Divider/>

<Title3>
  <Label>
  Incidents Reported: {incidentData?.reported_incident}
  </Label>
</Title3>


<GraphBox>
<FullGraph>
<MyBarChart
      series={[
        { data: incidentBarData?.map(item => item.open), stack: 'A', label: 'Open', color:"#4979d1"  },
        { data:incidentBarData.map(item => item.closed), stack: 'A', label: 'Closed', color:"#ee7743"  },
        // { data: [14, 6, 5, 8, 9], label: 'Series B1' },
      ]}
      xAxis={[{ scaleType: 'band', data: incidentBarData?.map(item => item.label) }]}
      barLabel={(item, context) => {
        if ((item.value ?? 0) > 10) {
          return 'High';
        }
        return context.bar.height < 60 ? null : item.value?.toString();
      }}
      // width={600}
    
      height={300}
     
    />
</FullGraph>
</GraphBox>




<Title3>
  <Label>
  Services Requested: {serviceData?.reported_service}
  </Label>
</Title3>


<GraphBox>
<FullGraph>
<BarChart
sx={{
  '& .MuiChart-bar': {
    minWidth: '10px', 
    maxWidth: '30px', 
  },
}}
     series={[
      { data: serviceBarData?.map(item => item.open), stack: 'A', label: 'Checkout', color:"#4979d1"  },
      { data:serviceBarData.map(item => item.closed), stack: 'A', label: 'Checkin', color:"#ee7743"  },
      // { data: [14, 6, 5, 8, 9], label: 'Series B1' },
    ]}
    xAxis={[{ scaleType: 'band', data: serviceBarData?.map(item => item.label) }]}
      barLabel={(item, context) => {
        if ((item.value ?? 0) > 10) {
          return 'High';
        }
        return context.bar.height < 60 ? null : item.value?.toString();
      }}
      // width={600}
    
      height={300}
      
    />
</FullGraph>
</GraphBox>




      </MainContainer>

      </MainContainer>



<MainContainer>
<Divider/>

<Title3>
  <Label>
  Incidents Reported per department - 
  </Label>
</Title3>


{incidentPerDep?.map((item,index)=>(
  <IncidentTableSet>
<Title3>
  <Label sx={{paddingLeft:'10px'}}>
  Department {index+1}: {item?.department}
  </Label>
</Title3>

<IncidentSubTableSet>

<Title3>
  <Label>
  Incidents Reported: {item?.incident_reported}
  </Label>
</Title3>

<Title3>
  <Label>
  Incidents Open: {item?.open_incident}
  </Label>
</Title3>

<Title3>
  <Label>
  Total Downtime: 2 Days, 12h : 23m : 34s
  </Label>
</Title3>
</IncidentSubTableSet>

{item?.assets.map((item2,index2)=>(
  <>
<IncidentSubTableSet>

<Title3>
  <Label>
  Asset {index2+1}: {item2?.asset}
  </Label>
</Title3>
<Title3>
  <Label>
  Code: {item2?.code}
  </Label>
</Title3>
</IncidentSubTableSet>
<TableContainer >
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell>
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '200px', gap: '10px' }}>
                <Label>Incident Details:</Label>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Reported Date:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.report_date}</Typography>
                  </Box>
                </Box>
            

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Reported Time:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.report_time}</Typography>
                  </Box>
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Issue:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.issue}</Typography>
                  </Box>
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Downtime:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.downtime}</Typography>
                  </Box>
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Status:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.status}</Typography>
                  </Box>
                </Box>


               
              </Box>
            </StyledTableCell>

            <StyledTableCell>
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '200px', gap: '10px' }}>
                <Label>   Incident cleared details: </Label>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Reported Date:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.clear_reported_date} Reported</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Reported Time:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.clear_reported_date}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Action Taken:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.actiontaken}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Engineer:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.engineer}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Mobile No:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{data.incidents.budgetSpent}</Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Email ID:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>{item2?.emailid}</Typography>
                  </Box>
                </Box>
              </Box>
            </StyledTableCell>

            <StyledTableCell>
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '200px', gap: '10px' }}>
                <Label>Expenditure details:</Label>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Cost:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>500 </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Reason:</Typography>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Typography>Replacement</Typography>
                  </Box>
                </Box>

              </Box>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
 </TableContainer>
 </>
))}


</IncidentTableSet>
))}



</MainContainer>



    </Wrapper>
  );
};

export default DonutChart;
