import React, { useEffect, useState } from "react";
import { Modal, IconButton, Box, Typography,Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import pdfImage from "../images/pdfImage.png";


const ViewImageModal = ({ imageUrl, handleClose, open }) => {
  // const [fileType, setFileType] = useState(null);

  // useEffect(() => {
  //   function isPDFLink() {
  //     let fileLink = imageUrl
  //     if (fileLink) {
  //       setFileType("PDF");
  //     } else {
  //       setFileType(null);
  //     }
  //   }
  //   isPDFLink();
  // }, [imageUrl]);




  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.target = "_blank";
    // link.download = "downloaded_image.jpg";
    link.click();
  };

  const printImage = (imageSrc) => {
    if (imageSrc) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`<img src="${imageSrc}" onload="window.print();window.close()" />`);
      printWindow.document.close();
    } else {
      console.log('Image not downloaded yet.');
    }
  };
  




  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box
          display="flex"
          flexDirection="column"
          width='300px'
          height="300px"
          sx={{background:'white'}}
          padding='20px'
          justifyContent='space-between'
          gap={"5px"}
          alignItems="center">
            <Box sx={{display:'flex', justifyContent:'end', width:'100%'}}>
            <IconButton
            edge="end"
            color="inherit"
            sx={{ bgcolor: "white" }}
            onClick={handleClose}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
            </Box>
          
   
            <img
              src={imageUrl}
              alt="Zoomed_Image"
              style={{ width: "130px", heignt: "130px" }}
            />

            <Typography>Scan QR code to get asset info</Typography>
        

        <Box display={"flex"} flexDirection={"row"} justifyContent={'center'} width={"100%"} gap='30px'>
          <Button onClick={()=>printImage(imageUrl)} sx={{borderRadius:'20px'}}  variant="contained">
             Print
          </Button>
          <Button onClick={()=>handleDownload(imageUrl)} sx={{borderRadius:'20px'}} variant="contained">
            Download
            </Button>
        </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ViewImageModal;
