import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const NavbarMain = ({handleToggle,toggle}) => {
  const navigate = useNavigate();

  return (
    <AppBar
      position="sticky"
      sx={{
        top: 0,
        zIndex: 999,
        bgcolor: "rgba(27, 37, 53, 1)",
        height: "60px",
      }}>
      <Toolbar>
        <Stack
          sx={{
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "row",
          }}>
          <Box sx={{  height: "50px",
          display:'flex',
              alignItems: "center",
              cursor:'pointer',
              justifyContent: "center",
             }} onClick={() => navigate("/")}>
          <Typography
            variant="h6"
            // component="div"
            sx={{
            
            
              alignItems: "center",
              justifyContent: "center",
             
            }}>
            Vajra
          </Typography>
          </Box>
          
          <Box
            onClick={() => navigate("/")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
              // padding: '8px',
              borderRadius: "8px",
            }}>
            {/* <HomeIcon onClick={handleToggle} toggle={toggle}  /> */}
          </Box>
        </Stack>
      </Toolbar>













    </AppBar>
  );
};

export default NavbarMain;
