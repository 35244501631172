import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  styled,
  Divider,
  FormHelperText,
  Autocomplete,
} from "@mui/material";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import "jspdf-autotable";

import {
  checkOut,
  departmentAlreadySaved,
  retrieveAllAssets,
} from "../redux/nbmData/action";
import SuccessPopUp from "../components/SuccessPopUp";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const InnerContainer = styled(Box)(({ theme }) => ({
   width: "600px",
   borderRadius: "8px",
   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
   gap: "10px",
  [theme.breakpoints.down("xl")]: {
    
  },
  [theme.breakpoints.down("lg")]: {
    
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding:'10px'
  },
  [theme.breakpoints.down("sm")]: {
 

  
  },
  [theme.breakpoints.down("xs")]: {

  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",
  gap:'10px',
 [theme.breakpoints.down("xl")]: {
   
 },
 [theme.breakpoints.down("lg")]: {
   
 },
 [theme.breakpoints.down("md")]: {
   flexDirection:'column',
   width: "100%",
 },
 [theme.breakpoints.down("sm")]: {


 
 },
 [theme.breakpoints.down("xs")]: {

 },
}));


const InputContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  display:'flex',
  gap: "15px",
  flexDirection:'column',
  height: "100%",
  textAlign: "start",
 [theme.breakpoints.down("xl")]: {
   
 },
 [theme.breakpoints.down("lg")]: {
   
 },
 [theme.breakpoints.down("md")]: {
   flexDirection:'column',
   width: "100%",
 },
 [theme.breakpoints.down("sm")]: {


 
 },
 [theme.breakpoints.down("xs")]: {

 },
}));


const Wrapper = styled(Box)(({ theme }) => ({
  display:'flex',
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
 [theme.breakpoints.down("xl")]: {
   
 },
 [theme.breakpoints.down("lg")]: {
   
 },
 [theme.breakpoints.down("md")]: {
  //  padding:'10px'
 },
 [theme.breakpoints.down("sm")]: {


 
 },
 [theme.breakpoints.down("xs")]: {

 },
}));





const AddCheckOut = () => {
  const allDepartment = useSelector((store) => store.data.alreadySaved);
  const allAssets = useSelector((store) => store.data.allAssets);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [previousDepartment, setPreviousDepartment] = useState("");
  const [previousDepartmentId, setPreviousDepartmentId] = useState("");

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const [destination, setDestination] = useState("");

  const [formErrors, setFormErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    destination: "",
  });
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/check-in");
  };

  const isValidEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };

  const isValidMobile = (value) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "name":
        setName(value);
        break;

      case "destination":
        setDestination(value);
        break;
      case "mobile":
        setMobile(value);
        break;
      case "email":
        setEmail(value);
        break;

      default:
        break;
    }

    if (name === "name" && value.trim() === "") {
      setFormErrors({ ...formErrors, [name]: "Name is required" });
    } else if (name === "destination" && value.trim() === "") {
      setFormErrors({ ...formErrors, [name]: "Destination is required" });
    } else if (name === "email" && !isValidEmail(value)) {
      setFormErrors({ ...formErrors, [name]: "Invalid email address" });
    } else if (name === "mobile" && !isValidMobile(value)) {
      setFormErrors({ ...formErrors, [name]: "Invalid mobile number" });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedOption) {
      setErrorMessage("Please select an asset.");
      return;
    }

    if (
      name.trim() !== "" &&
      destination.trim() !== "" &&
      email.trim() !== "" &&
      mobile.trim() !== ""
    ) {
      if (Object.values(formErrors).every((error) => !error)) {
        let data = {
          hospid: ids?.hospid,
          hospname: ids.hospname,
          assetid: selectedOption.assetid,
          assetname: selectedOption.name,
          assetcode: selectedOption.assetcode,
          depart: previousDepartment,
          departid: previousDepartmentId,
          to_where: destination,
          cont_name: name,
          cont_email: email,
          cont_phone: mobile,
          userid: ids?.userid,
          username: ids?.username,
          userrole: ids?.role,
          branchid: userBranch?.id,
        };

        dispatch(checkOut(data))
          .then(() => {
            setOpenModal(true);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
      }
    } else {
      setFormErrors({
        ...formErrors,
        name: name.trim() === "" ? "Name is required" : "",
        destination: destination.trim() === "" ? "Destination is required" : "",
        email: email.trim() === "" ? "Email is required" : "",
        mobile: mobile.trim() === "" ? "Mobile is required" : "",
      });
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);

    const filteredOptions = allAssets.filter((option) => {
      return (
        option.name.toLowerCase().includes(newInputValue.toLowerCase()) ||
        option.assetcode.toLowerCase().includes(newInputValue.toLowerCase())
      );
    });

    setOptions(filteredOptions);
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (newValue) {
      setPreviousDepartment(newValue.department);

      const matchingDepartment = allDepartment.find(
        (department) => department.departname === newValue.department
      );

      if (matchingDepartment) {
        setPreviousDepartmentId(matchingDepartment.departid);
      } else {
        setPreviousDepartmentId("");
      }

      setErrorMessage("");
    } else {
      setPreviousDepartment("");
      setPreviousDepartmentId("");
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState(allAssets);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(departmentAlreadySaved(data, token));
    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (allAssets && allAssets?.length > 0 && allAssets?.length > 0) {
      setOptions(allAssets);
    }
  }, [allAssets]);

  return (
    <Wrapper>
      <InnerContainer>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "white",
            padding: "20px",
          }}>
          <Label variant="subtitle1" color="primary">
            New Check out
          </Label>
          

        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "white",
            }}>
            <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
              Details
            </Label>
          </Box>
          <Divider style={{ background: "rgba(70, 144, 255, 1)" }} />

          <BoxContainer>
            <InputContainer>
              <Label>Asset Details</Label>
              <Stack>
                <Autocomplete
                  displayEmpty
                  sx={{
                    bgcolor: "#EFF5FE",
                    "& fieldset": { border: "none" },
                    borderRadius: "5px",
                  }}
                  id="search-autocomplete"
                  inputProps={{ "aria-label": "Without label" }}
                  options={options}
                  getOptionLabel={(option) =>
                    option.name + " - " + option.assetcode
                  }
                  inputValue={searchTerm}
                  onInputChange={handleInputChange}
                  onChange={handleOptionChange}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      placeholder="e.g. Ventilator/V10-20HI1-0"
                      variant="outlined"
                    />
                  )}
                />
                <HelperText variant="caption">{errorMessage}</HelperText>
              </Stack>
              <Stack>
                <Field
                  value={destination}
                  name="destination"
                  label="Destination"
                  onChange={handleChange}
                  variant="filled"
                  multiline
                  InputProps={{ disableUnderline: true }}
                />

                <HelperText variant="caption">
                  {formErrors.destination}
                </HelperText>
              </Stack>
            </InputContainer>

            <InputContainer>
              <Label>Contact Person Details </Label>
              <Stack>
                <Field
                  name="name"
                  value={name}
                  label="Name"
                  onChange={handleChange}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />

                <HelperText variant="caption">{formErrors.name}</HelperText>
              </Stack>
              <Stack>
                <Field
                  name="mobile"
                  value={mobile}
                  label="Mobile"
                  onChange={handleChange}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />

                <HelperText variant="caption">{formErrors.mobile}</HelperText>
              </Stack>
              <Stack>
                <Field
                  name="email"
                  value={email}
                  label="Email"
                  onChange={handleChange}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />

                <HelperText variant="caption">{formErrors.email}</HelperText>
              </Stack>
            </InputContainer>
          </BoxContainer>

          <>
            <Stack
              sx={{
                width: "100%",
                justifyContent: "space-between",
                marginTop: "20px",
              }}>
              <Divider />
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "16px",
                  }}>
                  <Stack
                    sx={{
                      flexShrink: 1,
                      width: "100%",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{
                        width: "154px",
                        borderRadius: "22px",
                        bgcolor: "rgba(70, 144, 255, 1)",
                      }}>
                      Submit
                    </Button>
                  </Stack>
                </Box>
              </>
            </Stack>
          </>
        </Box>
      </InnerContainer>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful!"}
        subText1={"New check out added."}
        subText2={"  "}
      />
    </Wrapper>
  );
};

export default AddCheckOut;
