import axios from "axios";
import React, { useEffect } from "react";
export const POST_AUTH_REQUEST = "POST_AUTH_REQUEST";
export const POST_AUTH_SUCCESS = "POST_AUTH_SUCCESS";
export const POST_AUTH_FAILURE = "POST_AUTH_FAILURE";

export const GET_AUTH_REQUEST = "GET_AUTH_REQUEST";
export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS";
export const GET_AUTH_FAILURE = "GET_AUTH_FAILURE";

export const FORGOT_AUTH_REQUEST = "FORGOT_AUTH_REQUEST";
export const FORGOT_AUTH_SUCCESS = "FORGOT_AUTH_SUCCESS";
export const FORGOT_AUTH_FAILURE = "FORGOT_AUTH_FAILURE";

export const REGISTER_AUTH_REQUEST = "REGISTER_AUTH_REQUEST";
export const REGISTER_AUTH_SUCCESS = "REGISTER_AUTH_SUCCESS";
export const REGISTER_AUTH_FAILURE = "REGISTER_AUTH_FAILURE";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";


export const GET_VERIFICATION_LINK_REQUEST = "GET_VERIFICATION_LINK_REQUEST";
export const GET_VERIFICATION_LINK_SUCCESS = "GET_VERIFICATION_LINK_SUCCESS";
export const GET_VERIFICATION_LINK_FAILURE = "GET_VERIFICATION_LINK_FAILURE";





const registerUserRequest = () => {
  return { type: REGISTER_AUTH_REQUEST };
};

const registerUserSuccess = (data) => {
  return { type: REGISTER_AUTH_SUCCESS, payload: data };
};

const registerUserFailure = () => {
  return { type: REGISTER_AUTH_FAILURE };
};

const postAuthRequest = () => {
  return {
    type: POST_AUTH_REQUEST,
  };
};

const postAuthSuccess = (auth) => {
  return {
    type: POST_AUTH_SUCCESS,
    payload: auth,
  };
};

const postAuthFailure = () => {
  return {
    type: POST_AUTH_FAILURE,
  };
};

const getAuthRequest = () => {
  return {
    type: GET_AUTH_REQUEST,
  };
};

const getAuthSuccess = (auth) => {
  return {
    type: GET_AUTH_SUCCESS,
    payload: auth,
  };
};

const getAuthFailure = () => {
  return {
    type: GET_AUTH_FAILURE,
  };
};

const forgotAuthRequest = () => {
  return {
    type: FORGOT_AUTH_REQUEST,
  };
};

const forgotAuthSuccess = (auth) => {
  return {
    type: FORGOT_AUTH_SUCCESS,
    payload: auth,
  };
};

const forgotAuthFailure = () => {
  return {
    type: FORGOT_AUTH_FAILURE,
  };
};

const refreshTokenRequest = () => {
  return {
    type: REFRESH_TOKEN_REQUEST,
  };
};

const refreshTokenSuccess = (auth) => {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: auth,
  };
};

const refreshTokenFailure = () => {
  return {
    type: REFRESH_TOKEN_FAILURE,
  };
};



const getVerificationLinkRequest = () => {
  return { type: GET_VERIFICATION_LINK_REQUEST };
};

const getVerificationLinkSuccess = (data) => {
  return { type: GET_VERIFICATION_LINK_SUCCESS, payload: data };
};

const getVerificationLinkFailure = () => {
  return { type: GET_VERIFICATION_LINK_FAILURE };
};










export const postAuth = (data) => (dispatch) => {
  dispatch(postAuthRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_POST_SIGN_UP,
    data,
  })
    .then((res) => {
     
      dispatch(postAuthSuccess(res.data));
    })
    .catch((error) => {
      dispatch(postAuthFailure(error));
    });
};

export const getAuth = (data) => (dispatch) => {
  dispatch(getAuthRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_POST_SIGN_IN,
    data,
  })
    .then((res) => {

      dispatch(getAuthSuccess(res.data));
      
      if (res.data?.fbtoken) {
        localStorage.setItem("userToken", res.data?.fbtoken);
      }



      if (res.data?.userdata !== undefined) {
        if (
          (res.data?.userdata?.city !== "NA" ||
            res.data?.userdata?.city !== "") &&
          res.data?.message !== "Inactive"
        ) {
          localStorage.setItem("userData", JSON.stringify(res.data?.userdata));
          sessionStorage.setItem("userData", JSON.stringify(res.data?.userdata));
//  let branch = {
//             branch: res.data.userdata?.bm_branches[0],
//             id:res.data.userdata.bm_branchids[0]
//            }  
//            localStorage.setItem("userBranch", JSON.stringify(branch));
//            sessionStorage.setItem("userBranch", JSON.stringify(branch));




          let data = {
            fbtoken: res.data?.fbtoken,
            userid: res.data?.userdata?.userid,
            refreshToken:res.data?.ref_token
          };
         
          localStorage.setItem("userTokenId", JSON.stringify(data));
        }
        if (
          res.data?.userdata?.city === "NA" ||
          res.data?.userdata?.city === ""
        ) {
          let data = {
            fbtoken: res.data?.fbtoken,
            userid: res.data?.userdata?.userid,
            refreshToken:res.data?.ref_token
          };
          
          localStorage.setItem("userTokenId", JSON.stringify(data));
        }
      }

    
     
     
    })
    .catch((error) => {
      dispatch(getAuthFailure(error));
    });
};

export const forgotAuth = (data) => (dispatch) => {
  dispatch(forgotAuthRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_POST_RESET_PASSWORD,
    data,
  })
    .then((res) => {
   
      dispatch(forgotAuthSuccess(res.data));
    })
    .catch((error) => {
      dispatch(forgotAuthFailure(error));
    });
};

export const registerUser = (data) => (dispatch) => {
 
  dispatch(registerUserRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_UPDATE_PROFILE,
    data,
  })
    .then((res) => {
    
      dispatch(registerUserSuccess(res.data));
    })
    .catch((error) => {
     
      dispatch(registerUserFailure(error));
    });
};




export const getVerificationLink = (data) => (dispatch) => {
 
  dispatch(getVerificationLinkRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_GET_VERIFICATION_LINK,
    data,
  })
    .then((res) => {
    
      dispatch(getVerificationLinkSuccess(res.data));
    })
    .catch((error) => {
    
      dispatch(getVerificationLinkFailure());
    });
};


 
export const refreshToken = (data) => (dispatch) => {

  dispatch(refreshTokenRequest());
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_TOKEN,
    data,
  })
    .then((res) => {
    
     if(res.data?.success){
      localStorage.setItem("userToken", res.data?.token);
      window.location.reload()
     }

      dispatch(refreshTokenSuccess(res.data));
    })
    .catch((error) => {
    
      dispatch(refreshTokenFailure());
    });
};

