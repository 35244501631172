import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import taskSubmit from "../images/taskSubmit.png";
import DoneIcon from '@mui/icons-material/Done';
import taskFailed from "../images/taskFailed.png";
import { Divider, IconButton } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "350px", lg: "350px", md: "300px", sm: "300px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "0px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "0px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "300px",
  height: "300px",

  [theme.breakpoints.down("xl")]: {
    width: "250px",
    height: "250px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("md")]: {
    width: "180px",
    height: "180px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function TaskModal({ openModal, handleCloseModal, toggle }) {
  return (
    <div>
      <Modal
        keepMounted
        open={(openModal || !openModal) && toggle}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Box
            sx={{
              color: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              flexDirection: "column",
              marginTop: "20px",
            }}>
            {openModal && (
              <>
              <IconButton style={{ color: "rgba(0, 201, 32, 1)" }}>
                <DoneIcon sx={{fontSize:'50px'}} />
              </IconButton>

              <Divider style={{ width:'100%', background: "rgba(223, 223, 223, 1)" }} />
                {/* <ImageBox as="img" src={taskSubmit} alt="submit" /> */}

                <Typography sx={{ mt: 2, color:'black', textAlign: "center" }}>
                  {`Task Submitted Sucessfully`}
                </Typography>
              </>
            )}

            {openModal === false && (
              <>
                <ImageBox as="img" src={taskFailed} alt="failed" />
                <Typography sx={{ mt: 2, color: "red", textAlign: "center" }}>
                  {`Task Submission Failed`}
                </Typography>
              </>
            )}

            <Button
              variant="contained"
              onClick={handleCloseModal}
              sx={{
                borderRadius: "35px",

                margin: "auto",
                textTransform: "none"
              }}
              size="large">
              Got it
            </Button>
          </Box>
        </ModalBox>
      </Modal>
    </div>
  );
}
