import * as React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirtyLensIcon from '@mui/icons-material/DirtyLens';
import ReportIcon from "@mui/icons-material/Report";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";

export default function PrivateFooter() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/dashboard-mobile");
        break;
      case 1:
        navigate("/asset-mobile");
        break;
      case 2:
        navigate("/incident-mobile");
        break;
      case 3:
        navigate("/request-mobile");
        break;
      case 4:
        navigate("/user-mobile");
        break;
      default:
        break;
    }
  };

  return (
    <Paper
      sx={{
        position: "sticky",
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid #ddd",
        width: "100vw"
        
      }}
      >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleNavigationChange}
        sx={{ justifyContent: "space-around" }}>
        <BottomNavigationAction
          label="Home"
          icon={<DashboardIcon />}
          sx={{ width: "none", flex: 1 , padding:'0px',minWidth:0 }}
        />
        <BottomNavigationAction
          label="Assets"
          icon={<DirtyLensIcon />}
          sx={{ width: "none", flex: 1, padding:'0px',minWidth:0 }}
        />
        <BottomNavigationAction
          label="Incidents"
          icon={<ReportIcon />}
          sx={{ width: "none", flex: 1, padding:'0px',minWidth:0 }}
        />
        <BottomNavigationAction
          label="Requests"
          icon={<RequestPageIcon />}
          sx={{ width: "none", flex: 1, padding:'0px',minWidth:0 }}
        />
        <BottomNavigationAction
          label="Users"
          icon={<PeopleIcon />}
          sx={{ width: "none", flex: 1, padding:'0px',minWidth:0 }}
        />
      </BottomNavigation>
    </Paper>
  );
}
