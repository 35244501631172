import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import {
  assetDetailsData,
  departmentAssetDetails,
  getDepartmentData,
} from "../redux/data/action";
import { v4 as uuid } from "uuid";
const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const SellOnlinePage = () => {
  const departmentData = useSelector((store) => store.data.department) || [];
  const [showDetail, setShowDetail] = useState(false);
  const departAssetData = useSelector(
    (store) => store.data.departmentAssetDetails
  );

  const detailData = useSelector((store) => store.data.assetDetails);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [usefulLife, setUsefulLife] = useState("");
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState([]);
  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [price, setPrice] = useState("");
  const [isPriceError, setIsPriceError] = useState("");
  const [isUsefulLifeError, setIsUsefulLifeError] = useState("");
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [yearlyData, setYearlyData] = useState([]);
  const [assetId, setAssetId] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [data, setData] = useState({});

  const [toggle, setToggle] = useState(false);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  let idSplit = assetId.substring(0, 4);
  const filteredDepartments = departmentData.filter(
    (department) => parseInt(department.totalassets) > 0
  );
  console.log("filteredDepartments", filteredDepartments);
  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}_` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}_` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };
  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${idSplit}_` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setFileUrl([...fileUrl, sendData]);
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };
  const handleUsefulLife = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setUsefulLife(value);
    if (value === "" || regex.test(value)) {
      setUsefulLife(value);
      setIsUsefulLifeError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsUsefulLifeError("Enter valid number");
    }
    if (value === "") {
      setIsUsefulLifeError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(detailData)?.length > 0) {
      setData(detailData);
    }
  }, [detailData]);

  const handleSubmit = () => {
    price === "NA" && setIsPriceError("Enter valid price");
    usefulLife === "NA" && setIsUsefulLifeError("Enter valid year");
    price === "" && setIsPriceError("Please fill the field");
    usefulLife === "" && setIsUsefulLifeError("Please fill the field");
    if (
      price !== "NA" &&
      usefulLife !== "NA" &&
      price !== "" &&
      usefulLife !== "" &&
      isPriceError === "" &&
      isUsefulLifeError === ""
    ) {
      let usefulLifeCount = usefulLife;
      const depreciationPercentage = (2 / usefulLifeCount) * 100;
      let remainingPrice = price;

      const data = [];
      for (let year = 1; year <= usefulLifeCount; year++) {
        const depreciationExpense = Math.floor(
          (depreciationPercentage / 100) * remainingPrice
        );
        data.push({ year, depreciationExpense });
        remainingPrice -= depreciationExpense;
      }

      setYearlyData(data);
      setShow(true);
    }
  };

  const handleDepartment = (e, item) => {
    if (item !== null && item !== undefined) {
      setSelectedId(item.id);
      setDepartment(item.name);
      setShow(false);
      setShowDetail(false);
      setYearlyData([]);
      setAssetValue("");
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess?.departmentids,
      };
      dispatch(getDepartmentData(data, token));
      setData({});
    }
  };
  console.log("data", data);

  const handleAssetName = (e, item) => {
    if (item !== null && item !== undefined) {
      setAssetId(item.value);
      setToggle(true);
      setAsset(item.name);
      setAssetValue(item.label);
      setShowDetail(true);
    }
    if (item === null && item === undefined) {
      setShow(false);
    }
  };
  console.log("assetId", assetId);
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      deptid: selectedId,
      branchid: userBranch?.id,
    };

    dispatch(departmentAssetDetails(data, token));
  }, [dispatch, selectedId]);

  useEffect(() => {
    setUsefulLife(detailData?.lifeyrs);
    setPrice(detailData?.price);
  }, [detailData]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: assetId,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
    setToggle(false);
  }, [dispatch, assetId, toggle]);

  let print = {
    name: detailData.name,
    dop: detailData?.dop,
    price: price,
    usefulLife: usefulLife,
    department: detailData.dept,
    yearlyData: yearlyData,
  };

  const downloadPDF = (asset) => {
    const pdf = new jsPDF();

    pdf.setFillColor(255, 251, 244);
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    // Title
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(16);
    pdf.text(
      "Calculated  Depreciation  Expense",
      pdf.internal.pageSize.width / 2,
      18,
      "center"
    );
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(14, 79, 205); // #0E4FCD color
    pdf.line(
      pdf.internal.pageSize.width / 2 - 90,
      25,
      pdf.internal.pageSize.width / 2 + 90,
      25
    );

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 50);
    pdf.setTextColor(0, 0, 0); // Black color for values
    pdf.text(`${asset.name}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Date of Purchase:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.dop}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Price:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.price}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Useful Life:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.usefulLife} years`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.department}`, 70, 90);

    // Additional Title and Underline above the table
    pdf.setTextColor(14, 79, 205); // #0E4FCD color
    pdf.setFontSize(16);
    pdf.text("Depreciation", pdf.internal.pageSize.width / 2, 105, "center");
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(
      pdf.internal.pageSize.width / 2 - 30,
      110,
      pdf.internal.pageSize.width / 2 + 30,
      110
    );

    const data = asset.yearlyData.map((item) => [
      `Depreciation Expense for year : ${item.year}`,
      `Expense : ${item.depreciationExpense}`,
    ]);

    pdf.autoTable({
      body: data,
      startY: 120,
    });

    pdf.save("Depreciation.pdf");
  };
  const handleClear = () => {
    setSelectedId("");
    setDepartment("");
    setAssetId("");
    setAsset("");
    setAssetValue("");
    setPrice("");
    setUsefulLife("");
    setYearlyData([]);
  };
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: "600px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="h6" color="primary">
            Advert equipments online
          </Label>
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}>
            <Label variant="h6" style={{ color: "#4690FF" }}>
              New Advert
            </Label>
          </Box>
          <Divider />

          <Stack
            flexDirection={"row"}
            gap="10px"
            alignItems={"center"}
            sx={{
              width: "100%",
              overflowY: "auto",

              padding: "5px 0",

              height: "40px",
              marginTop: "10px",
            }}>
            {yearlyData?.map((item) => (
              <Box
                key={item.year}
                sx={{
                  minWidth: "100px",
                }}>
                <Typography variant="body2">Year {item.year}</Typography>
                <Label variant="body2" sx={{ color: "rgba(70, 144, 255, 1)" }}>
                  ₹{item.depreciationExpense}
                </Label>
              </Box>
            ))}
          </Stack>
          <Stack sx={{ flexDirection: "row", width: "100%", gap: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                width: "50%",
                gap: "20px",
                textAlign: "start",
              }}>
              <Label>Asset Details</Label>

              <Autocomplete
                value={department}
                onChange={handleDepartment}
                options={filteredDepartments?.map((option) => ({
                  label: `${option?.departname}`,
                  name: option?.departname,
                  id: option?.departid,
                }))}
                renderInput={(params) => (
                  <Field
                    {...params}
                    label="Choose Department"
                    variant="filled"
                    sx={{ width: "100%" }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      sx: {
                        "& input:focus": {
                          backgroundColor: "transparent",
                        },
                      },
                    }}
                  />
                )}
              />

              <Autocomplete
                onChange={handleAssetName}
                value={assetValue}
                options={departAssetData?.map((option) => ({
                  label: `${option?.name} (${option?.assetcode})`,
                  value: option?.assetid,
                  name: option?.name,
                }))}
                renderInput={(params) => (
                  <Field
                    {...params}
                    label="Choose Asset"
                    variant="filled"
                    sx={{ width: "100%" }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      sx: {
                        "& input:focus": {
                          backgroundColor: "transparent",
                        },
                      },
                    }}
                  />
                )}
              />

              <Stack
                sx={{
                  flexGrow: 1,
                  flexDirection: "row",
                  width: "100%",
                  gap: "10px",
                }}>
                <Field
                  label="Model No."
                  onChange={handlePrice}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  sx={{ width: "50%" }}
                />
                <Field
                  label="Brand"
                  onChange={handlePrice}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  sx={{ width: "50%" }}
                />
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                width: "50%",
                gap: "20px",
                textAlign: "start",
              }}>
              <Label>Quotation</Label>
              <Field
                label="Expected Price Range"
                multiline
                onChange={handlePrice}
                variant="filled"
                InputProps={{ disableUnderline: true }}
              />

              <Stack>
                <Stack
                  flexDirection={"row"}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Label>Upload Images</Label>

                  {uploadedImageName?.length < 3 && (
                    <>
                      <label for="img">
                        <FileUploadIcon
                          size={20}
                          style={{ color: "rgba(27, 37, 53, 1)" }}
                        />
                      </label>
                      <input
                        type="file"
                        name="uploadfile"
                        id="img"
                        style={{
                          display: "none",
                          width: "42px",
                          height: "42px",
                        }}
                        onChange={handlePhotoUpload}
                      />
                    </>
                  )}
                </Stack>

                <Stack
                  sx={{
                    minHeight: "50px",
                    maxHeight: "150px",
                    width: "342px",
                    flexDirection: "row",
                    overflowX: "auto",
                    overflowY: "hidden",
                    gap: "20px",
                    alignItems: "center",
                  }}>
                  {fileUrl?.map((item, index) => (
                    <Badge
                      key={index}
                      badgeContent={
                        <CloseIcon
                          size={15}
                          style={{
                            color: " rgba(255, 58, 68, 1)",
                          }}
                          onClick={() => removePhoto(item?.imageid)}
                        />
                      }
                      sx={{
                        // height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                        border: "0.5px solid rgba(27, 37, 53, 1)",
                        padding: "5px",
                      }}>
                      <Box
                        as="img"
                        src={item?.image}
                        alt=""
                        sx={{ width: "70px", height: "90px" }}
                      />
                    </Badge>
                  ))}
                </Stack>
                {isPhotoError && (
                  <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                )}
              </Stack>
            </Box>
          </Stack>

          <Stack
            sx={{
              width: "100%",
              alignItems: "flex-end",
              gap: "20px",
              marginTop: "20px",
            }}>
            <Divider sx={{ width: "100%" }} />
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                width: "154px",
                borderRadius: "22px",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SellOnlinePage;
