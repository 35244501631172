import * as React from "react";
import { Box, Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AllAsset from "./AllAsset";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
export default function AssetsMobile() {
  const navigate = useNavigate();

  const [assetPageCount, setAssetPageCount] = React.useState({
    department:0,
    asset:0,
    movement:0,
    checkout:0
  })


  const handleDataChange = (childData) => {

    setAssetPageCount({
      department:childData.department,
      asset:childData.asset,
      movement:childData.movement,
      checkout:childData.checkout
    });

  };

  return (
    <>
    <Stack
      sx={{
        width: "100%",
        // height: "100vh",
        marginTop:'-11px',
       
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        bgcolor: "#f5f5f5",
        gap: "30px",
        paddingBottom:'11px',
      }}>
      <Stack
        sx={{
         
          height: "130px",
          bgcolor: "#1B2535",
          width: "100%",
          color: "white",
        }}>
        <Stack
          justifyContent={"space-between"}
          sx={{ flexDirection: "row", p: 2, alignItems: "end", height: "90%" }}>
          <Label variant="h4">Assets.</Label>
          <Label variant="h6">Request</Label>
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "80%",
          height: "45%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          gap: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/department-list")}
            sx={{
              cursor: "pointer",
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {assetPageCount?.department}
            </Label>
            <Label variant="h6">Department</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/all-assets")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {assetPageCount?.asset}
            </Label>
            <Label variant="h6">Assets</Label>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/asset-history-list")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {assetPageCount?.movement}
            </Label>
            <Label variant="h6">Movements</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/check-in")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {assetPageCount?.checkout}
            </Label>
            <Label variant="h6">Check Outs</Label>
          </Stack>
        </Box>
      </Box>
      <Divider sx={{ width: "90%" }} />
      <Button
        variant="contained"
        size="large"
        sx={{ borderRadius: "22px", width: "80%" }}
        onClick={() => navigate("/depreciation")}>
        Calculate Depreciation
      </Button>




    </Stack>


<AllAsset  handleDataChange={handleDataChange} />

    </>
  );
}
