import {
  Box,
  Button,
  FormControl,
  Table,
  TableCell,
  TableRow,
  Typography,
  styled,
  IconButton,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DownloadPDF, serviceDetail } from "../redux/nbmData/action";
import { useState } from "react";
import ServiceDetailPdf from "../components/ServiceDetailPdf";

const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  border: "3px solid #1746A2",
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "70%",
  justifyContent: "center",
  alignItems: "center",
  // height: '1163px',
  paddingLeft: "20px",

  borderRadius: "30px",

  paddingBottom: "20px",

  [theme.breakpoints.down("xl")]: {
    // width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "2px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const PhotoDiv = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12)",
  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-evenly",
  alignItems: "center",
  height: "183px",
  [theme.breakpoints.down("xl")]: {
    height: "160px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const PhotoMain = styled(Box)(({ theme }) => ({
  marginTop: "20px",

  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    // width: "710px",

    gap: "10px",
  },
  [theme.breakpoints.down("lg")]: {
    // width: "510px",

    gap: "10px",
    // flexDirection:"column",
  },
  [theme.breakpoints.down("md")]: {
    // width: "330px",

  
  },
  [theme.breakpoints.down("sm")]: {
    // width: "330px",

    flexDirection: "column",
  },
}));

const TextLabel = styled(Typography)(({ theme }) => ({
  // border: "1px solid red",
  textAlign: "left",
  color: "#1746A2",
  fontSize: "24px",
  marginTop: "3%",
  fontWeight: 400,
  marginBottom: "1%",
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const PhotoCard = styled(Box)(({ theme }) => ({
  // border: "1px solid red",
  width: "100px",
  height: "100px",

  borderRadius: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100px",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "90%",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const Download = styled(Button)(({ theme }) => ({
  color: "white",

  fontWeight: 400,
  textTransform: "none",
  borderRadius: "35px",
  backgroundColor: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    height: "40px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "40px",
  },
  [theme.breakpoints.down("xs")]: {
    height: "40px",
  },
}));

const TableDivider = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  color: "#1746A2",
  fontSize: "26px",
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "22px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  padding: "1%",
  flexDirection: "column",
  justifyContent: "space-evenly",
  marginBottom: "20px",
  textAlign: "left",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerTop = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",

  justifyContent: "right",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const OuterBottom = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  width: "980px",

  display: "flex",
  marginBottom: "5px",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    width: "740px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "580px",
  },
  [theme.breakpoints.down("md")]: {
    width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const OuterSecondLastBottom = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  width: "100%",

  display: "flex",
  marginBottom: "5px",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
    //width: "740px",
  },
  [theme.breakpoints.down("lg")]: {
    //width: "580px",
    marginTop: "0px",
  },
  [theme.breakpoints.down("md")]: {
    //width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    //width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableElement1 = styled(TableCell)(({ theme }) => ({
  textTransform: "none",
  height: "1px",
  whiteSpace: "nowrap",
  padding: "6px",
  fontWeight: "500",
  color: "#1746A2",
  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TableElement2 = styled(TableCell)(({ theme }) => ({
  textTransform: "none",
  height: "1px",

  padding: "6px",
  fontWeight: "500",

  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const DivideRow = styled(TableDivider)(({ theme }) => ({
  textTransform: "none",
  height: "1px",
  fontSize: "22px",
  padding: "2px",
  paddingRight: "5px",
  // margginTop:'20px',
  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const MainTable = styled(Table)(({ theme }) => ({
  // border: "1px solid red",
  textTransform: "none",
  height: "1px",
  position: "static",
  paddingBottom: "10px",

  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PhotoInner = styled(Box)(({ theme }) => ({
  // border: "1px solid brown",
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  width: "40%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

function ServiceDetailPage() {
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  let userBranch =
  JSON.parse(sessionStorage.getItem("userBranch")) ||
  JSON.parse(localStorage.getItem("userBranch"));
  let serviceData = useSelector((store) => store.nbmData.serviceDetail);

  const dispatch = useDispatch();

  const params = useParams();

  const [fileType, setFileType] = useState(null);
  const fileLink = serviceData.receipturl;

  useEffect(() => {
    if (fileLink?.endsWith(".pdf") || fileLink?.includes(".pdf")) {
      setFileType("pdf");
    } else if (
      fileLink?.endsWith(".jpg") ||
      fileLink?.endsWith(".jpeg") ||
      fileLink?.endsWith(".png") ||
      fileLink?.endsWith(".gif") ||
      fileLink?.includes("image/")
    ) {
      setFileType("image");
    } else {
      setFileType("unknown");
    }
  }, [fileLink]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      servid: params.id,
      branchid: userBranch?.id,
    };

    dispatch(serviceDetail(data));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.target = "_blank";
    link.download = "downloaded_image.jpg";
    link.click();
  };

  return (
    <MainDiv>
      <TopContainer sx={{}}>
        {serviceData?.servicestatus === "Cleared" ? (
          <TitleHead>Closed Service Details</TitleHead>
        ) : (
          <TitleHead>Open Service Details</TitleHead>
        )}
      </TopContainer>
      <InnerContainer sx={{ bgcolor: "white" }}>
        <InnerTop>
          <div
            style={{
              position: "relative",
              width: "160px",
              height: "145px",

              overflow: "hidden",
            }}>
            <div
              style={{
                position: "absolute",
                top: "-16px",
                right: "14px",
                backgroundColor:
                  serviceData?.servicestatus === "Open" ? "red" : "green",
                color: "#fff",
                padding: "5px",
                fontSize: "14px",
                transform: "translate(50%, -50%) rotate(45deg)",
                transformOrigin: "0 0",
                width: "calc(100% + 57px)",
                textAlign: "center",
              }}>
              {serviceData?.servicestatus === "Open" && (
                <Typography variant="outlined">Not Cleared</Typography>
              )}
              {serviceData?.servicestatus === "Cleared" && (
                <Typography variant="outlined">Cleared</Typography>
              )}
            </div>
          </div>
        </InnerTop>
        <TextContainer>
          <MainTable sx={{ position: "static" }}>
            <TableRow>
              <TableElement1>
                {" "}
                <Typography>Asset Name</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2>
                {" "}
                <Typography>{serviceData?.assetname}</Typography>
              </TableElement2>
            </TableRow>
            <TableRow>
              <TableElement1>
                {" "}
                <Typography>Asset code</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2>
                {" "}
                <Typography>{serviceData?.assetcode}</Typography>
              </TableElement2>
            </TableRow>
            <TableRow>
              <TableElement1>
                {" "}
                <Typography>Department</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2>
                {" "}
                <Typography>{serviceData?.deptname}</Typography>
              </TableElement2>
            </TableRow>
            <TableRow>
              <TableElement1>
                {" "}
                <Typography>Issue Date</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2>
                {" "}
                <Typography>{serviceData?.reqdate}</Typography>
              </TableElement2>
            </TableRow>
            {serviceData?.servicestatus === "Cleared" && (
              <>
                <TableRow>
                  <TableElement1>Clear Date</TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    {" "}
                    <Typography>{serviceData?.servicedate}</Typography>
                  </TableElement2>
                </TableRow>
                {}
                <TableRow>
                  <TableElement1>
                    {" "}
                    <Typography>Name</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    {" "}
                    <Typography>{serviceData?.servicername} </Typography>
                  </TableElement2>
                </TableRow>
                {serviceData?.swversion !== "NA" &&
                  serviceData?.swversion !== "" && (
                    <TableRow>
                      <TableElement1>
                        {" "}
                        <Typography>Software Version</Typography>
                      </TableElement1>
                      <DivideRow>:</DivideRow>
                      <TableElement2>
                        {" "}
                        <Typography>{serviceData?.swversion} </Typography>
                      </TableElement2>
                    </TableRow>
                  )}

                <TableRow>
                  <TableElement1>
                    {" "}
                    <Typography>Mobile Number</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    {" "}
                    <Typography>{serviceData?.mobile}</Typography>{" "}
                  </TableElement2>
                </TableRow>
                {serviceData?.amccamc !== "NA" &&
                  serviceData?.amccamc !== "" && (
                    <TableRow>
                      <TableElement1>
                        {" "}
                        <Typography>AMC/CMC</Typography>
                      </TableElement1>

                      <DivideRow>:</DivideRow>
                      <TableElement2>
                        {" "}
                        <Typography>{serviceData?.amccamc}</Typography>
                      </TableElement2>
                    </TableRow>
                  )}

                <TableRow>
                  <TableElement1>
                    {" "}
                    <Typography>Status</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    {" "}
                    <Typography>{serviceData?.fixedstatus}</Typography>
                  </TableElement2>
                </TableRow>

                {serviceData?.paidservice !== "NA" &&
                  serviceData?.paidservice !== "" && (
                    <>
                      <TableRow>
                        <TableElement1>
                          {" "}
                          <Typography>Paid Service</Typography>
                        </TableElement1>

                        <DivideRow>:</DivideRow>
                        <TableElement2>
                          <Typography>{serviceData?.paidservice}</Typography>
                        </TableElement2>
                      </TableRow>
                    </>
                  )}
                {serviceData?.servicecost !== "NA" &&
                  serviceData?.servicecost !== "" && (
                    <TableRow>
                      <TableElement1>
                        {" "}
                        <Typography>Service Cost</Typography>
                      </TableElement1>
                      <DivideRow>:</DivideRow>
                      <TableElement2>
                        {" "}
                        <Typography>{serviceData?.servicecost}</Typography>
                      </TableElement2>
                    </TableRow>
                  )}
              </>
            )}
          </MainTable>
        </TextContainer>
        <BottomContainer>
          <FormControl sx={{}}>
            {serviceData?.servicestatus === "Cleared" ? (
              <>
                <TextLabel>
                  {" "}
                  <Typography>Problem Identified </Typography>
                </TextLabel>

                <Typography>{serviceData?.probidentified}</Typography>
              </>
            ) : (
              <>
                <TextLabel>
                  {" "}
                  <Typography>Issue </Typography>
                </TextLabel>

                <Typography>{serviceData?.issue}</Typography>
              </>
            )}

            {serviceData?.servicestatus === "Cleared" && (
              <>
                <TextLabel>
                  {" "}
                  <Typography>Action Taken</Typography>
                </TextLabel>

                <Typography>{serviceData?.actiontaken}</Typography>

                {serviceData?.remarks !== "NA" &&
                  serviceData?.remarks !== "" && (
                    <>
                      <TextLabel>
                        {" "}
                        <Typography>Remarks</Typography>
                      </TextLabel>{" "}
                      <Typography>{serviceData?.remarks}</Typography>
                    </>
                  )}
              </>
            )}
            <PhotoMain>
              {serviceData?.servicestatus === "Cleared" &&
                serviceData?.issueimgs !== "NA" &&
                serviceData?.issueimgs !== "" && (
                  <PhotoInner>
                    <TextLabel>Issue Image</TextLabel>
                    <PhotoDiv
                      sx={{
                        border: "2px solid rgba(0, 168, 132, 0.5)",
                        bgcolor: "#EFF5FE",
                      }}>
                      {serviceData?.issueimgs?.map((images) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}>
                          <PhotoCard as="img" src={images} alt="incImage" />
                          <IconButton onClick={() => handleDownload(images)}>
                            {" "}
                            <FileDownloadIcon color="warning" />
                          </IconButton>
                        </Box>
                      ))}
                    </PhotoDiv>
                  </PhotoInner>
                )}

              {serviceData?.servicestatus === "Cleared" &&
                serviceData?.servicedimgs !== "NA" &&
                serviceData?.servicedimgs !== "" && (
                  <PhotoInner>
                    <TextLabel>Service Image</TextLabel>
                    <PhotoDiv
                      sx={{
                        border: " 2px solid rgba(255, 75, 75, 0.5)",
                        bgcolor: "#EFF5FE",
                      }}>
                      {serviceData?.servicedimgs?.map((images) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}>
                          <PhotoCard as="img" src={images} alt="incImage" />
                          <IconButton onClick={() => handleDownload(images)}>
                            {" "}
                            <FileDownloadIcon color="warning" />
                          </IconButton>
                        </Box>
                      ))}
                    </PhotoDiv>
                  </PhotoInner>
                )}
              {serviceData?.servicestatus === "Cleared" &&
                serviceData?.receipturl !== "NA" &&
                serviceData?.receipturl !== "" && (
                  <PhotoInner>
                    <TextLabel>Receipt </TextLabel>
                    <PhotoDiv
                      sx={{
                        border: " 2px solid rgba(255, 75, 75, 0.5)",
                        bgcolor: "#EFF5FE",
                      }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}>
                        {fileType === "pdf" ? (
                          <Button
                            variant="contained"
                            sx={{ bgcolor: "#FF731D", borderRadius: "30px" ,textTransform:"none" }}
                            onClick={() =>
                              dispatch(
                                DownloadPDF(serviceData.receipturl, "receipt")
                              )
                            }>
                            Receipt <FileDownloadIcon color="white" />
                          </Button>
                        ) : (
                          <>
                            <PhotoCard
                              as="img"
                              src={serviceData?.receipturl}
                              alt="incImage"
                            />
                            <IconButton
                              onClick={() =>
                                handleDownload(serviceData?.receipturl)
                              }>
                              {" "}
                              <FileDownloadIcon color="warning" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </PhotoDiv>
                  </PhotoInner>
                )}

              {serviceData?.servicestatus === "Open" &&
                serviceData?.issueimgs !== "NA" &&
                serviceData?.issueimgs !== "" && (
                  <PhotoInner>
                    <TextLabel sx={{ border: "none" }}>Issue Image</TextLabel>
                    <PhotoDiv sx={{ bgcolor: "#EFF5FE" }}>
                      {serviceData?.issueimgs !== "NA" &&
                        serviceData?.issueimgs?.map((images) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}>
                            <PhotoCard as="img" src={images} alt="incImage" />
                            <IconButton onClick={() => handleDownload(images)}>
                              {" "}
                              <FileDownloadIcon color="warning" />
                            </IconButton>
                          </Box>
                        ))}
                    </PhotoDiv>
                  </PhotoInner>
                )}
            </PhotoMain>
          </FormControl>
        </BottomContainer>
      </InnerContainer>
      {serviceData?.servicestatus === "Cleared" && (
        <OuterSecondLastBottom sx={{ marginTop: "2%" }}>
          {/* <Download
           onClick={() =>
             dispatch(
               DownloadPDF(serviceData?.servicereport, serviceData?.assetname)
             )
           }>
           Download Report &nbsp; <DownloadForOfflineOutlinedIcon />
         </Download> */}

          <ServiceDetailPdf data={serviceData} />
        </OuterSecondLastBottom>
      )}

      {serviceData?.servicestatus === "Open" && (
        <OuterBottom>
          <Link
            style={{ textDecoration: "none" }}
            to={`/asset-detail/${serviceData?.assetid}`}>
            <Button
              size="large"
              sx={{
                bgcolor: "#1746A2",
                textTransform: "none",

                borderRadius: "35px",
              }}
              variant="contained"
              value="clear">
              Clear Service
            </Button>
          </Link>
        </OuterBottom>
      )}
    </MainDiv>
  );
}

export default ServiceDetailPage;
