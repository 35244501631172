import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";

import { useDispatch, useSelector } from "react-redux";

import { getCalibrated } from "../redux/nbmData/action";

import empty from "../images/empty.png";
import DialogBox from "../components/DialogBox";
import UpperContainer from "../components/UpperContainer";
import NoData from "../components/NoData";
import CalibrationRequestModal from "../nbmComponents/CalibrationRequestModal";
import TableSort from "../components/TableSort";
import FilterMenu from "../components/FilterMenu";
import FilterListIcon from "@mui/icons-material/FilterList";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",
  minHeight: "400px",

  padding: "2%",
  flexDirection: "column",
  alignItems: "center",
  background: "#F4F6F6",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "#1746A2",
  fontSize: "18px",
  fontWeight: "500",
  textAlign: "start",
  bgcolor: "rgba(40, 98, 212, 0.1)",
  // ,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TableRows = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    fontSize: "18px",
    fontWeight: "400",
    color: "#212427",
    textAlign: "start",
    maxWidth: 130,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease",
    "&:hover": {
      maxWidth: "none",
      whiteSpace: "normal",
      overflow: "visible",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
      "&:hover": {
        maxWidth: 130,
        whiteSpace: "wrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      whiteSpace: "wrap",
      wordWrap: "break-word",
    },
    [theme.breakpoints.down("xs")]: {},
  },
}));

const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const Calibration = () => {
  const calibrationList = useSelector((store) => store.data.calibratedList);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [open, setOpen] = useState(false);
  const [single, setSingle] = useState([]);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [anchorElSort2, setAnchorElSort2] = React.useState(null);

  const currentDate = new Date();
  const [showOnlyExpired, setShowOnlyExpired] = useState(false);
  const [showNotExpired, setShowNotExpired] = useState(false);

  const [anchorElFilter2, setAnchorElFilter2] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? calibrationList.filter((row) => row.req_status === filterValue)
      : calibrationList;
    setData(newFilteredData);
  }, [filterValue]);

  const handleClickFilter2 = (event) => {
    setAnchorElFilter2(event.currentTarget);
  };

  const handleCloseFilter2 = () => {
    setAnchorElFilter2(null);
  };

  const handleFilter2 = (status) => {
    setFilterValue(status);
    setAnchorElFilter2(null);
    setPage(0);
    setFilterChanged(true);
  };

  const status = [...new Set(calibrationList?.map((item) => item.req_status))];

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const uniqueMonths = [
    ...new Set(calibrationList.map((item) => item.calibduedate.split("-")[1])),
  ].sort();
  const uniqueYears = [
    ...new Set(calibrationList.map((item) => item.calibduedate.split("-")[2])),
  ].sort();

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      downloadCsvFile();
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.calibdate.split("-").reverse().join("-"));
      const dateB = new Date(b.calibdate.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.calibdate.split("-").reverse().join("-"));
      const dateB = new Date(b.calibdate.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortHighToLow1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.calibduedate.split("-").reverse().join("-"));
      const dateB = new Date(b.calibduedate.split("-").reverse().join("-"));
      return dateA - dateB;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh1 = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.calibduedate.split("-").reverse().join("-"));
      const dateB = new Date(b.calibduedate.split("-").reverse().join("-"));
      return dateB - dateA;
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const department = [
    ...new Set(calibrationList?.map((item) => item.department)),
  ];

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getCalibrated(data, token));
  }, [dispatch, open]);

  useEffect(() => {
    if (calibrationList && calibrationList?.length > 0) {
      setData(calibrationList);
    }
  }, [calibrationList]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const [filterChanged, setFilterChanged] = useState(false);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterIncidents();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterIncidents = () => {
    let filteredIncidents = [...data];

    if (searchQuery) {
      filteredIncidents = filteredIncidents.filter(
        (incident) =>
          incident.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          incident.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategories.length > 0) {
      filteredIncidents = filteredIncidents.filter((incident) =>
        selectedCategories.includes(incident.department)
      );
    }

    if (selectedYear) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentYear = incident.calibduedate.split("-")[2];
        return incidentYear === selectedYear;
      });
    }

    if (selectedMonth) {
      filteredIncidents = filteredIncidents.filter((incident) => {
        const incidentMonth = incident.calibduedate.split("-")[1];
        return incidentMonth === String(selectedMonth).padStart(2, "0");
      });
    }
    const currentDate = new Date();
    filteredIncidents = filteredIncidents.filter((incident) => {
      if (!incident.calibduedate) {
        return false;
      }
      if (showOnlyExpired && showNotExpired) {
        return true;
      }
      if (showOnlyExpired) {
        return formatDate(incident.calibduedate) < currentDate;
      }
      if (showNotExpired) {
        return formatDate(incident.calibduedate) >= currentDate;
      }
      return true;
    });

    return filteredIncidents;
  };

  const filteredProducts = filterIncidents();

  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    filteredProducts.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(calibrationList);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const sortDataByKey = (key, ascending) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const handleClickPopper = (e, data) => {
    setOpen(true);
    setSingle(data);
  };
  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.name;
      case "date":
        return item.calibdate;
      case "duedate":
        return item.calibduedate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );
  return (
    <Wrapper>
      <UpperContainer
        data={calibrationList}
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        selectedMonth={selectedMonth}
        handleMonthChange={handleMonthChange}
        uniqueMonths={uniqueMonths}
        selectedYear={selectedYear}
        handleYearChange={handleYearChange}
        uniqueYears={uniqueYears}
        sortDataByKey={sortDataByKey}
        sortLowToHigh={sortLowToHigh}
        sortHighToLow={sortHighToLow}
        sortLowToHigh1={sortLowToHigh1}
        sortHighToLow1={sortHighToLow1}
        department={department}
        selectedCategories={selectedCategories}
        showOnlyExpired={showOnlyExpired}
        setShowOnlyExpired={setShowOnlyExpired}
        showNotExpired={showNotExpired}
        setShowNotExpired={setShowNotExpired}
        handleButtonClick2={handleButtonClick2}
        ids={ids}
        handleCategoryChange={handleCategoryChange}
        text="Calibration"
      />

      {calibrationList?.length === 0 ? (
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: " 15px ",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "600px",
          }}>
          <Cards>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={empty}
              alt="green iguana"
            />
            <CardContent>
              <Typography
                sx={{ color: " #212427" }}
                color="text.secondary"
                gutterBottom>
                No reported calibration .
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => navigate("/home")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                variant="contained">
                Go to Home
              </Button>
            </CardActions>
          </Cards>
        </BoxWrapper>
      ) : filteredProducts?.length === 0 ? (
        <>
          <NoData text={"calibration"} />
        </>
      ) : (
        <BoxWrapper style={{ overflowX: "auto" }}>
          <Box sx={{ overflow: "auto" }}>
            <TableContainer
              sx={{
                width: "100%",
                maxHeight: 540,
              }}>
              <Table
                stickyHeader
                sx={{
                  borderRadius: " 15px",
                  bgcolor: "white",

                  whiteSpace: "nowrap",
                }}>
                <TableHead
                  sx={{
                    borderRadius: " 15px 15px 0px 0px",
                  }}>
                  <TableRow
                    sx={{
                      borderRadius: " 15px 15px 0px 0px",
                      bgcolor: "rgba(40, 98, 212, 0.1)",
                    }}>
                    <TableHeading
                      sx={{
                        borderRadius: "15px 0px 0px 0px",
                      }}>
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.name}
                        onClick={() => handleSortTable("name")}>
                        Asset Name
                      </TableSortLabel>
                    </TableHeading>

                    <TableHeading sx={{}}>Asset code</TableHeading>
                    <TableHeading sx={{}}>Serial no</TableHeading>
                    <TableHeading sx={{}}>Department</TableHeading>
                    <TableHeading sx={{ textAlign: "center" }}>
                      Status
                    </TableHeading>
                    <TableHeading sx={{}}>
                      {" "}
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.date}
                        onClick={() => handleSortTable("date")}>
                        Date/Time
                      </TableSortLabel>
                    </TableHeading>
                    <TableHeading sx={{}}>
                      <TableSortLabel
                        active={true}
                        style={{ color: "#1746A2" }}
                        direction={sortDirection.duedate}
                        onClick={() => handleSortTable("duedate")}>
                        Due Date
                      </TableSortLabel>
                    </TableHeading>

                    <TableHeading
                      sx={{
                        borderRadius: "0px 15px 0px 0px",
                        textAlign: "start",
                      }}>
                      Request Status
                      <Tooltip title="Filter Status">
                        <IconButton onClick={handleClickFilter2} size="small">
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>
                      <FilterMenu
                        anchorEl={anchorElFilter2}
                        onClose={handleCloseFilter2}
                        onFilter={handleFilter2}
                        status={status}
                      />
                    </TableHeading>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts
                    .filter((asset) => {
                      if (showOnlyExpired && showNotExpired) {
                        return true;
                      }
                      if (showOnlyExpired) {
                        return formatDate(asset.calibduedate) < currentDate;
                      }
                      if (showNotExpired) {
                        return formatDate(asset.calibduedate) >= currentDate;
                      }
                      return true;
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                    ?.map((item, index) => {
                      const expirationDate = formatDate(item.calibduedate);

                      const isExpired = expirationDate < currentDate;

                      const textColor = isExpired ? "red" : "green";
                      if (showOnlyExpired && !isExpired) {
                        return null;
                      }
                      return (
                        <TableRow
                          key={item?.assetid}
                          component={Link}
                          // to={`/asset-detail/${item?.assetid}`}
                          sx={{
                            borderRadius: " 15px 15px 0px 0px",
                            "&:hover": {
                              backgroundColor: "#E8ECF6",
                            },
                            textDecoration: "none",
                          }}>
                          <TableRows>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.name}
                            </Link>
                          </TableRows>

                          <TableRows sx={{}}>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.assetcode}
                            </Link>
                          </TableRows>

                          <TableRows sx={{}}>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.serialno}
                            </Link>
                          </TableRows>
                          <TableRows sx={{}}>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.department}
                            </Link>
                          </TableRows>
                          <TableRows sx={{ textAlign: "center" }}>
                            <Box
                              sx={{
                                borderRadius: "30px",
                                margin: "auto",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: {
                                  xl: "18px",
                                  lg: "16px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                                height: "30px",
                                padding: "4px",

                                width: { xl: "120px", md: "100px" },
                                border: "1px solid #00A884",

                                color: " #00A884",
                              }}>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                to={`/asset-detail/${item?.assetid}`}>
                                Calibrated
                              </Link>
                            </Box>
                          </TableRows>
                          <TableRows>
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.calibdate}
                            </Link>
                          </TableRows>
                          <TableRows
                            style={{ whiteSpace: "nowrap", color: textColor }}>
                            {" "}
                            <Link
                              style={{
                                textDecoration: "none",
                                color: textColor,
                              }}
                              to={`/asset-detail/${item?.assetid}`}>
                              {item?.calibduedate}
                            </Link>
                          </TableRows>

                          <TableRows sx={{ textAlign: "center" }}>
                            <Button
                              onClick={(e) => handleClickPopper(e, item)}
                              // disabled={
                              //   item?.req_status === "Requested" ||
                              //   (userAccess.role !== "Admin" &&
                              //     userAccess.role !== "Super_Admin")
                              // }

                              disabled={
                                item?.req_status === "Requested" ||
                                userAccess.role === "Guest"
                              }
                              size="small"
                              sx={{
                                borderRadius: "35px",
                                textTransform: "none",
                                bgcolor: "#1746A2",
                              }}
                              variant="contained">
                              {item?.req_status === "NA"
                                ? "Request"
                                : "Requested"}
                            </Button>
                          </TableRows>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {filteredProducts?.length > rowsPerPage && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                sx={{
                  display: "flex",
                  justifyContent: isMobileScreen ? "center" : "end",

                  alignItems: isMobileScreen ? "center" : "end",
                  padding: "16px",

                  width: isMobileScreen ? "80%" : "90%",
                  "& .MuiTablePagination-selectLabel": {
                    fontSize: !isMobileScreen ? "14px" : "95%",
                  },

                  "& .MuiTablePagination-actions": {
                    fontSize: !isMobileScreen ? "14px" : "12px",
                  },

                  "& .MuiTablePagination-select": {
                    position: "relative",
                    cursor: "text",

                    alignItems: "center",
                    color: "inherit",

                    flexShrink: 0,
                    marginRight: "3px",
                    marginLeft: "-36px",
                  },

                  "& .MuiTablePagination-displayedRows": {
                    marginRight: "1px",
                    marginLeft: "-30px",
                    fontSize: !isMobileScreen ? "14px" : "12px",
                  },
                }}
                component="div"
                count={
                  showOnlyExpired
                    ? filteredProducts.filter(
                        (asset) => formatDate(asset.calibduedate) < currentDate
                      ).length
                    : filteredProducts.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Box>
        </BoxWrapper>
      )}

      <CalibrationRequestModal
        open={open}
        setopen={setOpen}
        data={single}
        // setRender={setRender}
      />

      <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />
    </Wrapper>
  );
};

export default Calibration;
