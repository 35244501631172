import { Box } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ChooseBmOrNbm from "../components/ChooseBmOrNbm";

function ChooseRoute() {
  return (
    <Routes>
      <Route path="*" element={<ChooseBmOrNbm />} />
      <Route path="/select" element={<ChooseBmOrNbm />} />
    </Routes>
  );
}

export default ChooseRoute;
