import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import thunk from 'redux-thunk'
import { authReducer } from "./auth/reducer";
import { dataReducer } from "./data/reducer";
import { nbmDataReducer } from "./nbmData/reducer";

const root = combineReducers({
    auth:authReducer,
    data:dataReducer,
    nbmData:nbmDataReducer  
})

export const store = legacy_createStore(
    root,
    applyMiddleware(thunk)
)