import { Box, Typography, styled } from "@mui/material";
import React from "react";
import internet from "../images/offline.png";

const ImageBox = styled(Box)(({ theme }) => ({
  width: "290px",
  height: "290px",
  [theme.breakpoints.down("xl")]: {
    width: "220px",
    height: "220px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Text = styled(Typography)(({ theme }) => ({
  color: "#3A67B3",
  width: "90%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: "#FF731D",
  fontSize: "22px",
  width: "90%",
  fontWeight: "500",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BottomText = styled(Typography)(({ theme }) => ({
  color: "#FF731D",
  width: "90%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const NoInternet = () => {
  return (
    <Wrapper>
      <TitleText>It seems you're offline. </TitleText>
      <ImageBox as="img" src={internet} alt="no-internet" />

      <Text>Kindly check your internet connection and attempt once more.</Text>
      <BottomText>Thank you.</BottomText>
    </Wrapper>
  );
};

export default NoInternet;
