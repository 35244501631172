import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import styled from "@emotion/styled";
import thumpPic from "../images/thumpPic.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "350px", lg: "350px", md: "300px", sm: "300px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "3px solid #FF731D",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function SuccessModal({
  openModal,
  handleCloseModal,
  text,
  text2,
}) {
  return (
    <div>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              color: "#1746A2",
            }}>
            <Button
              sx={{ bgcolor: "white", textTransform: "none" }}
              onClick={handleCloseModal}>
              {" "}
              Close
            </Button>
          </Box>
          <Box
            sx={{
              color: "red",
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              as="img"
              src={thumpPic}
              style={{ width: "20%", height: "20%" }}></Box>
            <Typography
              variant="body2"
              sx={{ color: "#1746A2", textAlign: "center" }}>
              {`${text} `}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "orange", textAlign: "center" }}>
              Thank you!
            </Typography>
          </Box>
        </ModalBox>
      </Modal>
    </div>
  );
}
