import styled from "@emotion/styled";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuid } from "uuid";

import { useDispatch } from "react-redux";
import {
  assetDetailsData,
  clearService,
  clearServiceImgDel,
  clearServiceReceipt,
  clearServiceReceiptDelete,
  getDepartmentData,
  serviceImageUpload,
  serviceIssue,
} from "../redux/nbmData/action";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const BlueStepper = styled(Stepper)(({ theme }) => ({
  ".MuiStepConnector-active": {
    "& > .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
      width: "700px",
    },
  },
  "& .MuiStepConnector-line": {
    borderLeftWidth: "558px", // Adjust the width as needed
  },
}));

const ClearService = () => {
  const issueData = useSelector((store) => store.data.serviceIssue);
  const detailData = useSelector((store) => store.data.assetDetails);
  const response = useSelector((store) => store?.data?.clearService);

  const [expenditures, setExpenditures] = useState([]);
  const [newExpenditure, setNewExpenditure] = useState({
    description: '',
    totalAmountSpent: '',
    expImage: [{ image: '', imageId: '' }]
  });


  let assetId = localStorage.getItem("assetId") || "";
  let idSplit = assetId.substring(0, 4);

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));





  const [department, setDepartment] = useState("");
  const [accessory, setAccessory] = useState("");
  const [isAccessoryError, setIsAccessoryError] = useState("");


  const [accessoryType, setAccessoryType] = useState("");
  const [isAccessoryTypeError, setIsAccessoryTypeError] = useState("");


  const [isWarrantyError, setIsWarrantyError] = useState(null);
  const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);




  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const servid = localStorage.getItem("serviceId");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const [fileUrl, setFileUrl] = useState([]);
  const [fileUrlAccessory, setFileUrlAccessory] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const [isAccessoryPhotoError, setIsAccessoryPhotoError] = useState("");
  let ids = JSON.parse(sessionStorage.getItem("userData")) || JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const [nameError, setNameError] = useState("");
  const [isMobileNumberError, setIsMobileNumberError] = useState("");
  const [isCompanyError, setIsCompanyError] = useState("");
  const [isIssueError, setIsIssueError] = useState("");
  const [isActionError, setIsActionError] = useState("");
  const [isStatusError, setIsStatusError] = useState("");

  const [amccmError, setAmccmcError] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [software, setSoftware] = useState("");
  const [company, setCompany] = useState("");
  const [amcCmc, setAmcCmc] = useState("");

  const [status, setStatus] = useState("");
  const [issue, setIssue] = useState("");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");

  const dispatch = useDispatch();
  const params = useParams();
  const [isPaid, setIsPaid] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState("");
  const [showError, setShowError] = useState(false);
  const [toggle, setToggle] = useState(false);


  const [unitPrice, setUnitPrice] = useState("");
  const [isUnitPriceError, setIsUnitPriceError] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isQuantityError, setIsQuantityError] = useState("");
  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [isTotalAmountSpentError, setIsTotalAmountSpentError] = useState("");
  const [description, setDescription] = useState("");
  const [isDescriptionError, setIsDescriptionError] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openModal, setOpenModal] = React.useState(false);

  const [receipt, setReceipt] = useState("");

  const handlePaidChange = (event) => {
    if (event.target.value === "no") {
      setAmount("");
      setFile("");
    }
    setIsPaid(event.target.value);
    setShowError(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setIsStatusError(false);
  };

  const handleAmountChange = (event) => {
    const newValue = event.target.value;

    // Validate the input (you can customize this part)
    if (newValue.match(/^\d*$/) || newValue === "") {
      setAmount(newValue);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const uploadedImageName = fileUrl?.map((el) => el?.imageid);

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(serviceImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const handleAccessoryPhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsAccessoryPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsAccessoryPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrlAccessory([...fileUrlAccessory, sendData]);

          // dispatch(serviceImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };







  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              params.id + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: params.id + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(serviceImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const handleReceiptUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 3 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImageReceipt(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFile(file);
          setReceipt(sendData);
          dispatch(clearServiceReceipt(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImageReceipt = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              params.id + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: params.id + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch.id,
              };
              setFile(file);
              setReceipt(sendData);

              dispatch(clearServiceReceipt(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const handleDelete = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    dispatch(clearServiceImgDel(data));
    setFileUrl(fileUrl?.filter((el) => el?.imageid !== id));
  };

  const handleDeleteReceipt = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    dispatch(clearServiceReceiptDelete(data));
    setReceipt(receipt.imageid !== id);
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;

    if (value === "") {
      setName(value);
      setNameError("Please enter a valid Name");
    } else if (value.trim() === "") {
      setName(value);
      setNameError("Invalid name");
    } else if (regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };

  const handleCompany = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;

    if (value === "") {
      setCompany(value);
      setIsCompanyError("Please enter a valid company");
    } else if (value.trim() === "") {
      setCompany(value);
      setIsCompanyError("Invalid company");
    } else if (regex.test(value)) {
      setCompany(value);
      setIsCompanyError("");
    } else {
      setIsCompanyError("Invalid company");
    }
  };

  const handleNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^\+?[0-9]{10}$/;

    if (value === "") {
      setMobile(value);
      setIsMobileNumberError("Please enter a valid mobile number");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setMobile(value);
      setIsMobileNumberError("");
    } else {
      setIsMobileNumberError("Invalid mobile number");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    // const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    if (value === "") {
      setEmail(value);
      setIsEmailError("Please enter a valid email");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setEmail(value);
      setIsEmailError(null);
    } else {
      setIsEmailError("Enter valid email");
    }
  };

  const handleIssue = (e) => {
    const value = e.target.value;
    const regex = /^[a-z 0-9,.'-]+$/i;

    if (value === "") {
      setIssue(value);
      setIsIssueError("Please enter a valid issue");
    } else if (value.trim() === "") {
      setIssue(value);
      setIsIssueError("Invalid issue");
    } else if (regex.test(value)) {
      setIssue(value);
      setIsIssueError("");
    } else {
      setIsIssueError("Invalid issue");
    }
  };
  const handleAction = (e) => {
    const value = e.target.value;
    const regex = /^[a-z 0-9,.'-]+$/i;

    if (value === "") {
      setAction(value);
      setIsActionError("Please enter a valid action");
    } else if (value.trim() === "") {
      setAction(value);
      setIsActionError("Invalid action");
    } else if (regex.test(value)) {
      setAction(value);
      setIsActionError("");
    } else {
      setIsActionError("Invalid action");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      servid: params.id2,
      branchid: userBranch.id,
    };
    dispatch(serviceIssue(data, token));
  }, [dispatch]);

  const handleClicks = () => {
    const trimmedName = name.trim();
    const trimmedMobile = mobile.trim();
    const trimmedCompany = company.trim();
    const trimmedEmail = email.trim();
    const trimmedAction = action.trim();
    const trimmedIssue = issue.trim();
    const trimmedStatus = status.trim();

    if (
      trimmedName === "" ||
      trimmedMobile === "" ||
      trimmedCompany === "" ||
      trimmedEmail === "" ||
      trimmedAction === "" ||
      trimmedIssue === "" ||
      trimmedStatus === ""
    ) {
      setNameError(trimmedName === "" ? "Please enter a valid Name" : null);
      setIsMobileNumberError(
        trimmedMobile === "" ? "Please enter a valid mobile number" : null
      );
      setIsCompanyError(
        trimmedCompany === "" ? "Please enter a valid company" : null
      );
      setIsEmailError(
        trimmedEmail === "" ? "Please enter a valid email" : null
      );
      setIsIssueError(
        trimmedIssue === "" ? "Please enter a valid issue" : null
      );
      setIsStatusError(
        trimmedStatus === "" ? "Please enter a valid status" : null
      );
      setIsActionError(
        trimmedAction === "" ? "Please enter a valid action" : null
      );
      setShowError(true);

      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }

    if (
      !isPaid ||
      (isPaid === "yes" && (!amount || receipt === "" || receipt === false))
    ) {
      setShowError(true);
      return;
    }
    if (
      name !== "" &&
      mobile !== "" &&
      company !== "" &&
      issue !== "" &&
      action !== "" &&
      status !== ""
    ) {
      let datas = {
        assetid: params.id,
        hospid: userIds.hospid,
        serviceid: params.id2,
        userid: userIds.userid,
        username: userIds.username,
        swversion: software === "" ? "NA" : software.trim(),
        paidservice: isPaid,
        asset_name: detailData.name,
        serial_no: detailData.serialno,
        model_no: detailData.modelno,
        department: detailData.dept,
        req_date: issueData.reqdate,
        req_time: issueData.reqtime,
        branchid: userBranch?.id,
        servicecost: amount?.length > 0 ? amount : "NA",
        "amc/camc": amcCmc === "" ? "NA" : amcCmc,
        fixstatus: status,
        probidentify: trimmedIssue,
        action_taken: trimmedAction,
        servicer_name: trimmedName,
        company: trimmedCompany,
        phone: trimmedMobile,
        email: trimmedEmail,
        feedback: remark === "" ? "NA" : remark.trim(),
        clearimages: uploadedImageName?.length > 0 ? uploadedImageName : "NA",
        // receipt_id: receipt ? receipt.imageid : "NA",
        assetcode: detailData.code,
         expenditure: expenditures?.map((item)=> {return item.description}),
          amt_spent: expenditures?.map((item) => { return Number(item.totalAmountSpent) }),
          receipt_id: ["test.img"],
      
      };
      setToggle(true);
      handleOpenModal()
      dispatch(clearService(datas, token));
    }
  };

  useEffect(() => {
    if (response === "Updated" && name !== "") {
      
    }
  }, [response]);
  console.log("response",response)

  useEffect(() => {}, [snackbarOpen, snackbarMessage]);
  console.log("receipt", file);









  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);


  const calibrationValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsCalibrationDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsCalibrationDateError(null);
      setIsCalibrationDateError(null);
    }
  };

  const warrantyValidDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsWarrantyError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsWarrantyError(null);
      // setIsCmcEndDateError(null);
    }
  };

  useEffect(() => {
    let Id = uuid();
    localStorage.setItem("assetId", Id);
  }, []);



  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };


  const handleAccessory = (e, item) => {
    if (item !== null && item !== undefined) {
      // setSelectedId(item.id);
      setAccessoryType(item.name);
      // setShow(false);
      // setShowDetail(false);
      // setYearlyData([]);
      // setAssetValue("");
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess?.departmentids,
      };
      // dispatch(getDepartmentData(data, token));
      // setData({});
    }
  };


  const handleDeleteAccessory = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    // dispatch(clearServiceImgDel(data));
    setFileUrlAccessory(fileUrl?.filter((el) => el?.imageid !== id));
  };


  const handleDescription = (e) => {
    const value = e.target.value.trim();
    const regex =  /^[a-zA-Z0-9.,'"\s-]*$/;
    if (value === "") {
      setDescription(value);
      setIsDescriptionError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setDescription(value);
      setIsDescriptionError(null);
    } else {
      setIsDescriptionError("Enter a valid description");
    }
  };



  const handleTotalAmountSpent = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError(null);
    } else {
      setIsTotalAmountSpentError("Enter a valid amount");
    }
  };





const handleAddExpenditure = () => {
  setExpenditures([...expenditures, { ...newExpenditure }]);
  setNewExpenditure({
    description: '',
    totalAmountSpent: '',
    expImage: [{ image: '', imageId: '' }]
  });
};

const handleRemoveExpenditure = (index) => {
  const updatedExpenditures = expenditures.filter((_, i) => i !== index);
  setExpenditures(updatedExpenditures);
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setNewExpenditure({ ...newExpenditure, [name]: value });
};





const handleTotalAmountSpentObject = (e,index,) => {
  handleTotalAmountSpent(e)
let value = e.target.value
let edit = [...expenditures]
   edit[index].totalAmountSpent = value
setExpenditures([...edit]);
};




const handleDescriptionObject = (e,index,) => {
  handleDescription(e)
let value = e.target.value
let edit = [...expenditures]
   edit[index].description = value
setExpenditures([...edit]);
};


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ bgcolor: "white" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}>
              <Stack
                sx={{
                  width: "50%",
                  height: "100%",

                  gap: "15px",
                  textAlign: "start",
                }}>
                <Stack>
                  <Typography
                    sx={{
                      marginBottom: "20px",
                      color: "rgba(70, 144, 255, 1)",
                      fontWeight: "700",
                    }}>
                    Representative
                  </Typography>
                  <Field
                    label="Name "
                    onChange={handleName}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{nameError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    label="Email ID"
                    onChange={handleEmail}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isEmailError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    label="Company"
                    onChange={handleCompany}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isCompanyError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    label="Mobile No."
                    onChange={handleNumber}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">
                    {isMobileNumberError}
                  </HelperText>
                </Stack>
                <Stack>
                  <Field
                    label="Software Version"
                    onChange={(e) => setSoftware(e.target.value)}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        );
      case 1:
        return (
          <Stack sx={{ bgcolor: "white", gap: "15px" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
              }}>
              <Stack
                sx={{
                  width: "50%",

                  gap: "15px",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Stack sx={{ gap: "10px" }}>
                  <Label>Problem Identified</Label>
                  <Field
                    multiline
                    rows={3}
                    label="Problem Identified"
                    onChange={handleIssue}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    sx={{ width: "100%" }}
                  />
                  {<HelperText>{isIssueError}</HelperText>}
                </Stack>
                <Stack sx={{ gap: "10px" }}>
                  <Label>Action Taken</Label>
                  <Field
                    multiline
                    rows={3}
                    label="Action Taken"
                    onChange={handleAction}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    sx={{ width: "100%" }}
                  />
                  {<HelperText>{isActionError}</HelperText>}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "50%",

                  gap: "3px",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Label>Services</Label>
                <Stack
                  sx={{
                    flexDirection: "row",

                    alignItems: "center",
                  }}>
                  <FormLabel sx={{ width: "150px" }}>AMC/CMC</FormLabel>
                  <RadioGroup
                    row
                    onChange={(e) => setAmcCmc(e.target.value)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group">
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </Stack>
                {<HelperText>{amccmError}</HelperText>}

                <Stack
                  sx={{
                    flexDirection: "row",

                    alignItems: "center",
                  }}>
                  <FormLabel sx={{ width: "150px" }}>Status</FormLabel>
                  <RadioGroup
                    value={status}
                    onChange={handleStatusChange}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group">
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </Stack>
                {<HelperText>{isStatusError}</HelperText>}

                <Stack
                  sx={{
                    flexDirection: "row",

                    alignItems: "center",
                  }}>
                  <FormLabel sx={{ width: "150px" }}>Paid Service</FormLabel>
                  <RadioGroup
                    name="paid"
                    value={isPaid}
                    sx={{
                      display: "flex",

                      flexDirection: "row",
                    }}
                    onChange={handlePaidChange}>
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </Stack>
                {showError &&
                  (!amount || receipt === "" || receipt === false) && (
                    <HelperText>Please fill both fields</HelperText>
                  )}

                {showError && !isPaid && (
                  <HelperText>Please select an option for "Paid"</HelperText>
                )}
                {isPaid === "yes" && (
                  <Stack sx={{ gap: "20px" }}>
                    <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          width: "150px",
                        }}>
                        Service cost
                      </Typography>

                      <TextField
                        style={{
                          width: 150,
                          height: "45px",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        value={amount}
                        onChange={handleAmountChange}
                        error={showError && !amount}
                        sx={{
                          bgcolor: "rgba(244, 246, 246, 1)",
                          // border: "1px solid rgba(23, 70, 162, 0.20)",
                          "& fieldset": { border: "none" },
                          textAlign: "center",
                          borderRadius: "6px",
                        }}
                        placeholder="Rs : 3,000"
                      />
                    </Stack>

                    <Stack
                      sx={{
                        width: "100%",

                        flexDirection: "row",

                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <Label>Receipt</Label>

                      <Stack>
                        <label htmlFor="file-upload">
                          <FileUploadIcon
                            size={20}
                            disabled={receipt !== "" && receipt !== false}
                            style={{
                              color:
                                receipt !== "" && receipt !== false
                                  ? "rgb(222,222,222)"
                                  : "black",
                            }}
                          />
                        </label>

                        <input
                          disabled={receipt !== "" && receipt !== false}
                          type="file"
                          accept=".pdf,.jpg,.jpeg,.png"
                          onChange={handleReceiptUpload}
                          style={{ display: "none" }}
                          id="file-upload"
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        height: "93px",
                        width: "342px",
                        flexDirection: "row",
                        overflowX: "auto",
                        overflowY: "hidden",

                        alignItems: "center",
                        background: "rgba(244, 246, 246, 1)",
                        paddingLeft: "20px",
                      }}>
                      {receipt && (
                        <>
                          {file.type.startsWith("image") ? (
                            <Badge
                              badgeContent={
                                <CloseIcon
                                  onClick={() =>
                                    handleDeleteReceipt(receipt.imageid)
                                  }
                                  size={15}
                                  style={{
                                    color: " rgba(255, 58, 68, 1)",
                                  }}
                                />
                              }
                              sx={{
                                // height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "10px",

                                padding: "5px",
                              }}>
                              <Box
                                as="img"
                                src={receipt?.image}
                                alt=""
                                sx={{ width: "50px", height: "50px" }}
                              />
                            </Badge>
                          ) : (
                            <>
                              <Badge
                                badgeContent={
                                  <CloseIcon
                                    onClick={() =>
                                      handleDeleteReceipt(receipt.imageid)
                                    }
                                    size={15}
                                    style={{
                                      color: " rgba(255, 58, 68, 1)",
                                    }}
                                  />
                                }
                                sx={{
                                  // height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",

                                  padding: "5px",
                                }}>
                                <PictureAsPdfIcon sx={{fontSize:"60px"}} />
                              </Badge>
                            </>
                          )}
                          {showError &&
                            (!amount ||
                              receipt === "" ||
                              receipt === false) && (
                              <HelperText>Please fill both fields</HelperText>
                            )}
                        </>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "30px",
              }}>
              <Stack
                sx={{
                  gap: "10px",

                  width: "50%",
                  textAlign: "start",
                }}>
                <Label>Remarks</Label>
                <Field
                  multiline
                  rows={3}
                  label="Remarks"
                  onChange={(e) => setRemark(e.target.value)}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  sx={{ width: "100%" }}
                />
              </Stack>
              <Stack
                sx={{
                  width: "50%",

                  gap: "3px",
                  // justifyContent: "space-between",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Stack
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    flexDirection: "row",
                  }}>
                  <Label>Upload Images</Label>
                  {uploadedImageName?.length < 3 && (
                    <>
                      <label for="img">
                        <FileUploadIcon
                          size={20}
                          style={{
                            color: "rgba(27, 37, 53, 1)",
                            cursor: "pointer",
                          }}
                        />
                      </label>

                      <input
                        type="file"
                        name="uploadfile"
                        id="img"
                        style={{
                          display: "none",
                          width: "42px",
                          height: "42px",
                        }}
                        onChange={handlePhotoUpload}
                      />
                    </>
                  )}
                </Stack>

                <Stack
                  sx={{
                    height: "93px",
                    width: "342px",
                    flexDirection: "row",
                    overflowX: "auto",
                    overflowY: "hidden",
                    gap: "20px",
                    alignItems: "center",
                    background: "rgba(244, 246, 246, 1)",
                    paddingLeft: "20px",
                  }}>
                  {fileUrl?.map((item, index) => (
                    <Badge
                      key={index}
                      badgeContent={
                        <CloseIcon
                          size={15}
                          style={{
                            color: " rgba(255, 58, 68, 1)",
                          }}
                          onClick={() => handleDelete(item?.imageid)}
                        />
                      }
                      sx={{
                        // height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",

                        padding: "5px",
                      }}>
                      <Box
                        as="img"
                        src={item?.image}
                        alt=""
                        sx={{ width: "50px", height: "50px" }}
                      />
                    </Badge>
                  ))}
                </Stack>
                {isPhotoError && (
                  <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                )}
              </Stack>
            </Stack>

<Stack sx={{width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
<Label style={{color:'#4690FF', textAlign:'left'}}>
Expenditure
</Label>

<Button onClick={handleAddExpenditure} variant="outlined" sx={{borderRadius:'25px'}}>
Add Expenditure
</Button>
</Stack>


           { expenditures?.map((item, index)=>(
 <Stack
 sx={{
   display: "flex",
   gap: "30px",
 }}>
<Stack
 sx={{
   justifyContent: "space-between",
   flexDirection: "row",
   gap: "30px",
 }}>




 <Stack
   sx={{
     gap: "10px",
  
     width: "50%",
     textAlign: "start",
   }}>
      <Field
     value={item.description}
     placeholder="Reason description"
     onChange={(e)=>handleDescriptionObject(e,index)}
     variant="outlined"
     sx={{ width: "100%" }}
   />
   <HelperText>
    {isDescriptionError}
   </HelperText>
 </Stack>


 <Stack
                sx={{
                  gap: "10px",
                  width: "50%",
                  textAlign: "start",
                }}>
                   <Field
                  value={item.totalAmountSpent}
                  placeholder="Total amoutnt spent"
                  onChange={(e)=>handleTotalAmountSpentObject(e,index)}
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <HelperText>
    {isTotalAmountSpentError}
   </HelperText>
              </Stack>
              
              


</Stack>







<Stack
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "30px",
              }}>
      
   
            
<Stack
                sx={{
                  width: "50%",

                  gap: "3px",
                  // justifyContent: "space-between",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Stack
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    flexDirection: "row",
                  }}>
                  <Label>Upload Receipt (Png, jpeg or pdf)</Label>
                  {uploadedImageName?.length < 3 && (
                    <>
                      <label for="img2">
                        <FileUploadIcon
                          size={20}
                          style={{
                            color: "rgba(27, 37, 53, 1)",
                            cursor: "pointer",
                          }}
                        />
                      </label>

                      <input
                        type="file"
                        name="uploadfile"
                        id="img2"
                        style={{
                          display: "none",
                          width: "42px",
                          height: "42px",
                        }}
                        onChange={handleAccessoryPhotoUpload}
                      />
                    </>
                  )}
                </Stack>

                <Stack
                  sx={{
                    height: "93px",
                    width: "342px",
                    flexDirection: "row",
                    overflowX: "auto",
                    overflowY: "hidden",
                    gap: "20px",
                    alignItems: "center",
                    background: "rgba(244, 246, 246, 1)",
                    paddingLeft: "20px",
                  }}>
                  {fileUrlAccessory?.map((item, index) => (
                    <Badge
                      key={index}
                      badgeContent={
                        <CloseIcon
                          size={15}
                          style={{
                            color: " rgba(255, 58, 68, 1)",
                          }}
                          onClick={() => handleDeleteAccessory(item?.imageid)}
                        />
                      }
                      sx={{
                        // height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",

                        padding: "5px",
                      }}>
                      <Box
                        as="img"
                        src={item?.image}
                        alt=""
                        sx={{ width: "50px", height: "50px" }}
                      />
                    </Badge>
                  ))}
                </Stack>
                {isAccessoryPhotoError && (
                  <HelperText sx={{ color: "red" }}>{isAccessoryPhotoError}</HelperText>
                )}
              </Stack>
          
</Stack>

<Stack
              sx={{
                justifyContent: "end",
                flexDirection: "row",
                gap: "30px",
              }}>


<Button onClick={()=>handleRemoveExpenditure(index)} variant="outlined" sx={{borderRadius:'25px'}}>
Delete Expenditure
</Button>


              </Stack>








<Divider/>





</Stack>





           ))}








          </Stack>
        );
      default:
        return null;
    }
  };



const handleOpenModal = ()=> {
  setOpen(true)
}

const handleCloseModal =()=> {
  setOpen(false)
  navigate('/service-list')
}


  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: "800px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="subtitle1" color="primary">
          Clear Service
          </Label>
          {/* <IconButton>
            <Calculate color="primary" />
          </IconButton> */}
        </Stack>

        <Stack
          sx={{
            width: "100%",
            maxWidth: 800,
            margin: "auto",
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <BlueStepper
            activeStep={activeStep}
            nonLinear
            style={{
              height: "70px",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Step>
              <StepLabel>Step 1</StepLabel>
            </Step>
            <Step>
              <StepLabel>Step 2</StepLabel>
            </Step>
          </BlueStepper>

          <Box sx={{ marginTop: 2, width: "90%" }}>
            {getStepContent(activeStep)}

            <>
              <Stack
                sx={{
                  width: "100%",

                  justifyContent: "space-between",
                  marginTop: "20px",
                }}>
                <Divider />
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "16px",
                    }}>
                    <Stack
                      sx={{
                        flexShrink: 1,
                        width: "100%",
                        alignItems: "flex-end",

                        flexDirection: "row",
                        justifyContent: "end",
                        padding: "20px",
                        gap: "10px",
                      }}>
                      {activeStep === 0 ? null : (
                        <Button
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}
                          onClick={() =>
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep - 1
                            )
                          }>
                          Back
                        </Button>
                      )}

                      {activeStep === 0 ? (
                        <Button
                          onClick={handleNext}
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}>
                          Next
                        </Button>
                      ) : (
                        <Button
                          onClick={handleClicks}
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}>
                          Submit
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </>
              </Stack>
            </>
          </Box>
        </Stack>
      </Stack>
      <SuccessPopUp
        openModal={open}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful!"}
        subText1={"Service cleared."}
        subText2={"  "}
      />
    </Stack>
  );
};

export default ClearService;
