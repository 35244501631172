import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, styled } from "@mui/material";
import Navbar3 from "./Navbar3";
import Footer from "./Footer";

const MainDiv = styled(Box)(({ theme }) => ({
  bgcolor: "white",

  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",

  alignItems: "center",
  marginTop: "7%",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    marginTop: "9%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
export default function FAQ() {
  return (
    <MainDiv sx={{}}>
      <Navbar3 />
      <h1>Frequently Asked Questions (FAQs)</h1>
      <Box sx={{ width: "80%", marginBottom: "30px" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <h4>How to place an order?</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"start"}>
              If you are a new customer create a free account by using this link
              https://vajra.software/registration. If you are an existing
              customer use the sign-in option provided in this link
              https://vajra.software/my-account/. If you have a pre-approved
              business account you get exclusive pricing, GST invoices, bulk
              purchasing options, credit facility by using the mobile
              application. You can also download the ASTHRA app from Google Play
              Store for a user-friendly shopping experience. Browse through our
              extensive product list, choose the product you need, add to cart,
              proceed to the cart, and input your personal details, including
              delivery address, contact number, and email id. Further, proceed
              to checkout to make the required payment. Once the payment is
              placed, your order has been confirmed and ready to be shipped to
              your delivery address.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <h4> Do I need an account to place order?</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"start"}>
              Yes, you do. For security purposes and authenticity of order
              delivery, we provide our services to only the registered users. To
              order with us, register today at Vajra and become an esteemed
              user. We would be very glad to be of your service. For any
              queries, call us at +91-8971580265 or write to us at
              contact@vajra.software
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <h4> Do I need an account to view the price of the product?</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"start"}>
              Yes, it is possible only post-registration with Vajra. To view the
              price of any product on Vajra, or request for Quotations, one has
              to Register on https://vajra.software or ASTHRA App and become a
              User.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <h4> How to contact Vajra</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"start"}>
              If you are having any trouble with registering or placing an
              order; or if you have any other queries related to our products
              and services, please feel free to contact us at
              contact@vajra.software or call us at +91-8971580265. We are here
              to be of your service during our regular business hours.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <h4> Is there any mobile app available</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"start"}>
              Yes there is. We have an Android App called Vajra where healthcare
              facilities such as hospitals, laboratories, and pharmacies can
              place their orders, and at the same time, Medical device Vendors
              can Sell their products to hospitals.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Footer />
      </Box>
    </MainDiv>
  );
}
