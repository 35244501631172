import {
  Autocomplete,
  Button,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import {
  AssetHistory,
  departmentAlreadySaved,
  retrieveAllAssets,
} from "../redux/data/action";
import SuccessModal from "../components/SuccessModal";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
  width: "100%",
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const AssetMovement = ({ handleCloseDepart }) => {
  const allDepartment = useSelector((store) => store.data.alreadySaved);
  const allAssets = useSelector((store) => store.data.allAssets);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const response = useSelector((store) => store.data.assetHistoryData);

  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);

  const [previousDepartment, setPreviousDepartment] = useState("");
  const [previousDepartmentId, setPreviousDepartmentId] = useState("");

  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState(allAssets);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [toggle, setToggle] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    handleCloseDepart()
  };
  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);

    const filteredOptions = allAssets.filter((option) => {
      return (
        option.name.toLowerCase().includes(newInputValue.toLowerCase()) ||
        option.assetcode.toLowerCase().includes(newInputValue.toLowerCase())
      );
    });

    setOptions(filteredOptions);
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);

    if (newValue) {
      setPreviousDepartment(newValue.department);

      const matchingDepartment = allDepartment.find(
        (department) => department.departname === newValue.department
      );

      if (matchingDepartment) {
        setPreviousDepartmentId(matchingDepartment.departid);
      } else {
        setPreviousDepartmentId("");
      }
    } else {
      setPreviousDepartment("");
      setPreviousDepartmentId("");
    }
  };

  const handleDepartmentChange = (event, newValue) => {
    if (newValue) {
      setSelectedDepartment(newValue.departname);
      setSelectedDepartmentId(newValue.departid);
    } else {
      setSelectedDepartment("");
      setSelectedDepartmentId("");
    }
  };

  const handleSubmit = () => {
    if (!selectedOption && !selectedDepartment) {
      setErrorMessage("Please select an asset name and a department.");
    } else if (!selectedOption) {
      setErrorMessage("Please select an asset name.");
    } else if (!selectedDepartment) {
      setErrorMessage("Please select a department.");
    } else if (previousDepartment === selectedDepartment) {
      setErrorMessage("Selected department must differ from the current one.");
    } else {
      setErrorMessage("");

      let data = {
        hospid: ids?.hospid,
        username: ids?.username,
        userid: ids?.userid,
        hospname: ids.hospname,
        branchid: userBranch?.id,
        assetid: selectedOption.assetid,
        assetname: selectedOption.name,
        assetcode: selectedOption.assetcode,
        p_depart: previousDepartment,
        p_departid: previousDepartmentId,
        to_depart: selectedDepartment,
        to_departid: selectedDepartmentId,
      };

      dispatch(AssetHistory(data));
      setOpenModal(true)
    }
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(departmentAlreadySaved(data, token));
    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (allAssets && allAssets?.length > 0 && allAssets?.length > 0) {
      setOptions(allAssets);
    }
  }, [allAssets]);

  return (
    <>
      <Stack
        style={{
          width: "40%",
          gap: "10px",
          height: "100%",
        }}>
        <Stack
          flexDirection={"row"}
          height="50px"
          justifyContent={"space-between"}
          alignItems={"center"}
          padding="16px"
          gap={"10px"}
          sx={{
            bgcolor: "white",
            boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            width: "100%",
          }}>
          <Typography style={{ color: "rgba(70, 144, 255, 1)" }}>
            Add New Movement
          </Typography>
          <IconButton color="primary" onClick={handleCloseDepart}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack
          sx={{
            bgcolor: "white",
            alignItems: "start",
            padding: "15px",

            width: "100%",
            gap: "10px",
            textAlign: "start",
            justifyContent: "start",

            height: "100%",
          }}>
          <Typography>Details</Typography>
          <Stack
            sx={{
              width: "100%",
              background: "rgba(70, 144, 255, 1)",
              margin: "5px 0",
            }}>
            <Divider />
          </Stack>
          <Stack sx={{ width: "100%" }}>
            <Autocomplete
              id="search-autocomplete"
              inputProps={{ "aria-label": "Without label" }}
              options={options}
              getOptionLabel={(option) => option.name + "-" + option.assetcode}
              inputValue={searchTerm}
              onInputChange={handleInputChange}
              onChange={handleOptionChange}
              renderInput={(params) => (
                <Field
                  {...params}
                  placeholder="Select Asset"
                  variant="outlined"
                />
              )}
            />
          </Stack>

          <Stack sx={{ width: "100%" }}>
            <Field
              value={previousDepartment}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon sx={{ color: "#1746A2" }} />
                </InputAdornment>
              }
              placeholder="Department Name"
            />
          </Stack>

          <Stack sx={{ width: "100%", marginTop: "50px" }}>
            <Autocomplete
              id="department-autocomplete"
              options={allDepartment}
              getOptionLabel={(option) => option.departname}
              onChange={handleDepartmentChange}
              renderInput={(params) => (
                <Field
                  {...params}
                  placeholder="Choose Department"
                  variant="outlined"
                />
              )}
            />
            {<HelperText>{errorMessage}</HelperText>}
          </Stack>
          <Stack
            sx={{
              width: "100%",
              background: "rgba(70, 144, 255, 1)",
              margin: "5px 0",
            }}>
            <Divider />
          </Stack>
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "4%",
            }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={userAccess?.role === "Guest"}
              style={{ width: "100px", borderRadius: "22px" }}>
              {" "}
              Submit
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"New Asset movement created  "}
        subText2={"  "}
      />
    </>
  );
};

export default AssetMovement;
