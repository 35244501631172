import {
  Typography,
  Box,
  FormControl,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  FormHelperText,
  AppBar,
  Toolbar,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import feature1 from "../images/feature1.png";
import feature2 from "../images/feature2.png";
import feature3 from "../images/feature3.png";
import authImage from "../images/authImage.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAuth, getVerificationLink, postAuth } from "../redux/auth/action";
import { useSelector } from "react-redux";
import Navbar4 from "../components/Navbar4";
import Footer2 from "../components/Footer2";
import styled from "@emotion/styled";
import Progress from "../components/Progress";
import FooterMain from "./FooterMain";
import NavbarMain from "./NavbarMain";


const SignInput = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
}));

const CustomLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "rgba(192, 192, 192, 1)",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  width: "300px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

function SignInPage() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [savedTime, setSavedTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let token = localStorage.getItem("userToken");

  const navigate = useNavigate();

  const signin = useSelector((store) => store.auth.signInAuth);
  const verification = useSelector((store) => store.auth.getLink);
  const isLoading = useSelector((store) => store.auth.isLoading);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isUsernameError, setIsUsernameError] = useState("");
  const [isPasswordError, setIsPasswordError] = useState("");
  const dispatch = useDispatch();

  const handleUserName = (e) => {
    const value = e.target.value;
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (value === "" || regex.test(value.trim())) {
      setUserName(value.trim());
      setIsUsernameError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setIsUsernameError("Enter valid email");
    }
    if (value === "") {
      setIsUsernameError("Please fill the field");
    }
  };

  const handlePassword = (e) => {
    const value = e.target.value;

    if (value.length >= 6) {
      setPassword(value.trim());
      setIsPasswordError("");
    }
    if (value.trim().length < 6) {
      setIsPasswordError("Password must have 6 characters");
    }
    if (value === "") {
      setIsPasswordError("Please fill the field");
    }
  };

  const handleClick = () => {
    if (userName !== "" && password !== "") {
      if (
        password.length >= 6 &&
        isUsernameError === "" &&
        isPasswordError === ""
      ) {
        let data = {
          email: userName.trim(),
          password: password.trim(),
        };

        dispatch(getAuth(data));
        localStorage.setItem("loginEvent", Date.now().toString());
      }
    }
    userName === "" && setIsUsernameError("Please fill the field");
    password === "" && setIsPasswordError("Please fill the field");
  };

  useEffect(() => {
    if (signin?.message === "Incomplete" && signin?.success === false) {
      navigate("/register");
      window.location.reload();
    }
    if (signin?.message === "LoggedIn" && signin?.success === true) {
      navigate("/select");
      // window.location.reload();
    }
  }, [token, navigate, signin, token]);

  useEffect(() => {
    const savedTime = localStorage.getItem("savedTime");
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - savedTime;

    if (savedTime && timeDifference < 60000) {
      setIsButtonDisabled(true);
      setRemainingTime(Math.ceil((60000 - timeDifference) / 1000));

      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - savedTime;
        const remainingSeconds = Math.ceil((60000 - timeDifference) / 1000);

        if (remainingSeconds > 0) {
          setRemainingTime(remainingSeconds);
        } else {
          localStorage.removeItem("savedTime");
          setSavedTime(null);
          setIsButtonDisabled(false);
          setRemainingTime(0);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isButtonDisabled]);

  const handleNavigate = () => {
    navigate("/signup");
    window.location.reload();
  };
  const handleSaveTime = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem("savedTime", currentTime);
    setIsButtonDisabled(true);
  };

  const handleSend = () => {
    if (userName !== "") {
      let data = {
        email: userName,
      };
      dispatch(getVerificationLink(data));
      handleSaveTime();
      setIsUsernameError("");
    }
    if (userName === "") {
      setIsUsernameError("Please fill the field");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
        bgcolor: "white",
      }}>
      <NavbarMain />

      <Stack
        sx={{
          flex: 1,

          justifyContent: "center",
          alignItems: "center",
        }}>
        <Stack
          sx={{
            p: { sm: 0, lg: 5 },
            height: "calc(100vh - 150px)",

            width: { sm: "100%", lg: "60%" },
            flexDirection: "row",
            gap: "30px",
          }}>
          <Stack
            sx={{
              width: "40%",
              display: { xs: "none", sm: "none", lg: "flex" },
            }}>
            <div
            
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                backgroundColor: "#1B2535",
              }}>
              <img
                  src={authImage}
                  alt="authImage"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
            </div>
          </Stack>

          <Stack
            sx={{
              width: { sm: "100%", lg: "60%" },

              boxShadow: "0px 0px 10px 0px rgba(219, 231, 229, 1)",
            }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "20px ",
                alignItems: "center",
              }}>
              <form
                style={{
                  width: { sm: "100%", lg: "60%" },

                  height: "100%",
                }}>
                <Stack
                  sx={{
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",

                    height: "100%",
                    gap: "20px",
                    justifyContent: "space-around",
                  }}>
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    Hello again!
                  </Typography>
                  <Stack sx={{ gap: "20px" }}>
                    <Stack>
                      <CustomLabel>Email</CustomLabel>
                      <SignInput
                        size="small"
                        placeholder="e.g. abc@gmail.com"
                        onChange={handleUserName}
                        onKeyPress={handleKeyPress}
                        sx={{
                          bgcolor: "#EFF5FE",
                        }}
                        // endAdornment={
                        //   <InputAdornment position="end">
                        //     <IconButton disabled>
                        //       <PermIdentityIcon sx={{ color: "#787878" }} />
                        //     </IconButton>
                        //   </InputAdornment>
                        // }
                      />
                      <HelperText>{isUsernameError}</HelperText>
                    </Stack>

                    <Stack>
                      <CustomLabel>Password</CustomLabel>
                      {/* <SignInput
                        size="small"
                        placeholder="e.g. aBcH0sp1t@l"
                        onChange={handlePassword}
                        onKeyPress={handleKeyPress}
                        sx={{
                          bgcolor: "#EFF5FE",
                        }}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ marginRight: "2px" }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end">
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      /> */}

<SignInput
      size="small"
      placeholder="e.g. aBcH0sp1t@l"
      onChange={handlePassword}
      onKeyPress={handleKeyPress}
      sx={{ bgcolor: "#EFF5FE" }}
      id="outlined-adornment-password"
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ marginRight: "2px" }}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />



                      <HelperText>{isPasswordError}</HelperText>
                    </Stack>
                  </Stack>

                  <Stack
                    sx={{
                      width: "100%",
                    }}>
                    <Divider style={{ background: "#4690FF" }} />
                  </Stack>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      sx={{ width: "150px", borderRadius: "22px" }}>
                      Sign In
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      textAlign: "center",

                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    {signin?.message !== "USER_DISABLED" &&
                      signin?.message !== "EMAIL_NOT_VERIFIED" &&
                      signin?.message !== "LoggedIn" &&
                      signin?.message !== "Inactive" && (
                        <FormHelperText
                          sx={{
                            color: "#EA232B",
                            fontFamily: "Poppins",
                          }}>
                          {signin?.message}{" "}
                        </FormHelperText>
                      )}

                    {signin?.message === "Inactive" && (
                      <FormHelperText
                        sx={{
                          color: "#EA232B",
                          fontFamily: "Poppins",
                        }}>
                        Account is not active please wait for some time or
                        contact Admin
                      </FormHelperText>
                    )}

                    {signin?.message === "USER_DISABLED" && (
                      <FormHelperText
                        sx={{
                          color: "#EA232B",
                          fontFamily: "Poppins",
                        }}>
                        Your account is not activated yet
                      </FormHelperText>
                    )}

                    {signin?.message === "EMAIL_NOT_VERIFIED" && (
                      <FormHelperText
                        sx={{
                          color: "#EA232B",
                          fontFamily: "Poppins",
                        }}>
                        Your email is not verified yet.{" "}
                      </FormHelperText>
                    )}

                    {signin?.message === "NoUser" && (
                      <FormHelperText
                        sx={{
                          color: "#EA232B",
                          fontFamily: "Poppins",
                        }}>
                        Your account is deleted contact Admin.
                      </FormHelperText>
                    )}

                    {verification === "Sent" && (
                      <FormHelperText
                        sx={{
                          color: "#EA232B",
                          fontFamily: "Poppins",
                        }}>
                        Please check your mail
                      </FormHelperText>
                    )}

                    {signin?.message === "Your email is not verified yet." && (
                      <Button
                        disabled={isButtonDisabled}
                        onClick={handleSend}
                        sx={{ textTransform: "none" }}>
                        Get verification link{" "}
                        {remainingTime === 0 ? "" : remainingTime}
                      </Button>
                    )}

                    {signin?.message === "EMAIL_NOT_VERIFIED" && (
                      <Button
                        disabled={isButtonDisabled}
                        onClick={handleSend}
                        sx={{ textTransform: "none" }}>
                        Get verification link{" "}
                        {remainingTime === 0 ? "" : remainingTime}
                      </Button>
                    )}
                  </Stack>
                  <Typography>
                    Forgot password?
                    <br />
                    <Link
                      to="/forgot-password"
                      style={{
                        color: "rgba(70, 144, 255, 1)",
                        textDecoration: "none",
                      }}>
                      Click here to get password
                    </Link>
                  </Typography>
                </Stack>
              </form>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <FooterMain />
    </Box>
  );
}

export default SignInPage;
