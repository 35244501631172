import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
} from "@mui/material";


import GridViewIcon from '@mui/icons-material/GridView';
import jsQR from "jsqr";
import { useState } from "react";

import "jspdf-autotable";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";


const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));


const MainWrapper = styled(Box)(({ theme }) => ({
  width: "650px",
  display:'flex',
  borderRadius: "8px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));



const QrCodeScanner = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [scannedData, setScannedData] = useState(null);

  useEffect(() => {
    let stream;

    const startScanner = async () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "environment" },
          });
          if (videoRef.current instanceof HTMLVideoElement) {
            videoRef.current.srcObject = stream;

            videoRef.current.onloadedmetadata = () => {
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.width = videoRef.current.videoWidth;
              canvas.height = videoRef.current.videoHeight;

              const scanQRCode = async () => {
                if (videoRef.current instanceof HTMLVideoElement) {
                  context.drawImage(
                    videoRef.current,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                  );
                  const imageData = context.getImageData(
                    0,
                    0,
                    canvas.width,
                    canvas.height
                  );

                  const code = scanQR(imageData, canvas.width, canvas.height);

                  if (code) {
                    setScannedData(code.data);
                  }
                }

                requestAnimationFrame(scanQRCode);
              };

              scanQRCode();
            };
          } else {
            console.error("videoRef.current is not a valid HTMLVideoElement.");
          }
        } catch (error) {
          console.error("Error accessing camera:", error);
        }
      } else {
        console.error("getUserMedia is not supported in this browser");
      }
    };

    // QR code scanning function
    const scanQR = (imageData, width, height) => {
      const code = jsQR(imageData.data, width, height);
      return code;
    };

    startScanner();

    // Cleanup function to stop the camera stream when the component unmounts
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (scannedData) {
      navigate(`/asset-detail/${scannedData}`);
    }
  }, [scannedData, navigate]);
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // padding:'10px',
        height: "100%",
      }}>
      <MainWrapper
        sx={{
       
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="h6" color="primary">
            Scan QR code
          </Label>

          <GridViewIcon size={30} style={{ color: "black" }} />
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div
              style={{
                width: "250px",
                height: "250px",
                position: "relative",
                overflow: "hidden",
              }}>
              <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  border: "4px solid #4690FF",
                  borderBottomWidth: "0",
                  borderRightWidth: "0",
                  zIndex: "1",
                  top: "0",
                  left: "0",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  border: "4px solid #4690FF",
                  borderBottomWidth: "0",
                  borderLeftWidth: "0",
                  zIndex: "1",
                  top: "0",
                  right: "0",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  border: "4px solid #4690FF",
                  borderTopWidth: "0",
                  borderRightWidth: "0",
                  zIndex: "1",
                  bottom: "0",
                  left: "0",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  border: "4px solid #4690FF",
                  borderTopWidth: "0",
                  borderLeftWidth: "0",
                  zIndex: "1",
                  bottom: "0",
                  right: "0",
                }}
              />
            </div>

            <Typography color="primary">Scan your QR code here.</Typography>
            <Box sx={{ maxWidth: "85%", marginTop: "10px" }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "rgba(181, 181, 182, 1)" }}>
                Please check your system settings to ensure that the camera is
                not locked. If the issue persists, consider adjusting your
                browser or device camera settings.
              </Typography>
            </Box>
          </Box>
        </Box>
      </MainWrapper>
    </Stack>
  );
};

export default QrCodeScanner;
