import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  styled,
  Divider,
  FormHelperText,
  Autocomplete,
} from "@mui/material";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import "jspdf-autotable";

import {
 
  departmentAlreadySaved,
  postDepartment,
  retrieveDepart,
  updateDepartment,
} from "../redux/data/action";
import SuccessPopUp from "../components/SuccessPopUp";
import { useEffect } from "react";

const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const Label2 = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 500,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

 
}));


const OuterSet = styled(Box)(({ theme }) => ({
  width: "600px",

  borderRadius: "8px",

  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",

    // flexDirection:"column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));


const SplitContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "170px",
  minHeight: "170px",
  marginTop: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",

    // flexDirection:"column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));




const EditDepartment = ({ data, showEdit }) => {
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const allDepartment = useSelector((store) => store.nbmData.alreadySaved);
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const response = useSelector((store) => store.data.editDepart);

  const inchargeName = useSelector((store) => store.data.incharge);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inCharge, setInCharge] = useState(data?.departincharge);
  const [departName, setDepartName] = useState(data?.departname);
  const [description, setDescription] = useState(data?.departdesc);

  const [inchargeuserId, setInchargeUserId] = useState(data?.inchargeuserid);
  const [departNameError, setDepartNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const text = "Department Edited";

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    showEdit(false);
    // navigate("/department");
  };

  const handleDepartment = (e) => {
    let value = e.target.value;
    setDepartName(value);

    const isDepartmentExist = allDepartment?.some(
      (department) =>
        department.departname.toLowerCase() === value.toLowerCase().trim()
    );

    if (!value.trim()) {
      setDepartNameError("Department is required.");
    } else if (isDepartmentExist) {
      setDepartNameError("Department already exist.");
    } else if (value.length < 3) {
      setDepartNameError("Please enter valid department.");
    } else {
      setDepartNameError("");
    }
  };

  const handleIncharge = (e, newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setInCharge(newValue.name);
      setInchargeUserId(newValue.id);
    } else {
      setInCharge("");
      setInchargeUserId("");
    }
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
    if (!e.target.value) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError("");
    }
  };

  const handleSubmit = () => {
    if (departName === "") {
      setDepartNameError("Please fill the field");
    }

    if (departNameError === "" && departName !== "") {
      let Sdata = {
        hospid: ids?.hospid,
        username: ids?.username,
        userid: ids?.userid,
        department: departName.trim(),
        departid: data?.departid,
        branchid: userBranch.id,
        departdesc: description?.length === "" ? "NA" : description.trim(),
        departincharge: inCharge?.length === "" ? "NA" : inCharge,
        inchargeuserid: inchargeuserId === "" ? "NA" : inchargeuserId,
      };

      setToggle(true);
      dispatch(updateDepartment(Sdata, token));
    }
  };

  useEffect(() => {
    let data = {
      hosp_id: ids.hospid,
      userid: ids.userid,
      branchid: userBranch?.id,
    };

    dispatch(postDepartment(data, token));
  }, [dispatch]);

  useEffect(() => {
    let datas = {
      hospid: ids.hospid,
      userid: ids.userid,
      departid: data?.departid,
    };
    dispatch(retrieveDepart(datas));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(departmentAlreadySaved(data));
  }, [dispatch]);

  useEffect(() => {
    if (response === "Updated" && departName !== "") {
      setOpenModal(true);
    }
  }, [response]);

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}>

      <OuterSet
        sx={{
          width: "600px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "white",
            padding: "20px",
          }}>
          <Label variant="subtitle1" color="primary">
            Edit Department
          </Label>
         
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}>
            <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
              Department Details
            </Label>
          </Box>
          <Divider style={{ background: "rgba(70, 144, 255, 1)" }} />

          <SplitContainer
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "170px",
              minHeight: "170px",
              marginTop: "20px",
            }}>
            <Stack
              sx={{
                width: "50%",
                marginRight: "16px",
                gap: "22px",
                // justifyContent: "space-between",

                height: "100%",
                textAlign: "start",
              }}>
              <Stack>
                <Field
                  label="Department Name "
                  value={departName}
                  onChange={handleDepartment}
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{departNameError}</HelperText>
              </Stack>
              <Stack>
                <Autocomplete
                  sx={{ bgcolor: "#EFF5FE" }}
                  options={inchargeName?.map((option) => ({
                    name: option.username,
                    id: option?.userid,
                  }))}
                  getOptionLabel={(option) => option.name} // Specify how to get the label for each option
                  value={{ name: inCharge, id: inchargeuserId }} // Set the initial value
                  onChange={handleIncharge}
                  renderInput={(params) => (
                    <TextField placeholder="e.g. Arunraj MK" {...params} />
                  )}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "50%",
                height: "100%",

                gap: "15px",
                textAlign: "start",
              }}>
              <Stack sx={{ height: "100%" }}>
                <Field
                  multiline
                  rows={5}
                  label="Description"
                  value={description}
                  onChange={handleDescription}
                  variant="filled"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{descriptionError}</HelperText>
              </Stack>
            </Stack>
          </SplitContainer>

          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "16px",
              }}>
              <Stack
                sx={{
                  flexShrink: 1,
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{
                    width: "154px",
                    borderRadius: "22px",
                    bgcolor: "rgba(70, 144, 255, 1)",
                  }}>
                  Submit
                </Button>
              </Stack>
            </Box>
          </>
          <>
            <Stack
              sx={{
                width: "100%",

                justifyContent: "space-between",
                marginTop: "20px",
              }}>
              <Divider />
              <Stack
                flexDirection={"row"}
                sx={{
                  width: "100%",

                  justifyContent: "space-between",
                  marginTop: "10px",
                }}></Stack>
            </Stack>
          </>
        </Box>
      </OuterSet>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"Your changes has been submitted successfully.  "}
        subText2={"   "}
        toggle={toggle}
      />
    </Stack>
  );
};

export default EditDepartment;
