import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
function DrowerComp({ handleScrollTo }) {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={"left"}>
        <List sx={{ fontFamily: "Poppins" }}>
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemText fontFamily="Poppins">Home</ListItemText>
          </ListItemButton>

          <ListItemButton component={Link} to="/" onClick={handleScrollTo}>
            <ListItemText fontFamily="Poppins">How vajra works</ListItemText>
          </ListItemButton>

          <ListItemButton onClick={() => navigate("/signin")}>
            <ListItemText>Sign in</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/signup")}>
            <ListItemText>Sign up</ListItemText>
          </ListItemButton>
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "black", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default DrowerComp;
