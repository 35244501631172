import * as React from "react";
import { Box, Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DashboardPage from "./DashboardPage";
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textAlign:'left',
wordWrap: "break-word", whiteSpace: "pre-wrap"
}));

const ButtonSet = styled(Button)(({ theme }) => ({
 borderRadius:'30px',
 
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width:'140px'
  },
  [theme.breakpoints.down("sm")]: {},
}));



export default function DashboardMobile() {
  const navigate = useNavigate();
  const [request, setRequest] = React.useState("asset")
  const [dashboardDataCount, setDashboardDataCount] = React.useState({
    department:0,
    checkin:0,
    checkout:0,
    asset:0,
    downtime:0
})

// let dashboardDataCount = {
//   department:0,
//   checkin:0,
//   checkout:0,
//   asset:0,
//   downtime:0
// }

const handleDataChange = (childData) => {
console.log("99999999999999------------------->>>>>>>>>>>")


  setDashboardDataCount({
    department:childData.department,
    checkin:childData.checkin,
    checkout:childData.checkout,
    asset:childData.asset,
    downtime:childData.downtime
  });
};

const handleAsset = ()=>{
  setRequest("asset")
}
const handleIncident = ()=>{
  setRequest("incident")
}
  
  return (
    <>
    <Stack
      sx={{
        width: "100%",
        // height: "100vh",
        marginTop:'-11px',
       
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        bgcolor: "#f5f5f5",
        gap: "30px",
        paddingBottom:'11px',
      }}>
      <Stack
        sx={{
         
          height: "150px",
          bgcolor: "#1B2535",
          width: "100%",
          color: "white",
        }}>
        <Stack
          justifyContent={"space-between"}
          sx={{ flexDirection: "row", p: 2, alignItems: "end", height: "90%" }}>
          <Label variant="h4">Dashboard.</Label>
         
        </Stack>




        <Stack
          justifyContent={"space-between"}
          sx={{ flexDirection: "row",padding:'10px', alignItems: "end", height: "90%",color:'#FF6B00' }}>
            <Box>
            <Label sx={{textAlign:'left'}} variant="h6">Not Working.</Label>
            <Label variant="h6" sx={{fontSize: '0.75rem'}}>{dashboardDataCount?.downtime}</Label>
            </Box>
          
          <Label variant="h6">
            <WarningAmberIcon sx={{fontSize:'40px', color:'#FF6B00'}}  />
          </Label>
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "80%",
          height: "45%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          gap: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // display: "grid",
            // gridTemplateColumns:'repeat(3,1fr)',
  
            height: "130px",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/department-list")}
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              background:'white',
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {dashboardDataCount?.department}
            </Label>
            <Label variant="h6">Depart.</Label>
          </Stack>

          <Stack
            onClick={() => navigate("/all-assets")}
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              background:'white',
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {dashboardDataCount?.asset}
            </Label>
            <Label variant="h6">Assets.</Label>
          </Stack>

          <Stack
            onClick={() => navigate("/check-in")}
            sx={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-between",
              alignItems: "start",
              background:'white',
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {dashboardDataCount?.checkin}
            </Label>
            <Label variant="h6">Check in</Label>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            paddingTop:"20px",
            gap: "10px",
          }}>

<ButtonSet variant={request==="asset"?"contained":"outlined"} sx={{
        ...(request === 'incident' && {
          color: 'black',
          borderColor: 'black',
        }),
      }} onClick={handleAsset}>Assets</ButtonSet>

<ButtonSet sx={{
        ...(request === 'asset' && {
          color: 'black',
          borderColor: 'black',
        }),
      }}  variant={request==="incident"?"contained":"outlined"}      onClick={handleIncident}>Incidents</ButtonSet>

          {/* <Stack
            onClick={() => navigate("/home?management=asset")}
            sx={{
              width: "48%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            Working
            </Label>
            <Label variant="h6">Assets Management</Label>
          </Stack>
          
          <Stack
            onClick={() => navigate("/home?management=incident")}
            sx={{
              width: "48%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            Working
            </Label>
            <Label variant="h6">Incidents 
            Management</Label>
          </Stack> */}
        </Box>
      </Box>
      {/* <Divider sx={{ width: "90%" }} /> */}
      {/* <Button
        variant="contained"
        size="large"
        sx={{ borderRadius: "22px", width: "80%" }}
        onClick={() => navigate("/depreciation")}>
        Calculate Depreciation
      </Button> */}



    
    </Stack>
    <DashboardPage handleDataChange={handleDataChange} request={request} />
    </>
  );
}
