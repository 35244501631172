import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import pdfImage from "../images/pdfImage.png";

import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuid } from "uuid";
import { AddAssetReport, assetDeleteReport } from "../redux/nbmData/action";
import { useDispatch } from "react-redux";

import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import styled from "@emotion/styled";
import ButtonGroup from "@mui/material/ButtonGroup";

const ImageSet = styled(Box)(({ theme }) => ({
  width: "140px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const UploadButton = styled(Button)(({ theme }) => ({
  borderRadius: "15px",
  textTransform: "none",
  bgcolor: "#ADC2EC",
  color: "#1746A2",
  "&:hover": {
    backgroundColor: "#ADC2EC",
    color: "#1746A2",
  },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const AddReport = ({ handleAssetReport, assetId }) => {
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const dispatch = useDispatch();
  let userBranch =
  JSON.parse(sessionStorage.getItem("userBranch")) ||
  JSON.parse(localStorage.getItem("userBranch"));
  const initialRow = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    imageid: "",
    image: "",
    branchid: userBranch?.id,
    report_name: "Service Report",
  };

  const [openReport, setOpenReport] = useState(false);

  const [fileError, setFileError] = useState("");

  const [data, setData] = useState([initialRow]);
  const [dataToSend, setDataToSend] = useState([initialRow]);
  const [newRow, setNewRow] = useState({ ...initialRow });

  const handleOpenReport = () => {
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
    const filteredData = dataToSend.filter((item) => item.image !== "");
    handleAssetReport(filteredData);
  };

  const handleAddRow = () => {
    setData([...data, { ...newRow }]);
    setDataToSend([...data, { ...newRow }]);
    setNewRow({ ...initialRow });
  };

  const handleRemoveRow = (index) => {
    const updatedData = [...data];
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };
    dispatch(assetDeleteReport(dataDelete));
    updatedData.splice(index, 1);
    setData(updatedData);
    setDataToSend(updatedData);
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    const updatedData = [...data];

    if (file) {
      const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
      const fileType = file.type.split("/")[1];

      if (allowedTypes.includes(fileType)) {
        const splitId = uuid().substring(0, 6);

        const imageid = assetId.substring(0, 6);
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          updatedData[index].imageid = `${imageid}_${splitId}.${fileType}`;
          updatedData[index].image = dataUrl;
          setData(updatedData);
          setDataToSend(updatedData);
          let assetRep = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: updatedData[index].imageid,
            image: updatedData[index].image,
            branchid: userBranch?.id,
          };

          dispatch(AddAssetReport(assetRep));
          setFileError("");
        };
        reader.readAsDataURL(file);
      } else {
        setFileError("Please upload pdf, jpg, jpeg or png file");
      }
    } else {
      updatedData[index].imageid = "";
      updatedData[index].image = "";
      setData(updatedData);
      setDataToSend(updatedData);
    }
  };

  const handleClearImage = (index) => {
    const updatedData = [...data];
    updatedData[index].image = "";
    setData(updatedData);
    setDataToSend(updatedData);
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };

    dispatch(assetDeleteReport(dataDelete));
  };

  const handleReportName = (event, index) => {
    const updatedData = [...data];
    const selectedReportName = event.target.value;
    updatedData[index].report_name = selectedReportName;
    setData(updatedData);
    setDataToSend(updatedData);
  };

  const handleUpload = () => {
    const filteredData = dataToSend.filter((item) => item.image !== "");
    handleAssetReport(filteredData);
    handleCloseReport();
  };

  return (
    <div style={{ paddingBottom: "10px" }}>
      <UploadButton
        onClick={handleOpenReport}
        sx={{
          bgcolor: "#ADC2EC",
        }}>
        <DataThresholdingIcon sx={{ color: "#1746A2" }} />
        Upload Report
      </UploadButton>

      <Dialog open={openReport} onClose={handleCloseReport}>
        <DialogContent>
          <Box>
            <Typography sx={{ color: "" }}>Add Report</Typography>
            {data?.map((row, index) => (
              <div
                style={{ display: "flex", padding: "10px", gap: "10px" }}
                key={index}>
                {row.image ? (
                  <ImageSet
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      bgcolor: "#EFF5FE",
                    }}>
                    <Box
                      width={"50px"}
                      height={"50px"}
                      as="img"
                      src={`${
                        row.imageid?.split(".")?.pop() === "pdf"
                          ? pdfImage
                          : row.image
                      }`}
                      alt="Selected"
                    />

                    <Button
                      onClick={() => handleClearImage(index)}
                      sx={{
                        borderRadius: "0 10px 10px 0",
                        height: "45px",
                        bgcolor: "#839ED0",
                        "&:hover": {
                          bgcolor: "#1746A2",
                        },
                      }}>
                      <IconButton>
                        <CancelIcon sx={{ color: "white" }}/> 
                      </IconButton>
                    </Button>
                  </ImageSet>
                ) : (
                  <>
                    <input
                      type="file"
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={(event) => handleImageUpload(event, index)}
                      style={{ display: "none" }}
                      id="file-upload"
                    />

                    <label htmlFor="file-upload">
                      <ButtonGroup
                        component="span"
                        sx={{
                          textTransform: "none",
                          height: "45px",
                          borderRadius: "10px",
                        }}
                        aria-label="split button">
                        <Button
                          component="span"
                          sx={{
                            bglocor: "#EFF5FE",
                            color: "black",
                            textTransform: "none",
                            borderRadius: "10px 0 0 10px",
                            height: "45px",
                          }}>
                          {" "}
                          Upload IMG/PDF{" "}
                        </Button>
                        <Button
                          component="span"
                          sx={{
                            bgcolor: "#839ED0",
                            height: "45px",
                            "&:hover": {
                              bgcolor: "#1746A2",
                            },
                          }}
                          aria-label="select merge strategy"
                          aria-haspopup="menu">
                          <FileUploadIcon sx={{ color: "white" }} />
                        </Button>
                      </ButtonGroup>
                    </label>
                  </>
                )}
                <Select
                  sx={{ height: "45px" }}
                  value={row.report_name}
                  onChange={(event) => handleReportName(event, index)}>
                  <MenuItem value="Service Report">Service Report</MenuItem>
                  <MenuItem value="Installation Report">
                    Installation Report
                  </MenuItem>
                  <MenuItem value="Warranty Document">
                    Warranty Document
                  </MenuItem>
                  <MenuItem value="Calibration Document">
                    Calibration Document
                  </MenuItem>
                  <MenuItem value="Service Invoice">Service Invoice</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>

                <IconButton onClick={() => handleRemoveRow(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Typography>{fileError}</Typography>
            <Box
              sx={{
                display: "flex",
                minWidth: "240px",
                paddingTop: "10px",
                justifyContent: "space-between",
              }}>
              <Button
                sx={{ textTransform: "none", bgcolor: "#1746A2" }}
                variant="contained"
                onClick={handleAddRow}>
                Add Row
              </Button>
              {data?.length !== 0 ? (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    "&:hover": {
                      bgcolor: "#1746A2",
                      color: "white",
                    },
                  }}
                  onClick={handleUpload}>
                  Submit
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={handleCloseReport}>
                  Cancel
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddReport;
