import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { refreshToken } from "../redux/auth/action";
import styled from "@emotion/styled";
import ReportIcon from "@mui/icons-material/Report";
import { logOut } from "../redux/data/action";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const Main = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "450px",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "270px",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const TopBox = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const ReImage = styled(Box)(({ theme }) => ({
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bgcolor: "#FEE4E2",
  borderRadius: "50%",
  height: "40px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

export default function ReloginModal({ status }) {
  const [open, setOpen] = React.useState(false);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let refresh = JSON.parse(localStorage.getItem("userTokenId"));

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    // let data = {
    //   refreshtoken: refresh?.refreshToken,
    //   userid: userIds.userid,
    // };
    // dispatch(refreshToken(data));



    if (status === "INVALID_TOKEN") {
      let data = {
        userid: ids.userid,
      };
      dispatch(logOut(data, token));
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }


  };

  // React.useEffect(() => {
  //   if (status === "INVALID_TOKEN") {
  //     let data = {
  //       userid: ids.userid,
  //     };
  //     dispatch(logOut(data, token));
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     navigate("/signin");
  //     window.location.reload();
  //   }
  // }, [status]);

  return (
    <div>
      <Modal
        keepMounted
        open={status === "INVALID_TOKEN"}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        
        <Box sx={style}>
          <Main>
            <TopBox>
              <ReImage sx={{ bgcolor: "#FEE4E2" }}>
                <ReportIcon sx={{ color: "#FF0000" }} />
              </ReImage>

              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    textAlign: "left",
                    fontSize: "18px",
                  }}>
                  Session expired
                </Typography>

                <Typography
                  sx={{
                    textAlign: "left",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}>
                  Please Login Again To use Service
                </Typography>
              </Box>
            </TopBox>

            <Box
              sx={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}>
              <Button
                variant="contained"
                onClick={handleClick}
                sx={{
                  borderRadius: "10px",
                  margin: "auto",
                  textTransform: "none",
                  bgcolor: "#D92D20",
                }}
                size="medium">
                Login Again
              </Button>
            </Box>
          </Main>
        </Box>
      </Modal> 
    </div>
  );
}
