import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";

import { Stack, Typography, styled, Button, Divider, Tooltip } from "@mui/material";
import { useState } from "react";
import { AssetHistoryRetrieve } from "../redux/nbmData/action";

import { useEffect } from "react";

import AddDepartmentPage from "../pages/AddDepartmentPage";
import SortSearchFilter from "../components/SortSearchFilter";
import AssetMovement from "../pages/AssetMovement";
import { Link } from "react-router-dom";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const AllAssetHistory = () => {
  const assetHistoryData = useSelector(
    (store) => store?.nbmData?.assetHistoryRetrieveData
  );

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
 
  const [filteredData, setFilteredData] = useState([]);
  
  const [showMovementBox, setShowMovementBox] = useState(false);
  const handleAddMovementClick = () => {
    setShowMovementBox(true);
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
    };
    dispatch(AssetHistoryRetrieve(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (assetHistoryData && assetHistoryData?.length > 0) {
      setData(assetHistoryData);
    }
  }, [assetHistoryData]);

  const handleCloseDepart = () => {
    setShowMovementBox(false);
  };
  return (
    <>
      <Stack>
        <Box
          display="flex"
          flexDirection="row"
          //   justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
          height="85vh"
          width={"100%"}
          style={{}}>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            style={{ width: "100%" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="stretch"
              gap="10px">
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-between"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                }}>
                <Typography>{`Total : ${data?.length}`}</Typography>
              </Stack>
              <Box flex="2">
                <SortSearchFilter
                  data={data}
                  setData={setData}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  heading={"My Orders"}
                  Filter1="p_depart"
                  Filter2="to_depart"
                  sortPrice=""
                  sortDate="date"
                  name="assetname"
                />
              </Box>
            </Box>
            <Stack
              style={{
                width: "100%",
                height: "100%",

                bgcolor: "white",
              }}
              overflow="auto"
              flexDirection={"column"}>
              {/* Table */}
              <TableContainer
                component={Paper}
                style={{
                  height: "100%",
                  // maxHeight: "calc(88vh - 120px)",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  width: "100%",
                }}>
                <Table stickyHeader>
                  {/* Table Header */}
                  <TableHead>
                    <TableRow>
                      <TableHeading>Asset Name</TableHeading>
                      <TableHeading>Asset Code</TableHeading>
                      <TableHeading>Prev Depart</TableHeading>
                      <TableHeading>Curr Depart</TableHeading>

                      <TableHeading>Date</TableHeading>
                      <TableHeading>Time </TableHeading>
                      <TableHeading>User</TableHeading>
                    </TableRow>
                  </TableHead>
                  {/* Table Body with Scrollbar */}
                  <TableBody style={{ overflowY: "scroll" }}>
                    {filteredData.map((item, index) => (
                      <TableRow>
                        <TableCells
                          component={Link}
                          to={`/asset-detail/${item.assetid}`}
                          sx={{ textDecoration: "none" }}>
                          <Tooltip title={item.assetname}>
                            {item.assetname}
                          </Tooltip>
                        </TableCells>
                        <TableCells sx={{}}>{item?.assetcode}</TableCells>
                        <TableCells>{item?.p_depart}</TableCells>
                        <TableCells>{item?.to_depart}</TableCells>
                        <TableCells style={{ whiteSpace: "nowrap" }}>
                          {item?.date}
                        </TableCells>
                        <TableCells style={{ whiteSpace: "nowrap" }}>
                          {item?.time}
                        </TableCells>
                        <TableCells style={{ whiteSpace: "nowrap" }}>
                          {item?.username}
                        </TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {!showMovementBox && (
                <Stack
                  sx={{
                    width: "100%",
                    padding: "20px",
                    bgcolor: "white",
                    gap: "20px",
                  }}>
                  <Divider />
                  <Stack
                    sx={{
                      bgcolor: "white",
                      alignItems: "flex-end",

                      justifyContent: "column",
                    }}>
                    <Button
                      onClick={handleAddMovementClick}
                      variant="contained"
                      sx={{
                        borderRadius: "22px",
                        background: "rgba(70, 144, 255, 1)",
                        marginRight: "20px",
                      }}>
                      Add movements <AddIcon />
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
          {showMovementBox && (
            <AssetMovement handleCloseDepart={handleCloseDepart} />
          )}
        </Box>
      </Stack>
    </>
  );
};

export default AllAssetHistory;
