import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function Footer2() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: { xl: "80px", lg: "50px", md: "50px", sm: "50px", xs: "50px" },
        bgcolor: "#1746A2",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: { lg: "18px", md: "18px", xs: "14px", sm: "14px" },
          color: "white",
          marginLeft: "20px",
        }}>
        {" "}
        © 2023 Vajra, All Rights Reserved{" "}
      </Typography>
      <Box
        sx={{
          marginRight: "5%",
          display: "flex",
          flexDirection: "row",
        }}>
        <Button
          onClick={() => navigate("/terms-and-conditions")}
          sx={{
            fontWeight: 400,
            fontSize: { lg: "18px", md: "18px", xs: "14px", sm: "14px" },
            color: "white",

            textTransform: "none",
          }}>
          {" "}
          Terms & Conditions
        </Button>
        <Button
          onClick={() => navigate("/faq")}
          sx={{
            fontWeight: 400,
            fontSize: { lg: "18px", md: "18px", xs: "14px", sm: "14px" },
            color: "white",

            textTransform: "none",
          }}>
          | FAQ's{" "}
        </Button>
      </Box>
    </Box>
  );
}

export default Footer2;
