import { Box, Button, styled } from "@mui/material";
import React, { useState } from "react";

import { useSelector } from "react-redux";

import Department from "./DepartmentList";

import SavedDepart from "./SavedDepart";
import EmptySavedDepart from "./EmptySavedDepart";
import { useNavigate } from "react-router-dom";

const ButtonWrapper1 = styled(Button)(({ theme }) => ({
  width: "269px",

  boxSizing: "border-box",

  fontWeight: "500",
  fontSize: "20px",
  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px 0px 0px 10px",

  [theme.breakpoints.down("xl")]: {
    width: "50%",

    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "140px",

    fontSize: "12px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const ButtonWrapper2 = styled(Button)(({ theme }) => ({
  width: "269px",

  boxSizing: "border-box",
  fontSize: "20px",
  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "0px 10px 10px 0px",

  [theme.breakpoints.down("xl")]: {
    width: "50%",

    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "140px",

    fontSize: "12px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  width: "892px",

  marginTop: "10px",
  marginBottom: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "400px",

    fontSize: "12px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "6%",
    width: "300px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const Main = () => {
  const [flag, setFlag] = useState(true);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [b1color, setB1Color] = useState("#FF731D");
  const [b2color, setB2Color] = useState("rgba(33, 36, 39, 0.5)");
  const [show, setShow] = useState(false);
  const data = useSelector((store) => store.nbmData.alreadySaved);

  const navigate = useNavigate();

  const handleClick1 = () => {
    setFlag(true);
    setB1Color("#FF731D");
    setB2Color("rgba(33, 36, 39, 0.5)");
  };
  const handleClick2 = () => {
    setFlag(false);
    setB1Color("rgba(33, 36, 39, 0.5)");
    setB2Color("#FF731D");
    setShow(true);
    navigate("/add-department");
  };

  return (
    <Box
      sx={{
        display: "flex",

        paddingTop: "20px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        minHeight: "500px",
      }}>
      <ButtonBox>
        <ButtonWrapper1
          onClick={handleClick1}
          sx={{
            bgcolor: `${b1color}`,
            color: "white",

            textTransform: "none",
          }}>
          Already Saved
        </ButtonWrapper1>

        <ButtonWrapper2
          disabled={userAccess?.deldepart !== "Yes"}
          onClick={handleClick2}
          sx={{
            textTransform: "none",
            color: `${b1color}`,

            bgcolor: "white",
          }}>
          Add Department
        </ButtonWrapper2>
      </ButtonBox>
      {data?.length > 0
        ? !show && <SavedDepart />
        : !show && (
            <EmptySavedDepart
              disabled={userAccess?.deldepart !== "Yes"}
              handParentClick={handleClick2}
            />
          )}

      {show && flag ? <SavedDepart /> : show && <Department />}
    </Box>
  );
};

export default Main;
