import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { departmentAlreadySaved } from "../redux/nbmData/action";
import emp from "../images/emptyDepartment.png";

const ImageCard = styled(CardMedia)(({ theme }) => ({
  width: "250px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FlexBox = styled(CardActions)(({ theme }) => ({
  gap: "40px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Wrapper = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",
  // border: "1px solid green",
  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const EmptySavedDepart = (props, ref) => {
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const navigate = useState();
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const handleClickChild = () => {
    props.handParentClick();
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(departmentAlreadySaved(data, token));
  }, [dispatch]);

  return (
    <Wrapper sx={{}}>
    <BoxWrapper
      style={{ overflowX: "auto", minHeight: "500px" }}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: " 15px ",
        justifyContent: "center",
        alignItems: "center",
        
      }}
     >
      <Typography
        sx={{
          color: "#1746A2",
          width: "90%",
          fontWeight: "500",
          fontSize: "18px",
        }}
        color="text.secondary"
        gutterBottom>
        No departments are currently in your account. Please add your
        department.
      </Typography>
      <Cards>
        <ImageCard
          component="img"
          // sx={{ width: 171 }}
          image={emp}
          alt="green iguana"
        />
        <CardContent></CardContent>
        <FlexBox>
          <Button
            onClick={() => navigate("/")}
            sx={{
              borderRadius: "35px",
              textTransform: "none",
              bgcolor: "#1746A2",
            }}
            variant="contained">
            Go to home
          </Button>
          <Button
            onClick={handleClickChild}
            sx={{
              borderRadius: "35px",
              textTransform: "none",
              bgcolor: "#1746A2",
            }}
            variant="contained">
            Add Department
          </Button>
        </FlexBox>
      </Cards>
    </BoxWrapper>
  </Wrapper>
  );
};

export default EmptySavedDepart;
