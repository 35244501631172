import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "350px", lg: "350px", md: "300px", sm: "300px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function SimpleModal({
  openModal,
  closeModal,
  text
}) {
  return (
    <div>
      <Modal
        keepMounted
        open={openModal}
        onClose={closeModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
        <Box sx={{width:'100%', border:'1px solid red', display:'flex', justifyContent:'end'}}> <IconButton onClick={closeModal}> <CloseIcon sx={{color:'#FF731D'}} /> </IconButton>  </Box>
          <Box
            sx={{
              color: "red",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              marginTop: "20px",
            }}>
               
            <Typography sx={{ mt: 2, color: "#1746A2", textAlign: "center" }}>
              {`${text}`}
            </Typography>
            <Button
              variant="contained"
              onClick={closeModal}
              sx={{
                borderRadius: "35px",
                margin: "auto",
                textTransform: "none",
                bgcolor: "#1746A2",
              }}
              size="large">
              Okay
            </Button>
          </Box>
        </ModalBox>
      </Modal>
    </div>
  );
}
