import { TablePagination, useMediaQuery } from "@mui/material";
import React from "react";

const Pagination = ({
  filteredProducts,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    filteredProducts?.length > rowsPerPage && (
      <TablePagination
        sx={{
          display: "flex",
          justifyContent: isMobileScreen ? "center" : "end",

          alignItems: isMobileScreen ? "center" : "end",
          padding: "16px",

          width: isMobileScreen ? "80%" : "90%",
          "& .MuiTablePagination-selectLabel": {
            fontSize: !isMobileScreen ? "14px" : "95%",
          },

          "& .MuiTablePagination-actions": {
            fontSize: !isMobileScreen ? "14px" : "12px",
          },

          "& .MuiTablePagination-select": {
            position: "relative",
            cursor: "text",

            alignItems: "center",
            color: "inherit",

            flexShrink: 0,
            marginRight: "3px",
            marginLeft: "-36px",
          },

          "& .MuiTablePagination-displayedRows": {
            marginRight: "1px",
            marginLeft: "-30px",
            fontSize: !isMobileScreen ? "14px" : "12px",
          },
        }}
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredProducts?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    )
  );
};

export default Pagination;
