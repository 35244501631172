import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Typography,
  styled,
} from "@mui/material";
import React from "react";

import emp from "../images/noAmcCmc.png";
import { useNavigate } from "react-router-dom";



const ImageCard = styled(CardMedia)(({ theme }) => ({
  width: "350px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FlexBox = styled(CardActions)(({ theme }) => ({
  gap: "40px",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));



const BoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: "2%",

  width: "100%",
  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "800px",
  },
  [theme.breakpoints.down("md")]: {
    width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "370px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "280px",
  },
}));

const EmptyAmcCmc = ({ text }) => {
  const navigate = useNavigate();

  return (
    <>
     
        <BoxWrapper
          style={{ overflowX: "auto" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: " 15px ",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "600px",
          }}>
            <Typography
              sx={{
                color: "#1746A2",
                width: "90%",
                fontWeight: 500,
                fontSize: "20px",
              }}
              color="text.secondary"
              gutterBottom>
              {`No reported ${text}`}
            </Typography>

            <FlexBox sx={{ gap: "20px" }}>
              <ImageCard component="img" image={emp} alt="green iguana" />
              <Button
                onClick={() => navigate("/")}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                variant="contained">
                Go to home page
              </Button>
            </FlexBox>
          </BoxWrapper>
        {/* </Wrapper>
      </MainWrapper> */}
    </>
  );
};

export default EmptyAmcCmc;
