import React, { useState } from 'react';

const TestExp = () => {
  const [expenditures, setExpenditures] = useState([]);
  const [newExpenditure, setNewExpenditure] = useState({
    accessoryName: '',
    description: '',
    accessoryType: '',
    unitPrice: '',
    totalAmountSpent: '',
    quantity: '',
    expImage: [{ image: '', imageId: '' }]
  });

  const handleAddExpenditure = () => {
    setExpenditures([...expenditures, { ...newExpenditure }]);
    setNewExpenditure({
      accessoryName: '',
      description: '',
      accessoryType: '',
      unitPrice: '',
      totalAmountSpent: '',
      quantity: '',
      expImage: [{ image: '', imageId: '' }]
    });
  };

  const handleRemoveExpenditure = (index) => {
    const updatedExpenditures = expenditures.filter((_, i) => i !== index);
    setExpenditures(updatedExpenditures);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewExpenditure({ ...newExpenditure, [name]: value });
  };

  const handleAccessoryTypeChange = (e) => {
    setNewExpenditure({ ...newExpenditure, accessoryType: e.target.value });
  };

  const renderFields = () => {
    const { accessoryType } = newExpenditure;
    switch (accessoryType) {
      case 'other':
        return (
          <>
            <input
              type="text"
              name="accessoryName"
              placeholder="Accessory Name"
              value={newExpenditure.accessoryName}
              onChange={handleChange}
            />
            <input
              type="number"
              name="quantity"
              placeholder="Quantity"
              value={newExpenditure.quantity}
              onChange={handleChange}
            />
          </>
        );
      case 'replacement':
        return (
          <>
            <input
              type="text"
              name="accessoryName"
              placeholder="Accessory Name"
              value={newExpenditure.accessoryName}
              onChange={handleChange}
            />
            <input
              type="number"
              name="unitPrice"
              placeholder="Unit Price"
              value={newExpenditure.unitPrice}
              onChange={handleChange}
            />
            <input
              type="text"
              name="expImage"
              placeholder="Image URL"
              value={newExpenditure.expImage[0].image}
              onChange={(e) =>
                setNewExpenditure({
                  ...newExpenditure,
                  expImage: [{ image: e.target.value, imageId: '' }]
                })
              }
            />
            <input
              type="number"
              name="quantity"
              placeholder="Quantity"
              value={newExpenditure.quantity}
              onChange={handleChange}
            />
            <input
              type="number"
              name="totalAmountSpent"
              placeholder="Total Amount Spent"
              value={newExpenditure.totalAmountSpent}
              onChange={handleChange}
            />
            <input
              type="text"
              name="accessoryType"
              placeholder="Accessory Type"
              value={newExpenditure.accessoryType}
              onChange={handleAccessoryTypeChange}
            />
          </>
        );
      case 'new':
        return (
          <>
            <input
              type="text"
              name="description"
              placeholder="Description"
              value={newExpenditure.description}
              onChange={handleChange}
            />
            <input
              type="number"
              name="totalAmountSpent"
              placeholder="Total Amount Spent"
              value={newExpenditure.totalAmountSpent}
              onChange={handleChange}
            />
            <input
              type="text"
              name="expImage"
              placeholder="Image URL"
              value={newExpenditure.expImage[0].image}
              onChange={(e) =>
                setNewExpenditure({
                  ...newExpenditure,
                  expImage: [{ image: e.target.value, imageId: '' }]
                })
              }
            />
            <input
              type="text"
              name="accessoryType"
              placeholder="Accessory Type"
              value={newExpenditure.accessoryType}
              onChange={handleAccessoryTypeChange}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <h1>Expenditure Management</h1>
      <select name="accessoryType" value={newExpenditure.accessoryType} onChange={handleAccessoryTypeChange}>
        <option value="">Select Accessory Type</option>
        <option value="replacement">Accessory Replacement</option>
        <option value="new">New Accessory</option>
        <option value="other">Other</option>
      </select>
      {renderFields()}
      <button onClick={handleAddExpenditure}>Add Expenditure</button>
      <ul>
        {expenditures.map((expenditure, index) => (
          <li key={index}>
            {Object.entries(expenditure).map(([key, value]) => (
              <p key={key}>{`${key}: ${value}`}</p>
            ))}
            <button onClick={() => handleRemoveExpenditure(index)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TestExp;