// FilterMenu.js
import React from "react";
import { Menu, MenuItem } from "@mui/material";

const FilterMenu = ({ anchorEl, onClose, onFilter, status }) => {
  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem onClick={() => onFilter(null)}>All</MenuItem>
      {status?.map((item) => (
        <MenuItem key={item} onClick={() => onFilter(item)}>
          {item === "check_in"
            ? "Check in"
            : item === "check_out"
            ? "Check out"
            : item === "NA"
            ? "Not Requested"
            : item === "Requested"
            ? "Requested"
            : item}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default FilterMenu;
