import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme } from "@mui/material/styles";
import { Box, useMediaQuery, styled, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

function BulkDeleteDepartment({ open, onClose, onDelete, id, text }) {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dialogStyle = {
    width: isSmallScreen ? "80vw" : "30vw",
    borderRadius:'0px',
    padding: "15px",
  };

  const TopHead = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "end",
  }));

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      PaperProps={{ style: isSmallScreen ? dialogStyle : dialogStyle }}>
      <TopHead>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: "#FF731D" }} />
        </IconButton>
      </TopHead>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", textAlign: "center" }}>
        <div
          style={{
            display: "flex",

            alignItems: "center",
            width: "100%",
          }}>
          <DialogContent
            sx={{
              width: "100%",
              padding: "0px",
              paddingLeft: "2%",
            }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ width: "100%", color: "black", fontSize: "20px", fontWeight:600 }}>
              Move assets to another department before deleting.
            </DialogContentText>
          </DialogContent>
        </div>
      </DialogTitle>

      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button
          onClick={onDelete}
          variant="contained"
          style={{
            color: "white",
            borderRadius: "20px",
            textTransform: "none",
          }}
          autoFocus
          size="large">
          Go to asset page
        </Button>
      </Box>
    </Dialog>
  );
}
export default BulkDeleteDepartment;
