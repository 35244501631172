import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { getVendorService } from "../redux/nbmData/action";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import AddServiceCompany from "./AddServiceCompany";
const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const ServiceCompanyList = () => {
  const serviceData = useSelector((store) => store.data.getVendorService);
  const status = useSelector((store) => store.data.postVendorService);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showMovementBox, setShowMovementBox] = useState(false);

  const handleAddMovementClick = () => {
    setShowMovementBox(true);
  };

  const handleCloseDepart = () => {
    setShowMovementBox(false);
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
    };
    dispatch(getVendorService(data, token));
  }, [dispatch, status]);

  useEffect(() => {
    if (serviceData && serviceData?.length > 0) {
      setData(serviceData);
    }
  }, [serviceData]);

  return (
    <Stack>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        gap="10px"
        height="85vh"
        width={"100%"}>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          style={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px">
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}>
              <Typography>{`Total : ${data?.length}`}</Typography>
            </Stack>
            <Box flex="2">
              <SortSearchFilter
                data={data}
                setData={setData}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                heading={"My Orders"}
                Filter1="company"
                Filter2=""
                sortPrice=""
                sortDate=""
                dueDate=""
                name="company"
                csvName="Assets vendor"
              />
            </Box>
          </Box>
          <Stack
            style={{
              width: "100%",
              height: "100%",

              bgcolor: "white",
            }}
            overflow="auto"
            flexDirection={"column"}>
            <TableContainer
              component={Paper}
              style={{
                height: "100%",

                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableHeading> Company Name</TableHeading>
                    <TableHeading> Phone</TableHeading>
                    <TableHeading>Email</TableHeading>
                    <TableHeading> Asset Name</TableHeading>

                    <TableHeading> Asset Code</TableHeading>
                  </TableRow>
                </TableHead>

                <TableBody style={{ overflowY: "scroll" }}>
                  {filteredData.map((item, index) => (
                    <TableRow>
                      <TableCells
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                        sx={{ textDecoration: "none" }}>
                        <Tooltip title={item.company}>{item.company}</Tooltip>
                      </TableCells>
                      <TableCells sx={{}}>{item?.mobile}</TableCells>
                      <TableCells>
                        <Tooltip title={item.email}>{item.email}</Tooltip>
                      </TableCells>
                      <TableCells>
                        {Array.isArray(item.assetname) && (
                          <Tooltip title={item.assetname}>
                            {item.assetname.join(", ")}
                          </Tooltip>
                        )}
                      </TableCells>

                      <TableCells>
                        {Array.isArray(item.assetcode) && (
                          <Tooltip title={item.assetcode}>
                            {item.assetcode.join(", ")}
                          </Tooltip>
                        )}
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {!showMovementBox && (
              <Stack
                sx={{
                  width: "100%",
                  padding: "20px",
                  bgcolor: "white",
                  gap: "20px",
                }}>
                <Divider />
                <Stack
                  sx={{
                    bgcolor: "white",
                    alignItems: "flex-end",

                    justifyContent: "column",
                  }}>
                  <Button
                    onClick={handleAddMovementClick}
                    variant="contained"
                    sx={{
                      borderRadius: "22px",
                      background: "rgba(70, 144, 255, 1)",
                      marginRight: "20px",
                    }}>
                    Add Service <AddIcon />
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        {showMovementBox && (
          <AddServiceCompany handleCloseDepart={handleCloseDepart} />
        )}
      </Box>
    </Stack>
  );
};

export default ServiceCompanyList;
