import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import styled from "@emotion/styled";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "400px", lg: "370px", md: "300px", sm: "300px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    border: "10px solid blue",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

export default function AccessCheckModal({ status, text }) {
  const handleCloseUser = () => {
    if (text === "Biomedical") {
      sessionStorage.setItem("SbmOrNbm", "NBM");
      localStorage.setItem("bmOrNbm", "NBM");
      window.location.reload();
      window.location.replace("/home");
    }
    if (text === "Non-biomedical") {
      sessionStorage.setItem("SbmOrNbm", "BM");
      localStorage.setItem("bmOrNbm", "BM");
      window.location.reload();
      window.location.replace("/home");
    }
  };

  return (
    <div>
      <Modal
        keepMounted
        open={status}
        onClose={handleCloseUser}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Box
            sx={{
              color: "red",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
            }}>
            <Typography sx={{ mt: 2, color: "green", textAlign: "center" }}>
              You don't have access to the {text} feature at the moment.
            </Typography>

            <Box
              sx={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10px",
              }}>
              <Button
                variant="contained"
                onClick={handleCloseUser}
                sx={{
                  borderRadius: "35px",
                  width: "150px",
                  height: "30px",
                  margin: "auto",
                  textTransform: "none",
                  bgcolor: "#1746A2",
                }}
                size="large">
                Close
              </Button>
            </Box>
          </Box>
        </ModalBox>
      </Modal>
    </div>
  );
}
