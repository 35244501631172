import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

export default function LoadingComponent() {
  return (
    <div>
      <Backdrop
        sx={{
          color: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}>
        <Stack gap={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      </Backdrop>
    </div>
  );
}
