import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Badge,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import { useEffect } from "react";
import { getIncidentDetail, profile } from "../redux/nbmData/action";
import { useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';


const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const IncidentDetailPage = () => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let incidentData = useSelector((store) => store.data.incidentDetails);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch.id,
    };
    dispatch(getIncidentDetail(data, token));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.download = "downloaded_image.jpg";
    link.click();
  };
  const navigate = useNavigate();

  const [fileType, setFileType] = useState(null);
  const fileLink = incidentData.receipturl;

  useEffect(() => {
    if (fileLink?.endsWith(".pdf") || fileLink?.includes(".pdf")) {
      setFileType("pdf");
    } else if (
      fileLink?.endsWith(".jpg") ||
      fileLink?.endsWith(".jpeg") ||
      fileLink?.endsWith(".png") ||
      fileLink?.endsWith(".gif") ||
      fileLink?.includes("image/")
    ) {
      setFileType("image");
    } else {
      setFileType("unknown");
    }
  }, [fileLink]);

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
    };

    dispatch(profile(data));
  }, [dispatch]);

  const HospitalLeftData = {
    "Asset Name": incidentData.assetname,
    "Asset Code": incidentData.assetcode,
    Department: incidentData.deptname,
  };
  const HospitalLeftDataOpen = {
    "Asset Name": incidentData.assetname,
    "Asset Code": incidentData.assetcode,
    Department: incidentData.deptname,
    "Serial No.": incidentData.serialno,
  };
  const HospitalRightData = {
    "Issue Date": incidentData.start_date,
    "Clear Date": incidentData.clear_date,
    "Total Downtime": incidentData.tot_downtime,
  };

  const HospitalRightDataOpen = {
    "Issue Date": incidentData.start_date,
    "Issue Time": incidentData.start_time,
  };
  const UserLeftData = {
    Name: incidentData.person_name,
    "Contact No.": incidentData.person_phone,
    Email: incidentData.person_email,
  };

  const IssueData = {
    "Problem Identified ": incidentData?.incdt_issue,
    "Action Taken": incidentData?.actiontaken,
    ...(incidentData.feedback !== "NA" && {
      Remark: incidentData.feedback,
    }),
  };

  return (
    <>
      {incidentData?.incid_status === "Cleared" ? (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            height: "100%",
          }}>
          <Stack
            sx={{
              width: "750px",

              borderRadius: "8px",

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              gap: "10px",
            }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",

                padding: "10px 20px 10px 20px",
              }}>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}>
                Cleared Incident
              </Label>

              <Label>{incidentData.clear_date}</Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Box sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Contact Person</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Box sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(UserLeftData).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}>
                              {key}
                            </TableCells>

                            <TableCells sx={{}}>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ width: "40%" }}></Box>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Issue Details</Label>
                <Divider />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Stack
                  sx={{
                    width: "80%",
                    gap: "10px",
                  }}>
                  {Object.entries(IssueData).map(([key, value]) => (
                    <Stack
                      sx={{
                        width: "100%",

                        textAlign: "start",
                        minHeight: "130px",
                        background: "rgba(244, 246, 246, 1)",
                        padding: "20px",
                      }}>
                      <Label>{key}</Label>

                      <Typography sx={{ marginTop: "10px" }}>
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>

                <Stack
                  sx={{
                    width: "20%",
                    gap: "10px",
                  }}>
                  <>
                    {incidentData?.incdt_imgs !== "NA" &&
                      incidentData?.incdt_imgs !== "" && (
                        <>
                          <>
                            {incidentData?.incdt_imgs?.map((images) => (
                              <Badge
                                badgeContent={
                                  <DownloadIcon
                                    size={15}
                                    style={{}}
                                    onClick={() => handleDownload(images)}
                                  />
                                }
                                color="secondary"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}>
                                <Stack
                                  sx={{
                                    width: "100%",

                                    height: "130px",
                                  }}>
                                  <Box
                                    as="img"
                                    src={images}
                                    alt="incImage"
                                    style={{ height: "100%" }}
                                  />
                                </Stack>
                              </Badge>
                            ))}
                          </>
                        </>
                      )}
                    {incidentData?.clear_imgs !== "NA" &&
                      incidentData?.clear_imgs !== "" && (
                        <>
                          {incidentData?.clear_imgs?.map((images) => (
                            <Badge
                              badgeContent={
                                <DownloadIcon
                                  size={15}
                                  style={{}}
                                  onClick={() => handleDownload(images)}
                                />
                              }
                              color="secondary"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}>
                              <Stack
                                sx={{
                                  width: "100%",

                                  height: "130px",
                                }}>
                                <Box
                                  as="img"
                                  src={images}
                                  alt="incImage"
                                  style={{ height: "100%" }}
                                />
                              </Stack>
                            </Badge>
                          ))}
                        </>
                      )}
                  </>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            height: "100%",
          }}>
          <Stack
            sx={{
              width: "750px",

              borderRadius: "8px",

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              gap: "10px",
            }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",

                padding: "10px 20px 10px 20px",
              }}>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}>
                Incident Summary
              </Label>

              <Label sx={{ color: "#FF6B00" }}>
                {incidentData.incid_status}
              </Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Box sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}>
                  Issue
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Stack
                  sx={{
                    width: "50%",
                    gap: "10px",
                  }}>
                  <Stack
                    sx={{
                      width: "100%",

                      textAlign: "start",
                      minHeight: "170px",
                      background: "rgba(244, 246, 246, 1)",
                      padding: "20px",
                      marginLeft: "10px",
                    }}>
                    <Typography sx={{ marginTop: "10px" }}>
                      {incidentData?.incdt_issue}
                    </Typography>
                  </Stack>
                </Stack>
                {incidentData?.incdt_imgs !== "NA" &&
                  incidentData?.incdt_imgs !== "" && (
                    <Stack
                      display="flex"
                      flexDirection={"row"}
                      sx={{
                        width: "50%",

                        height: "170px",
                        overflowY: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                        overflowX: "auto",
                      }}>
                      {incidentData?.incdt_imgs?.map((images, index) => (
                        <Box
                          sx={{ height: "95%" }}
                          key={index}
                          position="relative"
                          display="inline-block">
                          <Box
                            as="img"
                            src={images}
                            alt="incImage"
                            style={{
                              height: "100%",
                              width: "140px",
                            }}
                          />
                          <Box position="absolute" bottom={-25} right={0} p={1}>
                            <IconButton>
                              <DownloadIcon
                                onClick={() => handleDownload(images)}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: "750px",
              height: "70px",
              padding: "20px",
              flexDirection: "column",
              alignItems: "flex-end",
            }}>
            <Button
              onClick={() => navigate(`/clear-incident/${params.id}`)}
              variant="contained"
              sx={{ width: "150px", borderRadius: "22px" }}>
              Clear Incident
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default IncidentDetailPage;
