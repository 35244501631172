import React from "react";
import {
  Box,
  Grid,
  Container,
  Paper,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import NavbarMain from "../components/NavbarMain";
import FooterMain from "../components/FooterMain";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VajraPic from "../images/VajraPic.png";
const items = [
  {
    dataAssetImage:
      "https://www.google.com/imgres?q=Puritan%20Bennett%20980%20Ventilator%20Puritan%20Bennett%20980%20Ventilator&imgurl=http%3A%2F%2F5.imimg.com%2Fdata5%2FBW%2FFY%2FKQ%2FGLADMIN-3061%2Fmedtronic-puritan-bennett-980-ventilator-500x500.jpg&imgrefurl=https%3A%2F%2Fm.indiamart.com%2Fitems%2Fpuritan-bennett-980-ventilator-medtronic-s258838.html&docid=SixkYaevWPfQFM&tbnid=XOQ1OaMmgp5JZM&vet=12ahUKEwiiyIyWovuFAxX4amwGHXhTDkwQM3oECGMQAA..i&w=500&h=500&hcb=2&ved=2ahUKEwiiyIyWovuFAxX4amwGHXhTDkwQM3oECGMQAA",
    hospitalAssetName: "Asset Name 1",
    department: "Cardiology",
    brand: "Brand A",
    price: 1500,
    condition: "Good",
    usageYears: 2,
    shippedFrom: "Supplier X",
    uploadedDate: "2024-05-07",
    status: "New",
  },
  {
    dataAssetImage:
      "https://www.google.com/imgres?q=AIRVO%202&imgurl=http%3A%2F%2F5.imimg.com%2Fdata5%2FDG%2FWY%2FMY-14102592%2Fairvo-2-highflow-oxygen-therapy.png&imgrefurl=https%3A%2F%2Fwww.indiamart.com%2Fproddetail%2Fairvo-2-highflow-oxygen-therapy-refurbished-18482289491.html&docid=EHlbZrGRzmA17M&tbnid=N9ruFJuYkiLRdM&vet=12ahUKEwjX8qutovuFAxV6SmwGHadiBxUQM3oECBYQAA..i&w=553&h=415&hcb=2&ved=2ahUKEwjX8qutovuFAxV6SmwGHadiBxUQM3oECBYQAA",
    hospitalAssetName: "Asset Name 2",
    department: "Orthopedics",
    brand: "Brand B",
    price: 2000,
    condition: "Excellent",
    usageYears: 1,
    shippedFrom: "Supplier Y",
    uploadedDate: "2024-05-06",
    status: "New",
  },
  {
    dataAssetImage:
      "https://www.google.com/imgres?q=Incubator%208000%20SC&imgurl=http%3A%2F%2F3.imimg.com%2Fdata3%2FYL%2FEC%2FMY-12940355%2Finfant-warmer-infant-incubator-500x500.jpg&imgrefurl=https%3A%2F%2Fm.indiamart.com%2Fproddetail%2Finfant-warmer-infant-incubator-8254862330.html&docid=NR8wetRF-iEqAM&tbnid=toBw_ThVf_SA3M&vet=12ahUKEwie9uS5ovuFAxU3SmcHHf2gBTYQM3oECBcQAA..i&w=375&h=500&hcb=2&itg=1&ved=2ahUKEwie9uS5ovuFAxU3SmcHHf2gBTYQM3oECBcQAA",
    hospitalAssetName: "Asset Name 3",
    department: "Radiology",
    brand: "Brand C",
    price: 1200,
    condition: "Fair",
    usageYears: 3,
    shippedFrom: "Supplier Z",
    uploadedDate: "2024-05-05",
    status: "Old",
  },
  {
    dataAssetImage: "path/to/image4.jpg",
    hospitalAssetName: "Asset Name 4",
    department: "Oncology",
    brand: "Brand D",
    price: 1800,
    condition: "Good",
    usageYears: 1,
    shippedFrom: "Supplier W",
    uploadedDate: "2024-05-04",
    status: "Old",
  },
];
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "5px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const SellOnlineList = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
        background: "#F4F6F6",
        alignItems: "center",
      }}>
      <NavbarMain />

      <Stack maxWidth="lg" sx={{ marginBottom: 10, gap: "20px" }}>
        {/* Big Box */}
        <Stack>
          {/* Box 1 */}
          <Stack
            sx={{
              height: "150px",

              marginTop: "20px",
              flexDirection: "row",
              textAlign: "start",
              p: 2,
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px #DBE7E5",
            }}>
            <Stack
              sx={{
                width: "40%",

                justifyContent: "space-between",
              }}>
              <Typography variant="h6" style={{ color: "#4690FF" }}>
                Save on <br />
                Healthcare Costs!
              </Typography>
              <Stack
                sx={{
                  flexDirection: "row",

                  gap: "5px",
                }}>
                <Typography variant="body1">Faster. </Typography>
                <Typography variant="body1">Easier.</Typography>
                <Typography variant="body1">Efficient</Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "70%",

                flexDirection: "row",
              }}>
              {items.map(() => (
                <Stack
                  sx={{
                    width: "250px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Box
                    as="img"
                    style={{ width: "100px", height: "100px" }}
                    src={
                      "https://images.unsplash.com/photo-1629909613654-28e377c37b09?q=80&w=1468&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>

          {/* Box 2 */}
          <Stack
            sx={{
              //   width: "100%",
              //   height: "48px",

              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px #DBE7E5",
              marginTop: "40px",
            }}>
            <Typography>Categories</Typography>
            <Typography>Products 102 results</Typography>
            <Typography>Filter Sort</Typography>
          </Stack>
        </Stack>

        {/* Another Big Box */}
        <Stack
          sx={{
            width: "100%",

            justifyContent: "space-between",
          }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",

              justifyContent: "space-between",
            }}>
            {/* Box 1 inside the row */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "25%",
                gap: "10px",
              }}>
              <Stack
                sx={{
                  flexGrow: 1,

                  height: "350px",
                  p: 2,
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px #DBE7E5",
                }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Show All" />
                  <FormControlLabel control={<Checkbox />} label="Cardiology" />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Blood Center"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Intensive Care Unit"
                  />
                  <FormControlLabel control={<Checkbox />} label="Radiology" />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Critical Care"
                  />
                  <FormControlLabel control={<Checkbox />} label="Oncology" />
                  <FormControlLabel control={<Checkbox />} label="Laboratory" />
                </FormGroup>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <Stack
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px #DBE7E5",
                }}>
                <Box as="img" style={{ width: "100%" }} src={VajraPic} />
              </Stack>
            </Stack>

            {/* Box 2 inside the row */}
            <Stack sx={{ width: "70%", gap: "20px" }}>
              {items.map((item) => (
                <>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      height: "160px",
                      p: 2,
                      bgcolor: "white",
                      boxShadow: "0px 0px 15px 0px #DBE7E5",
                    }}>
                    {/* Content for Box 2 */}
                    <Stack sx={{ width: "300px" }}>
                      <Box
                        as="img"
                        style={{ width: "100%", height: "100%" }}
                        src={
                          "https://images.unsplash.com/photo-1513224502586-d1e602410265?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                      />
                    </Stack>
                    <Stack
                      sx={{
                        width: "450px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <Stack
                        sx={{
                          width: "70%",
                          textAlign: "start",
                        }}>
                        <Stack
                          sx={{
                            justifyContent: "space-between",
                            flexDirection: "row",
                            gap: "20px",
                          }}>
                          <Label>Puritan Bennett 980 Ventilator</Label>
                          <Typography>Medtronic</Typography>
                        </Stack>
                        <Typography
                          sx={{
                            display: "flex",

                            alignItems: "center",
                            gap: "5px",
                          }}>
                          <LocalOfferIcon />
                          Laboratory
                        </Typography>
                      </Stack>

                      <Stack
                        sx={{
                          width: "70%",
                          textAlign: "start",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}>
                        <Label variant="subtitle1">₹23,000</Label>
                        <Typography
                          sx={{
                            display: "flex",

                            alignItems: "center",
                            gap: "5px",
                          }}>
                          <FavoriteBorderIcon size={20} />
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        width: "350px",
                        gap: "20px",
                      }}>
                      <TableContainer
                        style={{ overflowX: "auto", maxWidth: "100%" }}
                        sx={{}}>
                        <Table sx={{ width: "100%" }}>
                          <TableBody>
                            <TableRow>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{ color: "rgba(27, 37, 53, 1)" }}>
                                Condition
                              </TableCells>

                              <TableCells>{item.condition}</TableCells>
                            </TableRow>
                            <TableRow>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{ color: "rgba(27, 37, 53, 1)" }}>
                                Usage
                              </TableCells>

                              <TableCells>{item.usageYears}</TableCells>
                            </TableRow>
                            <TableRow>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{ color: "rgba(27, 37, 53, 1)" }}>
                                Shipped from
                              </TableCells>

                              <TableCells>{item.shippedFrom}</TableCells>
                            </TableRow>
                            <TableRow>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{ color: "rgba(27, 37, 53, 1)" }}>
                                Uploaded
                              </TableCells>

                              <TableCells>{item.uploadedDate}</TableCells>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Button variant="outlined">Contact Seller</Button>
                    </Stack>
                  </Stack>
                </>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <FooterMain />
    </Box>
  );
};

export default SellOnlineList;
