import React, { useState } from "react";
import jsPDF from "jspdf";
import { Box, Button, Typography, styled } from "@mui/material";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
const datas = {
  incidt_id: "incidt_ct445c9157c407242711",
  assetid: "4efb9305-3f0a-4f7e-bca2-7eb6b41682a6",
  assetname: "CT scanner",
  serialno: "UYEW90",
  deptname: "CARDIOLOGY OPD",
  incdt_issue: "Check",
  assetcode: "2177HE",
  incdt_imgs: [
    "https://vajra-files.s3.ap-south-1.amazonaws.com/mpnimsh62cf063ba77b2d026d7a/incidents/assetId_a4528e52-e846-4c8e-8465-9abffece67c7.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5AFYYFMAT6RUX7G5%2F20231216%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231216T095127Z&X-Amz-SignedHeaders=host&X-Amz-Expires=900&X-Amz-Signature=1a6343305992acd6c0d44e09fe6bb53347833914cb1bfcd399d1afd71f66f99e",
  ],
  start_date: "07-11-2023",
  start_time: "14:24:27",
  clear_date: "07-11-2023",
  clear_time: "14:25:26",
  tot_downtime: "0 m 59 s ",
  actiontaken: "All done",
  person_name: "Mk",
  person_phone: "9988998899",
  person_email: "vinoth@gmail.com",
  feedback: "Done",
  clear_imgs: [
    "https://vajra-files.s3.ap-south-1.amazonaws.com/mpnimsh62cf063ba77b2d026d7a/cleared-incidents/assetId_214c5715-be7a-460e-aa8e-3814d9e757d4.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5AFYYFMAT6RUX7G5%2F20231216%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231216T095127Z&X-Amz-SignedHeaders=host&X-Amz-Expires=900&X-Amz-Signature=cdbb0d254775cc8552b61656f3e97d3074e51379701bd69946405aa131e3dc1b",
  ],
  incid_status: "Cleared",
  incid_report:
    "https://vajra-files.s3.ap-south-1.amazonaws.com/mpnimsh62cf063ba77b2d026d7a/incident-reports/incidt_ct445c9157c407242711.pdf?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA5AFYYFMAT6RUX7G5%2F20231216%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231216T095127Z&X-Amz-SignedHeaders=host&X-Amz-Expires=900&X-Amz-Signature=f21848f120bc8dc5b18e36af168cd09b1ee736d5775ea22e4f1e4d22958238cc",
};

const InnerContainer = styled(Box)(({ theme }) => ({
  border: "3px solid #1746A2",
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  // height: '1163px',
  paddingLeft: "20px",

  borderRadius: "30px",

  paddingBottom: "20px",

  [theme.breakpoints.down("xl")]: {
    // width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "2px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const OuterSecondLastBottom = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  width: "980px",

  display: "flex",
  marginBottom: "5px",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
    // width: "740px",
  },
  [theme.breakpoints.down("lg")]: {
    // width: "580px",
    marginTop: "0px",
  },
  [theme.breakpoints.down("md")]: {
    // width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    // width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const IncidentDetailPdf = ({ data }) => {

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [pdfUrl, setPdfUrl] = useState(null);

  const generatePDF = () => {
    const pdf = new jsPDF();

    pdf.setFontSize(16);
    pdf.setTextColor("#FF731D");

    const startY = 12;
    pdf.text(`Incident Report`, 20, startY);

    // Calculate the width of the text to draw the underline
    const textWidth = pdf.getTextWidth(`Incident Report`);
    pdf.setDrawColor("#FF731D");
    // Set the line width for the underline
    // pdf.setLineWidth(0.5);

    // Draw the underline
    pdf.line(20, startY + 2, 20 + textWidth, startY + 2);

    // Render "Asset Name" in blue

    pdf.setFontSize(12);
    pdf.setTextColor("#1746A2");
    pdf.text("Asset Name", 20, 30);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 30);
    pdf.text(`${data.assetname}`, 100, 30);

    // Render "Asset Code" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Asset Code", 20, 40);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 40);
    pdf.text(`${data.assetcode}`, 100, 40);

    // Render "Department" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Department", 20, 50);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 50);
    pdf.text(`${data.deptname}`, 100, 50);

    pdf.setTextColor("#1746A2");
    pdf.text("Serial Number", 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 60);
    pdf.text(`${data.serialno}`, 100, 60);

    pdf.setTextColor("#1746A2");
    pdf.text("Total Downtime", 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 70);
    pdf.text(`${data.tot_downtime}`, 100, 70);

    // Render "Issue Date" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Start Date", 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 80);
    pdf.text(`${data.start_date}`, 100, 80);

    // Render "Clear Date" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Clear Date", 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 90);
    pdf.text(`${data.clear_date}`, 100, 90);

    // Render "Start Time" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Start Time", 20, 100);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 100);
    pdf.text(`${data.start_time}`, 100, 100);

    // Render "Clear Time" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Clear Time", 20, 110);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 110);
    pdf.text(`${data.clear_time}`, 100, 110);

    // Render "Name" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Name", 20, 120);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 120);
    pdf.text(`${data.person_name}`, 100, 120);

    // Render "Email" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Email", 20, 130);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 130);
    pdf.text(`${data.person_email}`, 100, 130);

    // Render "Mobile Number" in blue
    pdf.setTextColor("#1746A2");
    pdf.text("Mobile Number", 20, 140);
    pdf.setTextColor(0, 0, 0);
    pdf.text(":", 80, 140);
    pdf.text(`${data.person_phone}`, 100, 140);

    // pdf.setTextColor("#1746A2");
    // pdf.text("Paid Service", 20, 160);
    // pdf.setTextColor(0, 0, 0);
    // pdf.text(":", 80, 160);
    // pdf.text(`${data.paidservice}`, 100, 160);

    // pdf.setTextColor("#1746A2");
    // pdf.text("Service Cost", 20, 170);
    // pdf.setTextColor(0, 0, 0);
    // pdf.text(":", 80, 170);
    // pdf.text(`${data.servicecost}`, 100, 170);
    // pdf.setTextColor("#1746A2");
    // pdf.text("Company", 20, 180);
    // pdf.setTextColor(0, 0, 0);
    // pdf.text(":", 80, 180);
    // pdf.text(`${data.company}`, 100, 180);

    pdf.setTextColor("#1746A2");
    pdf.setFont("helvetica", "bold");
    pdf.text("Problem Identified", 20, 160);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("helvetica", "normal");
    pdf.text(`${data.incdt_issue}`, 20, 170);

    pdf.setTextColor("#1746A2");
    pdf.setFont("helvetica", "bold");
    pdf.text("Action Taken", 20, 180);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("helvetica", "normal");
    pdf.text(`${data.actiontaken}`, 20, 190);

    pdf.setTextColor("#1746A2");
    pdf.setFont("helvetica", "bold");
    pdf.text("Feedback", 20, 200);
    pdf.setTextColor(0, 0, 0);
    pdf.setFont("helvetica", "normal");
    pdf.text(`${data.feedback}`, 20, 210);

    const startYIncident = 240;
    const imageWidth = 20;
    const imageHeight = 20;
    const borderWidth = 0.3; // Set the desired border width
    const padding = 0; // Set the desired padding

    // Add issue images to the PDF
    if (
      Array.isArray(data.incdt_imgs) &&
      data.incdt_imgs !== "NA" &&
      data.incdt_imgs.length > 0
    ) {
      pdf.setTextColor("#1746A2");
      pdf.setFont("helvetica", "bold");
      pdf.text("Issue image", 20, 230);

      data.incdt_imgs.forEach((img, index) => {
        const startX = 20 + index * (imageWidth + 10);

        // Add blue color border and padding
        pdf.setDrawColor("#1746A2"); // Set border color to blue
        pdf.setLineWidth(borderWidth); // Set border width
        pdf.rect(
          startX - borderWidth / 2 - padding, // X-coordinate
          startYIncident - borderWidth / 2 - padding, // Y-coordinate
          imageWidth + 2 * padding + borderWidth, // Width
          imageHeight + 2 * padding + borderWidth // Height
        );

        // Add the image inside the border
        pdf.addImage(
          img,
          "JPEG",
          startX + padding,
          startYIncident + padding,
          imageWidth,
          imageHeight
        );
      });

      // Move the starting position for cleared images
      const totalIssueImagesWidth = data.incdt_imgs.length * (imageWidth + 10);
      const startXClear = 20 + totalIssueImagesWidth + 10;

      // Add cleared images to the PDF in the same row
      if (Array.isArray(data.clear_imgs) && data.clear_imgs !== "NA") {
        pdf.setTextColor("#1746A2");
        pdf.setFont("helvetica", "bold");
        pdf.text("Cleared Image", startXClear, 230);

        data.clear_imgs.forEach((img, index) => {
          const startX = startXClear + index * (imageWidth + 10);

          // Add blue color border and padding
          pdf.setDrawColor("#1746A2"); // Set border color to blue
          pdf.setLineWidth(borderWidth); // Set border width
          pdf.rect(
            startX - borderWidth / 2 - padding, // X-coordinate
            startYIncident - borderWidth / 2 - padding, // Y-coordinate
            imageWidth + 2 * padding + borderWidth, // Width
            imageHeight + 2 * padding + borderWidth // Height
          );

          // Add the image inside the border
          pdf.addImage(
            img,
            "JPEG",
            startX + padding,
            startYIncident + padding,
            imageWidth,
            imageHeight
          );
        });
      }
    } else if (
      Array.isArray(data.clear_imgs) &&
      data.clear_imgs !== "NA" &&
      data.clear_imgs.length > 0
    ) {
      // No issue images, use cleared images directly
      pdf.setTextColor("#1746A2");
      pdf.setFont("helvetica", "bold");
      pdf.text("Cleared Image", 20, 230);

      data.clear_imgs.forEach((img, index) => {
        const startX = 20 + index * (imageWidth + 10);

        // Add blue color border and padding
        pdf.setDrawColor("#1746A2"); // Set border color to blue
        pdf.setLineWidth(borderWidth); // Set border width
        pdf.rect(
          startX - borderWidth / 2 - padding, // X-coordinate
          startYIncident - borderWidth / 2 - padding, // Y-coordinate
          imageWidth + 2 * padding + borderWidth, // Width
          imageHeight + 2 * padding + borderWidth // Height
        );

        // Add the image inside the border
        pdf.addImage(
          img,
          "JPEG",
          startX + padding,
          startYIncident + padding,
          imageWidth,
          imageHeight
        );
      });
    }

    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl);
    startTimer();

    // pdf.save(`incident_report_${data.incidt_id}.pdf`);
  };

  const handleDownload = () => {
    // Trigger download
    const a = document.createElement("a");
    a.href = pdfUrl;
    a.download = `service_report_${data.assetid}.pdf`;
    a.click();
  };
  const scrollToDiv = () => {
    const targetDiv = document.getElementById("targetDiv");
    if (targetDiv) {
      targetDiv.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const startTimer = () => {
    setTimeout(scrollToDiv, 800); // 2000 milliseconds = 2 seconds
  };
  return (
    // <Button
    //   onClick={generatePDF}
    //   style={{
    //     textTransform: "none",
    //     borderRadius: "35px",
    //     backgroundColor: "#1746A2",
    //     color: "white",
    //   }}>
    //   Generate PDF
    // </Button>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div style={{ textAlign: "center", marginTop: 20, width: "100%" }}>
        {!pdfUrl && (
          <Button
            onClick={generatePDF}
            variant="contained"
            disabled={userAccess.role === "Guest"}
            style={{
              color: "white",

              textTransform: "none",
              borderRadius: "35px",
              bgcolor:
                userAccess?.role === "Guest"
                  ? "grey"
                  : "rgba(23, 70, 162, 0.96)",
            }}>
            Generate PDF
          </Button>
        )}
      </div>
      <>
        {pdfUrl && (
          <div
            style={{
              marginTop: 20,
              textAlign: "center",
              width: "90%",
              maxWidth: "900px",
            }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
              <Viewer fileUrl={pdfUrl} />
            </Worker>
          </div>
        )}
      </>
      {pdfUrl && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            onClick={handleDownload}
            style={{
              color: "white",

              textTransform: "none",
              borderRadius: "35px",
              backgroundColor: "#1746A2",
            }}>
            Download PDF
          </Button>
        </div>
      )}
    </div>
  );
};

export default IncidentDetailPdf;
