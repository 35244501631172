import React from "react";
import { Pie, PieChart, Cell, Label } from "recharts";



const HalfPieChart2 = ({value1, value2, value3, total}) => {


  const data = [
    { name: "Value 1", value: Number(value1) },
    { name: "Value 2", value: Number(value2) },
    { name: "Value 3", value: Number(value3) },
  ];



  return (
    <div
      style={{
        width: "100%",
        height: "100%",

        display: "flex",
        justifyContent: "center",
      }}>
      <PieChart height={100} width={350}>
        <Pie
          startAngle={180}
          endAngle={0}
          data={data}
          innerRadius={80}
          outerRadius={110}
          dataKey="value"
          labelLine={false}
          blendStroke
          isAnimationActive={false}>
          <Cell fill="#1B2535" />
          <Cell fill="#8B9099" />
          <Cell fill="#BBBEC3" />
          <Label
            value={total}
            position="center"
            style={{
              fontSize: 26,
              fill: "#1B2535",
            }}
          />
        </Pie>
      </PieChart>
    </div>
  );
};

export default HalfPieChart2;
