import React from "react";
import Box from "@mui/material/Box";
import GraphComponent from "./GraphComponent";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import dayjs from 'dayjs';
import {
  Divider,
  IconButton,
  Paper,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { StackedLineChartTwoTone } from "@mui/icons-material";
import HalfPieChart from "../components/HalfPieChart";
import HalfPieChart2 from "../components/HalfPieChart2";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SquareIcon from '@mui/icons-material/Square';
import AddIcon from '@mui/icons-material/Add';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment/moment";
import monthlyRating from "../images/monthlyRating.png"
import { getDashboard, getDashboardList, getDashboardList2 } from "../redux/data/action";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";


const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  fontsize: "small",
}));

const TitleHeading = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
  fontsize: "large",
  // width:'40%'
}));

const Wrapper = styled(Box)(({ theme }) => ({
      display:"flex",
    
      flexDirection:"column",
      alignItems:"stretch",
      gap:"10px",
      minHeight:"90vh",
      padding:"16px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    alignItems:"center",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display:"flex",
  width:'100%',
  // height:'100px',
 
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {
flexDirection:"column",
// height:'200px',
width:'100%',
display:'none',
},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));
const GraphTop = styled(Box)(({ theme }) => ({
  display:"grid",
  width:'100%',
  gridTemplateColumns:"repeat(4,1fr)",
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {
  gridTemplateColumns:"repeat(3,1fr)",
},
[theme.breakpoints.down("md")]: {
  gridTemplateColumns:"repeat(3,1fr)",
},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));


const GraphTopCard = styled(Box)(({ theme }) => ({

 display:'flex',
 flexDirection:'column',
 justifyContent:'center',
 alignItems:'start',
 gap:'10px',
 height:'70px',
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {

},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));



const ImageCut = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:'center',
  alignItems:'center',
   flexDirection:'column',
  width:'100%',
  height:'100%',
  borderRadius:'10px',
  background:'#78a9f3',
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {

},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));

const TopRight = styled(Box)(({ theme }) => ({
  display:"grid",
  width:'60%',
  gridTemplateColumns:'repeat(3,1fr)',
  // height:'100px',
 
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {

width:'100%',
gridTemplateColumn:'repeat(2,1fr)',
},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));

const TopCard = styled(Box)(({ theme }) => ({
  display:"flex",
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  width:'100%',
  // height:'100px',
  padding:'5px',
  gap:'10px',
 
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {

},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));


const AddButton = styled(Button)(({ theme }) => ({
  borderRadius:'25px',
  border:'1px solid black',
  color:'black',
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {

},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));

const TopWrapper = styled(Box)(({ theme }) => ({
  display:"flex",
 
  flexDirection:"column",
  alignItems:"stretch",
  gap:"10px",
  minHeight:"90vh",
  padding:"16px",
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {},
[theme.breakpoints.down("md")]: {},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));

const InnerSet = styled(Box)(({ theme }) => ({
 display:'flex',
 
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {
  flexDirection:'column'
},
[theme.breakpoints.down("md")]: {

},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));

const LeftHalf = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  width:'50%',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {
  width:'100%',
 },
 [theme.breakpoints.down("md")]: {
 flexDirection:'column',
 width:'100%',
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));

 const TopSet = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  justifyContent:'space-between',
  width:'100%',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {

 },
 [theme.breakpoints.down("md")]: {
   display:'none'
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));

 const RightHalf = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  width:'50%',
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {
  width:'100%',
 },
 [theme.breakpoints.down("md")]: {
 flexDirection:'column',
 width:'100%',
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));

 const DowntimeBox = styled(Box)(({ theme }) => ({
  height:"100px",
  display:'flex',
  gap:'10px',
  backgroundColor:'white',
  
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {
  width:'100%',
 },
 [theme.breakpoints.down("md")]: {
 width:'100%',
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));

 const DowntimeSplit = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
  padding:'10px',
  gap: "20px",
  width:'100%',
  boxShadow:'0px 0px 15px 0px #DBE7E5',
[theme.breakpoints.down("xl")]: {},
[theme.breakpoints.down("lg")]: {
 
},
[theme.breakpoints.down("md")]: {
  flexDirection: "column",
  alignItems:'start'
},
[theme.breakpoints.down("sm")]: {},
[theme.breakpoints.down("xs")]: {},
}));


 const SemiCircleBox = styled(Box)(({ theme }) => ({
  bgcolor:"white",
  display:'flex',
  flexDirection:'column',
  textAlign:"center",
  backgroundColor:'white',
  justifyContent:"space-between",
  padding:'10px',
  boxShadow:"0px 0px 15px 0px #DBE7E5",
  height: "310px",
  alignItems: "center",
  gap: "5px",
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {
  height: "200px",
  alignItems: "start",
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));

 const SplitCircle = styled(Box)(({ theme }) => ({
flexDirection:'column',
display:'flex',

 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {
  flexDirection:'row',
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));


 const CustomDivider = styled(Divider)(({ theme }) => ({

   width:'90%',
   [theme.breakpoints.down("xl")]: {},
   [theme.breakpoints.down("lg")]: {},
   [theme.breakpoints.down("md")]: {
    width:'0%',
   },
   [theme.breakpoints.down("sm")]: {},
   [theme.breakpoints.down("xs")]: {},
   }));
 

 const SemiGraphBox = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  height: "140px",
  width: "300px",
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {

 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));

 const IncidentSet = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  display:'flex',
 height: "70%",
    alignItems: "center",
  width: "100%",
   justifyContent: "space-between",
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {
  flexDirection:'column',
  height: "100%",
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));

 const BottomSet = styled(Box)(({ theme }) => ({
  display:"grid",
  gridTemplateColumns:"repeat(2,1fr)",
  justifyContent:"space-between",
  gap:"10px",
 [theme.breakpoints.down("xl")]: {},
 [theme.breakpoints.down("lg")]: {},
 [theme.breakpoints.down("md")]: {
  gridTemplateColumns:"repeat(1,1fr)",
 width:'100%',
 },
 [theme.breakpoints.down("sm")]: {},
 [theme.breakpoints.down("xs")]: {},
 }));


const DashboardPage = ({handleDataChange,request}) => {
  let userIds = JSON.parse(sessionStorage.getItem("userData")) || JSON.parse(localStorage.getItem("userData"));
  let userBranch = JSON.parse(sessionStorage.getItem("userBranch")) || JSON.parse(localStorage.getItem("userBranch"));
  let userAccess = JSON.parse(sessionStorage.getItem("user_access")) || JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  const [incidentReport, setIncidentReport] = React.useState("year");
  const [serviceReport, setServiceReport] = React.useState("year");
  const [getServiceValue, setGetServiceValue] = React.useState("2024");
  const [getIncidentValue, setGetIncidentValue] = React.useState("2024");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate()

  const [selectedDate, setSelectedDate] = useState(dayjs());

  
  const [assigned, setAssigned] = React.useState(false);
  const [userToggle, setUserToggle] = React.useState(false);
  const dashboardData = useSelector((store) => store.data.dashboard);
  const [value, setValue] = React.useState("day");
  const dashboardList = useSelector((store)=>store.data.dashboardList)
  const dashboardList2 = useSelector((store)=>store.data.dashboardList2)
  const [selectedGraph, setSelectedGraph] = React.useState([]);
  const [weeksCount, setWeeksCount] = React.useState(null);
  const [assetDetails, setAssetDetails] = React.useState(null);
  const [totalAsset, setTotalAsset] = useState("");
  const [totalCount, setTotalCount] = useState({});
  const [graphData, setGraphData] = useState([]);
  const [dashList, setDashList] = useState([]);
  const [dashList2, setDashList2] = useState([]);
  const [incidentGraphData, setIncidentGraphData] = useState([]);
  const [incidents, setIncidents] = React.useState(null);
  const [serviceCounts, setServiceCounts] = React.useState(null);
  const [departIds, setDepartIds] = React.useState(null);
  const [downtime, setDowntime] = React.useState({
    tot_days: "0",
    tot_hrs: "0",
    tot_mins: "0",
    tot_months: "0",
    tot_secs: "0",
    tot_years: "0",
  });






  const [allData, setAllData] = useState({});
  const [dayCounts, setDayCounts] = useState(null);
  const [monthCount, setMonthCount] = useState(null);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(dashboardData).length>0) {
    
if(dashboardData?.service_graph_years){
  setGraphData(dashboardData?.service_graph_years)
}
        setAllData(dashboardData)     
    }
  }, [dashboardData]);

console.log("===========>>>>>>>>>",allData)


useEffect(()=>{

  if (Object.keys(dashboardData).length>0) {
if(dashboardData?.incident_graph_years){
  setIncidentGraphData(dashboardData?.incident_graph_years)
}
  }
},[dashboardData])



  useEffect(() => {
    if (Object.keys(dashboardData)?.length>0
    ) {
      const totalAssetsValue = dashboardData?.calib_warranty_counts;
  
      setTotalAsset(totalAssetsValue)
    }
  }, [dashboardData]);


  useEffect(() => {
    if (Object.keys(dashboardData)?.length>0
    ) {
      const totalAssetsValue = dashboardData?.total;
  
      setTotalCount(totalAssetsValue)
    }
  }, [dashboardData]);




  const options2 = {
    vAxis: {
      title: "Count",
      minValue: 0,
      maxValue: 100,
      ticks: [0, 25, 50, 75, 100],
    },
    legend: { position: "none" },
    chart: {
      chartType: "Bar",
    },
  };

  const convertData = (data) => {
    const convertedData = [["", "Incident"]];
    data?.forEach(([label, value]) => {
      const numberValue = Number(value);
      if (!isNaN(numberValue)) {
        convertedData.push([label, numberValue]);
      }
    });

    return convertedData;
  };

  const departmentData = convertData(dashboardData?.departcounts);

  const days = convertData(dayCounts);
  const weeks = convertData(weeksCount);
  const months = convertData(monthCount);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchname: userBranch.branch,
      branchid: userBranch.id,
    };
    dispatch(getDashboard(data, token));
  }, []);

  useEffect(() => {
    if (
      dashboardData?.assetcounts?.length > 0 &&
      dashboardData?.assetcounts?.length !== undefined
    ) {
      setAssetDetails(dashboardData?.assetcounts[0]);
    }
    if (
      dashboardData?.servicecounts?.length > 0 &&
      dashboardData?.servicecounts?.length !== undefined
    ) {
      setServiceCounts(dashboardData?.servicecounts[0]);
    }

    if (
      dashboardData?.departids?.length > 0 &&
      dashboardData?.departids?.length !== undefined
    ) {
      setDepartIds(dashboardData?.departids);
    }

    if (
      dashboardData?.incidents?.length > 0 &&
      dashboardData?.incidents?.length !== undefined
    ) {
      setIncidents(dashboardData?.incidents[0]);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (
      dashboardData?.incidentweekcounts?.length > 0 &&
      dashboardData?.incidentweekcounts !== undefined
    ) {
    }
    setWeeksCount(dashboardData?.incidentweekcounts);
    setDayCounts(dashboardData?.incidentcounts);
    setMonthCount(dashboardData?.incidentmonthcounts);

    if (dashboardData?.downtime !== undefined) {
      setDowntime(dashboardData?.downtime);
    }
  }, [dashboardData]);

  let notCleared = incidents?.open_incidents;
  let cleared = incidents?.closed_incidents;
  let totalIncident = incidents?.reported_incidents;
  const incidentPercentage = (count) => {
    if (count == 0) {
      return 0;
    }
    if (count != 0) {
      const totalCount = Number(cleared) + Number(notCleared);

      return (count / totalCount) * 100;
    }
  };

  const clearedCount = incidentPercentage(cleared);
  const notClearedCount = incidentPercentage(notCleared);


  let calibrated = totalAsset?.calibrated;
  let notCalibrated = totalAsset?.notcalibrated;
  let notRequired = totalAsset?.notrequired;
  let totalCalibrated = totalAsset?.totalcalibrated;



  const calibrationPercentage = (count) => {
    const totalCount = Number(calibrated) + Number(notCalibrated) + Number(notRequired);

    return (count / totalCount) * 100;
  };

  const calibratedCount = calibrationPercentage(calibrated);
  const notCalibratedCount = calibrationPercentage(notCalibrated);
  const notRequiredCount = calibrationPercentage(notRequired);

  let totalAmc = totalAsset?.total_amc;
  let totalCmc = totalAsset?.total_cmc;
  let totalCmc_cmc = totalAsset?.total_amc_cmc;
  
  const amcCmcPercentage = (count) => {
    const totalCount = Number(totalAmc) + Number(totalCmc);

    return (count / totalCount) * 100;
  };

  const amcPercentage = amcCmcPercentage(totalAmc);
  const cmcPercentage = amcCmcPercentage(totalCmc);

  const handleDepartmentId = (value) => {
    const id = departIds?.find((item) => {
      return item[0] === value;
    });
    let departId = id[1];
    navigate(`/department-asset-details/${departId}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "day") {
      // alert("day")
      setSelectedGraph(days);
    }
    if (newValue === "week") {
      // alert("week")
      setSelectedGraph(weeks);
    }
    if (newValue === "month") {
      // alert("month")
      setSelectedGraph(months);
    }
  };

  useEffect(() => {
    if (
      dashboardData === "AUTHENTICATION_ERROR" ||
      userAccess?.act_status === "Inctive"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/signin");
      window.location.reload();
    }
  }, [dashboardData, navigate, userAccess]);

  const convertTime = () => {
    const { tot_years, tot_months, tot_days, tot_hrs, tot_mins, tot_secs } =
      downtime;
    const totalDuration = moment.duration({
      years: parseInt(tot_years),
      months: parseInt(tot_months),
      days: parseInt(tot_days),
      hours: parseInt(tot_hrs),
      minutes: parseInt(tot_mins),
      seconds: parseInt(tot_secs),
    });

    const parts = [];
    if (totalDuration.years() > 0)
      parts.push(
        `${totalDuration.years()} yr${totalDuration.years() > 1 ? "s" : ""}`
      );
    if (totalDuration.months() > 0)
      parts.push(
        `${totalDuration.months()} month${totalDuration.months() > 1 ? "s" : ""}`
      );
    if (totalDuration.days() > 0)
      parts.push(
        `${totalDuration.days()} day${totalDuration.days() > 1 ? "s" : ""}`
      );
    if (totalDuration.hours() > 0)
      parts.push(
        `${totalDuration.hours()} hr${totalDuration.hours() > 1 ? "s" : ""}`
      );
    if (totalDuration.minutes() > 0)
      parts.push(
        `${totalDuration.minutes()} min${
          totalDuration.minutes() > 1 ? "s" : ""
        }`
      );
    if (totalDuration.seconds() > 0)
      parts.push(
        `${totalDuration.seconds()} sec${
          totalDuration.seconds() > 1 ? "s" : ""
        }`
      );

    return parts.join(" ");
  };




  useEffect(() => {


    if (userAccess && Object.keys(userAccess)?.length !== 0) {
      setAssigned(userAccess?.departments[0] === "UN_ASSIGNED");
    }
    if (userAccess && Object.keys(userAccess)?.length === 0) {
      setUserToggle(Object.keys(userAccess)?.length === 0);
    }
  }, [userAccess]);


  useEffect(()=>{
    if(handleDataChange!==undefined){
      let data2 = {
        department:allData?.deptcount,
        checkin:allData?.checkincount,
        checkout:allData?.checkoutcount,
        asset:allData?.assetcount,
        downtime:convertTime()
      }
        handleDataChange(data2)
    }
  
  },[allData])



  const handleServiceReport = (e)=>{
  let value =  e.target.value;
  setServiceReport(value)

if(value === "year"){
  setGraphData(dashboardData?.service_graph_years)

  
}
if(value === "month"){
  setGraphData(dashboardData?.service_graph_months)
  // service_graph_months

}

if(value === "day"){
  setGraphData(dashboardData?.service_graph_days)
}

  }

 

  const handleIncidentReport = (e)=>{
    let value =  e.target.value
    setIncidentReport(value)
    if(value === "year"){
      setIncidentGraphData(dashboardData?.incident_graph_years)
      
    }
    if(value === "month"){
      setIncidentGraphData(dashboardData?.incident_graph_months)
      // let data = {
      //   hospid: userIds.hospid,
      //   userid: userIds.userid,
      //   branchid: userBranch.id,
      //   // "incident_or_service": "service",
      //  // (Or)
      //   "incident_or_service": "incident",
      //   "month_or_year": "month",
      // // (or)
      //   // "month_or_year": "year",
      //   "month_year":getIncidentValue,
      // // (or)
      //   // "month_year":"2023"
      // }
      // dispatch(getDashboardList(data))
    }
    if(value === "day"){
      setIncidentGraphData(dashboardData?.incident_graph_days)
    }
    }
  


    const handleGenerate = ()=>{
      navigate('/m-report')
    }


    const handleData = ()=>{
       let data = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        branchid: userBranch.id,
        // "incident_or_service": "service",
       // (Or)
        "incident_or_service": "incident",
        "month_or_year": incidentReport,
      // (or)
        // "month_or_year": "year",
        "month_year":getIncidentValue,
      // (or)
        // "month_year":"2023"
      }
      console.log("datadatadata",data)
      dispatch(getDashboardList(data))
    }

    const handleDataService = ()=>{
      let data = {
       hospid: userIds.hospid,
       userid: userIds.userid,
       branchid: userBranch.id,
       // "incident_or_service": "service",
      // (Or)
       "incident_or_service": "service",
       "month_or_year": serviceReport,
     // (or)
       // "month_or_year": "year",
       "month_year":getServiceValue,
     // (or)
       // "month_year":"2023"
     }

     dispatch(getDashboardList2(data))
   }

console.log("dashboardList2------------------------>",dashboardList2)



useEffect(()=>{
  let data = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    branchid: userBranch.id,
    // "incident_or_service": "service",
   // (Or)
    incident_or_service: "incident",
    "month_or_year": incidentReport,
  // (or)
    // "month_or_year": "year",
    month_year:getIncidentValue,
  // (or)
    // "month_year":"2023"
  }

  dispatch(getDashboardList(data))
  console.log("----->|||||||||||||||||||",incidentReport,getIncidentValue)
},[incidentReport,getIncidentValue])



useEffect(()=>{
  let data = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    branchid: userBranch.id,
    // "incident_or_service": "service",
   // (Or)
    incident_or_service: "service",
  month_or_year: serviceReport,
  // (or)
    // "month_or_year": "year",
    month_year:getServiceValue,
  // (or)
    // "month_year":"2023"
  }

  dispatch(getDashboardList2(data))

},[serviceReport,getServiceValue])



useEffect(()=>{
  if(dashboardList?.length>0){
    setDashList(dashboardList)
  }
},[dashboardList,incidentReport,getIncidentValue])



useEffect(()=>{
  if(dashboardList2?.length>0){
    setDashList2(dashboardList2)
  }
  console.log("--oo----------o---------o--o-o--o-o",serviceReport,getServiceValue)
},[dashboardList2,serviceReport,getServiceValue])


// setDashList2


  return (
    <Wrapper>
    <TopContainer>
   
     <TitleHeading  variant="h5">
     Good life Multi-speciality 
     Hospital, Vijaynagar
     </TitleHeading>

     <TopRight>
     <TopCard justifyContent={"space-evenly"} >
      <Label sx={{fontSize:'18px'}}>₹ {allData?.budget_spent}</Label>
      <Label>Budget Spent</Label>
     </TopCard>
     <TopCard>
     <Label sx={{fontSize:'18px'}}>Monthly Report</Label>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      width='90%'
        views={['year', 'month']}
        minDate={dayjs('2020-01-01')}
        maxDate={dayjs('2030-12-31')}
        value={selectedDate}
        onChange={(newValue) => {
          setSelectedDate(newValue);
        }}
        renderInput={(params) => <TextField {...params} helperText={null} />}
      />
      <Button sx={{borderRadius:'20px'}} onClick={handleGenerate} size="small" variant="contained">
        Generate
      </Button>
    </LocalizationProvider>
     </TopCard>
     <TopCard >
      <ImageCut>
      <Label sx={{color:'white',fontSize:'16px'}}>Coming Soon!</Label>
      <Label sx={{color:'white', }}>Monthly Ratings</Label>
      </ImageCut>
     </TopCard>
     </TopRight>

    </TopContainer>
     
        <InnerSet width="100%" gap={"20px"}>
       
          <LeftHalf  sx={{ gap: "20px"}}>
        
           {request !== "asset" && request !== "incident" ? <TopSet >
              <Stack
                width="184px"
                height="100px"
                bgcolor="white"
                alignItems={"start"}
                justifyContent={"space-between"}
                p={2}
                boxShadow={"0px 0px 15px 0px #DBE7E5"}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:'center',
                    width: "100%",
                  }}>
                  <Label variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {totalCount?.total_assets}
                  </Label>



<AddButton onClick={() => navigate("/add-asset")} variant="outlined">
    Add
</AddButton>



                  
                </Stack>
                <Label>Assets</Label>
              </Stack>
              
              <Stack
                width="184px"
                height="100px"
                bgcolor="white"
                alignItems={"start"}
                justifyContent={"space-between"}
                boxShadow={"0px 0px 15px 0px #DBE7E5"}
                p={2}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:'center',
                    width: "100%",
                  }}>
                  <Label variant="subtitle1" sx={{ fontWeight: 700,color:'#FF6B00' }}>
                  {totalCount?.total_notworking}
                  </Label>

                  <WarningAmberIcon sx={{fontSize:'22px', color:'#FF6B00'}} />

                
                  
                </Stack>
                <Label sx={{color:'#FF6B00'}}>Not Working</Label>
              </Stack>

              <Stack
                width="184px"
                height="100px"
                bgcolor="white"
                alignItems={"start"}
                justifyContent={"space-between"}
                boxShadow={"0px 0px 15px 0px #DBE7E5"}
                p={2}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:'center',
                    width: "100%",
                  }}>
                  <Label variant="subtitle1" sx={{ fontWeight: 700, }}>
                  {totalCount?.total_discard}
                  </Label>


               



                  
                
                 
                </Stack>
                <Label>Discard</Label>
              </Stack>
            </TopSet>:<></>}

           
          { request !== "incident"? 
          <Stack
              bgcolor="white"
              gap={"15px"}
              height={"100%"}
              p={2}
              boxShadow={"0px 0px 15px 0px #DBE7E5"}>
              <Stack>
                <Stack sx={{flexDirection:'row', width:'100%', justifyContent:'space-between'}}>
                  
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  sx={{
                    color: "#4690FF",
                    textAlign: "start",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}>
                Service
                </Typography>

                <Button onClick={()=>navigate('/service-list')} sx={{border:'1px solid #FF6B00', color:'#FF6B00', borderRadius:'20px'}} varient="outlined">
                  Add
                </Button>
                </Stack>
                

                <GraphTop>
 <GraphTopCard>
 <Label>{allData?.services?.open_service}/{allData?.services?.reported_service}</Label>
<Label>Open</Label>
 </GraphTopCard>

 <GraphTopCard>
 <Label>{allData?.services?.closed_service}/{allData?.services?.reported_service}</Label>
<Label>Close</Label>
</GraphTopCard>

<GraphTopCard>

<Label>₹ {
allData?.services?.expenditure}</Label>
<Label>Expenditure</Label>
</GraphTopCard>

 
</GraphTop>

<Divider/>

                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:'center'
                  }}>
                  <Label>Services reported</Label>

          


      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
     
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"

        value={serviceReport}
        onChange={handleServiceReport}
        

        
      >
        <MenuItem value="">
       
        </MenuItem>
        <MenuItem value={"year"}>Year</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
        <MenuItem value={"day"}>Day</MenuItem>
      </Select>
    </FormControl>
                </Stack>



                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <Typography>No. of services</Typography>

                  
                </Stack>

                <Divider sx={{ width: "100%" }} />
              </Stack>

              <Box width="100%" bgcolor="white" sx={{ height: 260 }}>
              {graphData?.length>0 && <GraphComponent data={graphData} date={serviceReport}  dataValue={setGetServiceValue} handleData={handleDataService} />}
              </Box>

              <Box width="100%" bgcolor="white">
                <TableContainer  sx={{maxHeight:'260px'}}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableHeading>Asset Name</TableHeading>

                        <TableHeading>Asset Code</TableHeading>
                        <TableHeading>Department</TableHeading>
                        {/* <TableHeading>Services</TableHeading> */}
                        <TableHeading>Status</TableHeading>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dashList2?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCells>{row.name}</TableCells>
                          <TableCells>{row.assetcode}</TableCells>
                          <TableCells>{row.department}</TableCells>
                          <TableCells
                            sx={{
                              textAlign: "center",
                              color:
                                row.status !== "Working"
                                  ? "#FF6B00"
                                  : "#4690FF",
                            }}>
                            {row.status}
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>




              <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:'center'
                  }}>
                  <Label>Need Attention - {allData?.service_need_attention?.length} </Label>

                </Stack>



              <Box width="100%" bgcolor="white">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeading>Asset Name</TableHeading>

                        <TableHeading>Asset Code</TableHeading>
                        <TableHeading>Department</TableHeading>
                        <TableHeading>Services</TableHeading>
                        <TableHeading>Status</TableHeading>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allData?.service_need_attention?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCells>{row.asset}</TableCells>
                          <TableCells>{row.code}</TableCells>
                          <TableCells>{row.dept}</TableCells>
                          <TableCells sx={{ textAlign: "center" }}>
                            {row.no_of_services}
                          </TableCells>
                          <TableCells
                            sx={{
                              textAlign: "center",
                              color:
                                row.status !== "Working"
                                  ? "#FF6B00"
                                  : "#4690FF",
                            }}>
                            {row.status}
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>:<></>}
          </LeftHalf>

    
         {request !== "asset" ? <RightHalf  sx={{ textAlign: "start", gap: "20px" }}>
                
            <DowntimeBox sx={{background:'white'}}>
            
            <Stack
                width="184px"
                height={{xl:"100px",md:'100%'}}
                bgcolor="white"
                alignItems={"start"}
                justifyContent={"space-between"}
                boxShadow={"0px 0px 15px 0px #DBE7E5"}
                p={2}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:'center',
                    width: "100%",
                  }}>
                  <Label variant="subtitle1" sx={{ fontWeight: 700 }}>
                  {totalCount?.total_department}
                  </Label>


                  <AddButton onClick={() => navigate("/department-list")} variant="outlined">
    Add
</AddButton>



                  
                
                 
                </Stack>
                <Label>Department</Label>
              </Stack>


              <DowntimeSplit
               >
                  <Box sx={{display:'flex', gap:'10px'}}>
                  <Label variant="subtitle1" sx={{ fontWeight: 700 }}>
                  Cumulative Downtime
                  </Label>
                 
                 < ArrowRightAltIcon sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/closed-incident")} />
                  </Box>
                 
             
                <Typography sx={{ color: "#FF6B00" }}>
                  {convertTime()}
                </Typography>
              </DowntimeSplit>
            </DowntimeBox>

            <Stack
              bgcolor="white"
              gap={"15px"}
              height={"100%"}
              p={2}
              boxShadow={"0px 0px 15px 0px #DBE7E5"}>
              <Stack>

              <Stack sx={{flexDirection:'row', width:'100%', justifyContent:'space-between'}}>
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  sx={{
                    color: "#4690FF",
                    textAlign: "start",
                    marginBottom: "10px",
                    fontWeight: 700,
                  }}>
                Incident
                </Typography>

                <Button onClick={()=>navigate('/incident-list')} sx={{border:'1px solid #FF6B00', color:'#FF6B00', borderRadius:'20px'}} varient="outlined">
                  Add
                </Button>
                </Stack>
                {/* closed_incid
: 
"14"
open_incid
: 
"7"
reported_incid
: 
"21" */}

<GraphTop>
<GraphTopCard>
<Label>{allData?.incidents?.open_incid}/{allData?.incidents?.reported_incid
}</Label>
<Label>Open</Label>
 </GraphTopCard>

 <GraphTopCard>
 <Label>{allData?.incidents?.closed_incid}/{allData?.incidents?.reported_incid
 }</Label>
<Label>Close</Label>
</GraphTopCard>

<GraphTopCard>

</GraphTopCard>

 
</GraphTop>


<Divider/>


                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems:'center'
                  }}>
                  <Label>Incidents reported</Label>

          


      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
     
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={incidentReport}
        onChange={handleIncidentReport}
        
      >
        <MenuItem value="">
       
        </MenuItem>
        <MenuItem value={"year"}>Year</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
        <MenuItem value={"day"}>Day</MenuItem>
      </Select>
    </FormControl>
                </Stack>



                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <Typography>No. of Incidents</Typography>

                 
                </Stack>
                <Divider sx={{ width: "100%" }} />
              </Stack>

              <Box width="100%" bgcolor="white" sx={{ height: 260 }}>
              {graphData?.length>0 && <GraphComponent data={incidentGraphData} date={incidentReport} dataValue={setGetIncidentValue} handleData={handleData} />}
              </Box>
              <Box width="100%" bgcolor="white">
                <TableContainer  sx={{maxHeight:'260px'}}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableHeading>Asset Name</TableHeading>

                        <TableHeading>Asset Code</TableHeading>
                        <TableHeading>Department</TableHeading>
                        {/* <TableHeading>Services</TableHeading> */}
                        <TableHeading>Status</TableHeading>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dashList?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCells>{row.name}</TableCells>
                          <TableCells>{row.assetcode}</TableCells>
                          <TableCells>{row.department}</TableCells>
                          <TableCells
                            sx={{
                              textAlign: "center",
                              color:
                                row.status !== "Working"
                                  ? "#FF6B00"
                                  : "#4690FF",
                            }}>
                            {row.status}
                          </TableCells>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>

           
            <BottomSet>
              <SemiCircleBox
               >
                <Stack>
                  <Label>Calibration</Label>
                </Stack>


                <SplitCircle >
                <Stack
                  sx={{
                    height: "80px",
                    width: "150px",
                   
                  }}>
                  <HalfPieChart2 value1={calibrated} value2={notCalibrated} value3={notRequired} total={totalCalibrated}  />
                </Stack>
                <CustomDivider  />
                <Stack
                  sx={{
                    alignItems: "start",
                    height: "100px",
                    gap: "20px",
                    marginTop: "10px",
                  }}>
                  <Label2 variant="body2" onClick={() => navigate("/request")}>
                     <SquareIcon sx={{ fontSize: '10px' }} />
                     {calibrated} Calibrated
                  </Label2>
                  <Label2
                    variant="body2"
                    onClick={() => navigate("/not-calibrated")}>
                    <SquareIcon sx={{ fontSize: '10px', color: "#8B9099" }} />
                    
                    {notCalibrated} Not Calibrated
                  </Label2>
                  <Label2
                    variant="body2"
                    onClick={() => navigate("/calibration-not-required")}>
                    <SquareIcon sx={{ fontSize: '10px', color: "#BBBEC3" }} />
                    {notRequired} Not Required
                  </Label2>
                </Stack>
                </SplitCircle>
                <Stack sx={{ alignItems: "center" }}>
                  <Label2
                    variant="body2"
                    sx={{
                      color: "#FF6B00",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: 700,
                    }}>
                    {totalAsset?.calibration_due} Due
                    <ArrowRightAltIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/request")}
                    />
                  </Label2>
                </Stack>
              </SemiCircleBox>
           
              <SemiCircleBox>
                <Stack>
                  <Label>Warranty</Label>
                </Stack>
                <SplitCircle>
                <Stack
                  sx={{
                    height: "80px",
                    width: "150px",
                  }}>
                  <HalfPieChart2 value1={totalAsset?.total_warranty} value2={totalAsset?.warranty_exp_soon} value3={totalAsset?.warranty_requested} total={totalAsset?.total_warranty} />
                </Stack>
                <CustomDivider  />
                <Stack
                  onClick={() => navigate("/request")}
                  sx={{
                    alignItems: "start",
                    height: "100px",
                    gap: "20px",
                    marginTop: "10px",
                  }}>
                  <Label2 variant="body2">
                     <SquareIcon sx={{ fontSize: '10px' }} />
                    {totalAsset?.total_warranty } Total
                  </Label2>
                  <Label2 variant="body2">
                    <SquareIcon sx={{ fontSize: '10px', color: "#8B9099" }} />
                    {totalAsset?.warranty_exp_soon} Expires soon
                  </Label2>
                  <Label2 variant="body2">
                 
                    <SquareIcon sx={{ fontSize: '10px', color: "#BBBEC3" }} />
                    {totalAsset?.warranty_requested} Requested
                  </Label2>
                </Stack>
                </SplitCircle>
                <Stack sx={{ alignItems: "center" }}>
                  <Label2
                    variant="body2"
                    sx={{
                      color: "#FF6B00",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: 700,
                    }}>
                    {totalAsset?.warranty_due} Due
                    <ArrowRightAltIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/request")}
                    />
                  </Label2>
                </Stack>
              </SemiCircleBox>
            </BottomSet>
          </RightHalf>:<></>}
        </InnerSet>
    
    </Wrapper>
  );
};

export default DashboardPage;
