import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  styled,
  Divider,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import {
  getDepartmentData,
  getSingleUser,
  getUserData,
  updateData,
  updateSubuserToSuperAdmin,
} from "../redux/nbmData/action";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditUserModal from "../components/EditUserModal";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const Label2 = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 500,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const allOption = {
  departid: "all",
  departname: "All",
};
const EditUserPage = () => {
  const dataEdit = useSelector((store) => store.data.singleUser);
  const departmentData = useSelector((store) => store.data.department);
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  const [toggle, setToggle] = useState(false);
  const [superAdminToggle, setSuperAdminToggle] = useState(false);
  const [selectAll, setSelectAll] = React.useState(false);

  const [department, setDepartment] = useState("");
  const [allData, setAllData] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");

  const [userRoll, setUserRoll] = useState("");

  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDepartments, setSelectedDepartments] = React.useState(
    dataEdit.departments
      ?.map((deptName) =>
        departmentData.find((dept) => dept.departname === deptName)
      )
      .filter(Boolean)
  );

  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [userRollError, setUserRollError] = useState("");
  const [emailError, setEmailError] = useState("");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [checked, setChecked] = useState({
    Super_Admin: false,
    Branch_Admin: false,
    Admin: false,
    Guest: false,
  });
  console.log("checked", checked);
  const [adminType, setAdminType] = useState("");

  const handleDesignation = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    if (value === "" || regex.test(value)) {
      setDesignation(value);
      setDesignationError("");
    }
    if (value !== "" && !regex.test(value)) {
      setDesignationError("Invalid designation");
    }
    if (value === "") {
      setDesignationError("Please fill the field");
    }
  };

  const handleNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d{10}$/;
    setMobileNumber(value);
    if (value === "" || regex.test(value)) {
      setMobileNumber(value);
      setNumberError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNumberError("Invalid number");
    }
    if (value === "") {
      setNumberError("Please fill the field");
    }
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;
    setName(value);
    if (value === "" || regex.test(value)) {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Invalid Name");
    }
    if (value === "") {
      setNameError("Please fill the field");
    }
  };



  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(getUserData(data));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      userid: params.id,
      hospid: userIds.hospid,
      branchid: userBranch?.id,
    };
    dispatch(getSingleUser(data));
  }, [dispatch]);
  console.log("selectedDepartments", selectedDepartments);
  const handleClick = () => {
    if (
      name !== "" &&
      email !== "" &&
      department !== "" &&
      designation !== "" &&
      userRoll !== "" &&
      nameError === "" &&
      emailError === "" &&
      designationError === "" &&
      selectedDepartments?.length !== 0 &&
      userRollError === ""
    ) {
      const formattedDepartments =
        selectedDepartments.length === 0
          ? [
              {
                depart_id: "all",
                depart_name: "All",
              },
            ]
          : selectedDepartments.map((dept) => ({
              depart_id: dept.departid,
              depart_name: dept.departname,
            }));

      let data = {
        adminid: userData.userid,
        hospid: userData.hospid,
        userid: params.id,
        username: name.trim(),
        usermobile: mobileNumber.trim(),
        userdesig: designation.trim(),
        branchname: userBranch?.branch,
        branchid: userBranch?.id,
        branchdepart: {
          branchId: userBranch?.id,
          branch: userBranch?.branch,
          role: selectedRole,
          addprod:
            selectedRole === "Branch_Admin" ||
            selectedRole === "Super_Admin" ||
            selectedRole === "Admin"
              ? "Yes"
              : "No",
          delprod:
            selectedRole === "Branch_Admin" ||
            selectedRole === "Super_Admin" ||
            selectedRole === "Admin"
              ? "Yes"
              : "No",

          editprod:
            selectedRole === "Branch_Admin" ||
            selectedRole === "Super_Admin" ||
            selectedRole === "Admin"
              ? "Yes"
              : "No",
          adddepart:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          deldepart:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          editdepart:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          adduser:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "Yes"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          deluser:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          edituser:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",

          department: formattedDepartments,
        },
      };

      let datatoSuperAdmin = {
        admin_id: userData.userid,
        userid: params.id,
        hosp_id: userData.hospid,
        hosp_name: userData.hospname,
        user_name: name.trim(),
        user_email: email,
        user_mobile: mobileNumber.trim(),
        user_desig: designation.trim(),
      };

      if (selectedRole === "Super_Admin") {
        dispatch(updateSubuserToSuperAdmin(datatoSuperAdmin));
        setSuperAdminToggle(true);
      }

      if (selectedRole !== "Super_Admin") {
        dispatch(updateData(data));
        setToggle(true);
      }
    }

    if (name === "") {
      setNameError("Please fill the field");
    }
    if (email === "") {
      setEmailError("Please fill the field");
    }
    if (mobileNumber === "") {
      setNumberError("Please fill the field");
    }
    if (department === "") {
      setDepartmentError("Please fill the field");
    }
    if (designation === "") {
      setDesignationError("Please fill the field");
    }
    if (userRoll === "") {
      setUserRollError("Please select the role");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userData.hospid,
      userid: userData.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (dataEdit && dataEdit?.length !== 0) {
      setAllData(dataEdit);
      setName(dataEdit?.username);
      setEmail(dataEdit?.email);
      setMobileNumber(dataEdit?.mobile);
      setDesignation(dataEdit?.designate);
      setDepartment(dataEdit?.departments);
      setUserRoll(dataEdit?.role);
      setSelectedRole(dataEdit?.role);

      const departmentsArray = dataEdit?.departids?.map((id, index) => ({
        departid: id,
        departname: dataEdit?.departments[index],
      }));
      setSelectedDepartments(departmentsArray);

      
    }
  }, [dataEdit]);

  const handleCloseModal = () => {
    setToggle(false);
    setSuperAdminToggle(false);
    navigate("/user");
  };

  const handleDepartmentsChange = (event, newDepartments) => {
    const isAllSelected = newDepartments.some(
      (department) => department.departid === "all"
    );

    if (isAllSelected) {
      newDepartments = newDepartments.filter(
        (department) => department.departid === "all"
      );
    }

    setSelectedDepartments(newDepartments);
  };
  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
        disabled={selectAll && option.departid !== "all"}
      />

      {option?.departname}
    </li>
  );

  useEffect(() => {
    if (dataEdit) {
      const { role } = dataEdit;
      const initialChecked = {
        Super_Admin: role === "Super_Admin",
        Branch_Admin: role === "Branch_Admin",
        Admin: role === "Admin",
        Guest: role === "Guest",
      };
      setChecked(initialChecked);
    }
  }, [dataEdit]);

  const handleCheckboxChange = (name) => (event) => {
    setUserRollError("");
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedRole(name);
    } else {
      setSelectedRole("");
    }
    if (name === "Super_Admin") {
      setSelectedRole(name);
      setSelectedDepartments([{ departid: "all", departname: "All" }]);
    }

    if (name === "Branch_Admin") {
      setSelectedRole(name);
      setSelectedDepartments([{ departid: "all", departname: "All" }]);
    }
    setChecked({ ...checked, [name]: event.target.checked });
  };
  console.log("selectedRole", selectedRole);

  console.log("adminType", adminType);
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: "600px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="subtitle1" color="primary">
            Add User
          </Label>

          <Label variant="subtitle1" color="primary">
            {userBranch.branch}
          </Label>
        </Stack>

        <Box sx={{ bgcolor: "white", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",
            }}>
            <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
              Edit User
            </Label>
          </Box>
          <Divider style={{ background: "rgba(70, 144, 255, 1)" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              //   height: "170px",
              //   minHeight: "170px",
              marginTop: "20px",
            }}>
            <Stack
              sx={{
                width: "50%",
                marginRight: "16px",
                gap: "15px",

                height: "100%",
                textAlign: "start",
              }}>
              <Label>Personal Details</Label>
              <Stack>
                <Field
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  variant="filled"
                  sx={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />

                <HelperText variant="caption">{emailError}</HelperText>
              </Stack>
              <Stack>
                <Field
                  onChange={handleName}
                  value={name}
                  label="User Name"
                  variant="filled"
                  sx={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{nameError}</HelperText>
              </Stack>
              <Stack>
                <Field
                  value={mobileNumber}
                  onChange={handleNumber}
                  label="Mobile Number"
                  variant="filled"
                  sx={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{numberError}</HelperText>
              </Stack>
              <Stack>
                <Field
                  value={designation}
                  onChange={handleDesignation}
                  label="Designation"
                  variant="filled"
                  sx={{ width: "100%" }}
                  InputProps={{ disableUnderline: true }}
                />
                <HelperText variant="caption">{designationError}</HelperText>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "50%",
                height: "100%",

                gap: "15px",
                textAlign: "start",
              }}>
              <Label>User Access</Label>
              <Stack sx={{ height: "100%" }}>
                <Stack
                  sx={{
                    gap: "20px",
                  }}>
                  <FormGroup sx={{ padding: "20px", bgcolor: "#F4F6F6" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRole === "Super_Admin"}
                          onChange={handleCheckboxChange("Super_Admin")}
                        />
                      }
                      label="Super Admin"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRole === "Branch_Admin"}
                          onChange={handleCheckboxChange("Branch_Admin")}
                        />
                      }
                      label="Branch Admin"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRole === "Admin"}
                          onChange={handleCheckboxChange("Admin")}
                        />
                      }
                      label="Department Admin"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRole === "Guest"}
                          onChange={handleCheckboxChange("Guest")}
                        />
                      }
                      label="Guest"
                    />
                  </FormGroup>
                  {(selectedRole === "Guest" || selectedRole === "Admin") && (
                    <Stack>
                      <Autocomplete
                        multiple
                        id="edit-departments"
                        options={[allOption, ...departmentData]}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option?.departname || ""}
                        value={selectedDepartments}
                        onChange={handleDepartmentsChange}
                        renderOption={renderOption}
                        style={{
                          textAlign: "left",
                          background: "#EFF5FE",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Departments"
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.departid === value.departid
                        }
                      />
                      {selectedDepartments?.length === 0 && (
                        <HelperText>Select at least one department</HelperText>
                      )}
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>

          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "16px",
              }}>
              <Stack
                sx={{
                  flexShrink: 1,
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}>
                <Button
                  onClick={handleClick}
                  disabled={userAccess?.adduser !== "Yes"}
                  variant="contained"
                  sx={{
                    width: "154px",
                    borderRadius: "22px",
                    bgcolor: "rgba(70, 144, 255, 1)",
                  }}>
                  Submit
                </Button>
              </Stack>
            </Box>
          </>
        </Box>
      </Stack>


      <EditUserModal status={toggle} handleCloseModal={handleCloseModal} toggle={toggle} />

      {/* <AddUserModal
        openAddUser={openAddUser}
        handleOpenUser={handleOpenUser}
        handleCloseUser={handleCloseUser}
        addedStatus={addedStatus}
        toggle={toggle}
      />
      <AddUserModal
        openAddUser={openAddUser}
        handleOpenUser={handleOpenUser}
        handleCloseUser={handleCloseUser}
        addedStatus={createSuperAdmins}
        toggle={toggle}
      /> */}
    </Stack>
  );
};

export default EditUserPage;
