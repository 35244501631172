import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Badge,
  Button,
} from "@mui/material";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "jspdf-autotable";
import { useEffect } from "react";
import { DownloadPDF, profile, serviceDetail } from "../redux/data/action";

import { useState } from "react";

import DownloadIcon from '@mui/icons-material/Download';
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const PhotoCard = styled(Box)(({ theme }) => ({
  //
  width: "100px",
  height: "130px",

  borderRadius: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100px",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
}));
const ServiceDetail = () => {
  const navigate = useNavigate();

  
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let serviceData = useSelector((store) => store.data.serviceDetail);

  const dispatch = useDispatch();

  const params = useParams();

  const [fileType, setFileType] = useState(null);
  const fileLink = serviceData.receipturl;

  useEffect(() => {
    if (fileLink?.endsWith(".pdf") || fileLink?.includes(".pdf")) {
      setFileType("pdf");
    } else if (
      fileLink?.endsWith(".jpg") ||
      fileLink?.endsWith(".jpeg") ||
      fileLink?.endsWith(".png") ||
      fileLink?.endsWith(".gif") ||
      fileLink?.includes("image/")
    ) {
      setFileType("image");
    } else {
      setFileType("unknown");
    }
  }, [fileLink]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      servid: params.id,
      branchid: userBranch.id,
    };

    dispatch(serviceDetail(data));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    // link.target = "_blank";
    link.download = "downloaded_image.jpg";
    link.click();
  };

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
    };

    dispatch(profile(data));
  }, [dispatch]);

  const HospitalLeftData = {
    "Asset Name": serviceData.assetname,
    "Asset Code": serviceData.assetcode,
    Department: serviceData.deptname,
    "Issue Date": serviceData.reqdate,
    "Clear Date": serviceData.servicedate,
  };
  const HospitalLeftDataOpen = {
    "Asset Name": serviceData.assetname,
    "Asset Code": serviceData.assetcode,
    Department: serviceData.deptname,
    "Issue Date": serviceData.reqdate,
  };
  const HospitalRightData = {
    "AMC/CMC": serviceData.amccamc,
    Status: serviceData.fixedstatus,
    "Paid Service": serviceData.remarks,
    ...(serviceData.paidservice !== "no" && {
      Cost: serviceData.servicecost,
      Receipt: "",
    }),
  };

  const HospitalRightDataOpen = {
    "Model No.": serviceData.modelno,

    Brand: serviceData.brand,
  };
  const UserLeftData = {
    Name: serviceData.servicername,
    "Contact No.": serviceData.mobile,
  };

  const IssueData = {
    "Problem Identified ": serviceData?.probidentified,
    "Action Taken": serviceData?.actiontaken,
    ...(serviceData.remarks !== "NA" && {
      Remark: serviceData.remarks,
    }),
  };

  return (
    <>
      {serviceData?.servicestatus === "Cleared" ? (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            height: "100%",
          }}>
          <Stack
            sx={{
              width: "750px",

              borderRadius: "8px",

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              gap: "10px",
            }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",

                padding: "10px 20px 10px 20px",
              }}>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}>
                Cleared Service
              </Label>

              <Label>{serviceData.servicedate}</Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Box sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightData).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>

                    {serviceData?.receipturl !== "NA" &&
                      serviceData?.receipturl !== "" && (
                        <Stack
                          sx={{
                            textAlign: "start",

                            height: "80px",
                            width: "90%",
                            alignItems: "start",
                            marginLeft: "10px",
                            paddingLeft: "10px",
                            bgcolor: "rgba(244, 246, 246, 1)",
                            justifyContent: "center",
                          }}>
                          <Box>
                            {fileType === "pdf" ? (
                              <Badge
                                badgeContent={
                                  <DownloadIcon
                                    style={{ cursor: "pointer" }}
                                    size={15}
                                    onClick={() =>
                                      dispatch(
                                        DownloadPDF(
                                          serviceData.receipturl,
                                          "receipt"
                                        )
                                      )
                                    }
                                  />
                                }
                                color="secondary"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}>
                                <PictureAsPdfIcon sx={{fontSize:'50px'}} />
                              </Badge>
                            ) : (
                              <Box>
                                <PhotoCard
                                  as="img"
                                  src={serviceData?.receipturl}
                                  alt="incImage"
                                />
                              </Box>
                            )}
                          </Box>
                        </Stack>
                      )}
                  </TableContainer>
                </Box>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Contact Person</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Box sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(UserLeftData).map(([key, value]) => (
                          <TableRow key={key}>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{
                                color: "rgba(27, 37, 53, 1)",
                                fontWeight: 700,
                              }}>
                              {key}
                            </TableCells>

                            <TableCells sx={{}}>{value}</TableCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ width: "40%" }}></Box>
              </Stack>

              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Issue Details</Label>
                <Divider />
              </Stack>

              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Stack
                  sx={{
                    width: "80%",
                    gap: "10px",
                  }}>
                  {Object.entries(IssueData).map(([key, value]) => (
                    <Stack
                      sx={{
                        width: "100%",

                        textAlign: "start",
                        minHeight: "130px",
                        background: "rgba(244, 246, 246, 1)",
                        padding: "20px",
                      }}>
                      <Label>{key}</Label>

                      <Typography sx={{ marginTop: "10px" }}>
                        {value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>

                <Stack
                  sx={{
                    width: "20%",
                    gap: "10px",
                  }}>
                  <>
                    {serviceData?.issueimgs !== "NA" &&
                      serviceData?.issueimgs !== "" && (
                        <Stack
                          display="flex"
                          flexDirection={"row"}
                          sx={{
                            width: "100%",

                            height: "170px",
                            overflowY: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                            overflowX: "auto",
                          }}>
                          {serviceData?.issueimgs?.map((images, index) => (
                            <Box
                              sx={{ height: "95%" }}
                              key={index}
                              position="relative"
                              display="inline-block">
                              <Box
                                as="img"
                                src={images}
                                alt="incImage"
                                style={{
                                  height: "100%",
                                  width: "140px",
                                }}
                              />
                              <Box
                                position="absolute"
                                bottom={-20}
                                right={0}
                                p={1}>
                                <IconButton style={{ cursor: "pointer" }}>
                                  <DownloadIcon
                                    onClick={() => handleDownload(images)}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                        </Stack>
                      )}

                    {serviceData?.servicedimgs !== "NA" &&
                      serviceData?.servicedimgs !== "" && (
                        <Stack
                          display="flex"
                          flexDirection={"row"}
                          sx={{
                            width: "100%",

                            height: "170px",
                            overflowY: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                            overflowX: "auto",
                          }}>
                          {serviceData?.servicedimgs?.map((images, index) => (
                            <Box
                              sx={{ height: "95%" }}
                              key={index}
                              position="relative"
                              display="inline-block">
                              <Box
                                as="img"
                                src={images}
                                alt="incImage"
                                style={{
                                  height: "100%",
                                  width: "140px",
                                }}
                              />
                              <Box
                                position="absolute"
                                bottom={-20}
                                right={0}
                                p={1}>
                                <IconButton style={{ cursor: "pointer" }}>
                                  <DownloadIcon
                                    onClick={() => handleDownload(images)}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                        </Stack>
                      )}
                  </>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",

            height: "100%",
          }}>
          <Stack
            sx={{
              width: "750px",

              borderRadius: "8px",

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              gap: "10px",
            }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",

                padding: "10px 20px 10px 20px",
              }}>
              <Label
                variant="subtitle1"
                sx={{ color: "rgba(70, 144, 255, 1)" }}>
                Service Request
              </Label>

              <Label>{serviceData.reqdate}</Label>
            </Stack>

            <Stack sx={{ bgcolor: "white", padding: "20px" }}>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label variant="subtitle1">Asset Details</Label>
                <Divider />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Box sx={{ width: "60%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ maxWidth: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalLeftDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells sx={{}}>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box sx={{ width: "40%" }}>
                  <TableContainer
                    style={{ overflowX: "auto", maxWidth: "100%" }}
                    sx={{}}>
                    <Table sx={{ width: "100%" }}>
                      <TableBody>
                        {Object.entries(HospitalRightDataOpen).map(
                          ([key, value]) => (
                            <TableRow key={key}>
                              <TableCells
                                component="th"
                                scope="row"
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  fontWeight: 700,
                                }}>
                                {key}
                              </TableCells>

                              <TableCells>{value}</TableCells>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
              <Stack
                sx={{
                  textAlign: "start",

                  marginTop: "25px",
                  paddingLeft: "10px",
                  marginBottom: "20px",
                  width: "100%",
                  gap: "10px",
                }}>
                <Label
                  variant="subtitle1"
                  sx={{ color: "rgba(70, 144, 255, 1)" }}>
                  Issue
                </Label>
                <Divider sx={{ bgcolor: "rgba(70, 144, 255, 1)" }} />
              </Stack>
              <Stack
                flexDirection={"row"}
                sx={{
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <Stack
                  sx={{
                    width: "50%",
                    gap: "10px",
                  }}>
                  <Stack
                    sx={{
                      width: "100%",

                      textAlign: "start",
                      minHeight: "170px",
                      background: "rgba(244, 246, 246, 1)",
                      padding: "20px",
                      marginLeft: "10px",
                    }}>
                    <Typography sx={{ marginTop: "10px" }}>
                      {serviceData?.issue}
                    </Typography>
                  </Stack>
                </Stack>
                {serviceData?.issueimgs !== "NA" &&
                  serviceData?.issueimgs !== "" && (
                    <Stack
                      display="flex"
                      flexDirection={"row"}
                      sx={{
                        width: "50%",

                        height: "170px",
                        overflowY: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                        overflowX: "auto",
                      }}>
                      {serviceData?.issueimgs?.map((images, index) => (
                        <Box
                          sx={{ height: "95%" }}
                          key={index}
                          position="relative"
                          display="inline-block">
                          <Box
                            as="img"
                            src={images}
                            alt="incImage"
                            style={{
                              height: "100%",
                              width: "140px",
                            }}
                          />
                          <Box position="absolute" bottom={-20} right={0} p={1}>
                            <IconButton style={{ cursor: "pointer" }}>
                              <DownloadIcon
                                onClick={() => handleDownload(images)}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Stack>
                  )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: "750px",
              height: "70px",
              padding: "20px",
              flexDirection: "column",
              alignItems: "flex-end",
            }}>
            <Button
              onClick={() => navigate(`/clear-service/${serviceData?.assetid}/${serviceData.serv_id}`)}
              variant="contained"
              sx={{ width: "150px", borderRadius: "22px" }}>
              Clear Service
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ServiceDetail;
