import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  MenuItem,
  Stack,
  Typography,
  styled,
  Tooltip,
  Select,
} from "@mui/material";
import { useState } from "react";
import { DownloadPDF, serviceReport } from "../redux/nbmData/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "20ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));


const TopperSet = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"stretch",
  gap:"10px",
    [theme.breakpoints.down("xl")]: {
    
    },
    [theme.breakpoints.down("lg")]: {
     
    },
    [theme.breakpoints.down("md")]: {
      flexDirection:'column'
    },
    [theme.breakpoints.down("sm")]: {
     
    },
    [theme.breakpoints.down("xs")]: {
     
    },
  }));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ReportPage = () => {
  const serviceData = useSelector((store) => store.nbmData?.serviceReport);
  console.log("serviceData", serviceData);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const token = localStorage.getItem("userToken");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("calibration");
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  console.log("data", data);
  const [report, setReport] = useState("service");
  console.log("report", report);
  const [show, setShow] = useState(false);

  const [getId, setGetId] = useState({});
  const [open, setopen] = useState(false);

  const handleChange = (e) => {
    setReport(e.target.value);
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess.departmentids,
    };
    setData([]);
    dispatch(serviceReport(data, e.target.value, token)); // Use e.target.value instead of report
  };
  useEffect(() => {
    handleChange({ target: { value: "service" } }); // Trigger handleChange with default value "service" when component mounts
    setShow(true); // Set show state to true to display the report
  }, []);
  useEffect(() => {
    let selectedData = [];
    switch (selectedOption) {
      case "services":
        selectedData = serviceData;
        break;
      case "incidents":
        selectedData = serviceData;
        break;
      case "calibrations":
        selectedData = serviceData;
        break;
      default:
        break;
    }
    setData(selectedData);
  }, [selectedOption, serviceData]);
  useEffect(() => {
    if (serviceData && serviceData?.length > 0) {
      setData(serviceData);
    }
  }, [serviceData]);
  useEffect(
    (e) => {
      let datas = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess.departmentids,
      };
      let report = "service";

      if (selectedOption === report) {
        dispatch(serviceReport(datas));
      }
    },
    [dispatch, selectedOption]
  );
  const handleClickPopper = (event, data) => {
    setGetId(data);
    setopen(true);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap="10px"
      height="85vh"
      padding="16px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="stretch"
        gap="10px"
        sx={{ display: { xs: "none", sm: "none", lg: "flex" } }}
        >
        {/* Box 1 */}
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              07 / 12 / 2023
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label variant="subtitle2">Next PM Check</Label>

              <ArrowRightAltIcon />
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              04
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label variant="subtitle2">Incident reports</Label>

              <ArrowRightAltIcon />
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              04
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label variant="subtitle2">Service reports</Label>

              <ArrowRightAltIcon />
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap="10px">
        <TopperSet
        >
          <Box flex="1">
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-around"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    textAlign: "left",
                    color: "rgba(70, 144, 255, 1)",
                  }}
                  onChange={handleChange}
                  displayEmpty
                  value={report}
                  MenuProps={MenuProps}>
                  <MenuItem value={"service"}>
                    <Typography variant="body1"> Service Report</Typography>
                  </MenuItem>
                  <MenuItem value={"incident"}>
                    <Typography variant="body1"> Incident Report</Typography>
                  </MenuItem>
                  <MenuItem value={"calibration"}>
                    <Typography variant="body1"> Calibration Report</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Box>
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              Filter1="department"
              Filter2=""
              FilterName1="Department"
              FilterName2=""
              sortDate="cleardate"
              dueDate=""
              name="assetname"
              csvName="All Assets"
            />
          </Box>
        </TopperSet>

        <Box flex="1" overflow="auto">
          {/* Table */}
          <TableContainer
            component={Paper}
            style={{
              height: "calc(73vh - 120px)",
              maxHeight: "calc(73vh - 120px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              width: "100%",
            }}>
            <Table stickyHeader>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableHeading>Asset Name</TableHeading>
                  <TableHeading>Asset Code</TableHeading>
                  <TableHeading>Serial no</TableHeading>
                  <TableHeading>Department</TableHeading>

                  <TableHeading> Date/Time</TableHeading>
                  <TableHeading style={{ textAlign: "center" }}>
                    Download
                  </TableHeading>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "scroll" }}>
                {filteredData?.map((item, index) => (
                  <TableRow
                    style={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 237, 237, 1)",
                      },
                    }}>
                    <TableCells>
                      <Tooltip title={item.assetname}>{item.assetname}</Tooltip>
                    </TableCells>

                    <TableCells>
                      <Tooltip title={item.assetcode}>{item.assetcode}</Tooltip>
                    </TableCells>
                    <TableCells>
                      <Tooltip title={item.serialno}>{item.serialno}</Tooltip>
                    </TableCells>
                    <TableCells>
                      <Tooltip title={item.department}>
                        {item.department}
                      </Tooltip>
                    </TableCells>

                    <TableCells sx={{}}>
                      {item?.cleardate}@{item?.cleartime}
                    </TableCells>
                    <TableCells
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={() =>
                        dispatch(DownloadPDF(item?.reporturl, item?.assetname))
                      }>
                      <PictureAsPdfIcon
                        size={20}
                        sx={{ color: "rgba(27, 37, 53, 1)" }}
                      />
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportPage;
