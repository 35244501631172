import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Stack,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";


import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuid } from "uuid";

import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import {
  assetDetailsData,
  clearIncident,
  clearIncidentImageRemove,
  clearIncidentImageUpload,
  getIncidentDetail,
  incidentIssue,
} from "../redux/nbmData/action";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "dayjs/locale/de";
import "dayjs/locale/en-gb";

import { DatePicker } from "@mui/x-date-pickers";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  height: "20px",
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const ClearIncident = () => {
  let incidentDetail = useSelector((store) => store.data.incidentDetails);
  let incidentIssues = useSelector((store) => store.data.incidentIssue);
  const detailData = useSelector((store) => store.data.assetDetails);
  const [description, setDescription] = useState("");
  const [isDescriptionError, setIsDescriptionError] = useState("");
  const [isAccessoryPhotoError, setIsAccessoryPhotoError] = useState("");
  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [isTotalAmountSpentError, setIsTotalAmountSpentError] = useState("");
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const response = useSelector((store) => store.data.clearIncident);
  const token = localStorage.getItem("userToken");
  const [fileUrlAccessory, setFileUrlAccessory] = useState([]);
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState([]);
  const [isPhotoError, setIsPhotoError] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [nameError, setNameError] = useState(null);
  const [isMobileNumberError, setIsMobileNumberError] = useState(null);
  const [isEmailError, setIsEmailError] = useState(null);
  const [isActionError, setIsActionError] = useState(null);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const uploadedAccessoryImageName = fileUrlAccessory?.map((el) => el?.imageid);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
 
  const [expenditures, setExpenditures] = useState([]);
  const [newExpenditure, setNewExpenditure] = useState({
    description: '',
    totalAmountSpent: '',
    expImage: [{ image: '', imageId: '' }]
  });
  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: `${"assetId_"}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(clearIncidentImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${"assetId_"}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: ids?.hospid,
                userid: ids?.userid,
                imageid: `${"assetId_"}` + uuid() + ".jpg",
                image: reader.result,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(clearIncidentImageUpload(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  
  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    localStorage.removeItem("incidentId");
    navigate(-1);
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;
    if (value === "") {
      setName(value);
      setNameError("Please enter a valid Name");
    } else if (value.trim() === "") {
      setName(value);
      setNameError("Invalid name");
    } else if (regex.test(value)) {
      setName(value);
      setNameError("");
    } else {
      setNameError("Invalid Name");
    }
  };

  const handleAction = (e) => {
    const value = e.target.value;
    const regex = /^[a-z 0-9,.'-]+$/i;

    if (value === "") {
      setAction(value);
      setIsActionError("Please enter a valid action");
    } else if (value.trim() === "") {
      setAction(value);
      setIsActionError("Invalid action");
    } else if (regex.test(value)) {
      setAction(value);
      setIsActionError("");
    } else {
      setIsActionError("Invalid action");
    }
  };
  const handleNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^\+?[0-9]{10}$/;

    if (value === "" || regex.test(value)) {
      setMobile(value);
      setIsMobileNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsMobileNumberError("wrong  Mobile number");
    }
    if (value === "") {
      setIsMobileNumberError("Please fill the field");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (value === "") {
      setEmail(value);
      setIsEmailError("Please enter a valid email");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setEmail(value);
      setIsEmailError(null);
    } else {
      setIsEmailError("Enter valid email");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch.id,
    };

    dispatch(getIncidentDetail(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch.id,
    };

    dispatch(incidentIssue(data, token));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: incidentDetail.assetid,
      branchid: userBranch.id,
    };
    dispatch(assetDetailsData(data, token));
  }, [dispatch]);

  const handleDelete = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    dispatch(clearIncidentImageRemove(data));
    setFileUrl(fileUrl?.filter((el) => el.imageid !== id));
  };

  const handleClicks = () => {
    const trimmedName = name.trim();
    const trimmedMobile = mobile.trim();

    const trimmedEmail = email.trim();
    const trimmedAction = action.trim();

    if (
      trimmedName === "" ||
      trimmedMobile === "" ||
      trimmedEmail === "" ||
      trimmedAction === ""
    ) {
      setNameError(trimmedName === "" ? "Please enter a valid Name" : null);
      setIsMobileNumberError(
        trimmedMobile === "" ? "Please enter a valid mobile number" : null
      );

      setIsEmailError(
        trimmedEmail === "" ? "Please enter a valid email" : null
      );

      setIsActionError(
        trimmedAction === "" ? "Please enter a valid action" : null
      );

      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }

    if (name !== "" && mobile !== "" && email !== "" && action !== "") {
      let datas = {
        hospid: userIds.hospid,
        username: userIds.username,
        userid: userIds.userid,
        userrole: userAccess.role,
        assetid: incidentDetail.assetid,
        incidid: incidentDetail.incidt_id,
        asset_name: detailData.name,
        assetcode: detailData.code,
        model_no: detailData.modelno,
        serial_no: detailData.serialno,
        department: detailData.dept,
        incid_startdate: incidentDetail.start_date,
        incid_starttime: incidentDetail.start_time,
        servicer_name: trimmedName,
        serviced_phone: trimmedMobile,
        serviced_email: trimmedEmail,
        action_taken: trimmedAction,
        serviced_feedback: remark.length > 0 ? remark.trim() : "NA",
        cleared_images:
          uploadedImageName?.length > 0 ? uploadedImageName : "NA",
        incid_issue: incidentIssues.incdt_issue,
        branchid: userBranch.id,


        expenditure: expenditures?.map((item)=> {return item.description}),
        amt_spent: expenditures?.map((item) => { return Number(item.totalAmountSpent) }),
        receipt_id: ["test.img"],
      


      };
      setOpenModal(true);
      setToggle(true);
      dispatch(clearIncident(datas, token));
    }
  };

  useEffect(() => {
    if (response === "Updated" && name !== "") {
      setOpenModal(true);
    }
  }, [response]);





  const handleAccessoryPhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsAccessoryPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsAccessoryPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: ids?.hospid,
            userid: ids?.userid,
            imageid: params.id + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch.id,
          };
          setFileUrlAccessory([...fileUrlAccessory, sendData]);

          // dispatch(serviceImageUpload(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };





  const handleDeleteAccessory = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch.id,
    };
    // dispatch(clearServiceImgDel(data));
    setFileUrlAccessory(fileUrlAccessory?.filter((el) => el?.imageid !== id));
  };


  const handleDescription = (e) => {
    const value = e.target.value.trim();
    const regex =  /^[a-zA-Z0-9.,'"\s-]*$/;
    if (value === "") {
      setDescription(value);
      setIsDescriptionError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setDescription(value);
      setIsDescriptionError(null);
    } else {
      setIsDescriptionError("Enter a valid description");
    }
  };



  const handleTotalAmountSpent = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]*$/;
    if (value === "") {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError("Please fill the field.");
    } else if (regex.test(value.replace(/\s/g, ""))) {
      setTotalAmountSpent(value);
      setIsTotalAmountSpentError(null);
    } else {
      setIsTotalAmountSpentError("Enter a valid amount");
    }
  };





const handleAddExpenditure = () => {
  setExpenditures([...expenditures, { ...newExpenditure }]);
  setNewExpenditure({
    description: '',
    totalAmountSpent: '',
    expImage: [{ image: '', imageId: '' }]
  });
};

const handleRemoveExpenditure = (index) => {
  const updatedExpenditures = expenditures.filter((_, i) => i !== index);
  setExpenditures(updatedExpenditures);
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setNewExpenditure({ ...newExpenditure, [name]: value });
};





const handleTotalAmountSpentObject = (e,index,) => {
  handleTotalAmountSpent(e)
let value = e.target.value
let edit = [...expenditures]
   edit[index].totalAmountSpent = value
setExpenditures([...edit]);
};




const handleDescriptionObject = (e,index,) => {
  handleDescription(e)
let value = e.target.value
let edit = [...expenditures]
   edit[index].description = value
setExpenditures([...edit]);
};









  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: "800px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label
            variant="subtitle1"
            color="primary"
            sx={{ paddingLeft: "20px" }}>
            Clear Incident
          </Label>
        </Stack>

        <Stack
          sx={{
            width: "100%",
            maxWidth: 800,
            margin: "auto",
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box sx={{ marginTop: 2, width: "90%" }}>
            <Stack sx={{ bgcolor: "white", gap: "30px" }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "start",
                  gap: "30px",
                }}>
                <Stack
                  sx={{
                    width: "50%",

                    gap: "15px",

                    height: "100%",
                    textAlign: "start",
                  }}>
                  <Label>Representative</Label>
                  <Stack>
                    <Field
                      label=" Name"
                      onChange={handleName}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">{nameError}</HelperText>
                  </Stack>
                  <Stack>
                    <Field
                      label=" Mobile Number"
                      onChange={handleNumber}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">
                      {isMobileNumberError}
                    </HelperText>
                  </Stack>

                  <Stack>
                    <Field
                      label="Email"
                      onChange={handleEmail}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">{isEmailError}</HelperText>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    width: "50%",

                    gap: "15px",

                    height: "100%",
                    textAlign: "start",
                  }}></Stack>
              </Stack>
              <Box sx={{ bgcolor: "white" }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "30px",
                  }}>
                  <Stack
                    sx={{
                      width: "50%",

                      gap: "15px",

                      height: "100%",
                      textAlign: "start",
                    }}>
                    <Stack sx={{ gap: "10px" }}>
                      <Label>Action Taken</Label>
                      <Field
                        multiline
                        rows={3}
                        label="Action Taken"
                        onChange={handleAction}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        sx={{ width: "100%" }}
                      />
                      {<HelperText>{isActionError}</HelperText>}
                    </Stack>
                    <Stack sx={{ gap: "10px" }}>
                      <Label>Remark</Label>
                      <Field
                        multiline
                        rows={3}
                        label="Remark"
                        onChange={(e) => setRemark(e.target.value)}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        sx={{ width: "100%" }}
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      width: "50%",

                      gap: "3px",
                      // justifyContent: "space-between",

                      height: "100%",
                      textAlign: "start",
                    }}>
                    <Stack
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",

                        flexDirection: "row",
                      }}>
                      <Label>Upload Images</Label>
                      {uploadedImageName?.length < 3 && (
                        <>
                          <label for="img">
                            <FileUploadIcon
                              size={20}
                              style={{
                                color: "rgba(27, 37, 53, 1)",
                                cursor: "pointer",
                              }}
                            />
                          </label>

                          <input
                            type="file"
                            name="uploadfile"
                            id="img"
                            style={{
                              display: "none",
                              width: "42px",
                              height: "42px",
                            }}
                            onChange={handlePhotoUpload}
                          />
                        </>
                      )}
                    </Stack>

                    <Stack
                      sx={{
                        height: "93px",
                        width: "342px",
                        flexDirection: "row",
                        overflowX: "auto",
                        overflowY: "hidden",
                        gap: "20px",
                        alignItems: "center",
                        background: "rgba(244, 246, 246, 1)",
                        paddingLeft: "20px",
                      }}>
                      {fileUrl?.map((item, index) => (
                        <Badge
                          key={index}
                          badgeContent={
                            <CloseIcon
                              size={15}
                              style={{
                                color: " rgba(255, 58, 68, 1)",
                              }}
                              onClick={() => handleDelete(item?.imageid)}
                            />
                          }
                          sx={{
                            // height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "10px",

                            padding: "5px",
                          }}>
                          <Box
                            as="img"
                            src={item?.image}
                            alt=""
                            sx={{ width: "50px", height: "50px" }}
                          />
                        </Badge>
                      ))}
                    </Stack>
                    {isPhotoError && (
                      <HelperText sx={{ color: "red" }}>
                        {isPhotoError}
                      </HelperText>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Stack>




            <Stack sx={{width:'100%', flexDirection:'row',
            paddingTop:'10px',
            paddingBottom:'10px',
               alignItems:'center', justifyContent:'space-between'}}>
<Label style={{color:'#4690FF', textAlign:'left'}}>
Expenditure
</Label>

<Button onClick={handleAddExpenditure} variant="outlined" sx={{borderRadius:'25px'}}>
Add Expenditure
</Button>
</Stack>


           { expenditures?.map((item, index)=>(
 <Stack
 sx={{
   display: "flex",
   gap: "30px",
 }}>
<Stack
 sx={{
   justifyContent: "space-between",
   flexDirection: "row",
   gap: "30px",
 }}>




 <Stack
   sx={{
     gap: "10px",
  
     width: "50%",
     textAlign: "start",
   }}>
      <Field
     value={item.description}
     placeholder="Reason description"
     onChange={(e)=>handleDescriptionObject(e,index)}
     variant="outlined"
     sx={{ width: "100%" }}
   />
   <HelperText>
    {isDescriptionError}
   </HelperText>
 </Stack>


 <Stack
                sx={{
                  gap: "10px",
                  width: "50%",
                  textAlign: "start",
                }}>
                   <Field
                  value={item.totalAmountSpent}
                  placeholder="Total amoutnt spent"
                  onChange={(e)=>handleTotalAmountSpentObject(e,index)}
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <HelperText>
    {isTotalAmountSpentError}
   </HelperText>
              </Stack>
              
              


</Stack>







<Stack
              sx={{
                justifyContent: "space-between",
                flexDirection: "row",
                gap: "30px",
              }}>
      
   
            
<Stack
                sx={{
                  width: "50%",

                  gap: "3px",
                  // justifyContent: "space-between",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Stack
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",

                    flexDirection: "row",
                  }}>
                  <Label>Upload Receipt (Png, jpeg or pdf)</Label>
                  {uploadedAccessoryImageName?.length < 3 && (
                    <>
                    
                      <label for="img2">
                        <FileUploadIcon
                          size={20}
                          style={{
                            color: "rgba(27, 37, 53, 1)",
                            cursor: "pointer",
                          }}
                        />
                      </label>

                      <input
                        type="file"
                        name="uploadfile"
                        id="img2"
                        style={{
                          display: "none",
                          width: "42px",
                          height: "42px",
                        }}
                        onChange={handleAccessoryPhotoUpload}
                      />
                    </>
                  )}
                </Stack>

                <Stack
                  sx={{
                    height: "93px",
                    width: "342px",
                    flexDirection: "row",
                    overflowX: "auto",
                    overflowY: "hidden",
                    gap: "20px",
                    alignItems: "center",
                    background: "rgba(244, 246, 246, 1)",
                    paddingLeft: "20px",
                  }}>
             {console.log("fileUrlAccessoryfileUrlAccessory",fileUrlAccessory)}
                  {fileUrlAccessory?.map((item, index) => (
                    <Badge
                      key={index}
                      badgeContent={
                        <CloseIcon
                          size={15}
                          style={{
                            color: " rgba(255, 58, 68, 1)",
                          }}
                          onClick={() => handleDeleteAccessory(item?.imageid)}
                        />
                      }
                      sx={{
                        // height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",

                        padding: "5px",
                      }}>
                        
                      <Box
                        as="img"
                        src={item?.image}
                        alt="acc-image"
                        sx={{ width: "50px", height: "50px" }}
                      />
                    </Badge>
                  ))}
                </Stack>
                {isAccessoryPhotoError && (
                  <HelperText sx={{ color: "red" }}>{isAccessoryPhotoError}</HelperText>
                )}
              </Stack>
          
</Stack>

<Stack
              sx={{
                justifyContent: "end",
                flexDirection: "row",
                gap: "30px",
              }}>


<Button onClick={()=>handleRemoveExpenditure(index)} variant="outlined" sx={{borderRadius:'25px'}}>
Delete Expenditure
</Button>


              </Stack>








<Divider/>





</Stack>





           ))}



            <>
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}>
                <Divider />
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "16px",
                    }}>
                    <Stack
                      sx={{
                        flexShrink: 1,
                        width: "100%",
                        alignItems: "flex-end",
                        gap: "20px",
                        flexDirection: "row",
                        justifyContent: "end",
                        padding: "20px",
                      }}>
                      <Button
                        onClick={handleClicks}
                        variant="contained"
                        sx={{
                          width: "154px",
                          borderRadius: "22px",
                          bgcolor: "rgba(70, 144, 255, 1)",
                        }}>
                        Submit
                      </Button>
                    </Stack>
                  </Box>
                </>
              </Stack>
            </>
          </Box>
        </Stack>
      </Stack>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"Your request has been successfully processed.  "}
        subText2={"  "}
      />
    </Stack>
  );
};

export default ClearIncident;
