import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  styled,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import { useEffect } from "react";
import { profile } from "../redux/nbmData/action";
import EditIcon from '@mui/icons-material/Edit';
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const ProfileDetail = () => {
  const navigate = useNavigate();
  const profileData = useSelector((store) => store?.nbmData?.profile);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
    };

    dispatch(profile(data));
  }, [dispatch]);

  const HospitalLeftData = {
    "Hospital Name": profileData.hospname,
    "Hospital Type": profileData.hosptype,
    Address: profileData.address,
  };
  const HospitalRightData = {
    City: profileData.city,
    State: profileData.state,
    Zip: profileData.zip,
  };
  const UserLeftData = {
    Username: profileData.username,
    Email: profileData.email,
  };
  const UserRightData = {
    Designation: profileData.designate,
    "Whatsapp No": profileData.mobile,
  };
  const SubscriptionData = {
    "Subscription Plan": userIds.subs_plan,
    "Subscription Date": userIds.subs_date,
    "Subscription Expiry": userIds.subs_expdate,
  };
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: "750px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "10px 20px 10px 20px",
          }}>
          <Label variant="subtitle1" sx={{ color: "rgba(70, 144, 255, 1)" }}>
            {profileData.hospname}
          </Label>

          {(userAccess?.role === "Super_Admin" ||
            userAccess?.role === "Owner") && (
            <>
              <IconButton onClick={() => navigate("/edit-profile")}>
                <EditIcon style={{ color: "rgba(70, 144, 255, 1)" }} />
              </IconButton>
            </>
          )}
        </Stack>

        <Stack sx={{ bgcolor: "white", padding: "20px" }}>
          <Stack
            sx={{
              textAlign: "start",

              marginTop: "25px",
              paddingLeft: "10px",
              marginBottom: "20px",
              width: "100%",
              gap: "10px",
            }}>
            <Label variant="subtitle1">Hospital Details</Label>
            <Divider />
          </Stack>
          <Stack
            flexDirection={"row"}
            sx={{
              gap: "20px",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <Box sx={{ width: "60%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}>
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(HospitalLeftData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}>
                          {key}
                        </TableCells>

                        <TableCells sx={{}}>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ width: "40%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}>
                <Table sx={{ width: "100%" }}>
                  <TableBody>
                    {Object.entries(HospitalRightData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}>
                          {key}
                        </TableCells>

                        <TableCells>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>

          <Stack
            sx={{
              textAlign: "start",

              marginTop: "25px",
              paddingLeft: "10px",
              marginBottom: "20px",
              width: "100%",
              gap: "10px",
            }}>
            <Label variant="subtitle1">User Details</Label>
            <Divider />
          </Stack>
          <Stack
            flexDirection={"row"}
            sx={{
              gap: "20px",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <Box sx={{ width: "60%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}>
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(UserLeftData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}>
                          {key}
                        </TableCells>

                        <TableCells sx={{}}>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ width: "40%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}>
                <Table sx={{ width: "100%" }}>
                  <TableBody>
                    {Object.entries(UserRightData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}>
                          {key}
                        </TableCells>

                        <TableCells>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>

          <Stack
            sx={{
              textAlign: "start",

              marginTop: "25px",
              paddingLeft: "10px",
              marginBottom: "20px",
              width: "100%",
              gap: "10px",
            }}>
            <Label variant="subtitle1">Subscription Details</Label>
            <Divider />
          </Stack>

          <Stack
            flexDirection={"row"}
            sx={{
              gap: "20px",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <Box sx={{ width: "60%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}>
                <Table sx={{ maxWidth: "100%" }}>
                  <TableBody>
                    {Object.entries(SubscriptionData).map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCells
                          component="th"
                          scope="row"
                          sx={{ color: "rgba(27, 37, 53, 1)" }}>
                          {key}
                        </TableCells>

                        <TableCells sx={{}}>{value}</TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ width: "40%" }}>
              <TableContainer
                style={{ overflowX: "auto", maxWidth: "100%" }}
                sx={{}}>
                <Table sx={{ width: "100%" }}>
                  <TableBody>
                    <TableRow>
                      <TableCells
                        component="th"
                        scope="row"
                        sx={{ color: "rgba(27, 37, 53, 1)" }}></TableCells>

                      <TableCells></TableCells>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileDetail;
