import {
  Box,
  Card,
  CardActions,
  CardMedia,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";



const MainWrapper = styled(Box)(({ theme }) => ({

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ImageCard = styled(CardMedia)(({ theme }) => ({
  width: "350px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Wrapper = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  padding: "2%",
  flexDirection: "column",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    height: "700px",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",

    height: "1180px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    height: "900px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  // marginTop: "2%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  borderRadius: "15px",

  background: "white",
  [theme.breakpoints.down("xl")]: {
    width: "100%",

    // border: "1px solid  black",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    height: "600px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "980px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "655px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const Cards = styled(Card)(({ theme }) => ({
  width: "688px",
  height: "351px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {
    width: "80%",
    height: "400px",
    fontSize: "16px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
    // border: "1px solid orange",
    marginTop: "2%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "2%",

    fontSize: "18px",

    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TaskEmpty = () => {
  return (

      <MainWrapper>
        <Wrapper sx={{}}>
          <BoxWrapper
           
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "15px ",

              alignItems: "center",
            }}>
            <Cards sx={{ gap: "20px" }}>
             
              <Typography
                sx={{
                 
                  width: "90%",
                  fontWeight: 500,
                  fontSize: "20px",
                }}
                color="text.secondary"
                gutterBottom>
                No tasks currently available to display.
              </Typography>
            </Cards>
          </BoxWrapper>
        </Wrapper>
      </MainWrapper>

  );
};

export default TaskEmpty;
