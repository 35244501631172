import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Divider, Stack, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import DoneIcon from '@mui/icons-material/Done';
import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
const style = {
  bgcolor: "background.paper",
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "300px",
}));

export default function Confirmation({
  handleCloseModal,
  open,
  text,
  handleDone
}) {

  return (
    <div style={{}}>
      <Modal
        keepMounted
        open={open}
      
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
            }}>
            <IconButton style={{ color: "red" }}>
              <CloseIcon sx={{fontSize:'80px'}} />
            </IconButton>
            <Typography variant="h6" style={{ fontWeight: 700, padding:'10px' }}>
              {text}
            </Typography>

            <Stack sx={{ width: "90%" }}>
              <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
            </Stack>


            <Box sx={{ width: "90%", display:'flex', gap:'20px', justifyContent:'center' }}>
            <Button
              size="small"
              variant="contained"
              onClick={handleCloseModal}
              sx={{
                borderRadius: "35px",

                textTransform: "none",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}>
              Cancel
            </Button>



            <Button
              size="small"
              variant="contained"
              onClick={handleDone}
              sx={{
                borderRadius: "35px",

                textTransform: "none",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}>
              Delete
            </Button>
            </Box>
           
          </Stack>
        </ModalBox>
      </Modal>
    </div>
  );
}
