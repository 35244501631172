import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import moment from "moment";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";
import pdfImage from "../images/pdfImage.png";
import { useDispatch } from "react-redux";
import {
  CreateQRCode,
  addAsset,
  deleteImage,
  getDepartmentData,
  uploadImage,
  uploadQrCodeImage,
  uploadedCertificate,
} from "../redux/nbmData/action";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddReport from "./AddReport";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import AddAssetModal from "../components/AddAssetModal";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const BlueStepper = styled(Stepper)(({ theme }) => ({
  ".MuiStepConnector-active": {
    "& > .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
      width: "700px",
    },
  },
  "& .MuiStepConnector-line": {
    borderLeftWidth: "558px",
  },
}));

const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",

  height: "80px",
  "& fieldset": {
    border: "none",
  },
}));

const BottomElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
}));

const HalfInput2 = styled(DatePicker)(({ theme }) => ({
  width: "145px",
  padding: 0,
  background: "white",
}));
const CheckBoxContainer = styled(Box)(({ theme }) => ({
  minHeight: "50px",
}));

const FormRadio = styled(FormControl)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  bgcolor: "red",
}));

const DateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
}));

const DueDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const YesDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "center",
}));

const CustomText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  textAlign: "left",
  fontWeight: "400px",
  color: "rgba(166, 166, 166, 1)",
}));

const AddAssetPage = () => {
  let assetId = localStorage.getItem("assetId") || "";
  let idSplit = assetId.substring(0, 4);
  const [toggle, setToggle] = useState(false);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const token = localStorage.getItem("userToken");
  const [locale, setLocale] = React.useState("en-gb");
  const qrCode = useSelector((store) => store.data.createQrCode) || [];
  const [assetReport, setAssetReport] = useState([]);
 
  const [calibrationStartDate, setCalibrationStartDate] = useState("");
  const [calibrationEndDate, setCalibrationEndDate] = useState("");
  const handleAssetReport = (data) => {
    setAssetReport(data);
  };

  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const departmentData = useSelector((store) => store.data.department) || [];
  const status = useSelector((store) => store.data.addAsset);
  const [department, setDepartment] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  const [certificateFlag, setCertificateFlag] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState([]);
  const [name, setName] = useState([]);

  const [assetName, setAssetName] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [brand, setBrand] = useState("");
  const [usefullLife, setUsefullLife] = useState("1");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [soldBy, setSoldBy] = useState("");
  const [price, setPrice] = useState("");
  const [assetType, setAssetType] = useState("");
  const [assetStatus, setAssetStatus] = useState("");
  const [calibrationStatus, setCalibrationStatus] = useState("");
  const [cmcAmc, setCmcAmc] = useState("");
  const [cmcAmcStartDate, setCmcAmcStartDate] = useState("");
  const [cmcAmcEndDate, setCmcAmcEndDate] = useState("");
  const [calibrationDate, setCalibrationDate] = useState("");
  const [required, setRequired] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [notes, setNotes] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [isAssetError, setIsAssetError] = useState(null);
  const [isSerialNumberError, setIsSerialNumberError] = useState(null);
  const [isUsefullLifeError, setIsUsefullLifeError] = useState(null);
  const [isModelNumberError, setIsModelNumberError] = useState(null);
  const [isBrandError, setIsBrandError] = useState(null);
  const [isSoldByError, setIsSoldByError] = useState(null);
  const [isWarrantyError, setIsWarrantyError] = useState(null);
  const [isPriceError, setIsPriceError] = useState(null);

  const [warrantyStartDate, setWarrantyStartDate] = useState("");
  const [warrantyEndDate, setWarrantyEndDate] = useState("");

  const [phone, setPhone] = useState("");

  const [isPhoneError, setIsPhoneError] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(null);

  const [isDueDateError, setIsDueDateError] = useState(null);
  const [isAssetCodeError, setIsAssetCodeError] = useState(null);

  const [isAmcEndDateError, setIsAmcEndDateError] = useState(null);
  const [isCmcEndDateError, setIsCmcEndDateError] = useState(null);

  const [isAssetTypeError, setIsAssetTypeError] = useState(null);
  const [isAssetStatusError, setIsAssetStatusError] = useState(null);
  const [isCalibrationStatusError, setIsCalibrationStatusError] =
    useState(null);
  const [isCmcAmcError, setIsCmcAmcError] = useState(null);
  const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);
  const [isRequiredError, setIsRequiredError] = useState(null);
  const [isDepartmentError, setIsDepartmentError] = useState(null);
  const [isDateOfPurchaseError, setIsDateOfPurchaseError] = useState(null);
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [isCertificateError, setIsCertificateError] = useState(null);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  const uploadedCertificateName = certificateUrl?.map((el) => el?.imageid);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${idSplit}_` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(uploadImage(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}_` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}_` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(uploadImage(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };
  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    dispatch(deleteImage(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };

  const handleCertificateUpload = (e) => {
    const singlefile = e.target.files[0];
    const splitId = assetId.substring(0, 4);
    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsCertificateError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImageCertificate(file);
      } else {
        setIsCertificateError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let checkData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${splitId}_` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setCertificateUrl([...certificateUrl, checkData]);
          dispatch(uploadedCertificate(checkData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImageCertificate = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}_` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let checkData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}_` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setCertificateUrl([...certificateUrl, checkData]);
              dispatch(uploadedCertificate(checkData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };

  const removeCertificate = (id) => {
    setCertificateUrl(certificateUrl?.filter((item) => item?.imageid !== id));
  };

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  const handleAssetName = (e) => {
    const value = e.target.value.trim();
    const regex = /^[a-zA-Z0-9\s-]+$/;

    if (value === "" || regex.test(value)) {
      setAssetName(value);
      setIsAssetError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetError("wrong asset Name");
    }
    if (value === "") {
      setIsAssetError("Please fill the field");
    }
  };

  const handleAssetCode = (e) => {
    const value = e.target.value.trim();
    if (value !== "") {
      setAssetCode(value);
      setIsAssetCodeError(null);
    }
    if (value === "") {
      setIsAssetCodeError("Please fill the field");
    }
  };

  const handleSerialNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^[A-Za-z0-9 -]+$/;
    setSerialNumber(value);
    if (regex.test(value) || value === "") {
      setSerialNumber(value);
      setIsSerialNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsSerialNumberError("wrong serial number");
    }
  };

  const handleModelNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^[A-Za-z0-9 -]+$/;
    if (regex.test(value) || value === "") {
      setModelNumber(value);
      setIsModelNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsModelNumberError("wrong model number");
    }
  };

  const handleBrand = (e) => {
    const value = e.target.value.trim();
    const regex = /^[A-Za-z0-9/-\s]+$/;
    if (value === "" || regex.test(value)) {
      setBrand(value);
      setIsBrandError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsBrandError("wrong brand Name");
    }
  };

  const handleSoldBy = (e) => {
    const value = e.target.value.trim();
    const regex = /^[A-Za-z\s]+$/;

    if (value === "" || regex.test(value)) {
      setSoldBy(value);
      setIsSoldByError(null);

      if (phone === "") {
        setIsPhoneError("Please fill the field");
      }
      if (email === "") {
        setIsEmailError("Please fill the field");
      }
    }
    if (value !== "" && !regex.test(value)) {
      setIsSoldByError("Enter valid details");
    }
    if (value === "") {
      setIsPhoneError(null);
      setIsEmailError(null);
      setEmail("");
      setPhone("");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

    if (value === "" || regex.test(value)) {
      setEmail(value);
      setIsEmailError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsEmailError("Enter valid email");
    }
    if (value === "") {
      setIsEmailError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d{10}$/;

    if (value === "" || regex.test(value)) {
      setPhone(value);
      setIsPhoneError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPhoneError("Enter valid Phone Number");
    }
    if (value === "") {
      setIsPhoneError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]+$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  const handleUsefulLife = (e) => {
    const value = e.target.value.trim();
    const regex = /^[0-9]+$/;
    setUsefullLife(value);
    if (value === "" || regex.test(value)) {
      setUsefullLife(value);
      setIsUsefullLifeError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsUsefullLifeError("Enter valid number");
    }
    if (value === "") {
      setIsUsefullLifeError(null);
      setIsPriceError(null);
      setIsDateOfPurchaseError(null);
    }
    if (value !== "" && price === "") {
      setIsPriceError("Please fill the field");
    }
    if (value !== "" && dateOfPurchase === "") {
      setIsDateOfPurchaseError("Please fill the field");
    }
  };

  const calibrationValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsCalibrationDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsCalibrationDateError(null);
      setIsCalibrationDateError(null);
    }
  };

  const handleCalibrationStartDate = (date) => {
    setCalibrationStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    calibrationValidateDateRange(date, calibrationEndDate);
  };

  const handleCalibrationEndDate = (date) => {
    setCalibrationEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    calibrationValidateDateRange(calibrationStartDate, date);
  };

  const warrantyValidDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsWarrantyError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsWarrantyError(null);
    }
  };

  const handleWarrantyStartDate = (date) => {
    warrantyValidDateRange(date, warrantyEndDate);
    setWarrantyStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    if (date && warrantyEndDate === "") {
      setIsWarrantyError("Please fill end date");
    }
  };

  const handleWarrantyEndDate = (date) => {
    setWarrantyEndDate(date && dayjs(date).format("YYYY-MM-DD"));

    warrantyValidDateRange(warrantyStartDate, date);

    if (date && warrantyStartDate === "") {
      setIsWarrantyError("Please fill start date");
    }
  };

  const handleNotes = (e) => {
    const value = e.target.value.trim();
    setNotes(value);
  };

  const handleDepartment = (event, newValue) => {
    setDepartment(newValue);
    dispatch(CreateQRCode(assetId));
    const selectedDepartment = departmentData?.find(
      (item) => item?.departname === newValue
    );

    if (selectedDepartment) {
      setDepartmentId(selectedDepartment?.departid);
      setIsDepartmentError(null);
    } else {
      setDepartmentId("");
    }
  };

  const handleAmcCmcStartDateChange = (date) => {
    setCmcAmcStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(date, cmcAmcEndDate);
  };

  const handleAmcCmcEndDateChange = (date) => {
    setCmcAmcEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(cmcAmcStartDate, date);
  };

  const amcValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsAmcEndDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsAmcEndDateError(null);
      setIsCmcEndDateError(null);
    }
  };

  const handleAmcCmc = (e) => {
    const value = e.target.value;
    setCmcAmc(value);
    if (value === "None") {
      setIsCmcEndDateError(null);
      setIsAmcEndDateError(null);
      setCmcAmcEndDate("");
      setCmcAmcStartDate("");
    }
    if (value === "AMC") {
      setIsCmcEndDateError("Please enter the date");
      setIsAmcEndDateError(null);
    }
    if (value === "CMC") {
      setIsAmcEndDateError("Please enter the date");
      setIsCmcEndDateError(null);
    }
    if (value !== "") {
      setIsCmcAmcError(null);
    }
  };

  const handleCalibrationStatus = (e) => {
    let value = e.target.value;
    setCalibrationStatus(value);
    if (value === "No") {
      setCalibrationDate("");
      // setDueDate("");
      setIsRequiredError("");
      // setIsCalibrationDateError(null);
      setIsCalibrationDateError(null);
    }
    if (value === "Yes") {
      setRequired("NA");
      setIsRequiredError(null);
      setCalibrationStartDate("");
      setCalibrationEndDate("");
      setIsCalibrationDateError("Please enter the date");
    }
    if (value !== "") {
      setIsCalibrationStatusError(null);
    }
  };

  useEffect(() => {
    let Id = uuid();
    localStorage.setItem("assetId", Id);
  }, []);

  const handleAssetStatus = (e) => {
    const value = e.target.value;
    setAssetStatus(value);
    if (value !== "") {
      setIsAssetStatusError(null);
    }
  };

  const handleAssetType = (e) => {
    const value = e.target.value;
    setAssetType(value);
    if (value !== "") {
      setIsAssetTypeError(null);
    }
  };

  const handlePurchaseDate = (date) => {
    setDateOfPurchase(date && dayjs(date).format("YYYY-MM-DD"));
    if (date !== "") {
      setIsDateOfPurchaseError(null);
    }
    setDateOfPurchase(date && dayjs(date).format("YYYY-MM-DD"));
  };

  const handleCalibrationDate = (e) => {
    let value = e.target.value;
    setCalibrationDate(value);
    if (value !== "") {
      setIsCalibrationDateError(null);
    }
  };

  const handleRequired = (e) => {
    let value = e.target.value;
    setRequired(value);
    setIsRequiredError(null);
    setCalibrationStartDate("");
    setCalibrationEndDate("");
  };



  const handleSubmit = () => {
    assetName === "" && setIsAssetError("Please fill the field");
    (department === "" || department === null) &&
      setIsDepartmentError("Please fill the field");
    assetType === "" && setIsAssetTypeError("Please fill the field");
    assetStatus === "" && setIsAssetStatusError("Please fill the field");
    calibrationStatus === "" &&
      setIsCalibrationStatusError("Please fill the field");
    cmcAmc === "" && setIsCmcAmcError("Please fill the field");
    assetCode === "" && setIsAssetCodeError("Please fill the field");
    // dateOfPurchase === "" && setIsDateOfPurchaseError("Please fill the field");
    fileUrl?.length <= 0 && setIsPhotoError("Please add an image");
    if (calibrationStatus === "Yes") {
      ((calibrationStartDate === "NA" || calibrationStartDate === "") &&
        setIsCalibrationDateError("Please enter the date")) ||
        ((calibrationEndDate === "NA" || calibrationEndDate === "") &&
          setIsCalibrationDateError("Please enter the date"));
    }
    if (calibrationStatus === "No") {
      (required === "NA" || required === "") &&
        setIsRequiredError("Please fill the field");
    }

    if (cmcAmc === "AMC" || cmcAmc === "CMC") {
      (cmcAmcEndDate === "" ||
        cmcAmcEndDate === "NA" ||
        cmcAmcStartDate === "" ||
        cmcAmcStartDate === "NA") &&
        setIsCmcEndDateError("Please fill this field");
    }

    if (
      assetName === "" ||
      department === "" ||
      department === null ||
      assetType === "" ||
      assetStatus === "" ||
      calibrationStatus === "" ||
      cmcAmc === "" ||
      fileUrl.length === 0 ||
      assetCode === ""
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }

    if (
      assetName !== "" &&
      department !== "" &&
      department !== null &&
      assetType !== "" &&
      assetStatus !== "" &&
      calibrationStatus !== "" &&
      cmcAmc !== "" &&
      fileUrl.length !== 0 &&
      assetCode !== ""
    ) {
      if (
        isAssetError === null &&
        isSerialNumberError === null &&
        isModelNumberError === null &&
        isPhoneError === null &&
        isEmailError === null &&
        isWarrantyError === null &&
        isDateOfPurchaseError === null &&
        isPriceError === null &&
        isAmcEndDateError === null &&
        isCmcEndDateError === null &&
        isAssetTypeError === null &&
        isAssetStatusError === null &&
        isCalibrationStatusError === null &&
        isCmcAmcError === null &&
        isUsefullLifeError === null &&
        isCalibrationDateError === null &&
        isRequiredError === null &&
        isPhotoError === null &&
        isCertificateError === null &&
        isAssetCodeError === null
      ) {
        const splitId = assetId.substring(0, 4);
        let dataQr = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          imageid: `${splitId}_ + uuid() + .${"png"}`,
          image: qrCode,
          branchid: userBranch?.id,
        };

        dispatch(uploadQrCodeImage(dataQr));

        let data = {
          assetid: assetId,
          hospid: userIds?.hospid,
          branchname: userBranch.branch,
          hospname: userIds?.hospname,
          userid: userIds?.userid,
          username: userIds?.username,
          assetname: assetName.trim(),
          serialno: serialNumber === "" ? "NA" : serialNumber.trim(),
          assetcode: assetCode === "" ? "NA" : assetCode.trim(),
          modelno: modelNumber === "" ? "NA" : modelNumber.trim(),
          department: department,
          department_id: departmentId.trim(),
          assettype: assetType.trim(),
          assetstatus: assetStatus.trim(),
          branchid: userBranch.id,
          brand: brand === "" ? "NA" : brand.trim(),
          sellermobile: phone === "" ? "NA" : phone,
          selleremail: email === "" ? "NA" : email,
          lifeyrs: usefullLife === "" ? "1" : usefullLife.trim(),
          price: price === "" ? "NA" : price.trim(),
          purchdate:
            dateOfPurchase === ""
              ? "NA"
              : moment(dateOfPurchase).format("DD-MM-YYYY"),
          warntdate:
            warrantyStartDate === ""
              ? "NA"
              : moment(warrantyStartDate).format("DD-MM-YYYY"),
          wrntymonth: "1",
          expdate:
            warrantyEndDate === "" || warrantyEndDate === "NA"
              ? "NA"
              : moment(warrantyEndDate).format("DD-MM-YYYY"),
          sellername: soldBy === "" ? "NA" : soldBy.trim(),
          prodimgs:
            fileUrl?.length > 0
              ? fileUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          wrntyimage:
            certificateUrl?.length > 0
              ? certificateUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          qrcodeimg: dataQr?.imageid,
          calibrate_status: calibrationStatus,
          calibrate_date:
            calibrationStartDate === ""
              ? "NA"
              : moment(calibrationStartDate).format("DD-MM-YYYY"),
          calibrate_req: required === "" ? "Na" : required,
          calibrate_duemonth: "1",
          calibrate_duedate:
            calibrationEndDate === ""
              ? "NA"
              : moment(calibrationEndDate)
                  .add(dueDate, "months")
                  .subtract(1, "days")
                  .format("DD-MM-YYYY"),
          cmcamc: cmcAmc,
          cmcamc_start:
            cmcAmcStartDate === ""
              ? "NA"
              : moment(cmcAmcStartDate).format("DD-MM-YYYY"),
          cmcamc_due:
            cmcAmcEndDate === ""
              ? "NA"
              : moment(cmcAmcEndDate).format("DD-MM-YYYY"),
          calibformat:
            calibrationStartDate === ""
              ? "NA"
              : moment(calibrationStartDate).format("YYYY-MM-DD"),
          purdateformat:
            dateOfPurchase === ""
              ? "NA"
              : moment(dateOfPurchase).format("YYYY-MM-DD"),
          notes: notes === "" ? "NA" : notes.trim(),
          report_names:
            assetReport?.length === 0
              ? []
              : assetReport?.map((item) => {
                  return item.report_name;
                }),
          report_ids:
            assetReport?.length === 0
              ? []
              : assetReport?.map((item) => {
                  return item.imageid;
                }),
        };
        dispatch(addAsset(data));

        setToggle(true);
        setOpenModal(true);
      }
    }
  };

  const handleCertificateFlag = () => {
    setCertificateFlag(!certificateFlag);

    setIsCertificateError(null);
  };

  const handleImageFlag = () => {
    setPhotoFlag(!photoFlag);

    setIsPhotoError(null);
  };

  const [activeStep, setActiveStep] = useState(0);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setToggle(false);
    navigate("/all-assets");
  };
  const handleNext = () => {
    assetName === "" && setIsAssetError("Please fill the field");
    (department === "" || department === null) &&
      setIsDepartmentError("Please fill the field");
    assetCode === "" && setIsAssetCodeError("Please fill the field");
    fileUrl?.length <= 0 && setIsPhotoError("Please add an image");
    if (
      assetName !== "" &&
      department !== "" &&
      department !== null &&
      assetCode !== "" &&
      fileUrl.length !== 0
    ) {
      if (
        isAssetError === null &&
        isSerialNumberError === null &&
        isModelNumberError === null &&
        isPhoneError === null &&
        isEmailError === null &&
        isWarrantyError === null &&
        isDateOfPurchaseError === null &&
        isPriceError === null &&
        isAmcEndDateError === null &&
        isCmcEndDateError === null &&
        isAssetTypeError === null &&
        isAssetStatusError === null &&
        isUsefullLifeError === null &&
        isAssetCodeError === null &&
        isPhotoError === null
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ bgcolor: "white" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "space-between",
                alignItems: "start",
                gap: "30px",
              }}>
              <Stack
                sx={{
                  width: "50%",

                  gap: "15px",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Stack>
                  <Field
                    name="destination"
                    label="Asset Name"
                    onChange={handleAssetName}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isAssetError}</HelperText>
                </Stack>
                <Stack>
                  <Autocomplete
                    disabled={departmentData?.length <= 0}
                    sx={{ bgcolor: "#EFF5FE" }}
                    value={department}
                    options={departmentData?.map((option) => option.departname)}
                    onChange={handleDepartment}
                    renderInput={(params) => (
                      <Field
                        placeholder="e.g. Emergency Department"
                        {...params}
                      />
                    )}
                  />

                  <HelperText variant="caption">{isDepartmentError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    label="Asset Code"
                    onChange={handleAssetCode}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isAssetCodeError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    label="Serial No"
                    onChange={handleSerialNumber}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">
                    {isSerialNumberError}
                  </HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    label="Useful Life"
                    onChange={handleUsefulLife}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">
                    {isUsefullLifeError}
                  </HelperText>
                </Stack>

                <Stack sx={{}}>
                  <Field
                    label="Seller Name"
                    onChange={handleSoldBy}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isSoldByError}</HelperText>
                </Stack>
                {soldBy && (
                  <>
                    <Stack>
                      <Field
                        name="destination"
                        label="Seller Email"
                        onChange={handleEmail}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />

                      <HelperText variant="caption">{isEmailError}</HelperText>
                    </Stack>
                    <Stack>
                      <Field
                        name="destination"
                        label="Seller Phone"
                        onChange={handlePhone}
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                      />

                      <HelperText variant="caption">{isPhoneError}</HelperText>
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack
                sx={{
                  width: "50%",
                  height: "100%",

                  gap: "15px",
                  textAlign: "start",
                }}>
                <Stack>
                  <Field
                    label="Brand"
                    onChange={handleBrand}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isBrandError}</HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    label="Model No."
                    onChange={handleModelNumber}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">
                    {isModelNumberError}
                  </HelperText>
                </Stack>
                <Stack>
                  <Field
                    name="destination"
                    label="Price"
                    onChange={handlePrice}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />

                  <HelperText variant="caption">{isPriceError}</HelperText>
                </Stack>
                <Stack>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}>
                    <DatePicker
                      label="Purchase Date"
                      slotProps={{
                        textField: {
                          error: false,
                          variant: "filled",
                        },
                      }}
                      format="DD-MM-YYYY"
                      sx={{
                        border: "none",
                        backgroundColor: "rgba(244, 246, 246, 1)",
                        "& label.Mui-focused": {
                          color: "rgba(166, 166, 166, 1)",
                        },
                        "& .MuiFilledInput-root": {
                          backgroundColor: "rgba(244, 246, 246, 1)",
                        },
                        "&:hover .MuiFilledInput-root": {
                          backgroundColor: "rgba(244, 246, 246, 1)",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      onChange={handlePurchaseDate}
                      InputProps={{ disableUnderline: true }}
                    />
                  </LocalizationProvider>
                  {<HelperText>{isDateOfPurchaseError}</HelperText>}
                  <Stack sx={{ marginTop: "20px", gap: "10px" }}>
                    <Stack>
                      <Stack
                        flexDirection={"row"}
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <Typography>Upload Images</Typography>

                        {uploadedImageName?.length < 3 && (
                          <>
                            <label for="img">
                              <UploadIcon
                                size={20}
                                sx={{
                                  color: "rgba(27, 37, 53, 1)",
                                  cursor: "pointer",
                                }}
                              />
                            </label>
                            <input
                              type="file"
                              name="uploadfile"
                              id="img"
                              style={{
                                display: "none",
                                width: "42px",
                                height: "42px",
                              }}
                              onChange={handlePhotoUpload}
                            />
                          </>
                        )}
                      </Stack>

                      <Stack
                        sx={{
                          minHeight: "50px",
                          maxHeight: "150px",
                          width: "342px",
                          flexDirection: "row",
                          overflowX: "auto",
                          overflowY: "hidden",
                          gap: "20px",
                          alignItems: "center",
                        }}>
                        {fileUrl?.map((item, index) => (
                          <Badge
                            key={index}
                            badgeContent={
                              <CloseIcon
                                style={{
                                  color: "rgba(255, 58, 68, 1)",
                                }}
                                onClick={() => removePhoto(item?.imageid)}
                              />
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                              border: "0.5px solid rgba(27, 37, 53, 1)",
                              padding: "5px",
                            }}>
                            <Box
                              as="img"
                              src={item?.image}
                              alt=""
                              sx={{ width: "70px", height: "90px" }}
                            />
                          </Badge>
                        ))}
                      </Stack>
                      {isPhotoError && (
                        <HelperText sx={{ color: "red" }}>
                          {isPhotoError}
                        </HelperText>
                      )}
                    </Stack>

                    <Stack sx={{}}>
                      <Stack
                        flexDirection={"row"}
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <Typography>Upload Warranty certificate</Typography>
                        <label for="img1">
                          <UploadIcon
                            size={20}
                            sx={{
                              color: "rgba(27, 37, 53, 1)",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                        <input
                          type="file"
                          name="uploadfile"
                          id="img1"
                          style={{
                            display: "none",
                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handleCertificateUpload}
                        />
                      </Stack>

                      <Stack
                        sx={{
                          minHeight: "50px",
                          maxHeight: "150px",
                          width: "342px",
                          flexDirection: "row",
                          overflowX: "auto",
                          overflowY: "hidden",
                          gap: "20px",
                          alignItems: "center",
                        }}>
                        {certificateUrl?.map((item, index) => (
                          <Badge
                            key={index}
                            badgeContent={
                              <CloseIcon
                                style={{
                                  color: " rgba(255, 58, 68, 1)",
                                }}
                                onClick={() => removeCertificate(item?.imageid)}
                              />
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "10px",
                              border: "0.5px solid rgba(27, 37, 53, 1)",
                              padding: "5px",
                            }}>
                            <Box
                              as="img"
                              src={
                                item.imageid.toLowerCase().endsWith(".pdf")
                                  ? pdfImage
                                  : item?.image
                              }
                              alt={item?.image}
                              sx={{ width: "70px", height: "90px" }}
                            />
                          </Badge>
                        ))}
                      </Stack>
                      {isCertificateError && (
                        <HelperText sx={{ color: "red" }}>
                          {isCertificateError}
                        </HelperText>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ bgcolor: "white", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
              }}>
              <Stack
                sx={{
                  width: "50%",
                  marginRight: "16px",
                  gap: "15px",

                  height: "100%",
                  textAlign: "start",
                }}>
                <Label>Asset</Label>

                <Stack
                  sx={{
                    height: "125px",
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}>
                  <Label>Type</Label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleAssetType}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    <FormControlLabel
                      value="New"
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontWeight={500}>
                          New
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Old"
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontWeight={500}>
                          Old
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Refurbished"
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontWeight={500}>
                          Refurbished
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  {<HelperText>{isAssetTypeError}</HelperText>}
                </Stack>

                <Stack
                  sx={{
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}>
                  <Label>Working Status</Label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleAssetStatus}>
                    <Stack>
                      <FormControlLabel
                        value="Working"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Working
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Not working"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Not working
                          </Typography>
                        }
                      />
                    </Stack>
                    <Stack>
                      <FormControlLabel
                        value="Need Service"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Need Service
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Discard"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Discard
                          </Typography>
                        }
                      />
                    </Stack>
                  </RadioGroup>
                  {
                    <HelperText sx={{ textAlign: "left" }}>
                      {isAssetStatusError}
                    </HelperText>
                  }
                </Stack>
                <Stack
                  sx={{
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}>
                  <Label>Warranty</Label>
                  <YesDateBox>
                    <DateBox>
                      <CustomText fontWeight="600">Start date</CustomText>

                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}>
                        <HalfInput2
                          format="DD/MM/YYYY"
                          onChange={handleWarrantyStartDate}
                          sx={{ bgcolor: "white" }}
                        />
                      </LocalizationProvider>
                    </DateBox>

                    <DateBox>
                      <CustomText fontWeight="600">End date</CustomText>

                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}>
                        <HalfInput2
                          format="DD/MM/YYYY"
                          onChange={handleWarrantyEndDate}
                        />
                      </LocalizationProvider>
                    </DateBox>
                  </YesDateBox>
                  <Stack>{<HelperText>{isWarrantyError}</HelperText>}</Stack>
                </Stack>

                <Stack sx={{}}>
                  <AddReport
                    handleAssetReport={handleAssetReport}
                    assetId={assetId}
                  />
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "50%",
                  height: "100%",

                  gap: "15px",
                  textAlign: "start",
                }}>
                <Typography style={{ color: "white" }}>. </Typography>

                <Stack
                  sx={{
                    minHeight: "125px",
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}>
                  <Label>Calibration Status</Label>
                  <CheckBoxContainer>
                    <FormRadio onChange={handleCalibrationStatus}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group">
                        <FormControlLabel
                          value="Yes"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              Yes
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              No
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {<HelperText>{isCalibrationStatusError}</HelperText>}
                    </FormRadio>
                    {calibrationStatus === "Yes" && (
                      <YesDateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">Start Date</CustomText>

                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}>
                            <HalfInput2
                              format="DD/MM/YYYY"
                              onChange={handleCalibrationStartDate}
                            />
                          </LocalizationProvider>
                          <Box>
                            {<HelperText>{isCalibrationDateError}</HelperText>}
                          </Box>
                        </DateBox>

                        <DueDateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">End Date</CustomText>

                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}>
                              <HalfInput2
                                // value={calibrationEndDate}
                                onChange={handleCalibrationEndDate}
                              />
                            </LocalizationProvider>

                            <Box>
                              {
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#EFF5FE" }}>
                                  {isCalibrationDateError}
                                </Typography>
                              }
                            </Box>
                          </DateBox>
                        </DueDateBox>
                      </YesDateBox>
                    )}
                    {calibrationStatus === "No" && (
                      <DateBox padding="5px" maxHeight="88px" maxWidth="258px">
                        <CustomText fontWeight="600" textAlign={"left"}>
                          Required ?
                        </CustomText>
                        <FormRadio>
                          <Box borderRadius="6px">
                            <RadioGroup
                              onChange={handleRequired}
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group">
                              <FormControlLabel
                                value="Required"
                                control={<Radio size="small" />}
                                label={<CustomText>Required</CustomText>}
                              />
                              <FormControlLabel
                                value="Not Required"
                                control={<Radio size="small" />}
                                label={<CustomText>Not Required</CustomText>}
                              />
                            </RadioGroup>
                            {<HelperText>{isRequiredError}</HelperText>}
                          </Box>
                        </FormRadio>
                      </DateBox>
                    )}
                  </CheckBoxContainer>
                </Stack>
                <Stack
                  sx={{
                    minHeight: "125px",
                    bgcolor: "#F4F6F6",
                    padding: "10px",
                    alignItems: "start",
                    gap: "20px",
                  }}>
                  <Label>CMC/AMC</Label>
                  <CheckBoxContainer>
                    <FormRadio onChange={handleAmcCmc}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group">
                        <FormControlLabel
                          value="CMC"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              CMC
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="AMC"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              AMC
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="None"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              None
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {<HelperText>{isCmcAmcError}</HelperText>}
                    </FormRadio>
                    {cmcAmc === "AMC" && (
                      <YesDateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">Start Date</CustomText>

                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}>
                            <HalfInput2
                              onChange={handleAmcCmcStartDateChange}
                            />
                          </LocalizationProvider>
                        </DateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">End Date</CustomText>

                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}>
                            <HalfInput2 onChange={handleAmcCmcEndDateChange} />
                          </LocalizationProvider>
                        </DateBox>
                      </YesDateBox>
                    )}

                    {cmcAmc === "CMC" && (
                      <YesDateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">Start Date</CustomText>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}>
                            <HalfInput2
                              onChange={handleAmcCmcStartDateChange}
                            />
                          </LocalizationProvider>
                        </DateBox>
                        <DateBox padding="5px">
                          <CustomText fontWeight="600">End Date</CustomText>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={locale}>
                            <HalfInput2 onChange={handleAmcCmcEndDateChange} />
                          </LocalizationProvider>
                        </DateBox>
                      </YesDateBox>
                    )}
                    {<HelperText>{isAmcEndDateError}</HelperText>}
                    {<HelperText>{isCmcEndDateError}</HelperText>}
                  </CheckBoxContainer>
                </Stack>
              </Stack>
            </Box>
            <BottomElement>
              <Label sx={{ marginTop: "15px" }}>Notes</Label>
              <NotesInput
                placeholder="e.g. Regular inspection log maintained to comply with regulations."
                onChange={handleNotes}
                sx={{ bgcolor: "#F4F6F6", width: "100%" }}
              />
            </BottomElement>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: "800px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label variant="subtitle1" color="primary">
            New asset
          </Label>
        </Stack>

        <Stack
          sx={{
            width: "100%",
            maxWidth: 800,
            margin: "auto",
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <BlueStepper
            activeStep={activeStep}
            nonLinear
            style={{
              height: "70px",
              width: "90%",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Step>
              <StepLabel>Step 1</StepLabel>
            </Step>
            <Step>
              <StepLabel>Step 2</StepLabel>
            </Step>
          </BlueStepper>

          <Box sx={{ marginTop: 2, width: "90%" }}>
            {getStepContent(activeStep)}

            <>
              <Stack
                sx={{
                  width: "100%",

                  justifyContent: "space-between",
                  marginTop: "20px",
                }}>
                <Divider />
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "16px",
                    }}>
                    <Stack
                      sx={{
                        flexShrink: 1,
                        width: "100%",
                        alignItems: "flex-end",
                        gap: "20px",
                        flexDirection: "row",
                        justifyContent: "end",
                        padding: "20px",
                      }}>
                      {activeStep === 0 ? null : (
                        <Button
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}
                          onClick={() =>
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep - 1
                            )
                          }>
                          Back
                        </Button>
                      )}

                      {activeStep === 0 ? (
                        <Button
                          onClick={handleNext}
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}>
                          Next
                        </Button>
                      ) : (
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          sx={{
                            width: "154px",
                            borderRadius: "22px",
                            bgcolor: "rgba(70, 144, 255, 1)",
                          }}>
                          Submit
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </>
              </Stack>
            </>
          </Box>
        </Stack>
      </Stack>
      <AddAssetModal
        status={status}
        handleCloseModal={handleCloseModal}
        toggle={toggle}
        text={"Successful! "}
        subText1={"New asset added.  "}
        subText2={"  "}
      />
    </Stack>
  );
};

export default AddAssetPage;
