// import { Box } from "@mui/material";
// import React, { useState } from "react";
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
//   Cell,
// } from "recharts";
// import { useNavigate } from "react-router-dom";

// const GraphComponent = ({data}) => {
//   const [currentPage, setCurrentPage] = useState(0);
//   const itemsPerPage = 5;
//   const navigate = useNavigate()

//   const transformedData = data.map((item) => ({
//     name: item[1],
//     service: parseInt(item[0]),
//     id: item[2]
//   }));

//   const maxValue = Math.max(...transformedData.map((item,index) => item.service));
//   const totalPages = Math.ceil(transformedData.length / itemsPerPage);

//   const handleNextPage = () => {
//     if (currentPage < totalPages - 1) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePreviousPage = () => {
//     if (currentPage > 0) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handlePageClick = (pageIndex) => {
//     setCurrentPage(pageIndex);
//   };

//   const paginatedData = transformedData.slice(
//     currentPage * itemsPerPage,
//     (currentPage + 1) * itemsPerPage
//   );


//   const handleDepartmentId = (value) => {

//     navigate(`/department-asset-details/${value}`);
//   };


//   return (
//     <div
//       style={{
//         width: "100%",
//         height: "100%",
//         textAlign: "start",
//       }}>
     
//       <ResponsiveContainer style={{ padding: 0 }}>
//         <BarChart  data={paginatedData}>
//           <XAxis
//             dataKey="name"
//             tick={{ fontSize: 12, fill: "#000000", fontWeight: 500 }}
//           />
//           <YAxis
//             width={25}
//             tick={{ fontSize: 12, fill: "#000000", fontWeight: 500 }}
//           />
//           <Tooltip cursor={{ fill: "transparent" }} />
//           <Bar dataKey="service" fill="#000000" barSize={20}>
//             {paginatedData?.map((entry, index) => (
//               <>
//                 <Cell
//                   cursor="pointer"
//                   fill={entry.service === maxValue ? "#FF6B00" : "#000000"}
//                   key={`cell-${index}`}
//                   onClick={()=>handleDepartmentId(entry.id)}
//                 />
//               </>
//             ))}
//           </Bar>
//         </BarChart>
//       </ResponsiveContainer>
//       <Box display="flex" justifyContent="center" alignItems="center" >
       
//         <Box display="flex" alignItems="center" sx={{cursor:"pointer"}} >
//           {Array.from({ length: totalPages }).map((_, index) => (
//             <>
//               {currentPage === index ?  <HorizontalRuleIcon onClick={() => handlePageClick(index)} style={{ color: currentPage === index ? "#000000" : "#cccccc" }} />:
//               <FiberManualRecordIcon   key={index}   onClick={() => handlePageClick(index)}
//                 style={{ color: currentPage === index ? "#000000" : "#cccccc",  fontSize:'10px' }}
//               />}
//            </>
//           ))}
//         </Box>
       
//       </Box>
      
//     </div>
//   );
// };

// export default GraphComponent;





import { Box } from "@mui/material";
import React, { useState } from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useNavigate } from "react-router-dom";

const GraphComponent = ({data,date,dataValue,handleData}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const navigate = useNavigate();

  const transformedData = data.map((item, index) => ({
    name: item[0], // Year
    service: parseInt(item[1]), // Count
    id: index // Index as ID
  }));

  const monthNamesArray = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const maxValue = Math.max(...transformedData.map((item) => item.service));
  const totalPages = Math.ceil(transformedData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const paginatedData = transformedData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleDepartmentId = (value) => {
   
    if(date==="year"){
     console.log("date",date,value.name)
     dataValue(value.name)
     handleData()
    }

    if(date==="month"){
      const month = String(value.id + 1).padStart(2, '0');
      dataValue(month)
      handleData()
    }

  };

  return (
    <div style={{ width: "100%", height: "100%", textAlign: "start" }}>
      <ResponsiveContainer width="100%" >
        <BarChart data={paginatedData}>
          <XAxis
            dataKey="name"
            tick={{ fontSize: 12, fill: "#000000", fontWeight: 500 }}
          />
          <YAxis
            width={25}
            tick={{ fontSize: 12, fill: "#000000", fontWeight: 500 }}
          />
          <Tooltip cursor={{ fill: "transparent" }} />
          <Bar dataKey="service" fill="#000000" barSize={20}>
            {paginatedData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                cursor="pointer"
                fill={entry.service === maxValue ? "#FF6B00" : "#000000"}
                onClick={() => handleDepartmentId(entry)}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
          {Array.from({ length: totalPages }).map((_, index) => (
            <React.Fragment key={index}>
              {currentPage === index ? (
                <HorizontalRuleIcon
                  onClick={() => handlePageClick(index)}
                  style={{ color: currentPage === index ? "#000000" : "#cccccc" }}
                />
              ) : (
                <FiberManualRecordIcon
                  onClick={() => handlePageClick(index)}
                  style={{ color: currentPage === index ? "#000000" : "#cccccc", fontSize: '10px' }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default GraphComponent;

