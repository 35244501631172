import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { useState } from "react";
import { allUserList, deleteUser } from "../redux/nbmData/action";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Checkbox from "@mui/material/Checkbox";

import DialogBox from "../components/DialogBox";
import Confirmation from "../components/Confirmation";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
const Label2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  whiteSpace: "nowrap",
  cursor: "pointer",
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  whiteSpace: "nowrap",
  padding: "10px",
}));
const Box1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "stretch",
  gap: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
const UserListPage = ({ handleDataChange }) => {
  const userData = useSelector((store) => store.data.usersList);
  const status = useSelector((store) => store.data.deleteUser);
  const [getId, setId] = useState("");
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };
  const handleSortClose = () => {
    setSortAnchorEl(null);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [nameSort, setNameSort] = useState(null);
  const [dateSort, setDateSort] = useState(null);
  const [taskCount, setTaskCount] = useState(0);
  const [calenderCounts, setCalenderCounts] = useState(0);
  const [completedTask, setCompletedTask] = useState(0);
  const [expirtCount, setExpirtCount] = useState(0);
  const [dueDateSort, setDueDateSort] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const handleCategoryCheckboxChange = (event) => {
    const category = event.target.name;
    setSelectedCategory((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((selected) => selected !== category)
        : [...prevSelected, category]
    );
  };

  const select = [...new Set(data?.map((item) => item?.departs))];

  const uniqueDepartments = [...new Set(select.flat())];
  console.log("uniqueDepartments", uniqueDepartments);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNameSortChange = (event) => {
    setNameSort(event.target.checked ? event.target.value : null);
  };
  const handleButtonClick3 = () => {
    if (userIds.subs_plan === "Basic" && userData?.length >= 1) {
      setOpenModal(true);
    } else {
      navigate("/addUser");
    }
  };

  useEffect(() => {
    const filterData = (
      data,
      selectedCategory,

      nameSort
    ) => {
      let filteredData = data.filter(
        (item) =>
          selectedCategory &&
          (selectedCategory?.length === 0 ||
            selectedCategory?.includes(item.departs))
      );

      filteredData = filteredData.filter((product) =>
        product.username.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (nameSort === "a-z") {
        filteredData.sort((a, b) =>
          a.username.trim().localeCompare(b.username.trim())
        );
      } else if (nameSort === "z-a") {
        filteredData.sort((a, b) =>
          b.username.trim().localeCompare(a.username.trim())
        );
      }

      return filteredData;
    };

    const newFilteredData = filterData(
      data,
      selectedCategory,
      selectedBrands,
      nameSort,

      dateSort,
      dueDateSort
    );
    setFilteredData(newFilteredData);
  }, [data, selectedCategory, nameSort, searchQuery, dateSort]);

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(allUserList(data, token));
  }, [dispatch, status]);

  useEffect(() => {
    if (Object.keys(userData)?.length > 0) {
      setData(userData?.users);
      setTaskCount(userData?.total_task);
      setCalenderCounts(userData?.calendar_count)
      setExpirtCount(userData?.expiry_count)
      setCompletedTask(userData?.completed_task)
    }
  }, [userData]);

  const handleSave = (value) => {
    let data = {
      departs: value.departs,
      designate: value.designate,
      email: value.email,
      username: value.username,
    };
    localStorage.setItem("editData", JSON.stringify(data));
  };

  const handleDeleteById = (item) => {
    setId(item);
    handleOpenDelete();
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteUser = () => {
    let data = {
      adminid: userIds.userid,
      userid: getId.id,
      hospid: userIds.hospid,
      branchid: userBranch.id,
      branchname: userBranch.branch,
      emailid: getId.email,
    };
    dispatch(deleteUser(data))
      .then(() => {
        let data = {
          userid: userIds.userid,
          hosp_id: userIds.hospid,
          branchid: userBranch.id,
        };
        dispatch(allUserList(data, token));
        handleCloseDelete();
      })
      .catch((error) => {});
  };



  useEffect(()=>{
    if(handleDataChange!==undefined){
      let data2 = {
        user:filteredData?.length,
        completed:completedTask,
        calender:calenderCounts,
        expiring:expirtCount
      }
      
        handleDataChange(data2)
    }
  
  },[filteredData])







  return (
    <Box
      display="flex"
      flexDirection="column"
      //   justifyContent="space-between"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px">
      {/* First Box */}
      <Box1>
        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            // gap={"5px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              04
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label variant="subtitle2">Completed Requests</Label>

              <ArrowRightAltIcon />
            </Stack>
          </Stack>
        </Box>

        <Box flex="1">
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              04
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label variant="subtitle2">Expiring Soon</Label>

              <ArrowRightAltIcon />
            </Stack>
          </Stack>
        </Box>
        <Box
          flex="1"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/task")}>
          <Stack
            bgcolor="lightblue"
            height="70px"
            justifyContent={"start"}
            alignItems={"start"}
            padding="15px"
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Label variant="subtitle1" sx={{ color: "#4690FF" }}>
              {taskCount}
            </Label>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}>
              <Label variant="subtitle2">My Tasks</Label>

              <ArrowRightAltIcon />
            </Stack>
          </Stack>
        </Box>
      </Box1>

      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}>
          <Label variant="h6">
            Users.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          <IconButton>
            <AddCircleIcon
              size={35}
              style={{ color: "#4690FF" }}
              onClick={() => navigate("/addUser")}
            />
          </IconButton>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px">
          <Box1
            flexDirection={"row"}
            height="50px"
            justifyContent={"space-between"}
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Typography>Total :</Typography>
            <Typography
              sx={{ color: "#4690FF" }}>{` ${data?.length}`}</Typography>
          </Box1>
          <Box flex="2">
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon size={15} style={{ color: "" }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Search>
              <Stack flexDirection={"row"}>
                <IconButton color="info" onClick={handleFilterClick}>
                  <FilterAltIcon size={16} />
                </IconButton>

                <Menu
                  anchorEl={filterAnchorEl}
                  open={Boolean(filterAnchorEl)}
                  onClose={handleFilterClose}>
                  <MenuItem
                    sx={{
                      paddingLeft: "20px",
                    }}>
                    <FilterAltIcon />
                    <Box sx={{ marginLeft: "10px", fontWeight: "700" }}>
                      {" "}
                      Filter
                    </Box>
                  </MenuItem>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Divider sx={{ backgroundColor: "black", width: "90%" }} />
                  </Stack>

                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                      alignItems: "start",

                      gap: "20px",
                    }}>
                    {uniqueDepartments && (
                      <Stack>
                        <Label variant="caption">Department</Label>

                        {uniqueDepartments?.map((uniqueDepartments) => (
                          <FormControlLabel
                            key={uniqueDepartments}
                            control={
                              <Checkbox
                                color="info"
                                checked={selectedCategory.includes(
                                  uniqueDepartments
                                )}
                                onChange={handleCategoryCheckboxChange}
                                name={uniqueDepartments}
                              />
                            }
                            label={
                              <Label2 variant="body2">
                                {uniqueDepartments}
                              </Label2>
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  </MenuItem>
                </Menu>

                <IconButton color="info" onClick={handleSortClick}>
                  <SwapVertIcon size={25} />
                </IconButton>

                <Menu
                  anchorEl={sortAnchorEl}
                  open={Boolean(sortAnchorEl)}
                  onClose={handleSortClose}>
                  <MenuItem
                    sx={{
                      paddingLeft: "20px",
                    }}>
                    <SwapVertIcon />
                    <Box sx={{ marginLeft: "10px", fontWeight: "700" }}>
                      {" "}
                      Sort
                    </Box>
                  </MenuItem>
                  <Stack
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Divider sx={{ backgroundColor: "black", width: "90%" }} />
                  </Stack>

                  <MenuItem
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "column",
                      alignItems: "start",

                      gap: "20px",
                    }}>
                    <Stack>
                      <Label variant="body2">Name Sort</Label>

                      <FormControlLabel
                        control={
                          <Checkbox
                            color="info"
                            value="a-z"
                            onChange={handleNameSortChange}
                            checked={nameSort === "a-z"}
                          />
                        }
                        label={<Label2 variant="body2">A-Z</Label2>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="info"
                            value="z-a"
                            onChange={handleNameSortChange}
                            checked={nameSort === "z-a"}
                          />
                        }
                        label={<Label2 variant="body2">Z-A</Label2>}
                      />
                    </Stack>
                  </MenuItem>
                </Menu>

                <IconButton
                  color="info"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                      mg: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}>
                  <AddCircleIcon
                    disabled={
                      userAccess?.adduser !== "Yes" ||
                      userAccess?.adduser === "Guest"
                    }
                    onClick={handleButtonClick3}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(90vh - 300px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    "&:hover": {
                      backgroundColor: "rgba(255, 237, 237, 1)",
                    },
                  }}>
                  <TableHeading>User Name</TableHeading>
                  <TableHeading>Role</TableHeading>

                  <TableHeading>Department</TableHeading>

                  <TableHeading>Designate</TableHeading>
                  <TableHeading>Email</TableHeading>
                  <TableHeading></TableHeading>
                  <TableHeading></TableHeading>
                </TableRow>
              </TableHead>
              {/* Table Body with Scrollbar */}
              <TableBody style={{ overflowY: "scroll" }}>
                {filteredData.map((item, index) => (
                  <TableRow
                    key={item.userid}
                    sx={{
                      "&:hover": {
                        bgcolor: "#EEF5FF",
                      },
                    }}>
                    <TableCells>
                      <Tooltip title={item.username}>{item.username}</Tooltip>
                    </TableCells>

                    <TableCells>
                      {item?.role === "Guest"
                        ? "Guest"
                        : item.role === "Admin"
                        ? "Department Admin"
                        : item?.role.replace(/_/g, " ")}
                    </TableCells>

                    <TableCells>
                      {item?.departs && item.departs.join(", ")}
                    </TableCells>

                    <TableCells>{item?.designate}</TableCells>

                    <TableCells>
                      <Tooltip title={item?.email}>{item?.email}</Tooltip>
                    </TableCells>
                    <TableCells
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}>
                      <IconButton
                        disabled={
                          userAccess?.edituser !== "Yes" ||
                          (userAccess.role === "Owner" &&
                            item?.role === "Super_Admin") ||
                          (userAccess.role === "Super_Admin" &&
                            item?.role === "Super_Admin") ||
                          (userAccess.role === "Admin" &&
                            item?.role === "Super_Admin") ||
                          (userAccess.role === "Admin" &&
                            item?.role === "Branch_Admin") ||
                          (userAccess.role === "Admin" &&
                            userAccess.departmentids !== "all" &&
                            item?.role === "Admin") ||
                          (userAccess.role === "Admin" &&
                            userAccess.departmentids !== "all" &&
                            item?.role === "Guest") ||
                          (userAccess.role !== "Guest" &&
                            userIds?.userid === item?.userid)
                        }>
                        <Link to={`/user/${item?.userid}`}>
                          <span
                            onClick={() => handleSave(item)}
                            style={{ cursor: "pointer" }}>
                            <BorderColorIcon
                              fontSize="small"
                              sx={{
                                color:
                                  userAccess?.edituser !== "Yes" ||
                                  userAccess?.edituser !== "Yes" ||
                                  (userAccess.role === "Owner" &&
                                    item?.role === "Super_Admin") ||
                                  (userAccess.role === "Super_Admin" &&
                                    item?.role === "Super_Admin") ||
                                  (userAccess.role === "Admin" &&
                                    item?.role === "Super_Admin") ||
                                  (userAccess.role === "Admin" &&
                                    item?.role === "Branch_Admin") ||
                                  (userAccess.role === "Admin" &&
                                    userAccess.departmentids !== "all" &&
                                    item?.role === "Admin") ||
                                  (userAccess.role === "Admin" &&
                                    userAccess.departmentids !== "all" &&
                                    item?.role === "Guest") ||
                                  (userAccess.role !== "Guest" &&
                                    userIds?.userid === item?.userid)
                                    ? "rgb(196, 206, 196)"
                                    : "rgba(70, 144, 255, 1)",
                              }}
                            />
                          </span>
                        </Link>
                      </IconButton>
                    </TableCells>
                    <TableCells>
                      <IconButton
                        disabled={
                          userAccess?.role === "Guest" ||
                          (userAccess.role === "Admin" &&
                            item?.role === "Super_Admin") ||
                          (userAccess.role === "Admin" &&
                            item?.role === "Branch_Admin") ||
                          (userAccess.role === "Admin" &&
                            userAccess.departmentids !== "all" &&
                            item?.role === "Admin" &&
                            userIds?.userid !== item?.userid) ||
                          (userAccess.role === "Admin" &&
                            userAccess.departmentids !== "all" &&
                            item?.role === "Guest")
                        }>
                        <span
                          onClick={() => handleDeleteById(item)}
                          style={{ cursor: "pointer" }}>
                          <DeleteIcon
                            fontSize="small"
                            sx={{
                              color:
                                userAccess?.role === "Guest" ||
                                (userAccess.role === "Admin" &&
                                  item?.role === "Super_Admin") ||
                                (userAccess.role === "Admin" &&
                                  item?.role === "Branch_Admin") ||
                                (userAccess.role === "Admin" &&
                                  userAccess.departmentids !== "all" &&
                                  item?.role === "Admin" &&
                                  userIds?.userid !== item?.userid) ||
                                (userAccess.role === "Admin" &&
                                  userAccess.departmentids !== "all" &&
                                  item?.role === "Guest")
                                  ? "rgb(196, 206, 196)"
                                  : "rgba(70, 144, 255, 1)",
                            }}
                          />
                        </span>
                      </IconButton>
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            sx={{
              width: "100%",
              padding: "10px",
              bgcolor: "white",
              gap: "20px",
            }}>
            <Divider />
            <Stack
              sx={{
                bgcolor: "white",
                alignItems: "flex-end",
                justifyContent: "column",
              }}>
              <Button
                onClick={() => navigate("/task")}
                variant="contained"
                size="small"
                sx={{
                  borderRadius: "22px",
                  background: "rgba(70, 144, 255, 1)",
                  marginRight: "20px",
                }}>
                Assign task <AddIcon />
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <DialogBox openModal={openModal} handleCloseModal={handleCloseModal} />

      <Confirmation
        handleCloseModal={handleCloseDelete}
        handleDone={handleDeleteUser}
        open={openDelete}
        text={"Are you sure you want to delete ?"}
      />
    </Box>
  );
};

export default UserListPage;
