import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Stack,
  Typography,
  styled,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { deleteDepart, getDepartmentData } from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import AddDepartmentPage from "./AddDepartmentPage";
import EditDepartment from "./EditDepartment";
import DeleteModal from "../components/DeleteModal";
import BulkDeleteDepartment from "../components/BulkDeleteDepartment";
import DialogBox from "../components/DialogBox";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Confirmation from "../components/Confirmation";
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const OuterSet = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  // alignItems: "stretch",
  gap: "10px",
  height: "85vh",
  width: "100%",
  bgcolor: "white",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100%",

    // flexDirection:"column",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DepartmentList = () => {
  const allDepartment = useSelector((store) => store.data.department);
  const DepartmentUpload = useSelector((store) => store.data.departmentName);
  console.log("DepartmentUpload", DepartmentUpload);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);

  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [getId, setGetId] = useState("");

  const response = useSelector((store) => store?.data?.deleteDepart);

  const [showEdit, setShowEdit] = useState(false);
  const [specificData, setSpecificData] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const text = "department";

  const [showMovementBox, setShowMovementBox] = useState(false);
  const [addMobileDep, setAddMobileDep] = useState(false);
  const handleAddMovementClick = () => {
    setShowMovementBox(true);
  };

  const handleAddMobileClick = () => {
    setAddMobileDep(!addMobileDep);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (item) => {
    setShowEdit(true);
    setAnchorEl(null);
    setSpecificData(item);
  };

  const handleDelete = (item) => {
    localStorage.setItem("bulkChange", getId.departname);
    navigate(`/department-asset-delete-details/${getId?.departid}`);
    // console.log("getId?.departid",getId)
  };

  const handleDeleteSingle = () => {
    let data = {
      userid: userData?.userid,
      departid: getId.departid,
      hospid: userData?.hospid,
    };
    console.log("())()()()()()()()()()()()", data);
    dispatch(deleteDepart(data, token));
    setToggle(true);
    handleCloseDelete();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (allDepartment?.length !== undefined && allDepartment?.length > 0) {
      setData(allDepartment);
    }
  }, [allDepartment, response]);

  useEffect(() => {
    let data = {
      hospid: userData?.hospid,
      userid: userData?.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };
    dispatch(getDepartmentData(data));
    setToggle(false);
  }, [dispatch, response, toggle, showEdit, DepartmentUpload]);
  const handleDeleteById = (getId) => {
    console.log("departiddepartid------------>>>>", getId);
    handleClose();

    setToggle(true);

    if (getId?.totalassets === "0") {
      handleOpenDelete();
      setGetId(getId);
    }
    if (getId?.totalassets !== "0") {
      setGetId(getId);
      handleOpenDeleteModal();
    }
  };

  const handleShowEdit = () => {
    setShowEdit(false);
  };

  console.log("filter", filteredData);
  const handleCloseDepart = () => {
    setShowMovementBox(false);
    setAddMobileDep(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding:'10px',
        alignItems: "center",
       
      }}>
      {showEdit ? (
        <EditDepartment data={specificData} showEdit={handleShowEdit} />
      ) : (
        <OuterSet>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            style={{ width: "100%" }}>
            <Stack
              justifyContent="space-between"
              flexDirection={"row"}
              alignItems={"center"}
              sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}>
              <Label variant="h6">
                Department.
                <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
              </Label>
              <IconButton onClick={handleAddMobileClick}>
                <AddCircleIcon size={35} style={{ color: "#4690FF" }} />
              </IconButton>
            </Stack>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="stretch"
              gap="10px">
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-between"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  display: { xs: "none", sm: "none", lg: "flex" },
                }}>
                <Typography>{`Total : ${data?.length}`}</Typography>
              </Stack>
              <Box flex="2">
                <SortSearchFilter
                  data={data}
                  setData={setData}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  heading={"My Orders"}
                  Filter1="departincharge"
                  Filter2=""
                  sortPrice=""
                  sortDate="createdate"
                  name="departname"
                />
              </Box>
            </Box>

            {addMobileDep && (
              <AddDepartmentPage handleCloseDepart={handleCloseDepart} />
            )}
            <Stack
              sx={{
                width: "100%",
                height: { xs: "70%", sm: "70%", lg: "100%" },

                bgcolor: "white",
              }}
              flexDirection={"column"}>
              <TableContainer
                component={Paper}
                sx={{
                  height: "100%",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                  width: "100%",
                }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableHeading>Department</TableHeading>
                      <TableHeading>Total Assets</TableHeading>
                      <TableHeading>Created Date</TableHeading>
                      <TableHeading>Incharge</TableHeading>

                      <TableHeading></TableHeading>
                      <TableHeading></TableHeading>
                    </TableRow>
                  </TableHead>

                  <TableBody style={{ overflowY: "scroll" }}>
                    {filteredData.map((item, index) => (
                      <TableRow>
                        <TableCells
                          component={Link}
                          to={`/department-asset-details/${item?.departid}`}>
                          {item.departname}
                        </TableCells>
                        <TableCells>{item.totalassets}</TableCells>
                        <TableCells>{item.createdate}</TableCells>
                        <TableCells>{item.departincharge}</TableCells>
                        <TableCells>
                          <BorderColorIcon
                            style={{ cursor: "pointer" }}
                            size={20}
                            disabled={userAccess?.editdepart !== "Yes"}
                            onClick={() => handleEdit(item)}
                            value="edit"
                          />
                        </TableCells>
                        <TableCells>
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            disabled={userAccess?.deldepart !== "Yes"}
                            value="delete"
                            onClick={() => handleDeleteById(item)}
                          />
                        </TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {!showMovementBox && (
                <Stack
                  sx={{
                    width: "100%",
                    padding: "20px",
                    bgcolor: "white",
                    gap: "20px",
                    display: { xs: "none", sm: "none", lg: "flex" },
                  }}>
                  <Divider />
                  <Stack
                    sx={{
                      bgcolor: "white",
                      alignItems: "flex-end",

                      justifyContent: "column",
                    }}>
                    <Button
                      onClick={handleAddMovementClick}
                      variant="contained"
                      sx={{
                        borderRadius: "22px",
                        background: "rgba(70, 144, 255, 1)",
                        // marginRight: "20px",
                      }}>
                      Add Department <AddIcon />
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
          {showMovementBox && (
            <AddDepartmentPage handleCloseDepart={handleCloseDepart} />
          )}

          {/* <DeleteModal
            open={openDelete}
            onClose={handleCloseDelete}
            onDelete={handleDeleteSingle}
            text={text}
            id={getId}
          /> */}
          <Confirmation
            handleCloseModal={handleCloseDelete}
            open={openDelete}
            handleDone={handleDeleteSingle}
            text={"Are you sure you want to delete ?"}
          />

          <BulkDeleteDepartment
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            onDelete={handleDelete}
            text={text}
            id={getId}
          />

          <DialogBox
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        </OuterSet>
      )}
    </Box>
  );
};
export default DepartmentList;
