import {
  Box,
  FormControl,
  FormLabel,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../redux/data/action";
import SubmissionPopUp from "./SubmissionPopUp";
function Demo() {
  const [name, setName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [hospitalNameError, setHospitalNameError] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((store) => store.data?.data);
  const [openModal, setOpenModal] = useState(false);
  const text =
    " We've received your request from the website. Our team will contact you soon. ";

  const text2 = "Thank you";
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const validateName = (e) => {
    let value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;
    setName(value);
    if (value === "" || regex.test(value.trim())) {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Invalid Name");
    }
    if (value === "") {
      setNameError("Please fill all field");
    }
    if (value.length < 3 || value === "") {
      setNameError("Invalid Name");
    }
  };

  const handleHospital = (e) => {
    let value = e.target.value;
    const regex = /^[-a-zA-Z0-9\s]+$/;
    setHospitalName(value);
    if (value === "" || regex.test(value.trim())) {
      setHospitalName(value);
      setHospitalNameError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setHospitalNameError("Invalid hospital name");
    }
    if (value.trim() === "") {
      setHospitalNameError("Please fill all field");
    }
    if (value.length < 3) {
      setHospitalNameError("Invalid hospital Name");
    }
  };

  const validatePhone = (e) => {
    let value = e.target.value;
    const regex = /^[0-9]{10}$/;
    setMobileNumber(value);

    if (value === "" || regex.test(value)) {
      setMobileNumber(value);
      setNumberError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNumberError("Invalid number");
    }
    if (value === "") {
      setNumberError("Please fill all field");
    }
    if (value.length !== 10) {
      setNumberError("Invalid number");
    }
  };

  const validateEmail = (e) => {
    let value = e.target.value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setEmailError("");
    }
    if (value !== "" && !regex.test(value)) {
      setEmailError("Invalid mail id");
    }
    if (value === "") {
      setEmailError("Please fill all field");
    }
  };

  const handleAddress = (e) => {
    let value = e.target.value;
    const regex = /^[-a-zA-Z0-9: ]*$/;
    setAddress(value);
    if (value === "" || regex.test(value.trim())) {
      setAddress(value);
      setAddressError("");
    }
    if (value !== "" && !regex.test(value.trim())) {
      setAddressError("Invalid address");
    }
    if (value.trim() === "") {
      setAddressError("Please fill all field");
    }
  };

  const handleClick = () => {
    if (
      name !== "" &&
      mobileNumber !== "" &&
      email !== "" &&
      hospitalName !== "" &&
      address !== "" &&
      emailError === "" &&
      nameError === "" &&
      numberError === "" &&
      hospitalNameError === "" &&
      addressError === ""
    ) {
      let data = {
        user_name: name,
        hosp_name: hospitalName,
        user_mobile: mobileNumber,
        user_email: email,
        hosp_address: address.trim(),
      };

      dispatch(postData(data))
        .then(() => {
          setOpenModal(true);
          setName("");
          setEmail("");
          setHospitalName("");
          setMobileNumber("");
          setAddress("");
        })
        .catch((error) => {});
    } else {
      if (name === "") {
        setNameError("Fill your name here");
      }
      if (email === "") {
        setEmailError("Fill your email");
      }
      if (mobileNumber === "") {
        setNumberError("Fill your mobile number");
      }
      if (hospitalName === "") {
        setHospitalNameError("Fill the hospital name");
      }
      if (address === "") {
        setAddressError("Fill the address");
      }
    }
  };

  useEffect(() => {
    if (data?.message === "Submitted") {
      setName("");
      setEmail("");
      setHospitalName("");
      setMobileNumber("");
      setAddress("");
    }
  }, [data]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick(e);
    }
  };
  return (
    <Box
      sx={{
        width: {
          xl: "400px",
          lg: "400px",
          md: "480px",
          sm: "280px",
          xs: "80%",
        },
        marginBottom: {
          xl: "20px",
          lg: "20px",
          md: "20px",
          sm: "20px",
          xs: "20px",
        },

        display: "flex",
        flexDirection: "column",

        alignItems: "center",

        padding: "20px",
      }}
      bgcolor={"#5C98E9"}
      borderRadius="30px">
      <Typography
        fontFamily="Poppins"
        marginTop={"5px"}
        fontWeight={"500"}
        color={"white"}
        fontSize={"26px"}>
        Schedule a Demo
      </Typography>
      <hr
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          color: "white",
          width: "80%",
        }}
      />

      <FormControl sx={{ width: "100%" }}>
        <Typography
          fontFamily="Poppins"
          marginTop={"5px"}
          fontWeight={"500"}
          color={"white"}
          textAlign={"start"}
          fontSize={"23px"}>
          Join Vajra
        </Typography>

        {nameError ? (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", color: "red", marginTop: "10px" }}>
            {nameError}
          </FormLabel>
        ) : (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", marginTop: "10px", color: "#FFFFFF" }}>
            Enter Name
          </FormLabel>
        )}

        <TextField
          size="small"
          value={name}
          sx={{
            bgcolor: "#EFF5FE",
            borderRadius: "5px",
          }}
          onKeyPress={handleKeyPress}
          onChange={validateName}
          placeholder="e.g. Alex"></TextField>

        {hospitalNameError ? (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", color: "red", marginTop: "10px" }}>
            {hospitalNameError}
          </FormLabel>
        ) : (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", marginTop: "10px", color: "#FFFFFF" }}>
            Hospital Name
          </FormLabel>
        )}
        <TextField
          size="small"
          value={hospitalName}
          sx={{
            bgcolor: "#EFF5FE",
            borderRadius: "5px",
            width: "100%",
          }}
          onChange={handleHospital}
          onKeyPress={handleKeyPress}
          placeholder="e.g. KMCNS Hospital"></TextField>

        {numberError ? (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", color: "red", marginTop: "10px" }}>
            {numberError}
          </FormLabel>
        ) : (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", marginTop: "10px", color: "#FFFFFF" }}>
            {" "}
            Mobile No
          </FormLabel>
        )}
        <TextField
          size="small"
          value={mobileNumber}
          sx={{
            bgcolor: "#EFF5FE",
            borderRadius: "5px",
          }}
          onChange={validatePhone}
          onKeyPress={handleKeyPress}
          placeholder="e.g. 8971580265"></TextField>

        {emailError ? (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", color: "red", marginTop: "10px" }}>
            {emailError}
          </FormLabel>
        ) : (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", marginTop: "10px", color: "#FFFFFF" }}>
            Email
          </FormLabel>
        )}
        <TextField
          size="small"
          value={email}
          sx={{
            bgcolor: "#EFF5FE",
            borderRadius: "5px",
          }}
          onChange={validateEmail}
          onKeyPress={handleKeyPress}
          placeholder="e.g. example@gmail.com"></TextField>

        {addressError ? (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", color: "red", marginTop: "10px" }}>
            {addressError}
          </FormLabel>
        ) : (
          <FormLabel
            fontFamily="Poppins"
            fontSize={"18px"}
            sx={{ textAlign: "left", marginTop: "10px", color: "#FFFFFF" }}>
            Address
          </FormLabel>
        )}
        <TextField
          size="small"
          value={address}
          sx={{
            bgcolor: "#EFF5FE",
            borderRadius: "5px",
          }}
          onChange={handleAddress}
          onKeyPress={handleKeyPress}
          placeholder="e.g. 123 Main Street, City, Country"></TextField>
      </FormControl>
      <Button
        sx={{
          bgcolor: "#1746A2",
          borderRadius: "30px",
          marginTop: "2%",
          textTransform: "none",
        }}
        onClick={handleClick}
        size="small"
        variant="contained">
        Request a demo
      </Button>
      {data === "Submitted" && <></>}
      {data === "All fields are mandatory" && (
        <Box
          sx={{
            margin: "auto",
            borderRadius: "14px",
            marginTop: "10px",
            bgcolor: "#EFF5FE",
            height: "27px",
            width: "300px",
          }}>
          {" "}
          <Typography
            paddingTop={"2px"}
            sx={{ fontSize: "12", color: "red", fontWeight: "600" }}
            fontFamily={"Poppins"}
            textAlign="center">
            Request unsuccessfull
          </Typography>{" "}
        </Box>
      )}

      <SubmissionPopUp
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        text={text}
        text2={text2}
      />
    </Box>
  );
}

export default Demo;
