import * as React from "react";
import { Box, Button, Divider, Stack, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import RequestPage from "./RequestPage";
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));
export default function RequestMobile() {
  const navigate = useNavigate();
  const [assetPageCount, setAssetPageCount] = useState({
    request:0,
    warranty:0,
    task:0,
    calibration:0
  })


  const handleDataChange = (childData) => {

    setAssetPageCount({
      request:childData.request,
      warranty:childData.warranty,
      task:childData.task,
      calibration:childData.calibration
    });
  };

  return (
    <>
    <Stack
      sx={{
        width: "100%",
        // height: "100vh",
        marginTop:'-11px',
       
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        bgcolor: "#f5f5f5",
        gap: "30px",
        paddingBottom:'11px',
      }}>
      <Stack
        sx={{
         
          height: "130px",
          bgcolor: "#1B2535",
          width: "100%",
          color: "white",
        }}>
        <Stack
          justifyContent={"space-between"}
          sx={{ flexDirection: "row", p: 2, alignItems: "end", height: "90%" }}>
          <Label variant="h4">Request.</Label>
          <Label variant="h6">Assign</Label>
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "80%",
          height: "45%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          gap: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/request")}
            sx={{
              cursor: "pointer",
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
              {assetPageCount.request}
            </Label>
            <Label variant="h6">Requests</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/warranty")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {assetPageCount.warranty}
            </Label>
            <Label variant="h6">Warranty</Label>
          </Stack>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50%",
            gap: "10px",
          }}>
          <Stack
            onClick={() => navigate("/task")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {assetPageCount.task}
            </Label>
            <Label variant="h6">My tasks</Label>
          </Stack>
          <Stack
            onClick={() => navigate("/request")}
            sx={{
              width: "48%",
              height:'100px',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "10px",
              p: 2,
              boxShadow: " 0px 0px 8px 0px #DBE7E5D9",
            }}>
            <Label variant="h6" sx={{ color: "#4690FF" }}>
            {assetPageCount.calibration}
            </Label>
            <Label variant="h6">Calibrations</Label>
          </Stack>
        </Box>
      </Box>
      <Divider sx={{ width: "90%" }} />
      <Button
        variant="contained"
        size="large"
        sx={{ borderRadius: "22px", width: "80%" }}
        onClick={() => navigate("/pm-checklist")}>
        Pm checklist
      </Button>






    </Stack>



    <RequestPage  handleDataChange={handleDataChange} />
    </>
  );
}
