import styled from "@emotion/styled";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CardActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import pdfImage from "../images/pdfImage.png";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import emp from "../images/noDepartment.png";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import {
  AddAssetReport,
  CreateQRCode,
  addAsset,
  assetDeleteReport,
  deleteCertificate,
  deleteImage,
  editAssetFun,
  getAsset,
  getDepartmentData,
  uploadImage,
  uploadQrCodeImage,
  uploadedCertificate,
} from "../redux/nbmData/action";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddAssetModal from "../components/AddAssetModal";
import AddReport from "./AddReport";

import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import SuccessPopUp from "../components/SuccessPopUp";

const Field = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "& fieldset": {
    border: "none",
  },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));
const Label2 = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 500,
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));
const BlueStepper = styled(Stepper)(({ theme }) => ({
  ".MuiStepConnector-active": {
    "& > .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
      width: "700px", // Adjust the width as needed
    },
  },
  "& .MuiStepConnector-line": {
    borderLeftWidth: "558px", // Adjust the width as needed
  },
}));

const NotesInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",

  height: "80px",
  "& fieldset": {
    border: "none",
  },
}));

const NotesTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  fontSize: "22px",
  marginTop: "30px",
  color: "#FF731D",
}));

const BottomElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
}));

const HalfInput2 = styled(DatePicker)(({ theme }) => ({
  width: "145px",
  padding: 0,
  background: "white",
}));
const CheckBoxContainer = styled(Box)(({ theme }) => ({
  minHeight: "50px",
}));

const FormRadio = styled(FormControl)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  bgcolor: "red",
}));

const DateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
}));

const DueDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

const YesDateBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  justifyContent: "center",
}));

const CustomText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  textAlign: "left",
  fontWeight: "400px",
  color: "rgba(166, 166, 166, 1)",
}));

const EditAsset = () => {
  const params = useParams();
  const navigate = useNavigate();
  const editData = useSelector((store) => store.data.getAsset);

  const status = useSelector((store) => store.data.editAsset);
  const [open, setOpen] = useState(false);
  const [assetReport, setAssetReport] = useState([]);
  const [fileError, setFileError] = useState("");
  const handleAssetReport = (data) => {
    setAssetReport(data);
  };
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const departmentData = useSelector((store) => store.data.department) || [];
  const [department, setDepartment] = useState("");
  const [preDepartment, setPreDepartment] = useState("");
  const [photoFlag, setPhotoFlag] = useState(false);
  const [certificateFlag, setCertificateFlag] = useState(false);
  const [fileUrl, setFileUrl] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState([]);
  const [name, setName] = useState([]);
  const [warranty, setWarranty] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [phone, setPhone] = useState("");
  const [isPhoneError, setIsPhoneError] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(null);
  const [assetName, setAssetName] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [brand, setBrand] = useState("");
  const [usefullLife, setUsefullLife] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [soldBy, setSoldBy] = useState("");
  const [price, setPrice] = useState("");
  const [assetType, setAssetType] = useState("");
  const [assetStatus, setAssetStatus] = useState("");
  const [calibrationStatus, setCalibrationStatus] = useState("");
  const [cmcAmc, setCmcAmc] = useState("");
  const [cmcAmcStartDate, setCmcAmcStartDate] = useState("");
  const [cmcAmcEndDate, setCmcAmcEndDate] = useState("");
  const [calibrationDate, setCalibrationDate] = useState("");

  const [calibrationStartDate, setCalibrationStartDate] = useState("");
  const [calibrationEndDate, setCalibrationEndDate] = useState("");

  const [required, setRequired] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [departmentPreId, setDepartmentPreId] = useState("");
  const [notes, setNotes] = useState("");
  const [isAssetError, setIsAssetError] = useState(null);
  const [isSerialNumberError, setIsSerialNumberError] = useState(null);
  const [isModelNumberError, setIsModelNumberError] = useState(null);
  const [isBrandError, setIsBrandError] = useState(null);
  const [isSoldByError, setIsSoldByError] = useState(null);
  const [isWarrantyError, setIsWarrantyError] = useState(null);
  const [isPriceError, setIsPriceError] = useState(null);
  const [isDueDateError, setIsDueDateError] = useState(null);
  const [isAmcEndDateError, setIsAmcEndDateError] = useState(null);
  const [isCmcEndDateError, setIsCmcEndDateError] = useState(null);
  const [isAssetTypeError, setIsAssetTypeError] = useState(null);
  const [isAssetStatusError, setIsAssetStatusError] = useState(null);
  const [isCalibrationStatusError, setIsCalibrationStatusError] =
    useState(null);

  const [warrantyStartDate, setWarrantyStartDate] = useState("");
  const [warrantyEndDate, setWarrantyEndDate] = useState("");

  const [isAssetCodeError, setIsAssetCodeError] = useState(null);
  const [isUsefullLifeError, setIsUsefullLifeError] = useState(null);
  const [isCmcAmcError, setIsCmcAmcError] = useState(null);
  const [isCalibrationDateError, setIsCalibrationDateError] = useState(null);
  const [isRequiredError, setIsRequiredError] = useState(null);
  const [isDepartmentError, setIsDepartmentError] = useState(null);
  const [isDateOfPurchaseError, setIsDateOfPurchaseError] = useState(null);
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [isCertificateError, setIsCertificateError] = useState(null);
  const [locale, setLocale] = React.useState("en-gb");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submittedCalibrationStartDate, setSubmittedCalibrationStartDate] =
    useState("");
  const [submittedCalibrationEndDate, setSubmittedCalibrationEndDate] =
    useState("");

  const [submittedWarrantyStartDate, setSubmittedWarrantyStartDate] =
    useState("");
  const [submittedWarrantyEndDate, setSubmittedWarrantyEndDate] = useState("");

  const [submittedCmcAmcStartDate, setSubmittedCmcAmcStartDate] = useState("");
  const [submittedCmcAmcEndDate, setSubmittedCmcAmcEndDate] = useState("");
  const [submittedDateOfPurchase, setSubmittedDateOfPurchase] = useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
 
  let idSplit = params.id.substring(0, 4);
  const initialRow = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    imageid: "",
    image: "",
    report_name: "Service Report",
  };

  const [openReport, setOpenReport] = useState(false);

  const [reportData, setReportData] = useState([initialRow]);
  const [dataToSend, setDataToSend] = useState([initialRow]);
  const [newRow, setNewRow] = useState({ ...initialRow });

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate(-1);
  };

  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        compressImage(file);

        // return false;
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${idSplit}` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setFileUrl([...fileUrl, sendData]);

          dispatch(uploadImage(sendData, token));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);

              dispatch(uploadImage(sendData, token));
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };
  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    dispatch(deleteImage(data));
    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCertificateUpload = (e) => {
    const singlefile = e.target.files[0];

    const splitId = params.id.substring(0, 4);
    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      const allowedSize = 2 * 1024 * 1024; // 2 MB
      if (allowedTypes.indexOf(file.type) === -1) {
        setIsCertificateError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        setIsCertificateError("Greater than 2 mb can't be uploaded");
        return false;
      } else {
        setIsCertificateError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          //   setCertificateUrl([...fileUrl,reader.result]);

          let sendData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: `${splitId}_` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          let checkData = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: sendData.imageid,
            image: reader.result,
            fileType: fileType,
            branchid: userBranch?.id,
          };

          setCertificateUrl([...certificateUrl, checkData]);
          dispatch(uploadedCertificate(sendData));
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const removeCertificate = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };
    dispatch(deleteCertificate(data));
    setCertificateUrl(certificateUrl.filter((item) => item.imageid !== id));
    setOpen(true);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z ]+$/;
    if (value === "" || regex.test(value)) {
      setName(value);
    }
  };

  const handleAssetName = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    setAssetName(value);
    if (value === "" || regex.test(value)) {
      setAssetName(value);
      setIsAssetError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsAssetError("wrong asset Name");
    }
    if (value === "") {
      setIsAssetError("Please fill the field");
    }
  };

  const handleSerialNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setSerialNumber(value);
    if (regex.test(value) || value === "") {
      setSerialNumber(value);
      setIsSerialNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsSerialNumberError("wrong serial number");
    }
  };

  const handleModelNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setModelNumber(value);
    if (regex.test(value) || value === "") {
      setModelNumber(value);
      setIsModelNumberError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsModelNumberError("wrong model number");
    }
  };

  const handleBrand = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9 -]+$/;
    setBrand(value);
    if (value === "" || regex.test(value)) {
      setBrand(value);
      setIsBrandError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsBrandError("wrong brand Name");
    }
  };

  const handleSoldBy = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;
    setSoldBy(value);
    if (value === "" || regex.test(value)) {
      setSoldBy(value);
      setIsSoldByError(null);

      if (email === "NA" || email === "") {
        setIsEmailError("Enter valid email");
      }

      if (phone === "NA" || phone === "") {
        setIsPhoneError("Enter valid Phone Number");
      }

      if (phone === "") {
        setIsPhoneError("Please fill the field");
      }
      if (email === "") {
        setIsEmailError("Please fill the field");
      }
    }
    if (value !== "" && !regex.test(value)) {
      setIsSoldByError("Enter valid details");
    }
    if (value === "") {
      setIsPhoneError(null);
      setIsEmailError(null);
      setEmail("");
      setPhone("");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value.trim();
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
    setEmail(value);
    if (value === "" || regex.test(value)) {
      setEmail(value);
      setIsEmailError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsEmailError("Enter valid email");
    }
    if (value === "") {
      setIsEmailError("Please fill the field");
    }
  };

  const handlePhone = (e) => {
    console.log("e.target.value", e.target.value);
    const value = e.target.value.trim();
    const regex = /^\d{10}$/;
    setPhone(value);
    if (value === "" || regex.test(value)) {
      setPhone(value);
      setIsPhoneError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPhoneError("Enter valid Phone Number");
    }
    if (value === "") {
      setIsPhoneError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    setPrice(value);
    if (value === "" || regex.test(value)) {
      setPrice(value);
      setIsPriceError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsPriceError("Enter valid price");
    }
    if (value === "") {
      setIsPriceError("Please fill the field");
    }
  };

  const handleUsefulLife = (e) => {
    const value = e.target.value.trim();
    const regex = /^[1-9]\d*$/;
    setUsefullLife(value.trim());
    if (value.trim() === "" || regex.test(value.trim())) {
      setUsefullLife(value.trim());
      setIsUsefullLifeError(null);
    }
    if (value.trim() !== "" && !regex.test(value.trim())) {
      setIsUsefullLifeError("Enter valid number");
    }
    if (value.trim() === "") {
      setIsUsefullLifeError(null);
      setIsPriceError(null);
      setIsDateOfPurchaseError(null);
    }
    if (value.trim() !== "" && price === "") {
      setIsPriceError("Please fill the field");
    }
    if (value.trim() !== "" && dateOfPurchase === "") {
      setIsDateOfPurchaseError("Please fill the field");
    }
  };

  const handleDueDate = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (value === "" || regex.test(value)) {
      setDueDate(value);
      setIsDueDateError(null);
    }
    if (value !== "" && !regex.test(value)) {
      setIsDueDateError("Enter valid month count");
    }
    if (value > 99) {
      setIsDueDateError("Enter valid month count");
    }
    if (calibrationDate == "") {
      setIsCalibrationDateError("Please fill the field");
    }
  };

  const handleNotes = (e) => {
    const value = e.target.value;
    setNotes(value);
  };

  const handleDepartment = (event, value) => {
    setDepartmentPreId(editData?.departid);
    if (value !== null && value !== undefined) {
      setDepartmentId(value.id);
      setDepartment(value.name);

      if (value.name !== "" && value.name !== null) {
        setIsDepartmentError(null);
      }

      if (editData?.departid === value?.id) {
        setDepartmentPreId("NA");
      }
      if (editData?.departid !== value?.id) {
        setDepartmentPreId(editData?.departid);
      }

      if (editData?.dept === value.name) {
        setPreDepartment("NA");
      }
      if (editData?.dept !== value.name) {
        setPreDepartment(editData?.dept);
      }
    }
  };

  const handleAmcCmcStartDateChange = (date) => {
    setCmcAmcStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(date, cmcAmcEndDate);
  };

  const handleAmcCmcEndDateChange = (date) => {
    setCmcAmcEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    amcValidateDateRange(cmcAmcStartDate, date);
  };

  const amcValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsAmcEndDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsAmcEndDateError(null);
      setIsCmcEndDateError(null);
    }
  };

  const calibrationValidateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsCalibrationDateError("Invalid date Range");
    }
    if (new Date(startDate) <= new Date(endDate)) {
      setIsCalibrationDateError(null);
    }
  };

  const handleCalibrationStartDate = (date) => {
    setCalibrationStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    calibrationValidateDateRange(date, calibrationEndDate);
  };

  const handleCalibrationEndDate = (date) => {
    setCalibrationEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    calibrationValidateDateRange(calibrationStartDate, date);
  };

  const handleAmcCmc = (e) => {
    const value = e.target.value;
    setCmcAmc(value);
    if (value === "None") {
      setIsCmcEndDateError(null);
      setIsAmcEndDateError(null);
      setCmcAmcEndDate("");
      setCmcAmcStartDate("");
    }
    if (value === "AMC") {
      setIsCmcEndDateError("Please enter the date");
      setIsAmcEndDateError(null);
      if (cmcAmcStartDate !== "" && cmcAmcEndDate !== "") {
        setIsCmcEndDateError(null);
      }
      if (
        // cmcAmcStartDate === "Invalid date" &&
        // cmcAmcEndDate === "Invalid date"
        !dayjs(cmcAmcStartDate).isValid() ||
        !dayjs(cmcAmcEndDate).isValid()
      ) {
        setIsCmcEndDateError("Please enter the date");
      }
    }
    if (value === "CMC") {
      setIsAmcEndDateError("Please enter the date");
      setIsCmcEndDateError(null);
      if (cmcAmcStartDate !== "" && cmcAmcEndDate !== "") {
        setIsAmcEndDateError(null);
      }
      if (
        // cmcAmcStartDate === "Invalid date" &&
        // cmcAmcEndDate === "Invalid date"
        !dayjs(cmcAmcStartDate).isValid() ||
        !dayjs(cmcAmcEndDate).isValid()
      ) {
        setIsAmcEndDateError("Please enter the date");
      }
    }
    if (value !== "") {
      setIsCmcAmcError(null);
    }
  };

  const handleRequired = (e) => {
    let value = e.target.value;
    if (value !== "") {
      setRequired(value);
      setIsRequiredError(null);
    }
    setCalibrationStartDate("");
    setCalibrationEndDate("");
  };

  const handleCalibrationStatus = (e) => {
    let value = e.target.value;
    setCalibrationStatus(value);
    if (value === "No") {
      setCalibrationStartDate("");
      setCalibrationEndDate("");
      setIsRequiredError("");
      setIsDueDateError(null);
      setIsCalibrationDateError(null);
    }
    if (value === "Yes") {
      setRequired("NA");
      setIsRequiredError(null);
      setCalibrationEndDate("");
      setCalibrationStartDate("");
      setIsCalibrationDateError("Please fill the field");
    }
    if (value !== "") {
      setIsCalibrationStatusError(null);
    }
  };

  const handleAssetStatus = (e) => {
    const value = e.target.value;
    setAssetStatus(value);
    if (value !== "") {
      setIsAssetStatusError(null);
    }
  };

  const handleDepartmentId = (item) => {
    setDepartmentPreId(editData?.departid);
    setDepartmentId(item.departid);
    if (item !== "") {
      setIsDepartmentError(null);
    }

    if (editData?.departid === item?.departid) {
      setDepartmentPreId("NA");
    }
    if (editData?.departid !== item?.departid) {
      setDepartmentPreId(editData?.departid);
    }
  };

  const handleAssetType = (e) => {
    const value = e.target.value;
    setAssetType(value);
    if (value !== "") {
      setIsAssetTypeError(null);
    }
  };

  const handlePurchaseDate = (date) => {
    // const value = e.target.value;
    setDateOfPurchase(date && dayjs(date).format("YYYY-MM-DD"));
    if (date !== "") {
      setIsDateOfPurchaseError(null);
    }
  };

  const handleAssetCode = (e) => {
    const value = e.target.value;
    setAssetCode(value);
    if (value !== "") {
      setAssetCode(value);
      setIsAssetCodeError(null);
    }
    if (value === "") {
      setIsAssetCodeError("Please fill the field");
    }
  };

  const handleSubmit = () => {
    let rData = reportData?.filter((item) => {
      return item.image !== "";
    });

    assetName === "" && setIsAssetError("Please fill the field");

    (department === "" || department === null) &&
      setIsDepartmentError("Please fill the field");
    assetType === "" && setIsAssetTypeError("Please fill the field");
    assetStatus === "" && setIsAssetStatusError("Please fill the field");
    calibrationStatus === "" &&
      setIsCalibrationStatusError("Please fill the field");
    cmcAmc === "" && setIsCmcAmcError("Please fill the field");
    assetCode === "" && setIsAssetCodeError("Please fill the field");
    fileUrl.length <= 0 && setIsPhotoError("Please add an image");

    if (calibrationStatus === "Yes") {
      ((calibrationStartDate === "NA" || calibrationStartDate === "") &&
        setIsCalibrationDateError("Please fill the field")) ||
        ((calibrationEndDate === "NA" || calibrationEndDate === "") &&
          setIsCalibrationDateError("Please fill the field"));
    }
    if (calibrationStatus === "No") {
      (required === "NA" || required === "") &&
        setIsRequiredError("Please fill the field");
    }

    if (cmcAmc === "AMC" || cmcAmc === "CMC") {
      (cmcAmcEndDate === "" ||
        cmcAmcEndDate === "NA" ||
        cmcAmcStartDate === "" ||
        cmcAmcStartDate === "NA") &&
        setIsCmcEndDateError("Please fill this field");
    }
    if (
      assetName === "" ||
      department === "" ||
      department === null ||
      assetType === "" ||
      assetStatus === "" ||
      calibrationStatus === "" ||
      cmcAmc === "" ||
      fileUrl.length === 0 ||
      assetCode === ""
    ) {
      setSnackbarMessage("Please fill all the mandatory fields.");
      setSnackbarOpen(true);
      return;
    }
    if (
      assetName !== "" &&
      department !== null &&
      assetType !== "" &&
      assetStatus !== "" &&
      calibrationStatus !== "" &&
      cmcAmc !== "" &&
      fileUrl.length !== 0 &&
      assetCode !== ""
    ) {
      if (
        isAssetError === null &&
        isPhoneError === null &&
        isEmailError === null &&
        isSerialNumberError === null &&
        isModelNumberError === null &&
        isWarrantyError === null &&
        isDateOfPurchaseError === null &&
        isPriceError === null &&
        isAmcEndDateError === null &&
        isCmcEndDateError === null &&
        isUsefullLifeError === null &&
        isAssetTypeError === null &&
        isAssetStatusError === null &&
        isCalibrationStatusError === null &&
        isCmcAmcError === null &&
        isCalibrationDateError === null &&
        isRequiredError === null &&
        isPhotoError === null &&
        isCertificateError === null &&
        isAssetCodeError === null
      ) {
        let data = {
          assetid: params.id,
          hospid: userIds.hospid,
          hospname: userIds.hospname,
          userid: userIds.userid,
          username: userIds.username,
          assetname: assetName.trim(),
          serialno: serialNumber === "" ? "NA" : serialNumber.trim(),
          assetcode: assetCode === "" ? "NA" : assetCode.trim(),
          modelno: modelNumber === "" ? "NA" : modelNumber.trim(),

          sellermobile: phone === "" ? "NA" : phone,
          selleremail: email === "" ? "NA" : email,

          prev_department: editData?.dept,
          prev_department_id: editData?.departid,
          curr_department: department.trim(),
          curr_department_id: departmentId,

          department: department.trim(),
          department_id: departmentId,
          branchid: userBranch?.id,
          assettype: assetType,
          lifeyrs: usefullLife === "" ? "1" : usefullLife.trim(),
          assetstatus: assetStatus,
          brand: brand === "" ? "NA" : brand.trim(),
          price: price === "" ? "NA" : price.trim(),
          purchdate: dayjs(dateOfPurchase).isValid()
            ? dateOfPurchase !== submittedDateOfPurchase
              ? dayjs(dateOfPurchase).format("DD-MM-YYYY")
              : submittedDateOfPurchase
            : "NA",
          warntdate: dayjs(warrantyStartDate).isValid()
            ? warrantyStartDate !== submittedWarrantyStartDate
              ? dayjs(warrantyStartDate).format("DD-MM-YYYY")
              : submittedWarrantyStartDate
            : "NA",

          wrntymonth: "1",
          expdate: dayjs(warrantyEndDate).isValid()
            ? warrantyEndDate !== submittedWarrantyEndDate
              ? dayjs(warrantyEndDate).format("DD-MM-YYYY")
              : submittedWarrantyEndDate
            : "NA",
          sellername: soldBy === "" ? "NA" : soldBy.trim(),
          prodimgs:
            fileUrl.length > 0
              ? fileUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          wrntyimage:
            certificateUrl.length > 0
              ? certificateUrl?.map((item) => {
                  return item.imageid;
                })
              : "NA",
          calibrate_status: calibrationStatus,

          calibrate_date: dayjs(calibrationStartDate).isValid()
            ? calibrationStartDate !== submittedCalibrationStartDate
              ? dayjs(calibrationStartDate).format("DD-MM-YYYY")
              : submittedCalibrationStartDate
            : "NA",
          calibrate_req: required === "" ? "NA" : required,
          calibrate_duemonth: "1",
          calibrate_duedate: dayjs(calibrationEndDate).isValid()
            ? calibrationEndDate !== submittedCalibrationEndDate
              ? dayjs(calibrationEndDate).format("DD-MM-YYYY")
              : submittedCalibrationEndDate
            : "NA",
          cmcamc: cmcAmc,

          cmcamc_start: dayjs(cmcAmcStartDate).isValid()
            ? cmcAmcStartDate !== submittedCmcAmcStartDate
              ? dayjs(cmcAmcStartDate).format("DD-MM-YYYY")
              : submittedCmcAmcStartDate
            : "NA",

          cmcamc_due: dayjs(cmcAmcEndDate).isValid()
            ? cmcAmcEndDate !== submittedCmcAmcEndDate
              ? dayjs(cmcAmcEndDate).format("DD-MM-YYYY")
              : submittedCmcAmcEndDate
            : "NA",
          calibformat: dayjs(calibrationStartDate).isValid()
            ? calibrationStartDate !== submittedCalibrationStartDate
              ? dayjs(calibrationStartDate).format("YYYY-MM-DD")
              : submittedCalibrationStartDate
            : "NA",
          purdateformat: dayjs(dateOfPurchase).isValid()
            ? dateOfPurchase !== submittedDateOfPurchase
              ? dayjs(dateOfPurchase).format("YYYY-MM-DD")
              : submittedDateOfPurchase
            : "NA",
          notes: notes === "" ? "NA" : notes.trim(),
          report_names:
            rData?.length === 0
              ? []
              : rData?.map((item) => {
                  return item.report_name;
                }),
          report_ids:
            rData?.length === 0
              ? []
              : rData?.map((item) => {
                  return item.imageid;
                }),
        };

        dispatch(editAssetFun(data))
          .then(() => {
            setOpenModal(true);
          })
          .catch((error) => {
            console.error(error);
          });
        setToggle(true);
        // navigate(-1);
      }
    }
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: params.id,
      branchid: userBranch?.id,
    };
    dispatch(getAsset(data));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setPrice(editData?.price);
      setAssetName(editData?.name);
      setDepartment(editData?.dept);
      setSerialNumber(editData?.serialno);
      setModelNumber(editData?.modelno);
      setWarranty(editData?.wrntmonth);
      setUsefullLife(editData?.lifeyrs);
      setBrand(editData?.brand);
      setSoldBy(editData?.soldby);
      setCalibrationStatus(editData?.calibstatus);
      setDateOfPurchase(dayjs(editData?.dop, "DD-MM-YYYY"));
      setAssetStatus(editData?.status);
      setAssetType(editData?.type);
      setDepartmentId(editData?.departid);
      setNotes(editData?.notes);
      setAssetCode(editData?.code);
      setCmcAmc(editData?.cmcamc);
      setCmcAmcStartDate(dayjs(editData?.cmcamcstart, "DD-MM-YYYY"));
      setCmcAmcEndDate(dayjs(editData?.cmcamcdue, "DD-MM-YYYY"));
      setCalibrationDate(dayjs(editData?.calibdate, "DD-MM-YYYY"));

      setWarrantyStartDate(dayjs(editData?.wrntdate, "DD-MM-YYYY"));
      setWarrantyEndDate(dayjs(editData?.wrntexpdate, "DD-MM-YYYY"));
      setEmail(editData?.selleremail);
      setPhone(editData?.sellermobile);
      setRequired(editData?.calibreq);
      setDueDate(editData?.calibmnth);
      setCalibrationStartDate(dayjs(editData?.calibdate, "DD-MM-YYYY"));
      setCalibrationEndDate(dayjs(editData?.calibduedate, "DD-MM-YYYY"));
      setFileUrl(
        editData?.imgs === "NA" || editData?.imgs === ""
          ? []
          : editData?.imgs?.map((item) => {
              return {
                imageid: item.imgid,
                image: item.imgurl,
              };
            })
      );
      setCertificateUrl(
        editData?.wimg === "NA" || editData?.wimg === ""
          ? []
          : editData?.wimg?.map((item) => {
              return {
                imageid: item.imgid,
                image: item.imgurl,
              };
            })
      );

      setReportData(
        editData?.reportimgs === "NA" || editData?.reportimgs === ""
          ? []
          : editData?.reportimgs?.map((item, index) => {
              return {
                imageid: item.imgid,
                image: item.imgurl,
                report_name: editData?.reportnames[index],
              };
            })
      );
    }
  }, [editData]);

  const handleOpenReport = () => {
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
    const filteredData = dataToSend.filter((item) => item.image !== "");
    handleAssetReport(filteredData);
  };

  const handleAddRow = () => {
    setReportData([...reportData, { ...newRow }]);
    setDataToSend([...reportData, { ...newRow }]);
    setNewRow({ ...initialRow });
  };

  const handleRemoveRow = (index) => {
    const updatedData = [...reportData];
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };
    dispatch(assetDeleteReport(dataDelete));
    updatedData.splice(index, 1);
    setReportData(updatedData);
    setDataToSend(updatedData);
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    const updatedData = [...reportData];

    if (file) {
      const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
      const fileType = file.type.split("/")[1];

      if (allowedTypes.includes(fileType)) {
        const splitId = uuid().substring(0, 6);
        const imageid = params.id.substring(0, 6);
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          updatedData[index].imageid = `${imageid}_${splitId}.${fileType}`;
          updatedData[index].image = dataUrl;
          setReportData(updatedData);
          setDataToSend(updatedData);
          let assetRep = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: updatedData[index].imageid,
            image: updatedData[index].image,
            branchid: userBranch?.id,
          };

          dispatch(AddAssetReport(assetRep));
          setFileError("");
        };
        reader.readAsDataURL(file);
      } else {
        setFileError("Please upload pdf, jpg, jpeg or png file");
      }
    } else {
      updatedData[index].imageid = "";
      updatedData[index].image = "";
      setReportData(updatedData);
      setDataToSend(updatedData);
    }
  };
  const handleClearImage = (index) => {
    const updatedData = [...reportData];
    updatedData[index].image = "";
    setReportData(updatedData);
    setDataToSend(updatedData);
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };

    dispatch(assetDeleteReport(dataDelete));
  };

  const warrantyValidDateRange = (startDate, endDate) => {
    if (!dayjs(startDate).isValid() || !dayjs(endDate).isValid()) {
      setIsWarrantyError("Enter valid start and end date");
      return;
    } else {
      setIsWarrantyError(null);
    }

    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setIsWarrantyError("Invalid date range");
    } else {
      setIsWarrantyError(null);
    }
  };
  const handleWarrantyStartDate = (date) => {
    setWarrantyStartDate(date && dayjs(date).format("YYYY-MM-DD"));
    warrantyValidDateRange(date, warrantyEndDate);
  };

  const handleWarrantyEndDate = (date) => {
    setWarrantyEndDate(date && dayjs(date).format("YYYY-MM-DD"));
    warrantyValidDateRange(warrantyStartDate, date);
  };

  const handleReportName = (event, index) => {
    const updatedData = [...reportData];
    const selectedReportName = event.target.value;
    updatedData[index].report_name = selectedReportName;
    setReportData(updatedData);
    setDataToSend(updatedData);

    let assetRep = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      image: updatedData[index].image,
      branchid: userBranch?.id,
    };

    dispatch(AddAssetReport(assetRep));
  };

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setSubmittedCalibrationStartDate(editData.calibdate);
      setSubmittedCalibrationEndDate(editData.calibduedate);
      setSubmittedWarrantyStartDate(editData.wrntdate);
      setSubmittedWarrantyEndDate(editData.wrntexpdate);
      setSubmittedCmcAmcStartDate(editData.cmcamcstart);
      setSubmittedCmcAmcEndDate(editData.cmcamcdue);
      setSubmittedDateOfPurchase(editData.dop);
    }
  }, [editData]);

  console.log("email phone", email, phone);

  useEffect(() => {
    if (soldBy !== "NA" && soldBy !== "") {
      if (phone === "" || phone === "NA") {
        if (phone === "") {
          setIsPhoneError("Please fill the field");
        } else {
          setIsPhoneError("Enter valid Phone Number");
        }
      }

      if (email === "" || email === "NA") {
        if (email === "") {
          setIsEmailError("Please fill the field");
        } else {
          setIsEmailError("Enter valid email");
        }
      }
    }
  }, [phone, soldBy, email]);
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",

        height: "100%",
      }}>
      <Stack
        sx={{
          width: "800px",

          borderRadius: "8px",

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          gap: "10px",
        }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",

            bgcolor: "white",

            padding: "20px",
          }}>
          <Label
            variant="subtitle1"
            color="primary"
            sx={{ paddingLeft: "20px" }}>
            Edit Asset
          </Label>
        </Stack>

        <Stack
          sx={{
            width: "100%",
            maxWidth: 800,
            margin: "auto",
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box sx={{ marginTop: 2, width: "90%" }}>
            <Box sx={{ bgcolor: "white" }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",

                  justifyContent: "space-between",
                  alignItems: "start",
                  gap: "30px",
                }}>
                <Stack
                  sx={{
                    width: "50%",

                    gap: "15px",

                    height: "100%",
                    textAlign: "start",
                  }}>
                  <Stack>
                    <Field
                      label="Asset Name"
                      value={assetName}
                      onChange={handleAssetName}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">{isAssetError}</HelperText>
                  </Stack>
                  <Stack>
                    <Autocomplete
                      sx={{ bgcolor: "#EFF5FE" }}
                      options={departmentData?.map((option) => ({
                        name: option.departname,
                        id: option?.departid,
                      }))}
                      value={{ name: department, id: departmentId }}
                      getOptionLabel={(option) => option.name} // Specify how to get the label for each option
                      onChange={handleDepartment}
                      renderInput={(params) => (
                        <TextField
                          placeholder="e.g. Emergency Department"
                          {...params}
                        />
                      )}
                    />

                    <HelperText variant="caption">
                      {isDepartmentError}
                    </HelperText>
                  </Stack>
                  <Stack>
                    <Field
                      value={assetCode}
                      label="Asset Code"
                      onChange={handleAssetCode}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">
                      {isAssetCodeError}
                    </HelperText>
                  </Stack>
                  <Stack>
                    <Field
                      value={serialNumber}
                      label="Serial No"
                      onChange={handleSerialNumber}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">
                      {isSerialNumberError}
                    </HelperText>
                  </Stack>

                  <Stack>
                    <Field
                      label="Useful Life"
                      value={usefullLife}
                      onChange={handleUsefulLife}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">
                      {isUsefullLifeError}
                    </HelperText>
                  </Stack>

                  {/* <Label>Seller Details</Label> */}

                  <Stack sx={{}}>
                    <Field
                      label="Seller Name"
                      value={soldBy}
                      onChange={handleSoldBy}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">{isSoldByError}</HelperText>
                  </Stack>
                  {soldBy && soldBy !== "NA" && (
                    <>
                      <Stack>
                        <Field
                          label="Seller Email"
                          onChange={handleEmail}
                          value={email}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />

                        <HelperText variant="caption">
                          {isEmailError}
                        </HelperText>
                      </Stack>
                      <Stack>
                        <Field
                          value={phone}
                          label="Seller Phone"
                          onChange={handlePhone}
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />

                        <HelperText variant="caption">
                          {isPhoneError}
                        </HelperText>
                      </Stack>
                    </>
                  )}
                </Stack>
                <Stack
                  sx={{
                    width: "50%",
                    height: "100%",

                    gap: "15px",
                    textAlign: "start",
                  }}>
                  <Stack>
                    <Field
                      label="Brand"
                      value={brand}
                      onChange={handleBrand}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">{isBrandError}</HelperText>
                  </Stack>
                  <Stack>
                    <Field
                      label="Model No."
                      value={modelNumber}
                      onChange={handleModelNumber}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">
                      {isModelNumberError}
                    </HelperText>
                  </Stack>
                  <Stack>
                    <Field
                      label="Price"
                      value={price}
                      onChange={handlePrice}
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                    />

                    <HelperText variant="caption">{isPriceError}</HelperText>
                  </Stack>
                  <Stack>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={locale}>
                      <DatePicker
                        label="Purchase Date"
                        format="DD-MM-YYYY"
                        sx={{
                          border: "none",
                          backgroundColor: "rgba(244, 246, 246, 1)",
                          "& label.Mui-focused": {
                            color: "rgba(166, 166, 166, 1)",
                          },
                          "& .MuiFilledInput-root": {
                            backgroundColor: "rgba(244, 246, 246, 1)",
                          },
                          "&:hover .MuiFilledInput-root": {
                            backgroundColor: "rgba(244, 246, 246, 1)",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                        slotProps={{ textField: { variant: "filled" } }}
                        value={dateOfPurchase}
                        onChange={handlePurchaseDate}
                        InputProps={{ disableUnderline: true }}
                      />
                    </LocalizationProvider>
                    {<HelperText>{isDateOfPurchaseError}</HelperText>}
                    <Stack sx={{ marginTop: "20px", gap: "10px" }}>
                      <Stack>
                        <Stack
                          flexDirection={"row"}
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          <Typography>Upload Images</Typography>

                          <>
                            <label for="img">
                              <FileUploadIcon
                                size={20}
                                style={{ color: "rgba(27, 37, 53, 1)" }}
                              />
                            </label>
                            <input
                              type="file"
                              name="uploadfile"
                              id="img"
                              style={{
                                display: "none",
                                width: "42px",
                                height: "42px",
                              }}
                              onChange={handlePhotoUpload}
                            />
                          </>
                        </Stack>

                        <Stack
                          sx={{
                            minHeight: "50px",
                            maxHeight: "150px",
                            width: "342px",
                            flexDirection: "row",
                            overflowX: "auto",
                            overflowY: "hidden",
                            gap: "20px",
                            alignItems: "center",
                          }}>
                          {fileUrl?.map((item, index) => (
                            <Badge
                              key={index}
                              badgeContent={
                                <CloseIcon
                                  size={15}
                                  style={{
                                    color: " rgba(255, 58, 68, 1)",
                                  }}
                                  onClick={() => removePhoto(item?.imageid)}
                                />
                              }
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "10px",
                                border: "0.5px solid rgba(27, 37, 53, 1)",
                                padding: "5px",
                              }}>
                              <Box
                                as="img"
                                src={item?.image}
                                alt=""
                                sx={{ width: "70px", height: "90px" }}
                              />
                            </Badge>
                          ))}
                        </Stack>
                        {isPhotoError && (
                          <HelperText sx={{ color: "red" }}>
                            {isPhotoError}
                          </HelperText>
                        )}
                      </Stack>

                      <Stack sx={{}}>
                        <Stack
                          flexDirection={"row"}
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          <Typography>Upload Warranty certificate</Typography>
                          <label for="img1">
                            <FileUploadIcon
                              size={20}
                              style={{ color: "rgba(27, 37, 53, 1)" }}
                            />
                          </label>
                          <input
                            type="file"
                            name="uploadfile"
                            id="img1"
                            style={{
                              display: "none",
                              width: "42px",
                              height: "42px",
                            }}
                            onChange={handleCertificateUpload}
                          />
                        </Stack>

                        <Stack
                          sx={{
                            minHeight: "50px",
                            maxHeight: "150px",
                            width: "342px",
                            flexDirection: "row",
                            overflowX: "auto",
                            overflowY: "hidden",
                            gap: "20px",
                            alignItems: "center",
                          }}>
                          {certificateUrl?.map((item, index) => (
                            <Badge
                              key={index}
                              badgeContent={
                                <CloseIcon
                                  size={15}
                                  style={{
                                    color: " rgba(255, 58, 68, 1)",
                                  }}
                                  onClick={() =>
                                    removeCertificate(item?.imageid)
                                  }
                                />
                              }
                              sx={{
                                // height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "10px",
                                border: "0.5px solid rgba(27, 37, 53, 1)",
                                padding: "5px",
                              }}>
                              <Box
                                as="img"
                                src={
                                  item.imageid.toLowerCase().endsWith(".pdf")
                                    ? pdfImage
                                    : item?.image
                                }
                                alt={item?.image}
                                sx={{ width: "70px", height: "90px" }}
                              />
                            </Badge>
                          ))}
                        </Stack>
                        {isCertificateError && (
                          <HelperText sx={{ color: "red" }}>
                            {isCertificateError}
                          </HelperText>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <Box sx={{ bgcolor: "white" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}>
                <Stack
                  sx={{
                    width: "50%",
                    marginRight: "16px",
                    gap: "15px",
                    height: "100%",
                    textAlign: "start",
                  }}>
                  <Label>Asset</Label>

                  <Stack
                    sx={{
                      height: "125px",
                      bgcolor: "#F4F6F6",
                      padding: "10px",
                      alignItems: "start",
                      gap: "20px",
                    }}>
                    <Label>Type</Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={assetType}
                      onChange={handleAssetType}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}>
                      <FormControlLabel
                        value="New"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            New
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Old"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Old
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Refurbished"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="subtitle1" fontWeight={500}>
                            Refurbished
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    {<HelperText>{isAssetTypeError}</HelperText>}
                  </Stack>

                  <Stack
                    sx={{
                      // height: "125px",

                      bgcolor: "#F4F6F6",
                      padding: "10px",
                      alignItems: "start",
                      gap: "20px",
                    }}>
                    <Label>Working Status</Label>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={assetStatus}
                      onChange={handleAssetStatus}>
                      <Stack>
                        <FormControlLabel
                          value="Working"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              Working
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="Not working"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              Not working
                            </Typography>
                          }
                        />
                      </Stack>
                      <Stack>
                        <FormControlLabel
                          value="Need Service"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              Need Service
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="Discard"
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="subtitle1" fontWeight={500}>
                              Discard
                            </Typography>
                          }
                        />
                      </Stack>
                    </RadioGroup>
                    {
                      <HelperText sx={{ textAlign: "left" }}>
                        {isAssetStatusError}
                      </HelperText>
                    }
                  </Stack>
                  <Stack
                    sx={{
                      // height: "125px",
                      bgcolor: "#F4F6F6",
                      padding: "10px",
                      alignItems: "start",
                      gap: "20px",
                    }}>
                    <Label>Warranty</Label>
                    <YesDateBox>
                      <DateBox>
                        <CustomText fontWeight="600">Start date</CustomText>

                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}>
                          <HalfInput2
                            value={warrantyStartDate}
                            format="DD/MM/YYYY"
                            onChange={handleWarrantyStartDate}
                            sx={{ bgcolor: "white" }}
                          />
                        </LocalizationProvider>
                      </DateBox>

                      <DateBox>
                        <CustomText fontWeight="600">End date</CustomText>

                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={locale}>
                          <HalfInput2
                            format="DD/MM/YYYY"
                            value={warrantyEndDate}
                            onChange={handleWarrantyEndDate}
                          />
                        </LocalizationProvider>
                      </DateBox>
                    </YesDateBox>
                    <Stack>{<HelperText>{isWarrantyError}</HelperText>}</Stack>
                  </Stack>

                  <Stack
                    style={{
                      paddingBottom: "10px",

                      minHeight: "40px",
                      marginTop: "25px",
                    }}>
                    <Stack
                      sx={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}>
                      <Typography sx={{ fontWeight: 700 }}>
                        Upload Reports
                      </Typography>

                      <Stack
                        style={{
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",

                          flexDirection: "row",
                          display: "flex",
                        }}>
                        Img/Pdf
                        <AddIcon
                          onClick={handleAddRow}
                          size={20}
                          style={{
                            color: "rgba(27, 37, 53, 1)",
                            cursor: "pointer",
                          }}
                        />
                      </Stack>
                    </Stack>

                    <>
                      <Box>
                        {reportData?.map((row, index) => (
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginTop: "20px",
                            }}
                            key={index}>
                            <Select
                              sx={{
                                height: "45px",
                                background: "rgba(244, 246, 246, 1)",
                                width: "400px",
                                "& fieldset": {
                                  border: "none",
                                },
                              }}
                              value={row.report_name}
                              onChange={(event) =>
                                handleReportName(event, index)
                              }>
                              <MenuItem value="Service Report">
                                Service Report
                              </MenuItem>
                              <MenuItem value="Installation Report">
                                Installation Report
                              </MenuItem>
                              <MenuItem value="Warranty Document">
                                Warranty Document
                              </MenuItem>
                              <MenuItem value="Calibration Document">
                                Calibration Document
                              </MenuItem>
                              <MenuItem value="Service Invoice">
                                Service Invoice
                              </MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </Select>
                            {row.image ? (
                              <Stack
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  bgcolor: "rgba(244, 246, 246, 1)",

                                  width: "160px",
                                }}>
                                <Badge
                                  key={index}
                                  badgeContent={
                                    <CloseIcon
                                      size={15}
                                      style={{
                                        color: " rgba(255, 58, 68, 1)",
                                      }}
                                      onClick={() => handleClearImage(index)}
                                    />
                                  }
                                  sx={{
                                    // height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}>
                                  <Box
                                    as="img"
                                    src={`${
                                      row.imageid?.split(".")?.pop() === "pdf"
                                        ? pdfImage
                                        : row.image
                                    }`}
                                    alt=""
                                    sx={{ width: "40px", height: "40px" }}
                                  />
                                </Badge>
                              </Stack>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept=".pdf,.jpg,.jpeg,.png"
                                  onChange={(event) =>
                                    handleImageUpload(event, index)
                                  }
                                  style={{ display: "none" }}
                                  id="file-upload"
                                />

                                <label htmlFor="file-upload">
                                  <Stack
                                    sx={{
                                      height: "45px",
                                      width: "80px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background: "rgba(244, 246, 246, 1)",
                                    }}
                                    aria-label="split button">
                                    <FileUploadIcon
                                      size={20}
                                      sx={{ color: "black" }}
                                    />
                                  </Stack>
                                </label>
                              </>
                            )}

                            <IconButton onClick={() => handleRemoveRow(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ))}
                        <Typography>{fileError}</Typography>
                      </Box>
                    </>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    width: "50%",
                    height: "100%",

                    gap: "15px",
                    textAlign: "start",
                  }}>
                  <Typography style={{ color: "white" }}>. </Typography>

                  <Stack
                    sx={{
                      minHeight: "125px",
                      bgcolor: "#F4F6F6",
                      padding: "10px",
                      alignItems: "start",
                      gap: "20px",
                    }}>
                    <Label>Calibration Status</Label>
                    <CheckBoxContainer>
                      <FormRadio>
                        <RadioGroup
                          value={calibrationStatus}
                          onChange={handleCalibrationStatus}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group">
                          <FormControlLabel
                            value="Yes"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                Yes
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                No
                              </Typography>
                            }
                          />
                        </RadioGroup>
                        {<HelperText>{isCalibrationStatusError}</HelperText>}
                      </FormRadio>
                      {calibrationStatus === "Yes" && (
                        <YesDateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">Start Date</CustomText>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}>
                              <HalfInput2
                                value={calibrationStartDate}
                                format="DD/MM/YYYY"
                                onChange={handleCalibrationStartDate}
                              />
                            </LocalizationProvider>
                            <Box>
                              {
                                <HelperText>
                                  {isCalibrationDateError}
                                </HelperText>
                              }
                            </Box>
                          </DateBox>

                          <DueDateBox>
                            <DateBox padding="5px">
                              <CustomText fontWeight="600">End Date</CustomText>

                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={locale}>
                                <HalfInput2
                                  value={calibrationEndDate}
                                  onChange={handleCalibrationEndDate}
                                />
                              </LocalizationProvider>

                              <Box>
                                {
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#EFF5FE" }}>
                                    {isCalibrationDateError}
                                  </Typography>
                                }
                              </Box>
                            </DateBox>
                          </DueDateBox>
                        </YesDateBox>
                      )}
                      {calibrationStatus === "No" && (
                        <DateBox
                          padding="5px"
                          maxHeight="88px"
                          maxWidth="258px">
                          <CustomText fontWeight="600" textAlign={"left"}>
                            Required ?
                          </CustomText>
                          <FormRadio>
                            <Box borderRadius="6px">
                              <RadioGroup
                                onChange={handleRequired}
                                value={required}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                                <FormControlLabel
                                  value="Required"
                                  control={<Radio size="small" />}
                                  label={<CustomText>Required</CustomText>}
                                />
                                <FormControlLabel
                                  value="Not Required"
                                  control={<Radio size="small" />}
                                  label={<CustomText>Not Required</CustomText>}
                                />
                              </RadioGroup>
                              {<HelperText>{isRequiredError}</HelperText>}
                            </Box>
                          </FormRadio>
                        </DateBox>
                      )}
                    </CheckBoxContainer>
                  </Stack>
                  <Stack
                    sx={{
                      minHeight: "125px",
                      bgcolor: "#F4F6F6",
                      padding: "10px",
                      alignItems: "start",
                      gap: "20px",
                    }}>
                    <Label>CMC/AMC</Label>
                    <CheckBoxContainer>
                      <FormRadio onChange={handleAmcCmc}>
                        <RadioGroup
                          value={cmcAmc}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group">
                          <FormControlLabel
                            value="CMC"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                CMC
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="AMC"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                AMC
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="None"
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle1" fontWeight={500}>
                                None
                              </Typography>
                            }
                          />
                        </RadioGroup>
                        {<HelperText>{isCmcAmcError}</HelperText>}
                      </FormRadio>
                      {cmcAmc === "AMC" && (
                        <YesDateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">Start Date</CustomText>
                            {/* <HalfInput
                        
                          type="date"
                          onChange={handleAmcCmcStartDateChange}
                        /> */}
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}>
                              <HalfInput2
                                value={cmcAmcStartDate}
                                onChange={handleAmcCmcStartDateChange}
                              />
                            </LocalizationProvider>
                          </DateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">End Date</CustomText>
                            {/* <HalfInput
                        
                          type="date"
                          onChange={handleAmcCmcEndDateChange}
                        /> */}
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}>
                              <HalfInput2
                                value={cmcAmcEndDate}
                                onChange={handleAmcCmcEndDateChange}
                              />
                            </LocalizationProvider>
                          </DateBox>
                        </YesDateBox>
                      )}

                      {cmcAmc === "CMC" && (
                        <YesDateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">Start Date</CustomText>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}>
                              <HalfInput2
                                value={cmcAmcStartDate}
                                onChange={handleAmcCmcStartDateChange}
                              />
                            </LocalizationProvider>
                          </DateBox>
                          <DateBox padding="5px">
                            <CustomText fontWeight="600">End Date</CustomText>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}>
                              <HalfInput2
                                value={cmcAmcEndDate}
                                onChange={handleAmcCmcEndDateChange}
                              />
                            </LocalizationProvider>
                          </DateBox>
                        </YesDateBox>
                      )}
                      {<HelperText>{isAmcEndDateError}</HelperText>}
                      {<HelperText>{isCmcEndDateError}</HelperText>}
                    </CheckBoxContainer>
                  </Stack>
                </Stack>
              </Box>

              <BottomElement>
                <Label sx={{ marginTop: "15px" }}>Notes</Label>
                <NotesInput
                  placeholder="e.g. Regular inspection log maintained to comply with regulations."
                  value={notes}
                  onChange={handleNotes}
                  sx={{ bgcolor: "#F4F6F6", width: "100%" }}
                />
              </BottomElement>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}>
              <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                sx={{ width: "150px", borderRadius: "22px" }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: "150px", borderRadius: "22px" }}>
                Update
              </Button>
            </Box>
          </Box>
        </Stack>
      </Stack>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"Updated Asset successfully . "}
        subText2={"  "}
      />
    </Stack>
  );
};

export default EditAsset;
