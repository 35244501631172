import React, { useState } from "react";
import {
  Button,
  Select,
  IconButton,
  Box,
  Typography,
  Stack,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import pdfImage from "../images/pdfImage.png";

import { v4 as uuid } from "uuid";
import { AddAssetReport, assetDeleteReport } from "../redux/data/action";
import { useDispatch } from "react-redux";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';
const AddReport = ({ handleAssetReport, assetId }) => {
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const dispatch = useDispatch();

  const initialRow = {
    hospid: userIds.hospid,
    userid: userIds.userid,
    imageid: "",
    image: "",
    branchid: userBranch?.id,
    report_name: "Service Report",
  };

  const [openReport, setOpenReport] = useState(false);

  const [fileError, setFileError] = useState("");

  const [data, setData] = useState([initialRow]);
  const [dataToSend, setDataToSend] = useState([initialRow]);
  const [newRow, setNewRow] = useState({ ...initialRow });

  const handleOpenReport = () => {
    setOpenReport(true);
  };

  const handleCloseReport = () => {
    setOpenReport(false);
    const filteredData = dataToSend.filter((item) => item.image !== "");
    handleAssetReport(filteredData);
  };

  const handleAddRow = () => {
    setData([...data, { ...newRow }]);
    setDataToSend([...data, { ...newRow }]);
    setNewRow({ ...initialRow });
  };

  const handleRemoveRow = (index) => {
    const updatedData = [...data];
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };
    dispatch(assetDeleteReport(dataDelete));
    updatedData.splice(index, 1);
    setData(updatedData);
    setDataToSend(updatedData);
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    const updatedData = [...data];

    if (file) {
      const allowedTypes = ["pdf", "jpg", "jpeg", "png"];
      const fileType = file.type.split("/")[1];

      if (allowedTypes.includes(fileType)) {
        const splitId = uuid().substring(0, 6);

        const imageid = assetId.substring(0, 6);
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          updatedData[index].imageid = `${imageid}_${splitId}.${fileType}`;
          updatedData[index].image = dataUrl;
          setData(updatedData);
          setDataToSend(updatedData);
          let assetRep = {
            hospid: userIds.hospid,
            userid: userIds.userid,
            imageid: updatedData[index].imageid,
            image: updatedData[index].image,
            branchid: userBranch?.id,
          };

          dispatch(AddAssetReport(assetRep));
          setFileError("");
          handleUpload()
        };
        reader.readAsDataURL(file);
      } else {
        setFileError("Please upload pdf, jpg, jpeg or png file");
      }
    } else {
      updatedData[index].imageid = "";
      updatedData[index].image = "";
      setData(updatedData);
      setDataToSend(updatedData);
      
    }
  };

  const handleClearImage = (index) => {
    const updatedData = [...data];
    updatedData[index].image = "";
    setData(updatedData);
    setDataToSend(updatedData);
    let dataDelete = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: updatedData[index].imageid,
      branchid: userBranch?.id,
    };

    dispatch(assetDeleteReport(dataDelete));
  };

  const handleReportName = (event, index) => {
    const updatedData = [...data];
    const selectedReportName = event.target.value;
    updatedData[index].report_name = selectedReportName;
    setData(updatedData);
    setDataToSend(updatedData);
  };

  const handleUpload = () => {
    const filteredData = dataToSend.filter((item) => item.image !== "");
    handleAssetReport(filteredData);
    
  };

  return (
    <Stack
      style={{
        paddingBottom: "10px",

        minHeight: "40px",
        marginTop: "25px",
      }}>
      <Stack sx={{ justifyContent: "space-between", flexDirection: "row" }}>
        <Typography sx={{ fontWeight: 700 }}>Upload Reports</Typography>

        <Stack
          style={{
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",

            flexDirection: "row",
            display: "flex",
          }}>
          Img/Pdf
          {openReport ? (
            <>
              <AddIcon
                onClick={handleAddRow}
                size={20}
                style={{ color: "rgba(27, 37, 53, 1)" }}
              />
            </>
          ) : (
            <>
              <AddIcon
                onClick={handleOpenReport}
                size={20}
                style={{ color: "rgba(27, 37, 53, 1)" }}
              />
            </>
          )}
        </Stack>
      </Stack>

      {openReport && (
        <>
          <Box>
            {data?.map((row, index) => (
              <div
                style={{ display: "flex", gap: "10px", marginTop: "20px" }}
                key={index}>
                <Select
                  sx={{
                    height: "45px",
                    background: "rgba(244, 246, 246, 1)",
                    width: "400px",

                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  value={row.report_name}
                  onChange={(event) => handleReportName(event, index)}>
                  <MenuItem value="select_report" disabled>
                    Select Report
                  </MenuItem>
                  <MenuItem value="Service Report">Service Report</MenuItem>
                  <MenuItem value="Installation Report">
                    Installation Report
                  </MenuItem>
                  <MenuItem value="Warranty Document">
                    Warranty Document
                  </MenuItem>
                  <MenuItem value="Calibration Document">
                    Calibration Document
                  </MenuItem>
                  <MenuItem value="Service Invoice">Service Invoice</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
                {row.image ? (
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "rgba(244, 246, 246, 1)",

                      width: "160px",
                    }}>
                    <Box
                      width={"40px"}
                      height={"40px"}
                      as="img"
                      src={`${
                        row.imageid?.split(".")?.pop() === "pdf"
                          ? pdfImage
                          : row.image
                      }`}
                      alt="Selected"
                    />
                  </Stack>
                ) : (
                  <>
                    <input
                      type="file"
                      accept=".pdf,.jpg,.jpeg,.png"
                      onChange={(event) => handleImageUpload(event, index)}
                      style={{ display: "none" }}
                      id="file-upload"
                    />

                    <label htmlFor="file-upload">
                      <Stack
                        sx={{
                          height: "45px",
                          width: "80px",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(244, 246, 246, 1)",
                        }}
                        aria-label="split button">
                        <FileUploadIcon size={20} sx={{ color: "black" }} />
                      </Stack>
                    </label>
                  </>
                )}

                <IconButton onClick={() => handleRemoveRow(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Typography>{fileError}</Typography>
            {/* <Box
              sx={{
                display: "flex",
                minWidth: "240px",
                paddingTop: "10px",
                justifyContent: "space-between",
              }}>
              <Button
                sx={{ textTransform: "none", bgcolor: "#1746A2" }}
                variant="contained"
                onClick={handleAddRow}>
                Add Row
              </Button>
              {data?.length !== 0 ? (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    "&:hover": {
                      bgcolor: "#1746A2",
                      color: "white",
                    },
                  }}
                  onClick={handleUpload}>
                  Submit
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={handleCloseReport}>
                  Cancel
                </Button>
              )}
            </Box> */}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default AddReport;
