import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import deleteimg from "../images/delete.png";

function DeleteModal({ open, onClose, onDelete, id, text }) {
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const dialogStyle = {
    width: isSmallScreen ? "80vw" : "40vw",
    height: "220px",
    borderRadius: "8px",
  };

  const imageStyle = {
    width: "70px",
    height: "70px",
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        PaperProps={{ style: isSmallScreen ? dialogStyle : dialogStyle }}>
        <DialogTitle id="alert-dialog-title" sx={{ display: "flex" }}>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              width: "100%",
            }}>
            <img src={deleteimg} alt="Delete Icon" style={imageStyle} />

            <DialogContent
              sx={{
                width: "100%",
                padding: "0px",
                paddingLeft: "2%",
                paddingTop: "2%",
              }}>
              {`Delete ${text}`}
              <DialogContentText
                id="alert-dialog-description"
                style={{ width: "100%" }}>
                Are you sure you want to delete this {`${text}`} ? This action
                cannot be undone.
              </DialogContentText>
            </DialogContent>
          </div>
        </DialogTitle>

        <DialogActions sx={{ width: "95%" }}>
          <Button
            onClick={onClose}
            size="large"
            style={{
              border: "1px solid var(--gray-300, #D0D5DD)",
              color: "black",
              borderRadius: "8px",
              marginRight: "2%",
              textTransform: "none",
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => onDelete(id)}
            style={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "8px",
              textTransform: "none",
            }}
            autoFocus
            size="large">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
export default DeleteModal;
