import {
  Box,
  Button,
  IconButton,
  Table,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import { DownloadPDF, getIncidentDetail } from "../redux/nbmData/action";
import IncidentPdf from "../components/IncidentPdf";

const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  border: "3px solid #1746A2",
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "70%",
  justifyContent: "center",
  alignItems: "center",
  // height: '1163px',
  paddingLeft: "20px",

  borderRadius: "30px",

  paddingBottom: "20px",

  [theme.breakpoints.down("xl")]: {
    // width: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    padding: "2px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const PhotoDiv = styled(Box)(({ theme }) => ({
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12)",
  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-evenly",
  alignItems: "center",
  height: "183px",
  [theme.breakpoints.down("xl")]: {
    height: "160px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const PhotoMain = styled(Box)(({ theme }) => ({
  marginTop: "20px",

  borderRadius: "10px",
  width: "100%",
  display: "flex",

  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {
    // width: "710px",

    gap: "10px",
  },
  [theme.breakpoints.down("lg")]: {
    // width: "510px",

    gap: "10px",
    // flexDirection:"column",
  },
  [theme.breakpoints.down("md")]: {
    // width: "330px",

    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    // width: "330px",

    flexDirection: "column",
  },
}));

const TextLabel = styled(Typography)(({ theme }) => ({
  // border: "1px solid red",
  textAlign: "left",
  color: "#1746A2",
  fontSize: "24px",
  marginTop: "3%",
  fontWeight: 400,
  marginBottom: "1%",
  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const PhotoCard = styled(Box)(({ theme }) => ({
  // border: "1px solid red",
  width: "100px",
  height: "100px",

  borderRadius: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100px",
    height: "100px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("md")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60px",
    height: "60px",
    borderRadius: "10px",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "90%",

  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "90%",
  },
}));

const Download = styled(Button)(({ theme }) => ({
  color: "white",

  fontWeight: 400,
  textTransform: "none",
  borderRadius: "35px",
  backgroundColor: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    height: "40px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "40px",
  },
  [theme.breakpoints.down("xs")]: {
    height: "40px",
  },
}));

const TableDivider = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  color: "#1746A2",
  fontSize: "26px",
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "22px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  padding: "1%",
  flexDirection: "column",
  justifyContent: "space-evenly",
  marginBottom: "20px",
  textAlign: "left",
  [theme.breakpoints.down("xl")]: {
    // width: "90%",
  },
  [theme.breakpoints.down("lg")]: {
    // width: "580px",
  },
  [theme.breakpoints.down("md")]: {
    // width: "350px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerTop = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",

  justifyContent: "right",

  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const OuterBottom = styled(Box)(({ theme }) => ({
  // border: "2px solid blue",
  marginTop: "20px",
  width: "980px",

  display: "flex",
  marginBottom: "5px",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    width: "740px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "580px",
  },
  [theme.breakpoints.down("md")]: {
    width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const OuterSecondLastBottom = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  width: "100%",

  display: "flex",
  marginBottom: "5px",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    marginTop: "10px",
    //width: "740px",
  },
  [theme.breakpoints.down("lg")]: {
    //width: "580px",
    marginTop: "0px",
  },
  [theme.breakpoints.down("md")]: {
    //width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    //width: "350px",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TableElement1 = styled(TableCell)(({ theme }) => ({
  textTransform: "none",
  height: "1px",
  whiteSpace: "nowrap",
  padding: "6px",
  fontWeight: "500",
  color: "#1746A2",
  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const TableElement2 = styled(TableCell)(({ theme }) => ({
  textTransform: "none",
  height: "1px",

  padding: "6px",
  fontWeight: "500",

  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const DivideRow = styled(TableDivider)(({ theme }) => ({
  textTransform: "none",
  height: "1px",
  fontSize: "22px",
  padding: "2px",
  paddingRight: "5px",
  // margginTop:'20px',
  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const MainTable = styled(Table)(({ theme }) => ({
  // border: "1px solid red",
  textTransform: "none",
  height: "1px",
  position: "static",
  paddingBottom: "10px",

  borderBottom: "0",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const PhotoInner = styled(Box)(({ theme }) => ({
  // border: "1px solid brown",
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  width: "40%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {},
}));

function IncidentDetails() {
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  let userData = useSelector((store) => store.nbmData.incidentDetails);
  
  let userBranch =
  JSON.parse(sessionStorage.getItem("userBranch")) ||
  JSON.parse(localStorage.getItem("userBranch"));

  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      incidid: params.id,
      branchid: userBranch?.id
    };
    dispatch(getIncidentDetail(data, token));
  }, [dispatch]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;

    link.download = "downloaded_image.jpg";
    link.click();
  };

  return (
    <MainDiv>
      <TopContainer>
        <TitleHead>Incidents Details</TitleHead>
      </TopContainer>
      <InnerContainer sx={{ bgcolor: "white" }}>
        <InnerTop>
          <div
            style={{
              position: "relative",
              width: "160px",
              height: "145px",

              overflow: "hidden",
            }}>
            <div
              style={{
                position: "absolute",
                top: "-16px",
                right: "14px",
                backgroundColor:
                  userData?.incid_status === "Open" ? "red" : "green",
                color: "#fff",
                padding: "5px",
                fontSize: "14px",
                transform: "translate(50%, -50%) rotate(45deg)",
                transformOrigin: "0 0",
                width: "calc(100% + 57px)",
                textAlign: "center",
              }}>
              {userData?.incid_status === "Open" && (
                <Typography variant="outlined">Not Cleared</Typography>
              )}
              {userData?.incid_status === "Cleared" && (
                <Typography variant="outlined">Cleared</Typography>
              )}
            </div>
          </div>
        </InnerTop>
        <TextContainer sx={{}}>
          <MainTable sx={{ position: "static" }}>
            <TableRow>
              <TableElement1>
                <Typography>Asset Name</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2
                component="th"
                scope="row"
                style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                <Typography>{userData?.assetname}</Typography>
              </TableElement2>
            </TableRow>
            <TableRow>
              <TableElement1>
                <Typography> Asset code</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2
                component="th"
                scope="row"
                style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                <Typography>{userData?.assetcode}</Typography>
              </TableElement2>
            </TableRow>
            <TableRow>
              <TableElement1>
                <Typography>Department</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2
                style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                <Typography>{userData?.deptname}</Typography>
              </TableElement2>
            </TableRow>
            <TableRow>
              <TableElement1>
                <Typography>Issue Date</Typography>
              </TableElement1>
              <DivideRow>:</DivideRow>
              <TableElement2>
                <Typography>{userData?.start_date}</Typography>
              </TableElement2>
            </TableRow>
            {userData?.incid_status === "Cleared" && (
              <>
                <TableRow>
                  <TableElement1>
                    <Typography>Clear Date</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    <Typography>{userData?.clear_date}</Typography>
                  </TableElement2>
                </TableRow>
                <TableRow>
                  <TableElement1>
                    <Typography>Start Time</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    <Typography>{userData?.start_time}</Typography>
                  </TableElement2>
                </TableRow>
                <TableRow>
                  <TableElement1>
                    <Typography>Clear Time</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>{userData?.clear_time}</TableElement2>
                </TableRow>
                <TableRow>
                  <TableElement1>
                    <Typography>Total Downtime </Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    <Typography>{userData?.tot_downtime}</Typography>{" "}
                  </TableElement2>
                </TableRow>
                <TableRow>
                  <TableElement1>
                    <Typography>Name</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2
                    style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                    <Typography>{userData?.person_name} </Typography>
                  </TableElement2>
                </TableRow>
                <TableRow
                  sx={
                    {
                      // wordWrap: "break-word",
                    }
                  }>
                  <TableElement1>
                    <Typography>Email</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2
                    component="th"
                    scope="row"
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                    }}>
                    <Typography>{userData?.person_email} </Typography>
                  </TableElement2>
                </TableRow>
                <TableRow>
                  <TableElement1>
                    <Typography>Mobile Number</Typography>
                  </TableElement1>
                  <DivideRow>:</DivideRow>
                  <TableElement2>
                    <Typography>{userData?.person_phone}</Typography>
                  </TableElement2>
                </TableRow>
              </>
            )}
          </MainTable>
        </TextContainer>
        <BottomContainer>
          <TextLabel>Issue</TextLabel>

          <Typography>{userData?.incdt_issue}</Typography>

          {userData?.incid_status === "Cleared" && (
            <>
              <TextLabel>
                <Typography>Action Taken</Typography>
              </TextLabel>

              <Typography>{userData?.actiontaken}</Typography>

              <TextLabel>
                <Typography>Feedback</Typography>
              </TextLabel>

              <Typography>{userData?.feedback}</Typography>
            </>
          )}
          <PhotoMain>
            {userData?.incid_status === "Cleared" &&
              userData?.incdt_imgs !== "NA" &&
              userData?.incdt_imgs !== "" && (
                <PhotoInner>
                  <TextLabel>Issue Images</TextLabel>
                  <PhotoDiv sx={{ bgcolor: "#EFF5FE" }}>
                    {userData?.incdt_imgs !== "NA" &&
                      userData?.incdt_imgs?.map((images) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}>
                          <PhotoCard
                            as="img"
                            src={images}
                            alt="incImage"
                            sx={{ bgcolor: "white" }}
                          />
                          <IconButton onClick={() => handleDownload(images)}>
                            {" "}
                            <FileDownloadIcon color="warning" />
                          </IconButton>
                        </Box>
                      ))}
                  </PhotoDiv>
                </PhotoInner>
              )}
            {userData?.incid_status === "Cleared" &&
              userData?.clear_imgs !== "NA" &&
              userData?.clear_imgs !== "" && (
                <PhotoInner>
                  <TextLabel>Cleared Images</TextLabel>
                  <PhotoDiv sx={{ bgcolor: "#EFF5FE" }}>
                    {userData?.clear_imgs !== "NA" &&
                      userData?.clear_imgs?.map((images) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}>
                          <PhotoCard
                            as="img"
                            src={images}
                            alt="incImage"
                            sx={{ bgcolor: "white" }}
                          />
                          <IconButton onClick={() => handleDownload(images)}>
                            {" "}
                            <FileDownloadIcon color="warning" />
                          </IconButton>
                        </Box>
                      ))}
                  </PhotoDiv>
                </PhotoInner>
              )}

            {userData?.incid_status === "Open" &&
              userData?.incdt_imgs !== "NA" &&
              userData?.incdt_imgs !== "" && (
                <PhotoInner>
                  <TextLabel sx={{ border: "none" }}>Issue Image</TextLabel>
                  <PhotoDiv sx={{ bgcolor: "#EFF5FE" }}>
                    {userData?.incdt_imgs !== "NA" &&
                      userData?.incdt_imgs?.map((images) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}>
                          <PhotoCard as="img" src={images} alt="incImage" />
                          <IconButton onClick={() => handleDownload(images)}>
                            {" "}
                            <FileDownloadIcon color="warning" />
                          </IconButton>
                        </Box>
                      ))}
                  </PhotoDiv>
                </PhotoInner>
              )}
          </PhotoMain>
        </BottomContainer>
      </InnerContainer>
      {userData.incid_status === "Cleared" && (
        <OuterSecondLastBottom>
          <IncidentPdf data={userData} />
        </OuterSecondLastBottom>
      )}
      {userData?.incid_status === "Open" && (
        <OuterBottom>
          <Link
            style={{ textDecoration: "none" }}
            to={`/asset-detail/${userData?.assetid}`}>
            <Button
              size="large"
              sx={{
                bgcolor: "#1746A2",
                textTransform: "none",

                borderRadius: "35px",
              }}
              variant="contained"
              value="clear">
              Clear Incident
            </Button>
          </Link>
        </OuterBottom>
      )}
    </MainDiv>
  );
}

export default IncidentDetails;
