import React from 'react'
import Nodata from "../images/Nodata.png";
import { Box, Button, CardContent, CardMedia, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';






const EmptyWrapper = styled(Box)(({ theme }) => ({
    marginTop: "2%",
  
    width: "80%",
    borderRadius: "15px",
  
    background: "white",
    [theme.breakpoints.down("xl")]: {
      width: "80%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
     
    },
    [theme.breakpoints.down("xs")]: {
     
    },
  }));


function NoData({text}) {
const navigate = useNavigate()

  return (
    <EmptyWrapper
    // style={{ overflowX: "auto" }}
    sx={{
      display: "flex",
      flexDirection: "column",
      borderRadius: " 15px ",
      justifyContent: "center",
      alignItems: "center",
      // : "400px",
    }}>
    <CardContent>
      <Typography
        sx={{ color: " #1746A2", fontWeight: 700 }}
        color="text.secondary"
        gutterBottom>
       { `Sorry, no matching ${text} found on this page.`}
      </Typography>
    </CardContent>
    <CardMedia
      component="img"
      sx={{ width: "40%", height: "40%" }}
      image={Nodata}
      alt="green iguana"
    />

    <Box sx={{display:"flex", gap:"40px", padding:"20px"}}>
      <Button
        onClick={() => navigate("/home")}
        sx={{
          borderRadius: "35px",
          textTransform: "none",
          bgcolor: "#1746A2",
        }}
        variant="contained">
        Go to Home page
      </Button>


    
    </Box>
  </EmptyWrapper>
  )
}

export default NoData