import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { DownloadPDF, userManual } from "../redux/data/action";

import { useEffect } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderBottom: "1px solid rgba(195, 195, 195, 1)",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: "26px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const UserManualList = () => {
  const userManualData = useSelector((store) => store.data?.userManual);
  const localStorageId = JSON.parse(localStorage.getItem("userData"));
  const [brandData, setBrandData] = useState([]);

  const [manualBrand, setManualBrand] = useState("");

  const manual = userManualData?.brandlist?.map((el) => el?.brand_icon_url);

  const [productName, setProductName] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  const search = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = userManualData?.manuallist?.filter((item) => {
        return item?.product_name.toLowerCase().includes(keyword.toLowerCase());
      });

      let data = {
        manuallist: results,
      };

      setManualBrand(data);
    } else {
      setManualBrand(userManualData);
    }

    setProductName(keyword);
  };

  for (let i = 0; i < manual?.length; i++) {}

  useEffect(() => {
    let data = {
      hospid: localStorageId?.hospid,
      userid: localStorageId?.userid,
    };
    dispatch(userManual(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (userManualData?.brandlist?.length > 0) {
      setBrandData(userManualData?.brandlist);
    }
  }, [userManualData?.brandlist]);
  useEffect(() => {
    if (userManualData?.manuallist?.length > 0) {
      let data = {
        manuallist: userManualData?.manuallist,
      };
      setManualBrand(data);
    }
  }, [userManualData?.manuallist]);

  const handleFilter = (clickedname) => {
    const filtered = userManualData?.manuallist?.filter(
      (item) => item?.brand_name === clickedname?.brand_name
    );

    if (filtered?.length !== "") {
      let data = {
        manuallist: filtered,
      };
      setManualBrand(data);
    } else {
      setManualBrand(userManualData);
    }
  };
  return (
    <Stack>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        gap="10px"
        height="85vh"
        width={"100%"}>
        <Stack
          style={{
            width: "40%",
            gap: "10px",
            height: "100%",
          }}>
          <Stack
            flexDirection={"row"}
            height="50px"
            alignItems={"center"}
            padding="16px"
            gap={"10px"}
            sx={{
              bgcolor: "white",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              width: "100%",
            }}>
            Total brands:
            <Typography style={{ color: "rgba(70, 144, 255, 1)" }}>
              {brandData.length}
            </Typography>
          </Stack>

          <Stack
            sx={{
              bgcolor: "white",
              alignItems: "start",
              padding: "15px",

              width: "100%",
              gap: "10px",
              textAlign: "start",
              justifyContent: "start",

              height: "100%",

              overflow: "auto",
            }}>
            <Typography>Brands</Typography>
            <Stack
              sx={{
                width: "100%",
                background: "rgba(70, 144, 255, 1)",
                margin: "5px 0",
              }}>
              <Divider />
            </Stack>
            {brandData?.map((imageUrl, index) => (
              <>
                <Stack
                  onClick={() => handleFilter(imageUrl)}
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}>
                  <Button sx={{ color: "black" }}>
                    {imageUrl?.brand_name}
                  </Button>
                  <Box
                    as="img"
                    sx={{ height: "50px", width: "50px" }}
                    src={imageUrl?.brand_icon_url}
                  />
                </Stack>
              </>
            ))}
          </Stack>
        </Stack>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          style={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px">
            <Box flex="2">
              <Stack
                flexDirection={"row"}
                height="50px"
                justifyContent={"space-between"}
                alignItems={"center"}
                padding="16px"
                gap={"10px"}
                sx={{
                  bgcolor: "white",
                  boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon size={15} style={{ color: "" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    value={productName}
                    onChange={search}
                  />
                </Search>
              </Stack>
            </Box>
          </Box>
          <Stack
            style={{
              width: "100%",
              height: "100%",

              bgcolor: "white",
            }}
            overflow="auto"
            flexDirection={"column"}>
            <TableContainer
              component={Paper}
              style={{
                height: "100%",

                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableHeading> Asset Name</TableHeading>
                    <TableHeading> Model No</TableHeading>
                    <TableHeading style={{ textAlign: "center" }}>
                      Download Pdf
                    </TableHeading>
                  </TableRow>
                </TableHead>
                {/* Table Body with Scrollbar */}
                <TableBody style={{ overflowY: "scroll" }}>
                  {manualBrand?.manuallist?.map((el) => (
                    <TableRow key={el?.prod_id}>
                      <TableCells
                        component={Link}
                        sx={{ textDecoration: "none" }}>
                        <Tooltip title={el?.product_name}>
                          {el?.product_name}
                        </Tooltip>
                      </TableCells>

                      <TableCells>{el?.model_no}</TableCells>
                      <TableCells style={{ textAlign: "center" }}>
                        <DownloadIcon
                          size={20}
                          onClick={() =>
                            dispatch(
                              DownloadPDF(el?.manual_link, el?.product_name)
                            )
                          }
                        />
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack
              sx={{
                width: "100%",
                padding: "20px",
                bgcolor: "white",
                gap: "20px",
              }}>
              <Divider />
              <Stack
                sx={{
                  bgcolor: "white",

                  alignItems: "flex-end",
                  flexDirection: "column",
                }}>
                <Button
                  onClick={() => navigate("/contact")}
                  variant="contained"
                  sx={{
                    borderRadius: "22px",
                    background: "rgba(70, 144, 255, 1)",
                    marginRight: "20px",
                    width: "170px",
                  }}>
                  Request Manual
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default UserManualList;
