import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import CheckIn from "./CheckIn";
import CheckOut from "./CheckOut";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",

  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleBox = styled(Box)(({ theme }) => ({
  paddingTop: "10px",
  width: "90%",

  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  paddingBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  textAlign: "left",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const VendorButton = styled(Button)(({ theme, active }) => ({
  width: "200px",
  borderRadius: "10px 0 0 10px",
  textTransform: "none",
  transition: "background-color 0.3s, color 0.3s",
  backgroundColor: active ? "#FF731D" : "white",
  color: active ? "white" : "#FF731D",
  boxShadow: "rgba(0, 0, 0, 0.25)",

  "&:active": {
    backgroundColor: "#FF731D",
    color: "#FF731D",
  },

  "&:hover": {
    backgroundColor: active ? "#FF731D" : "white",
    color: active ? "white" : "#FF731D",
  },

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "180px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
  [theme.breakpoints.down("xs")]: {
    // width: '130px',
    width: "130px",
  },
}));

const ServiceButton = styled(Button)(({ theme, active }) => ({
  width: "200px",
  borderRadius: "0 10px 10px 0",
  textTransform: "none",
  transition: "background-color 0.3s, color 0.3s",
  backgroundColor: active ? "#FF731D" : "white",
  color: active ? "white" : "#FF731D",
  boxShadow: "rgba(0, 0, 0, 0.25)",
  "&:active": {
    backgroundColor: "#FF731D",
    color: "#FF731D",
  },

  "&:hover": {
    backgroundColor: active ? "#FF731D" : "white",
    color: active ? "white" : "#FF731D",
  },

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "180px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "130px",
  },
}));

function CheckinOut() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  return (
    <Wrapper>
      <TitleBox>
        <TitleHead></TitleHead>
      </TitleBox>
      <TopContainer>
        <ButtonBox>
          <VendorButton
            sx={{
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              "&:hover": null,
            }}
            onClick={() => navigate("/checkin-checkout/checkout")}
            active={path === "/checkin-checkout/checkout"}>
            Check out
          </VendorButton>
          <ServiceButton
            sx={{
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              "&:hover": null,
            }}
            onClick={() => navigate("/checkin-checkout/checkin")}
            active={path === "/checkin-checkout/checkin"}>
            Check in
          </ServiceButton>
        </ButtonBox>
      </TopContainer>

      {path === "/checkin-checkout/checkin" && <CheckIn />}
      {path === "/checkin-checkout/checkout" && <CheckOut />}
    </Wrapper>
  );
}

export default CheckinOut;
