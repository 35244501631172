import {
  FORGOT_AUTH_FAILURE,
  FORGOT_AUTH_REQUEST,
  FORGOT_AUTH_SUCCESS,
  GET_AUTH_FAILURE,
  GET_AUTH_REQUEST,
  GET_AUTH_SUCCESS,
  GET_VERIFICATION_LINK_FAILURE,
  GET_VERIFICATION_LINK_REQUEST,
  GET_VERIFICATION_LINK_SUCCESS,
  POST_AUTH_FAILURE,
  POST_AUTH_REQUEST,
  POST_AUTH_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REGISTER_AUTH_FAILURE,
  REGISTER_AUTH_REQUEST,
  REGISTER_AUTH_SUCCESS,
} from "./action";

const initState = {
  isLoading: false,
  isError: false,
  auth: [],
  signInAuth: [],
  signUpAuth: [],
  forgotAuth: [],
  getLink:[],
  isAuth: false,
  token: "",
  registerUser: [],
  refreshToken:[]
};

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case POST_AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case POST_AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        signUpAuth: action.payload,
      };

    case POST_AUTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case GET_AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case GET_AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        signInAuth: action.payload,
        isAuth: true,
      };

    case GET_AUTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isAuth: true,
      };
    case FORGOT_AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case FORGOT_AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        forgotAuth: action.payload,
      };

    case FORGOT_AUTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case REGISTER_AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case REGISTER_AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        registerUser: action.payload,
      };

    case REGISTER_AUTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };


      case GET_VERIFICATION_LINK_REQUEST:
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
  
      case GET_VERIFICATION_LINK_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          getLink: action.payload,
        };
  
      case GET_VERIFICATION_LINK_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
        case REFRESH_TOKEN_REQUEST:
          return {
            ...state,
            isLoading: true,
            isError: false,
          };
    
        case REFRESH_TOKEN_SUCCESS:
          return {
            ...state,
            isLoading: false,
            isError: false,
            refreshToken: action.payload,
          };
    
        case REFRESH_TOKEN_FAILURE:
          return {
            ...state,
            isLoading: false,
            isError: true,
          };

    default:
      return {
        ...state,
      };
  }
};
