import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PrivateRoute from "../routes/PrivateRoute";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import Vendor from "./Vendor";
import ServiceComanies from "./ServiceCompanyList";
import { useDispatch, useSelector } from "react-redux";
import { getVendor, getVendorService } from "../redux/nbmData/action";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",

  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  textAlign: "left",
  fonteight: "400",
  marginBottom: "10px",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SortAndFilter = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "end",
  paddingBottom: "10px",
  gap: "20px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const VendorButton = styled(Button)(({ theme, active }) => ({
  width: "200px",
  fontSize: "16px",
  borderRadius: "10px 0 0 10px",
  boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  transition: "background-color 0.3s, color 0.3s",
  backgroundColor: active ? "#FF731D" : "white",
  color: active ? "white" : "#FF731D",

  "&:active": {
    backgroundColor: "#FF731D",
    color: "#FF731D",
  },

  "&:hover": {
    backgroundColor: "#FF731D",
    color: "white",
  },

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "180px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
  [theme.breakpoints.down("xs")]: {
    // width: '130px',
    width: "130px",
  },
}));

const ServiceButton = styled(Button)(({ theme, active }) => ({
  width: "200px",
  boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.25)",
  fontSize: "16px",
  borderRadius: "0 10px 10px 0",
  textTransform: "none",
  transition: "background-color 0.3s, color 0.3s",
  backgroundColor: active ? "#FF731D" : "white",
  color: active ? "white" : "#FF731D",

  "&:active": {
    backgroundColor: "#FF731D",
    color: "#FF731D",
  },

  "&:hover": {
    backgroundColor: "#FF731D",
    color: "white",
  },

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "180px",
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "130px",
  },
}));

const FilterInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterMain = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FilterText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FormWrapper = styled(FormGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const Search = styled(OutlinedInput)(({ theme }) => ({
  width: 230,
  height: "40px",
  border: "1px solid rgba(23, 70, 162, 0.5)",
  bgcolor: "white",
  "& fieldset": { border: "none" },
  borderRadius: "36px",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "140px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "180px",
    height: "35px",
  },
  [theme.breakpoints.down("xs")]: {},
}));
const TitleBox = styled(Typography)(({ theme }) => ({
  width: "90%",
  paddingTop: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    // width: "330px",
  },
  [theme.breakpoints.down("xs")]: {
    // width: "280px",
  },
}));

const CountText = styled(Typography)(({ theme }) => ({
  color: "#1746A2",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

function VendorRelations() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const status = useSelector((store) => store.nbmData.postVendor);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const serviceStatus = useSelector((store) => store.nbmData.postVendorService);
  const vendorData = useSelector((store) => store.nbmData.getVendor);
  const serviceData = useSelector((store) => store.nbmData.getVendorService);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeButton, setActiveButton] = useState("vendor");
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [data, setData] = useState([]);
  const [sortAscending, setSortAscending] = useState(true);
  const [filterCompanies, setFilterCompanies] = useState([]);

  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);
  const [assetName, setAssetName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [filterChanged, setFilterChanged] = useState(false);

 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };
  const filter = (e) => {
    const keyword = e.target.value;
    setAssetName(keyword);

    const lowerCaseKeyword = keyword.toLowerCase();
    let filteredData;

    if (keyword === "") {
      filteredData =
        path === "/vendor-relations/service" ? serviceData : vendorData;
    } else {
      const results = (
        path === "/vendor-relations/service" ? serviceData : vendorData
      )?.filter((item) => {
        const includesKeyword = (field) =>
          field.toLowerCase()?.includes(lowerCaseKeyword);

        return (
          lowerCaseKeyword === "" ||
          includesKeyword(item.company) ||
          (item?.assetname !== "NA" && item?.assetname.some(includesKeyword)) ||
          (item?.assetcode !== "NA" && item?.assetcode.some(includesKeyword))
        );
      });

      filteredData = results;
    }

    setData(filteredData);
    setPage(0);
    setFilterChanged(true);
  };
  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const sortData = (value) => {
    const sortedData = [...data].sort((a, b) => {
      if (value) {
        return a.company.localeCompare(b.company);
      } else {
        return b.company.localeCompare(a.company);
      }
    });
    setData(sortedData);
    setPage(0);
    setFilterChanged(true);
  };

  const handleCompanyFilter = (event) => {
    const selectedCompany = event.target.value;
    if (filterCompanies.includes(selectedCompany)) {
      setFilterCompanies(
        filterCompanies?.filter((company) => company !== selectedCompany)
      );
    } else {
      setFilterCompanies([...filterCompanies, selectedCompany]);
    }
    setPage(0);
    setFilterChanged(true);
  };

  const filteredData = data?.filter((item) => {
    if (filterCompanies?.length === 0) {
      return true; // No filters applied, show all items
    }
    return filterCompanies.includes(item.company);
  });

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      branchid: userBranch?.id,
    };
    dispatch(getVendor(data));
  }, [dispatch, status]);

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
      branchid: userBranch?.id,
    };
    dispatch(getVendorService(data));
  }, [dispatch, serviceStatus]);

  useEffect(() => {
    if (path === "/vendor-relations/vendor") {
      if (vendorData && vendorData?.length !== undefined) {
        setData(vendorData);
      }
    }
    if (path === "/vendor-relations/service") {
      if (serviceData && serviceData?.length !== undefined) {
        setData(serviceData);
      }
    }
  }, [vendorData, path, serviceData]);

  return (
   
    <Wrapper>
      <TitleBox>
        <TitleHead>Vendor Relations</TitleHead>
        {data?.length > 0 && <CountText>{`Total : ${data?.length}`}</CountText>}
      </TitleBox>
      <TopContainer>
        <ButtonBox>
          <VendorButton
            onClick={() => navigate("/vendor-relations/vendor")}
            active={path === "/vendor-relations/vendor"}>
            Vendor
          </VendorButton>
          <ServiceButton
            onClick={() => navigate("/vendor-relations/service")}
            active={path === "/vendor-relations/service"}>
            Service Companies
          </ServiceButton>
        </ButtonBox>
      </TopContainer>

      {(path === "/vendor-relations/service" && serviceData.length > 0) ||
      (path === "/vendor-relations/vendor" && vendorData.length > 0) ? (
        <SortAndFilter>
          <Search
            type="search"
            value={assetName}
            placeholder="Search"
            onChange={filter}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  placeholder="Search"
                  aria-label="toggle password visibility"
                  edge="start">
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            }
          />

          <div>
            <IconButton
              id="basic-button"
              aria-controls={openSort ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openSort ? "true" : undefined}
              onClick={handleSort}>
              <SortByAlphaIcon sx={{ color: "#FF731D" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorElSort}
              open={openSort}
              onClose={handleCloseSort}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <MenuItem sx={{ color: "#FF731D" }}>Name Sort</MenuItem>
              <MenuItem
                // checked={sortAscending}
                onClick={() => sortData(true)}>
                A-Z
              </MenuItem>
              <MenuItem
                // checked={!sortAscending}
                onClick={() => sortData(false)}>
                Z-A
              </MenuItem>
            </Menu>
          </div>

          <div>
            <IconButton
              id="basic-button"
              aria-controls={openFilter ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openFilter ? "true" : undefined}
              onClick={handleFilter}>
              <FilterAltOutlinedIcon sx={{ color: "#FF731D" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorElFilter}
              open={openFilter}
              onClose={handleCloseFilter}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <MenuItem>
                <FilterMain>
                  <FilterText display="block">Company</FilterText>

                  <FilterInnerContainer>
                    <FormWrapper>
                      {data?.map((item) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="warning"
                              type="checkbox"
                              value={item.company}
                              checked={filterCompanies.includes(item.company)}
                              onChange={handleCompanyFilter}
                              name="gilad"
                            />
                          }
                          label={item.company}
                        />
                      ))}
                    </FormWrapper>
                  </FilterInnerContainer>
                </FilterMain>
              </MenuItem>
            </Menu>
          </div>
        </SortAndFilter>
      ) : null}

{path === "/vendor-relations/vendor" && (
      <Vendor filteredData={filteredData} setPage={setPage} setFilterChanged={setFilterChanged} page={page} rowsPerPage={rowsPerPage}  handleChangeRowsPerPage={handleChangeRowsPerPage}  filterChanged={filterChanged}/>
    )}
    {path === "/vendor-relations/service" && (
      <ServiceComanies filteredData={filteredData} setPage={setPage} setFilterChanged={setFilterChanged} page={page} rowsPerPage={rowsPerPage}  handleChangeRowsPerPage={handleChangeRowsPerPage} filterChanged={filterChanged}/>
    )}
    </Wrapper>
  );
}

export default VendorRelations;
