import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Divider, Stack, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import DoneIcon from '@mui/icons-material/Done';
import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
const style = {
  bgcolor: "background.paper",
};

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "white",
  border: "0px solid #000",

  boxShadow: 24,
  p: 4,
  height: "330px",
}));

export default function SuccessPopUp({ handleCloseUser, addedStatus, toggle }) {
  
  return (
    <div style={{}}>
      <Modal
        keepMounted
        open={addedStatus?.message !== undefined && toggle}
        // open={true}
        onClose={handleCloseUser}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}>
            <IconButton onClick={handleCloseUser}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
            }}>
            {addedStatus?.message === "Created" && (
              <IconButton style={{ color: "rgba(0, 201, 32, 1)" }}>
                <DoneIcon sx={{fontSize:'50px'
                }} />
              </IconButton>
            )}

            {addedStatus?.success === false && (
              <IconButton style={{ color: "#FFA500" }}>
                <ReportProblemIcon sx={{fontSize:'50px'}} />
              </IconButton>
            )}
            {addedStatus?.success === false ? (
              <>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  Error - User already exists
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  Successful!
                </Typography>
              </>
            )}

            {addedStatus?.success ? (
              <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                User Added Successfully. Enter the user's <br />
                mobile number as login password.
              </Typography>
            ) : (
              <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                {addedStatus?.message}
              </Typography>
            )}

            <Stack sx={{ width: "90%" }}>
              <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
            </Stack>

            <Button
              variant="contained"
              onClick={handleCloseUser}
              sx={{
                borderRadius: "35px",

                textTransform: "none",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}>
              Got it
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </div>
  );
}
