import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Menu, MenuItem, Select, Stack, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import StopIcon from '@mui/icons-material/Stop';
import { centerImage } from "highcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  calender,
  departmentUserDetails,
} from "../redux/nbmData/action";
const TableCells = styled(TableCell)(({ theme }) => ({
  textDecoration: "none",
  borderBottom: "none",

  padding: "10px",
  fontWeight: 500,
  whiteSpace: "wrap",
  wordWrap: "break-word",
  maxWidth: "60px",
}));
const FormInput = styled(TextField)(({ theme }) => ({
  width: "150px",
  bgcolor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
    bgcolor: "rgba(244, 246, 246, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover": {
    backgroundColor: theme.palette.background.paper, // Set hover background color
  },
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));


const localizer = momentLocalizer(moment);

const EventCalendar = () => {
  const dispatch = useDispatch();
  const userData = useSelector((store) => store.data.departmentUserList);

  const calenderData = useSelector((store) => store.data?.calenderData);

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  
  const [selectedDate, setSelectedDate] = useState(null);
  const [assetsForDate, setAssetsForDate] = useState([]);
  const [nextDueDate, setNextDueDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  console.log("selectedMonth", selectedMonth);
  console.log("nextDueDate", nextDueDate);

  const [selectedYear, setSelectedYear] = useState(moment().year());
  console.log("selectedYear", selectedYear);
  const [getDetail, setGetDetail] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const initialSelectedUsers = {};
  const [selectedUsers, setSelectedUsers] = useState(initialSelectedUsers);

  const [selectedUserDetail, setSelectedUserDetail] = useState({});

  const [getId, setGetId] = useState("");

  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const handleCloses = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (getDetail && getDetail.department_id) {
      let data = {
        adminid: userIds.userid,
        hosp_id: userIds.hospid,
        branchid: userBranch.id,
        departid: getDetail.department_id,
      };
      dispatch(departmentUserDetails(data));
    }
  }, [dispatch, getDetail]);

  const handleSelectChange = (event, asset_id) => {
    const { value } = event.target;

    const selectedUserObject = userData?.find(
      (user) => user.userid === value.userid
    );

    setSelectedUserDetail(selectedUserObject);

    setSelectedUsers((prevUsers) => ({
      ...prevUsers,
      [getDetail.asset_id]: selectedUserObject || value,
    }));
  };

  const [day, month, year] =
    getId && getId.calib_due_date.split("-").map(Number);

  const calibrationExpDate = new Date(year, month - 1, day);

  calibrationExpDate.setMonth(calibrationExpDate.getMonth() + 1);

  const handleButtonClick = (data) => {
    setGetDetail(data);
  };
  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,

      month: selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1,
      depart_ids: userAccess.departmentids,
      branchid: userBranch?.id,
      year: selectedYear,
    };
    dispatch(calender(data));
  }, [dispatch, selectedMonth, selectedYear]);

  const handleChangeMonth = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  useEffect(() => {
    const currentDate = moment().toDate();
    const sortedDates = Object.keys(assetsByDate).sort((a, b) =>
      moment(a, "DD-MM-YYYY").diff(moment(b, "DD-MM-YYYY"))
    );

    console.log("sortedDates11", sortedDates);

    const nextDueDate = sortedDates?.find(
      (date) => moment(date, "DD-MM-YYYY").toDate() > currentDate
    );
    console.log("nextDueDate1", nextDueDate);

    setNextDueDate(nextDueDate);
  }, [selectedMonth, selectedYear, calenderData]);
  console.log("nextDueDate", nextDueDate);
  const events = [];
  const assetsByDate = {};

  calenderData?.warranties?.forEach((warranty) => {
    const date = moment(warranty.warranty_exp_date, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    if (!assetsByDate[date]) assetsByDate[date] = [];
    assetsByDate[date].push(warranty);
  });

  calenderData?.calibs?.forEach((calib) => {
    const date = moment(calib.calib_due_date, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    if (!assetsByDate[date]) assetsByDate[date] = [];
    assetsByDate[date].push(calib);
  });

  calenderData?.amc_cmcs?.forEach((amcCmc) => {
    const date = moment(amcCmc.cmc_amc_due_date, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    if (!assetsByDate[date]) assetsByDate[date] = [];
    assetsByDate[date].push(amcCmc);
  });

  Object.keys(assetsByDate).forEach((date) => {
    const warrantiesCount = assetsByDate[date].filter((asset) =>
      asset.hasOwnProperty("warranty_exp_date")
    ).length;
    const calibsCount = assetsByDate[date].filter((asset) =>
      asset.hasOwnProperty("calib_due_date")
    ).length;
    const amcCmcsCount = assetsByDate[date].filter((asset) =>
      asset.hasOwnProperty("cmc_amc_due_date")
    ).length;

    const eventHeight = 7;
    const totalEvents = warrantiesCount + calibsCount + amcCmcsCount;

    let eventTitle = "";
    if (totalEvents > 8) eventTitle = "3+ more";

    if (warrantiesCount > 0) {
      events.push({
        start: moment(date).toDate(),
        end: moment(date).toDate(),
        color: "rgba(70, 144, 255, 1)",
        details: assetsByDate[date].filter((asset) =>
          asset.hasOwnProperty("warranty_exp_date")
        ),
        style: { height: eventHeight },
        title: eventTitle,
      });
    }

    if (calibsCount > 0) {
      events.push({
        start: moment(date).toDate(),
        end: moment(date).toDate(),
        color: "rgba(77, 220, 224, 1)",
        details: assetsByDate[date].filter((asset) =>
          asset.hasOwnProperty("calib_due_date")
        ),
        style: { height: eventHeight },
        title: eventTitle,
      });
    }

    if (amcCmcsCount > 0) {
      events.push({
        start: moment(date).toDate(),
        end: moment(date).toDate(),
        color: "rgba(35, 82, 117, 1)",
        details: assetsByDate[date].filter((asset) =>
          asset.hasOwnProperty("cmc_amc_due_date")
        ),
        style: { height: eventHeight },
        title: eventTitle,
      });
    }
  });

  const handleSelectEvent = (event) => {
   
    const formattedDate = new Date(event.start).toISOString().split('T')[0];
    // console.log("------------------>>>>>>",formattedDate)
    setSelectedDate(formattedDate);
    // console.log("==============>",event.details)
    
    setAssetsForDate(event.details);
  };


  // onClick={handleSubmit(this.props.first)}


  useEffect(() => {
    if (getDetail && getDetail.department_id) {
      let data = {
        adminid: ids.userid,
        hosp_id: ids.hospid,
        branchid: userBranch.id,
        departid: getDetail.department_id,
      };
      dispatch(departmentUserDetails(data));
    }
  }, [dispatch, getDetail]);
  const handleClicks = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleSubmit = (event) => {
    
  };




  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        gap: "20px",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "70%",
          gap: "10px",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "white",
            padding: "10px",
          }}>
          <Select
            value={selectedMonth}
            onChange={handleChangeMonth}
            native
            variant="standard">
            {moment.months()?.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </Select>
          <Button>Task</Button>
        </div>

        <div className="calendar-box" style={{ width: "100%", height: "100%" }}>
          <Calendar
            style={{ backgroundColor: "white" }}
            localizer={localizer}
            events={events}
            showAllEvents
            startAccessor="start"
            endAccessor="end"
            components={{
              toolbar: () => null,
            }}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: event.color,
                height: event.style?.height,
              },
            })}
            view={"month"}
            date={moment().set("month", selectedMonth).toDate()}
          />
        </div>
      </div>

      <div
        className="details-box"
        style={{
          width: "400px",

          background: "white",
          overflow: "auto",
          textAlign: "start",
        }}>
        <>
          <Stack sx={{ padding: "20px", gap: "20px" }}>
            <Stack
              sx={{ justifyContent: "space-between", flexDirection: "row" }}>
              <Label>Task</Label>
            </Stack>
            <Divider />

            <Stack
              sx={{ justifyContent: "space-between", flexDirection: "row" }}>
              <Stack
                sx={{
                  justifyContent: centerImage,
                  alignItems: "center",
                  gap: "5px",
                  flexDirection: "row",
                }}>
                <StopIcon style={{ color: "rgba(77, 220, 224, 1)" }} />
                Calibrations
              </Stack>
              <Stack
                sx={{
                  justifyContent: centerImage,
                  alignItems: "center",
                  gap: "5px",
                  flexDirection: "row",
                }}>
                <StopIcon style={{ color: "rgba(70, 144, 255, 1)" }} />
                Warranty
              </Stack>
              <Stack
                sx={{
                  justifyContent: centerImage,
                  alignItems: "center",
                  gap: "5px",
                  flexDirection: "row",
                }}>
                <StopIcon style={{ color: "rgba(35, 82, 117, 1)" }} />
                AMC/CMC
              </Stack>
            </Stack>
          </Stack>

          {!selectedDate && nextDueDate && (
            <Stack sx={{ padding: "20px" }}>
              <Stack>
                <h3 style={{ color: "rgba(70, 144, 255, 1)" }}>
                  {moment(nextDueDate).format("MMMM Do YYYY")}
                </h3>
                <Divider />
              </Stack>

              {assetsByDate[nextDueDate]?.map((asset, index) => (
                <Stack
                  key={index}
                  style={{
                    textAlign: "start",
                    gap: "10px",
                  }}>
                  <h4 style={{ color: "rgba(70, 144, 255, 1)" }}>
                    {asset.hasOwnProperty("warranty_exp_date")
                      ? "Warranty Asset"
                      : asset.hasOwnProperty("calib_due_date")
                      ? "Calibration Asset"
                      : "AMC/CMC Asset"}
                  </h4>

                  <>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              Asset Name
                            </TableCells>

                            <TableCells sx={{}}> {asset.asset_name}</TableCells>
                          </TableRow>
                          <TableRow>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              Asset code
                            </TableCells>

                            <TableCells sx={{}}> {asset.asset_code}</TableCells>
                          </TableRow>
                          <TableRow>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              Department
                            </TableCells>

                            <TableCells sx={{}}> {asset.department}</TableCells>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Stack sx={{ gap: "20px" }}>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          // alignItems: "center",
                        }}>
                        <FormInput
                          size="small"
                          label="Extension"
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />

                        <Button
                          variant="outlined"
                          sx={{
                            width: "150px",
                            bgcolor: "rgba(244, 246, 246, 1)",
                            color: "black",
                            border: "1px solid rgba(244, 246, 246, 1)",
                            textDecoration: "underline",
                          }}
                          key={`button_${asset.asset_id}`}
                          onClick={(event) => {
                            handleButtonClick(asset);
                            handleClicks(event);
                          }}>
                          <Typography>
                            {asset.assigned_to !== "NA" &&
                            asset.task_status !== "Completed" &&
                            selectedUsers[asset.asset_id]?.username ===
                              undefined
                              ? asset.assigned_to
                              : selectedUsers[asset.asset_id]?.username !==
                                undefined
                              ? selectedUsers[asset.asset_id]?.username
                              : "Assign"}
                          </Typography>
                        </Button>
                      </Stack>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}>
                        <Button
                          onClick={handleClicks}
                          variant="contained"
                          sx={{ width: "150px", borderRadius: "22px" }}>
                          Request
                        </Button>
                        {/* <Button
                          variant="contained"
                          sx={{ width: "150px", borderRadius: "22px" }}>
                          Complete
                        </Button> */}
                      </Stack>
                    </Stack>
                  </>
                </Stack>
              ))}
            </Stack>
          )}
        </>
        <>
          {selectedDate && (
            <Stack sx={{ padding: "20px" }}>
              <Stack style={{ color: "rgba(70, 144, 255, 1)" }}>
                <h3>{moment(selectedDate).format("MMMM Do YYYY")}</h3>
                <Divider />
              </Stack>

              {assetsForDate?.map((asset, index) => (
                <div key={index} style={{ textAlign: "start" }}>
                  <h4 style={{ color: "rgba(70, 144, 255, 1)" }}>
                    {asset.hasOwnProperty("warranty_exp_date")
                      ? "Warranty Asset"
                      : asset.hasOwnProperty("calib_due_date")
                      ? "Calibration Asset"
                      : "AMC/CMC Asset"}
                  </h4>
                  <>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              Asset Name
                            </TableCells>

                            <TableCells sx={{}}> {asset.asset_name}</TableCells>
                          </TableRow>
                          <TableRow>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              Asset code
                            </TableCells>

                            <TableCells sx={{}}> {asset.asset_code}</TableCells>
                          </TableRow>
                          <TableRow>
                            <TableCells
                              component="th"
                              scope="row"
                              sx={{ color: "rgba(27, 37, 53, 1)" }}>
                              Department
                            </TableCells>

                            <TableCells sx={{}}> {asset.department}</TableCells>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Stack sx={{ gap: "20px" }}>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          // alignItems: "center",
                        }}>
                        <FormInput
                          size="small"
                          label="Extension"
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                        />

                        <Button
                          variant="outlined"
                          sx={{
                            width: "150px",
                            bgcolor: "rgba(244, 246, 246, 1)",
                            color: "black",
                            border: "1px solid rgba(244, 246, 246, 1)",
                            textDecoration: "underline",
                          }}
                          key={`button_${asset.asset_id}`}
                          onClick={(event) => {
                            handleButtonClick(asset);
                            handleClicks(event);
                          }}>
                          <Typography>
                            {asset.assigned_to !== "NA" &&
                            asset.task_status !== "Completed" &&
                            selectedUsers[asset.asset_id]?.username ===
                              undefined
                              ? asset.assigned_to
                              : selectedUsers[asset.asset_id]?.username !==
                                undefined
                              ? selectedUsers[asset.asset_id]?.username
                              : "Assign"}
                          </Typography>
                        </Button>
                       
                        {userData && (
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloses}>
                            {asset.assigned_to === "NA" &&
                            selectedUsers[asset.asset_id] ? (
                              <MenuItem disabled>
                                {selectedUsers[asset.asset_id].username}
                              </MenuItem>
                            ) : (
                              <MenuItem disabled></MenuItem>
                            )}

                            {userData?.map((user) => (
                              <MenuItem
                                key={user.userid}
                                onClick={() => {
                                  handleSelectChange(
                                    { target: { value: user } },
                                    asset.asset_id
                                  );
                                  handleCloses();
                                }}
                                disabled={asset.assigned_to === user.username}
                                sx={{
                                  "&:hover": { backgroundColor: "#d0d0d0" },
                                  cursor:
                                    asset.assigned_to === user.username
                                      ? "not-allowed"
                                      : "pointer",
                                }}>
                                {user.username}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      </Stack>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}>
                        <Button onClick={()=>handleSubmit()}
                          variant="contained"
                          sx={{ width: "150px", borderRadius: "22px" }}>
                          Request
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                </div>
              ))}
            </Stack>
          )}
        </>
      </div>
    </div>
  );
};

export default EventCalendar;
