import React, { useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  Dialog,
  MenuItem,
  Select,
  styled,
  FormHelperText,
  useTheme,
  useMediaQuery,
  Menu,
} from "@mui/material";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CalibrationReq, departmentUserDetails } from "../redux/data/action";

import CloseIcon from "@mui/icons-material/Close";

import WarrantyModal from "./WarrantyModal";

const headingCellStyles = {
  color: "#1746A2",

  whiteSpace: "nowrap",
};

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const CalibrationRequestModal = ({ open, setopen, data }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [currentUser, setCurrentUser] = useState("");
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let text = "Calibration requested";
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const calibrationStatusData = useSelector(
    (store) => store.data?.calibrationReq
  );

  const userData = useSelector((store) => store.data.departmentUserList);
  const dispatch = useDispatch();
  const [year, setYear] = useState("");
  const [yearError, setYearError] = useState("");
  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setToggle(false);
    handleClose();
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (user) => {
    handleMenuClose();
    // Additional logic when a user is selected
    setCurrentUser(user);
  };

  const handleYear = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setYear(value);
    if (regex.test(value) || value === "") {
      setYear(value);
      setYearError("");
    }
    if (value === "") {
      setYearError("Please fill the field");
    }
  };

  const handleSelectChange = (e) => {
    setCurrentUser(e.target.value);
  };

  const handleClick = () => {
    if (year !== "" && yearError === "") {
      let dataToSend = {
        hospid: userIds.hospid,
        userid: userIds.userid,
        hospname: userIds.hospname,
        hospcity: userIds.city,
        assetid: data?.assetid,
        assetname: data?.name,
        req_id: currentUser === "" ? userIds.userid : currentUser?.userid,
        brand: data?.brand,
        depart: data?.department,
        dop: data?.dop,
        expdate: data?.calibduedate,
        req_years: year,
        reqname: currentUser === "" ? userIds.username : currentUser?.username,
        reqemail: currentUser === "" ? userIds.email : currentUser?.email,
        reqphone: currentUser === "" ? userIds.mobile : currentUser?.mobile,
        amc_cmc: "NA",
        branchid: userBranch?.id,
        depart_id: data.department_id,
        branchname: userBranch.branch,
      };

      dispatch(CalibrationReq(dataToSend));

      setYear("");
      setCurrentUser("");

      setToggle(true);
    }
    if (year === "") {
      setYearError("Please fill the field");
    }
  };

  const handleClose = () => {
    setopen(false);
    setYear("");
    setCurrentUser("");
    setYearError("");
  };
  useEffect(() => {
    if (data && data.department_id) {
      let datas = {
        adminid: userIds.userid,
        hosp_id: userIds.hospid,
        branchid: userBranch.id,
        departid: data.department_id,
      };
      dispatch(departmentUserDetails(datas));
    }
  }, [dispatch, data]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{
        borderRadius: "15px",
        "& .MuiDialog-paper": {
          borderRadius: "15px",
        },
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "15px 15px 0px 0px",
          padding: "8px",
          backgroundColor: "#6100FF",
        }}>
        <Typography
          variant={isMobileScreen ? "" : "h4"}
          sx={{
            color: "white",
            backgroundColor: "#6100FF",
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          Calibration
        </Typography>
        {isMobile ? (
          <Typography
            onClick={handleClose}
            variant="body2"
            sx={{
              color: "white",
              backgroundColor: "#6100FF",
              height: "100%",
              cursor: "pointer",
              paddingRight: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <CloseIcon />
          </Typography>
        ) : (
          <Typography
            onClick={handleClose}
            variant="body2"
            sx={{
              color: "white",
              backgroundColor: "#6100FF",
              height: "100%",
              cursor: "pointer",
            }}>
            Close
          </Typography>
        )}
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",

            maxHeight: 540,
          }}>
          <Table sx={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow sx={{ bgcolor: "#EFF5FE" }}>
                <TableCell style={headingCellStyles}>Asset Name</TableCell>
                <TableCell style={headingCellStyles}>Asset Code</TableCell>
                <TableCell style={headingCellStyles}>Department</TableCell>
                <TableCell
                  style={headingCellStyles}
                  sx={{ textAlign: "center" }}>
                  {" "}
                  Duration{" "}
                </TableCell>
                <TableCell
                  style={headingCellStyles}
                  sx={{ textAlign: "center" }}>
                  {" "}
                  Assign{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={"index"}>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.assetcode}</TableCell>
                <TableCell>{data.department}</TableCell>
                <TableCell>
                  {data.req_ststus !== "Requested" && (
                    <TextField
                      type="number"
                      value={year}
                      placeholder="Enter year"
                      size="small"
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      onChange={handleYear}
                      style={{ width: "130px", textAlign: "center" }}
                    />
                  )}
                  <HelperText>{yearError}</HelperText>
                </TableCell>

                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleButtonClick}
                    style={{
                      width: "150px",
                      color: "#1746A2",
                      border: "none",
                      textDecoration: "underline",
                      textTransform: "none",
                    }}>
                    {currentUser ? currentUser.username : "Select User"}
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}>
                    <MenuItem value="" disabled>
                      <Typography>Select User</Typography>
                    </MenuItem>

                    {userData?.map((user) => (
                      <MenuItem
                        key={user.userid}
                        onClick={() => handleMenuItemClick(user)}>
                        {user.username}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>

                <TableCell>
                  {
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      sx={{
                        bgcolor:
                          data.req_ststus === "Requested" ? "green" : "#FF4B4B",
                        textTransform: "none",
                      }}>
                      {data.req_ststus === "Requested"
                        ? "Requested"
                        : "Request"}
                    </Button>
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <WarrantyModal
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        response={calibrationStatusData}
        text={text}
        toggle={toggle}
        setClose={setOpenModal}
      />
    </Dialog>
  );
};

export default CalibrationRequestModal;
